import cssStyles from "./ArrayObjectList.module.css"
import Logger from "../../utils/Logger";
import {NODE_FILL_COLORS} from "../../utils/NodeTypeColors";

const LOGGER = new Logger("ArrayObjectList")

export function ArrayObjectList({listName="unknown", objectList}) {
    if (!objectList) {
        LOGGER.warn("objectList is null for listName: ", listName)
        return <></>
    }
    return (<ol>
        {objectList.map((object, index) => {
            if (!object) {
                LOGGER.warn("object passed is null for listName: ", listName)
                return <></>
            }
            const color = NODE_FILL_COLORS[object.type]
            return (
                <li key={listName + "-" + index} style={{color:color}} className={cssStyles.objectListName}>{object}</li>
            )
        })}
    </ol>)
}
