//with inspiration from https://www.sohamkamani.com/javascript/enums/

import Logger from "../utils/Logger";
import {array, date, mixed, object, string} from "yup";


import ActorsIcon from '@mui/icons-material/Person';
import ActorActivityIcon from '@mui/icons-material/AssignmentInd';
import ApplicationsIcon from '@mui/icons-material/Apps';
import ArchitectureBuildingBlocksIcon from '@mui/icons-material/Widgets';
import AccountsIcon from '@mui/icons-material/Person';
import BusinessProcessesIcon from '@mui/icons-material/Hub';
import CapabilitiesIcon from '@mui/icons-material/AddBusiness';
import CapabilityAnalysisIcon from '@mui/icons-material/QueryStats';
import DataExchangesIcon from '@mui/icons-material/MultipleStop';
import DataFlowsIcon from '@mui/icons-material/Waves';
import DataObjectsIcon from '@mui/icons-material/Schema';
import DesignDecisionIcon from '@mui/icons-material/Grading';
import DomainsIcon from '@mui/icons-material/Domain';
import FoldersIcon from '@mui/icons-material/Folder';
import FunctionalitiesIcon from '@mui/icons-material/AutoStories';
import LayoutsIcon from '@mui/icons-material/ViewQuilt';
import MaturityModelAnalysisIcon from '@mui/icons-material/Assessment';
import MiddlewareIcon from '@mui/icons-material/SettingsEthernet';
import PrinciplesIcon from '@mui/icons-material/Verified';
import ReferenceArchitecturesIcon from '@mui/icons-material/Map';
import ScenariosIcon from '@mui/icons-material/Theaters';
import SnapshotsIcon from '@mui/icons-material/AddAPhoto';
import TalsIcon from '@mui/icons-material/AdsClick';
import UnknownIcon from '@mui/icons-material/QuestionMark';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ViewsIcon from '@mui/icons-material/Preview';

const LOGGER = new Logger("Constants")

export const WORKSPACE_AUTHENTICATION_HEADER = "kenchiku-workspace-id"

export const TIMELINE_COLLECTION_NAME = "GlobalTimeline"

export const DATE_FORMAT_DAYJS = "YYYY/MM/DD"

//Node Type Related

const ROOT_OBJECT_FOLDERS_LABELS = {
    "_actors":"Actors",
    "_actor_activities":"Actor Activities",
    "_application_views":"Application Views",
    "_applications":"Applications",
    "_architecture_building_blocks":"Architecture Building Blocks",
    "_business_processes":"Business Processes",
    "_capabilities":"Capabilities",
    "_capability_analysis":"Capability Analysis",
    "_data_exchanges":"Data Exchanges",
    "_data_flows":"Data Flows",
    "_data_objects":"Data Objects",
    "_design_decisions":"Design Decisions",
    "_domains":"Domains",
    "_functionalities":"Functionalities",
    "_maturity_model_analysis":"Maturity Model Analysis",
    "_middlewares":"Middlewares",
    "_principles":"Principles",
    "_scenarios":"Scenarios",
    "_tal_views":"Tal Views",
}

export const WorkspaceRoles = {
    Admin: "admin",
    Editor: "editor",
    Reviewer: "reviewer",
}

export const MATURITY_MODEL_LIST = [
    {
        id: "Napier",
        name: "Napier",
        description: "The Napier Maturity Model is a maturity model for the adoption of Anti-Money Laundering (AML).",
    }
]

export const NodeTypeName = {
    Actor:"actor",
    ActorActivity: "actor_activity",
    Application:"application",
    ApplicationView:"application_view",
    ArchitectureBuildingBlock:"architecture_building_block",
    BusinessProcess:"business_process",
    Capability:"capability",
    CapabilityAnalysis:"capability_analysis",
    DataExchange:"data_exchange",
    DataFlow:"data_flow",
    DataObject:"data_object",
    DesignDecision:"design_decision",
    Domain:"domain",
    Folder:"folder",
    Functionality:"functionality",
    Layout:"layout",
    MaturityModelAnalysis:"maturity_model_analysis",
    Middleware:"middleware",
    Principle:"principle",
    ReferenceArchitecture:"reference_architecture",
    Scenario:"scenario",
    Snapshot:"snapshot",
    Subscription: "subscription",
    Tal:"tal",
    UserMap:"user_map",
    UserProfile:"user_profile",
    Workspace:"workspace",
}

export const NodeTypeProperty = {
    Actor:"actor",
    ActorActivity: "actoractivity",
    Application:"application",
    ApplicationView:"applicationview",
    ArchitectureBuildingBlock:"architecturebuildingblock",
    BusinessProcess:"businessprocess",
    Capability:"capability",
    CapabilityAnalysis:"capabilityanalysis",
    DataExchange:"dataexchange",
    DataFlow:"dataflow",
    DataObject:"dataobject",
    DesignDecision:"designdecision",
    Domain:"domain",
    Folder:"folder",
    Functionality:"functionality",
    Layout:"layout",
    MaturityModelAnalysis:"maturitymodelanalysis",
    Middleware:"middleware",
    Principle:"principle",
    ReferenceArchitecture:"referencearchitecture",
    Scenario:"scenario",
    Snapshot:"snapshot",
    Subscription: "subscription",
    Tal:"tal",
    Unknown:"unknown",
    UserMap:"usermap",
    UserProfile:"userprofile",
    Workspace:"workspace",
}

export const CollectionNames = Object.freeze({
    ActorsCollection: Symbol("Actors"),
    ActorActivitiesCollection: Symbol("ActorActivities"),
    ApplicationsCollection: Symbol("Applications"),
    ApplicationViewsCollection: Symbol("Application Views"),
    ArchitectureBuildingBlocksCollection: Symbol("ArchitectureBuildingBlocks"),
    BusinessProcessesCollection: Symbol("BusinessProcesses"),
    CapabilitiesCollection: Symbol("Capabilities"),
    CapabilityAnalysisCollection: Symbol("CapabilityAnalysis"),
    DataExchangesCollection: Symbol("DataExchanges"),
    DataFlowsCollection: Symbol("DataFlows"),
    DataObjectsCollection: Symbol("DataObjects"),
    DesignDecisionsCollection: Symbol("DesignDecisions"),
    DomainsCollection: Symbol("Domains"),
    FoldersCollection: Symbol("Folders"),
    FunctionalitiesCollection: Symbol("Functionalities"),
    KenchikuConfigCollection: Symbol("KenchikuConfigCollection"),
    LayoutsCollection: Symbol("Layouts"),
    ModelObjectsCollection: Symbol("ModelObjects"),
    MaturityModelAnalysisCollection: Symbol("MaturityModelAnalysis"),
    MiddlewaresCollection: Symbol("Middlewares"),
    PrinciplesCollection: Symbol("Principles"),
    ReferenceArchitectureCollection: Symbol("ReferenceArchitectures"),
    ScenariosCollection: Symbol("Scenarios"),
    SnapshotsCollection: Symbol("Snapshots"),
    SubscriptionsCollection: Symbol("Subscriptions"),
    TalsCollection: Symbol("Tals"),
    UnknownCollection: Symbol("Unknown"),
    UserMapsCollection: Symbol("UserMaps"),
    UserProfilesCollection: Symbol("UserProfiles"),
    WorkspacesCollection: Symbol("Workspaces"),
})

export const NodeFolderRootIds = Object.freeze({
    ActorRootId: Symbol("_actors"),
    ActorActivityRootId: Symbol("_actor_activities"),
    ApplicationRootId: Symbol("_applications"),
    ApplicationViewRootId: Symbol("_application_views"),
    ArchitectureBuildingBlockRootId: Symbol("_architecture_building_blocks"),
    BusinessProcessRootId: Symbol("_business_processes"),
    CapabilityRootId: Symbol("_capabilities"),
    CapabilityAnalysisRootId: Symbol("_capability_analysis"),
    DataExchangeRootId: Symbol("_data_exchanges"),
    DataFlowRootId: Symbol("_data_flows"),
    DataObjectRootId: Symbol("_data_objects"),
    DesignDecisionRootId: Symbol("_design_decisions"),
    DomainRootId: Symbol("_domains"),
    FolderRootId: Symbol("_folders"),
    FunctionalityRootId: Symbol("_functionalities"),
    LayoutRootId: Symbol("_layouts"),
    MaturityModelAnalysisRootId: Symbol("_maturity_model_analysis"),
    MiddlewareRootId: Symbol("_middlewares"),
    PrincipleRootId: Symbol("_principles"),
    ReferenceArchitectureRootId: Symbol("_reference_architectures"),
    ScenarioRootId: Symbol("_scenarios"),
    SnapshotRootId: Symbol("_snapshots"),
    TalRootId: Symbol("_tals"),
    UnknownRootId: Symbol(""),
    UserMapRootId: Symbol("_user_maps"),
    UserProfileRootId: Symbol("_user_profiles"),
    WorkspaceRootId: Symbol("_workspaces"),
})

export const ROOT_OBJECT_FOLDERS = Object.keys(NodeFolderRootIds).map((k)=>NodeFolderRootIds[k].description)

export const NodeType = Object.freeze({
    Actor: Symbol("actor"),
    ActorActivity: Symbol("actor_activity"),
    Application: Symbol("application"),
    ApplicationView: Symbol("application_view"),
    ArchitectureBuildingBlock: Symbol("architecture_building_block"),
    BusinessProcess: Symbol("business_process"),
    Capability: Symbol("capability"),
    CapabilityAnalysis: Symbol("capability_analysis"),
    DataExchange: Symbol("data_exchange"),
    DataFlow: Symbol("data_flow"),
    DataObject: Symbol("data_object"),
    DesignDecision: Symbol("design_decision"),
    Domain: Symbol("domain"),
    Folder: Symbol("folder"),
    Functionality: Symbol("functionality"),
    Layout: Symbol("layout"),
    MaturityModelAnalysis: Symbol("maturity_model_analysis"),
    Middleware: Symbol("middleware"),
    Principle: Symbol("principle"),
    ReferenceArchitecture: Symbol("reference_architecture"),
    Scenario: Symbol("scenario"),
    Snapshot: Symbol("snapshot"),
    UserMap: Symbol("user_map"),
    UserProfile: Symbol("user_profile"),
    Tal: Symbol("tal"),
    Workspace: Symbol("workspace"),
})

//TODO implement for all types...
export const NODETYPE_SCHEMAS = {
    workspace: object().shape({
        name: string().required(),
        description: string().required(),
        parentId: string().required().default(NodeFolderRootIds.WorkspaceRootId.description),
        users: array().of(object().shape({
            user_id: string().required(),
            role: string().required(),
        })),
        //Kudos to https://stackoverflow.com/a/67962539
        invitedUsers: array().required().of(object().shape({
            user_id: string().when('email', {
                is: (email) => !email || email.length === 0,
                then: string()
                    .required('Either email or user_id must be provided'),
            }),
            email: string().when('user_id', {
                is: (user_id) => !user_id || user_id.length === 0,
                then: string()
                    .required('Either email or user_id must be provided'),
            }),
            role: string().required(),
        }, ["email", "user_id"])),
    }),
    businessProcess: object().shape({
        id: string().required(),
        name: string().required(),
        description: string().required(),
        parentId: string().required().default(NodeFolderRootIds.BusinessProcessRootId.description),
        supportedCapabilityIds: array().of(string()).default([]),
        applicationUsages:array().of(mixed()).default([]),
        exchange: array().of(object().shape({
            steps: array().of(object().shape({
                stepId: string().required(),
                type: string().required().oneOf(["trigger", "task", "decision", "end"]),
                actorId: string().required(),
                applicationId: string(),
                functionalityId: string(),
                nextStepIds: array().of(string()),
            })),
        })),
        createdOn: date().default(() => new Date()),
        updatedOn: date().default(() => new Date()),
    }),
}


export const NODETYPE_FIELDS = {
    Actor: [
        "id",
        "name",
        "parentId",
        "description"
    ],
    ActorActivity: [
        "id",
        "name",
        "parentId",
        "description",
        "actorId",
        "applicationId",
        "businessProcessId",
    ],
    Application: [
        "id",
        "name",
        "parentId",
        //"architectureBuildingBlockIds",
        "description",
        "masterDataObjectIds",
        "confidentiality", "integrity", "availability",
        "rpo", "rto",
        "lifeCycleStage",
        "introductionCost", "yearlyCost",
        "supportedCapabilityIds",
        "supportedRateOfChange",
        "supportingMiddlewareIds",
        "strategicClassification"
    ],
    ApplicationView: [
        "id",
        "name",
        "parentId",
        "description",
        "applicationComponents",
        "graph"
    ],
    ArchitectureBuildingBlock: [
        "id",
        "name",
        "parentId",
        "functionalityIds",
        "aliases",
        "description"
    ],
    BusinessProcess: [
        "id",
        "name",
        "parentId",
        "supportedCapabilityIds",
        "description",
        "activities",
        "graph"
    ],
    Capability: [
        "id",
        "name",
        "parentId",
        "description",
        "tier",
        "level"
    ],
    CapabilityAnalysis: [
        "id",
        "name",
        "parentId",
        "description"
    ],
    DataExchange: [
        "id",
        "name",
        "parentId",
        "dataObjectIds",
        "sourceApplicationId",
        "targetApplicationId",
        "description",
        "supportingMiddlewareIds"
    ],
    DataFlow: [
        "id",
        "name",
        "parentId",
        "description",
        "dataExchangeIds",
        "graph"
    ],
    DataObject: [
        "id",
        "name",
        "parentId",
        "dataOwnerId",
        "description"
    ],
    DesignDecision: [
        "id",
        "name",
        "parentId",
        "description",
        "status",
        "decision",
        "alternatives",
        "rationale",
        "implications",
        "tradeOffs",
        "risksAndMitigations",
        "assumptions",
        "personTakingDecision",
        "dateTaken",
        "references",
        "principleIds",
        "impactedApplicationIds",
        "impactedDataExchangeIds",
    ],
    Domain: [
        "id",
        "name",
        "parentId",
        "description"
    ],
    Folder: [
        "id",
        "name",
        "parentId",
        "description",
        "canBeDeleted"
    ],
    Functionality: [
        "id",
        "name",
        "parentId",
        "description"
    ],
    Layout: [
        "id",
        "name",
        "nodeId",
        "diagram"
    ],
    MaturityModelAnalysis: [
        "id",
        "name",
        "parentId",
        "description",
        "maturityModelId",
        "maturityModelAnalysis"
    ],
    Middleware: [
        "id",
        "name",
        "parentId",
        "description"
    ],
    Principle: [
        "id",
        "name",
        "parentId",
        "description",
        "rationale",
        "implications",
        "dateTaken",
        "status",
    ],
    ReferenceArchitecture: [
        "id",
        "name",
        "parentId",
        "description",
        "decisions",
        "architectureBuildingBlockIds",
        "dataObjectIds"
    ],
    Scenario: [
        "id",
        "name",
        "parentId",
        "description",
        "explanation",
        "advantages",
        "disadvantages",
        "decisions",
        "newApplicationIds",
        "updatedApplicationIds",
        "deletedApplicationIds",
    ],
    Snapshot: [
        "id",
        "name",
        "snapshot",
        "description",
        "createDate"
    ],
    Tal: [
        "id",
        "name",
        "parentId",
        "description",
        "graph"
    ],
    Unknown: [],
    UserProfile: [
        "id",
        "email",
        "paddleCustomerId",
        "netlifyUserId",
        "subscriptionStatus",
        "subscriptionType",
    ],
    Workspace: [
        "id",
        "name",
        "adminId",
        "users"
    ],
    //"user_map": [],
}

export const NODETYPE_LABELS = {
    Actor: "Actor",
    ActorActivity: "Actor Activity",
    Application: "Application",
    ApplicationView: "Application View",
    ArchitectureBuildingBlock: "Architecture Building Block",
    BusinessProcess: "Business Process",
    Capability: "Capability",
    CapabilityAnalysis: "Capability Analysis",
    DataExchange: "Data Exchange",
    DataFlow: "Data Flow",
    DataObject: "Data Object",
    DesignDecision: "Design Decision",
    Domain: "Domain",
    Folder: "Folder",
    Functionality: "Functionality",
    Layout: "Layout",
    MaturityModelAnalysis: "Maturity Model Analysis",
    Middleware: "Middleware",
    Principle: "Principle",
    ReferenceArchitecture: "Reference Architecture",
    Scenario: "Scenario",
    Snapshot: "Snapshot",
    UserMap: "User Map",
    Unknown: "Unknown",
    UserProfile: "User Profile",
    Tal: "TAL",
    Workspace: "Workspace",
}

export const DEFAULT_CAPABILITY_TIER=1
export const DEFAULT_CAPABILITY_LEVEL=1

export const DEFAULT_RPO="unspecified"
export const RPOValues = [
    "unspecified",
    "0 minutes",
    "15 minutes",
    "1 hour",
    "4 hours",
    "8 hours",
    "12 hours",
    "24 hours",
    "5 days",
    "+5 days"
]

export const DEFAULT_RTO="unspecified"

export const RTOValues = [
    "unspecified",
    "15 minutes",
    "1 hour",
    "4 hours",
    "8 hours",
    "12 hours",
    "24 hours",
    "48 hours",
    "72 hours",
    "5 days",
    "+5 days"
]

export const DEFAULT_PrincipleStatus = "Proposed"

export const PrincipleStatusValues = [
    "Proposed",
    "Accepted",
    "Rejected",
    "Deprecated"
]

export const DEFAULT_DesignDecisionStatus = "Proposed"

export const DesignDecisionStatusValues = [
    "Proposed",
    "Accepted",
    "Rejected",
    "Deprecated"
]

export const Confidentiality = [
    "4",
    "3",
    "2",
    "1"
]
export const Integrity = [
    "4",
    "3",
    "2",
    "1"
]
export const Availability = [
    "4",
    "3",
    "2",
    "1"
]

//source of values: https://aws.amazon.com/what-is/application-lifecycle-management/
//those seem to be standard values in the market. Added two more: 1) Analyzed: has been analyzed, but not reetained as a solution, 2) Decommissioned: was decommissioned/Retired
export const DEFAULT_LifeCycleStageValues = "requirements"
export const LifeCycleStageValues = [
    {name:"analyzed", description: "Has been analyzed, but not reetained as a solution."},
    {name:"requirements", description: "In the initial stage, relevant stakeholders define what they require from the application."},
    {name:"development", description: "In the development phase, various teams work together to convert the requirements into a working application."},
    {name:"testing", description:"In the software testing phase, quality analysts assess the application to verify it meets requirements."},
    {name:"deployment", description: "During deployment, the developers release the application to end users. Release management also includes planning how the team deploys software changes over time. Agile development teams automate deployment to speed up the release of new features and updates."},
    {name:"maintenance", description: "In the maintenance phase, support and development teams work together to resolve remaining bugs, plan new updates, and improve the product further."},
    {name:"Decommissioned", description: "Was decommissioned/Retired."},
]

export const DEFAULT_SupportedRateOfChangeValues = "Medium"
export const SupportedRateOfChangeValues = [
    {name:"Slow", description: "Legacy or complex application difficult to change."},
    {name:"Medium", description: "Still manageably fast. People still know how to change it."},
    {name:"Fast", description: "Everyone is confident in changing this application."},
    {name:"Very Fast", description:"It's a piece of cake to modify the application."},
]

export const DEFAULT_StrategicClassificationValues = "Unspecified"
export const StrategicClassificationValues = [
    {name:"Unspecified", description: "No classification specified."},
    {name:"Tolerated", description: "The application is tolerated, but not actively supported."},
    {name:"Invest in", description: "The application is actively supported and invested in."},
    {name:"Migrate", description: "The application is planned to be migrated to another application."},
    {name:"Eliminate", description: "The application is planned for elimination."},
]

export const DEFAULT_ApplicationTypeValues = "business"

export const ApplicationTypeValues = [
    "all",
    "business",
    "customer",
    "it"
]

export const SUBSCRIPTION_TYPES = {
    STANDARD: "STANDARD",
    PRO: "PRO",
    NONE: "NONE"
}

export const SUPPORTED_SUBSCRIPTION_TYPES = [
    "STANDARD",
    "PRO",
    "NONE"
]

export const SUPPORTED_SUBSCRIPTION_STATUSES = [
    "active",
    "canceled",
    "past_due",
    "paused",
    "trialing",
    "unknown"
]

export function getRootFolderLabel(rootFolderId) {
    LOGGER.debug("rootFolderId:", rootFolderId)
    LOGGER.debug("ROOT_OBJECT_FOLDERS_LABELS[rootFolderId]:", ROOT_OBJECT_FOLDERS_LABELS[rootFolderId])
    return ROOT_OBJECT_FOLDERS_LABELS[rootFolderId]
}

export function getNodeTypeFields(nodeType) {
    switch(nodeType) {
        case NodeType.Actor.description:
            return NODETYPE_FIELDS.Actor
        case NodeType.ActorActivity.description:
            return NODETYPE_FIELDS.ActorActivity
        case NodeType.Application.description:
            return NODETYPE_FIELDS.Application
        case NodeType.ApplicationView.description:
            return NODETYPE_FIELDS.ApplicationView
        case NodeType.ArchitectureBuildingBlock.description:
            return NODETYPE_FIELDS.ArchitectureBuildingBlock
        case NodeType.BusinessProcess.description:
            return NODETYPE_FIELDS.BusinessProcess
        case NodeType.Capability.description:
            return NODETYPE_FIELDS.Capability
        case NodeType.DataExchange.description:
            return NODETYPE_FIELDS.DataExchange
        case NodeType.DataFlow.description:
            return NODETYPE_FIELDS.DataFlow
        case NodeType.DataObject.description:
            return NODETYPE_FIELDS.DataObject
        case NodeType.DesignDecision.description:
            return NODETYPE_FIELDS.DesignDecision
        case NodeType.Domain.description:
            return NODETYPE_FIELDS.Domain
        case NodeType.Folder.description:
            return NODETYPE_FIELDS.Folder
        case NodeType.Functionality.description:
            return NODETYPE_FIELDS.Functionality
        case NodeType.MaturityModelAnalysis.description:
            return NODETYPE_FIELDS.MaturityModelAnalysis
        case NodeType.Middleware.description:
            return NODETYPE_FIELDS.Middleware
        case NodeType.Principle.description:
            return NODETYPE_FIELDS.Principle
        case NodeType.ReferenceArchitecture.description:
            return NODETYPE_FIELDS.ReferenceArchitecture
        case NodeType.Scenario.description:
            return NODETYPE_FIELDS.Scenario
        case NodeType.Snapshot.description:
            return NODETYPE_FIELDS.Snapshot
        case NodeType.Tal.description:
            return NODETYPE_FIELDS.Tal
        case NodeType.UserProfile.description:
            return NODETYPE_FIELDS.UserProfile
        case NodeType.Workspace.description:
            return NODETYPE_FIELDS.Workspace
        default:
            LOGGER.debug("couldn't find NODETYPE_FIELDS for nodeType: ", nodeType)
            return NODETYPE_FIELDS.Unknown
    }
}

export function getNodeTypeProperty(nodeTypeString) {
    switch(nodeTypeString) {
        case NodeType.Actor.description:
            return NodeTypeProperty.Actor
        case NodeType.ActorActivity.description:
            return NodeTypeProperty.ActorActivity
        case NodeType.Application.description:
            return NodeTypeProperty.Application
        case NodeType.ApplicationView.description:
            return NodeTypeProperty.ApplicationView
        case NodeType.ArchitectureBuildingBlock.description:
            return NodeTypeProperty.ArchitectureBuildingBlock
        case NodeType.BusinessProcess.description:
            return NodeTypeProperty.BusinessProcess
        case NodeType.Capability.description:
            return NodeTypeProperty.Capability
        case NodeType.DataExchange.description:
            return NodeTypeProperty.DataExchange
        case NodeType.DataFlow.description:
            return NodeTypeProperty.DataFlow
        case NodeType.DataObject.description:
            return NodeTypeProperty.DataObject
        case NodeType.DesignDecision.description:
            return NodeTypeProperty.DesignDecision
        case NodeType.Domain.description:
            return NodeTypeProperty.Domain
        case NodeType.Folder.description:
            return NodeTypeProperty.Folder
        case NodeType.Functionality.description:
            return NodeTypeProperty.Functionality
        case NodeType.MaturityModelAnalysis.description:
            return NodeTypeProperty.MaturityModelAnalysis
        case NodeType.Middleware.description:
            return NodeTypeProperty.Middleware
        case NodeType.Principle.description:
            return NodeTypeProperty.Principle
        case NodeType.ReferenceArchitecture.description:
            return NodeTypeProperty.ReferenceArchitecture
        case NodeType.Scenario.description:
            return NodeTypeProperty.Scenario
        case NodeType.Snapshot.description:
            return NodeTypeProperty.Snapshot
        case NodeType.Tal.description:
            return NodeTypeProperty.Tal
        case NodeType.UserProfile.description:
            return NodeTypeProperty.UserProfile
        case NodeType.Workspace.description:
            return NodeTypeProperty.Workspace
        default:
            LOGGER.debug("couldn't find NodeTypeProperty for nodeType: ", nodeTypeString)
            return NodeTypeProperty.Unknown
    }
}

export function getNodeTypePropertyFromCollection(collectionNameConstant) {
    switch(collectionNameConstant) {
        case CollectionNames.ActorsCollection:
            return NodeTypeProperty.Actor
        case CollectionNames.ActorActivitiesCollection:
            return NodeTypeProperty.ActorActivity
        case CollectionNames.ApplicationsCollection:
            return NodeTypeProperty.Application
        case CollectionNames.ApplicationViewsCollection:
            return NodeTypeProperty.ApplicationView
        case CollectionNames.ArchitectureBuildingBlocksCollection:
            return NodeTypeProperty.ArchitectureBuildingBlock
        case CollectionNames.BusinessProcessesCollection:
            return NodeTypeProperty.BusinessProcess
        case CollectionNames.CapabilitiesCollection:
            return NodeTypeProperty.Capability
        case CollectionNames.CapabilityAnalysisCollection:
            return NodeTypeProperty.CapabilityAnalysis
        case CollectionNames.DataExchangesCollection:
            return NodeTypeProperty.DataExchange
        case CollectionNames.DataFlowsCollection:
            return NodeTypeProperty.DataFlow
        case CollectionNames.DataObjectsCollection:
            return NodeTypeProperty.DataObject
        case CollectionNames.DesignDecisionsCollection:
            return NodeTypeProperty.DesignDecision
        case CollectionNames.DomainsCollection:
            return NodeTypeProperty.Domain
        case CollectionNames.FoldersCollection:
            return NodeTypeProperty.Folder
        case CollectionNames.FunctionalitiesCollection:
            return NodeTypeProperty.Functionality
        case CollectionNames.MaturityModelAnalysisCollection:
            return NodeTypeProperty.MaturityModelAnalysis
        case CollectionNames.MiddlewaresCollection:
            return NodeTypeProperty.Middleware
        case CollectionNames.ReferenceArchitectureCollection:
            return NodeTypeProperty.ReferenceArchitecture
        case CollectionNames.ScenariosCollection:
            return NodeTypeProperty.Scenario
        case CollectionNames.SnapshotsCollection:
            return NodeTypeProperty.Snapshot
        case CollectionNames.TalsCollection:
            return NodeTypeProperty.Tal
        case CollectionNames.UserProfilesCollection:
            return NodeTypeProperty.UserProfile
        case CollectionNames.WorkspacesCollection:
            return NodeTypeProperty.Workspace
        default:
            LOGGER.debug("couldn't find NodeTypeProperty for collectionNameConstant: ", collectionNameConstant)
            return NodeTypeProperty.Unknown
    }
}

export function getNodeCollectionName(nodeType) {
    switch(nodeType) {
        case NodeType.Actor.description:
            return CollectionNames.ActorsCollection
        case NodeType.ActorActivity.description:
            return CollectionNames.ActorActivitiesCollection
        case NodeType.Application.description:
            return CollectionNames.ApplicationsCollection
        case NodeType.ApplicationView.description:
            return CollectionNames.ApplicationViewsCollection
        case NodeType.ArchitectureBuildingBlock.description:
            return CollectionNames.ArchitectureBuildingBlocksCollection
        case NodeType.BusinessProcess.description:
            return CollectionNames.BusinessProcessesCollection
        case NodeType.Capability.description:
            return CollectionNames.CapabilitiesCollection
        case NodeType.DataExchange.description:
            return CollectionNames.DataExchangesCollection
        case NodeType.DataFlow.description:
            return CollectionNames.DataFlowsCollection
        case NodeType.DataObject.description:
            return CollectionNames.DataObjectsCollection
        case NodeType.DesignDecision.description:
            return CollectionNames.DesignDecisionsCollection
        case NodeType.Domain.description:
            return CollectionNames.DomainsCollection
        case NodeType.Folder.description:
            return CollectionNames.FoldersCollection
        case NodeType.Functionality.description:
            return CollectionNames.FunctionalitiesCollection
        case NodeType.MaturityModelAnalysis.description:
            return CollectionNames.MaturityModelAnalysisCollection
        case NodeType.Middleware.description:
            return CollectionNames.MiddlewaresCollection
        case NodeType.Principle.description:
            return CollectionNames.PrinciplesCollection
        case NodeType.ReferenceArchitecture.description:
            return CollectionNames.ReferenceArchitectureCollection
        case NodeType.Scenario.description:
            return CollectionNames.ScenariosCollection
        case NodeType.Snapshot.description:
            return CollectionNames.SnapshotsCollection
        case NodeType.Tal.description:
            return CollectionNames.TalsCollection
        case NodeType.UserProfile.description:
            return CollectionNames.UserProfilesCollection
        case NodeType.Workspace.description:
            return CollectionNames.WorkspacesCollection
        default:
            LOGGER.debug("couldn't find collection name for nodeType: ", nodeType)
            return CollectionNames.UnknownCollection
    }
}

export let capabilityMaturityModelValues = [
    {maturityId: "not_evaluated", maturityName: "0 - Not Evaluated", description: "the starting point for use of a new or undocumented repeat process.", more: "It is characteristic of processes at this level that they are (typically) undocumented and in a state of dynamic change, tending to be driven in an ad hoc, uncontrolled and reactive manner by users or events. This provides a chaotic or unstable environment for the processes. (Example - a surgeon performing a new operation a small number of times - the levels of negative outcome are not known)."},
    {maturityId: "initial", maturityName: "1 - Initial", description: "the starting point for use of a new or undocumented repeat process.", more: "It is characteristic of processes at this level that they are (typically) undocumented and in a state of dynamic change, tending to be driven in an ad hoc, uncontrolled and reactive manner by users or events. This provides a chaotic or unstable environment for the processes. (Example - a surgeon performing a new operation a small number of times - the levels of negative outcome are not known)."},
    {maturityId: "repeatable", maturityName: "2 - Repeatable", description: "the process is at least documented sufficiently such that repeating the same steps may be attempted.", more: "It is characteristic of this level of maturity that some processes are repeatable, possibly with consistent results. Process discipline is unlikely to be rigorous, but where it exists it may help to ensure that existing processes are maintained during times of stress."},
    {maturityId: "defined", maturityName: "3 - Defined", description: "the process is defined/confirmed as a standard business process.", more: "It is characteristic of processes at this level that there are sets of defined and documented standard processes established and subject to some degree of improvement over time. These standard processes are in place. The processes may not have been systematically or repeatedly used - sufficient for the users to become competent or the process to be validated in a range of situations. This could be considered a developmental stage - with use in a wider range of conditions and user competence development the process can develop to next level of maturity."},
    {maturityId: "capable", maturityName: "4 - Capable", description: "the process is quantitatively managed in accordance with agreed-upon metrics.", more: "It is characteristic of processes at this level that, using process metrics, effective achievement of the process objectives can be evidenced across a range of operational conditions. The suitability of the process in multiple environments has been tested and the process refined and adapted. Process users have experienced the process in multiple and varied conditions, and are able to demonstrate competence. The process maturity enables adaptions to particular projects without measurable losses of quality or deviations from specifications. Process Capability is established from this level. (Example - surgeon performing an operation hundreds of times with levels of negative outcome approaching zero)."},
    {maturityId: "efficient", maturityName: "5 - Efficient", description: "process management includes deliberate process optimization/improvement.", more: "It is a characteristic of processes at this level that the focus is on continually improving process performance through both incremental and innovative technological changes/improvements. At maturity level 5, processes are concerned with addressing statistical common causes of process variation and changing the process (for example, to shift the mean of the process performance) to improve process performance. This would be done at the same time as maintaining the likelihood of achieving the established quantitative process-improvement objectives."},
]

export function getNodeTypeIcon(NodeTypeName, size) {
    const fontSize = (size || "medium")
    switch (NodeTypeName) {
        case NodeType.Actor.description:
            return <ActorsIcon fontSize={fontSize}/>
        case NodeType.ActorActivity.description:
            return <ActorActivityIcon fontSize={fontSize}/>
        case NodeType.Application.description:
            return <ApplicationsIcon fontSize={fontSize}/>
        case NodeType.ApplicationView.description:
            return <ViewsIcon fontSize={fontSize}/>
        case NodeType.ArchitectureBuildingBlock.description:
            return <ArchitectureBuildingBlocksIcon fontSize={fontSize}/>
        case NodeType.BusinessProcess.description:
            return <BusinessProcessesIcon fontSize={fontSize}/>
        case NodeType.Capability.description:
            return <CapabilitiesIcon fontSize={fontSize}/>
        case NodeType.CapabilityAnalysis.description:
            return <CapabilityAnalysisIcon fontSize={fontSize}/>
        case NodeType.DataExchange.description:
            return <DataExchangesIcon fontSize={fontSize}/>
        case NodeType.DataFlow.description:
            return <DataFlowsIcon fontSize={fontSize}/>
        case NodeType.DataObject.description:
            return <DataObjectsIcon fontSize={fontSize}/>
        case NodeType.DesignDecision.description:
            return <DesignDecisionIcon fontSize={fontSize}/>
        case NodeType.Domain.description:
            return <DomainsIcon fontSize={fontSize}/>
        case NodeType.Folder.description:
            return <FoldersIcon fontSize={fontSize}/>
        case NodeType.Functionality.description:
            return <FunctionalitiesIcon fontSize={fontSize}/>
        case NodeType.Layout.description:
            return <LayoutsIcon fontSize={fontSize}/>
        case NodeType.MaturityModelAnalysis.description:
            return <MaturityModelAnalysisIcon fontSize={fontSize}/>
        case NodeType.Middleware.description:
            return <MiddlewareIcon fontSize={fontSize}/>
        case NodeType.Principle.description:
            return <PrinciplesIcon fontSize={fontSize}/>
        case NodeType.ReferenceArchitecture.description:
            return <ReferenceArchitecturesIcon fontSize={fontSize}/>
        case NodeType.Scenario.description:
            return <ScenariosIcon fontSize={fontSize}/>
        case NodeType.Snapshot.description:
            return <SnapshotsIcon fontSize={fontSize}/>
        case NodeType.UserMap.description:
            return <UnknownIcon fontSize={fontSize}/>
        case NodeType.Tal.description:
            return <TalsIcon fontSize={fontSize}/>
        case NodeType.UserProfile.description:
            return <AccountCircleIcon fontSize={fontSize}/>
        case NodeType.Workspace.description:
            return <AccountsIcon fontSize={fontSize}/>
        default:
            return <UnknownIcon fontSize={fontSize}/>
    }
}

export function getNodeTypeRootFolderId(NodeTypeName) {
    switch (NodeTypeName) {
        case NodeType.Actor.description:
            return NodeFolderRootIds.ActorRootId
        case NodeType.ActorActivity.description:
            return NodeFolderRootIds.ActorActivityRootId
        case NodeType.Application.description:
            return NodeFolderRootIds.ApplicationRootId
        case NodeType.ApplicationView.description:
            return NodeFolderRootIds.ApplicationViewRootId
        case NodeType.ArchitectureBuildingBlock.description:
            return NodeFolderRootIds.ArchitectureBuildingBlockRootId
        case NodeType.BusinessProcess.description:
            return NodeFolderRootIds.BusinessProcessRootId
        case NodeType.Capability.description:
            return NodeFolderRootIds.CapabilityRootId
        case NodeType.CapabilityAnalysis.description:
            return NodeFolderRootIds.CapabilityAnalysisRootId
        case NodeType.DataFlow.description:
            return NodeFolderRootIds.DataFlowRootId
        case NodeType.DataObject.description:
            return NodeFolderRootIds.DataObjectRootId
        case NodeType.DesignDecision.description:
            return NodeFolderRootIds.DesignDecisionRootId
        case NodeType.Domain.description:
            return NodeFolderRootIds.DomainRootId
        case NodeType.Folder.description:
            return NodeFolderRootIds.FolderRootId
        case NodeType.Functionality.description:
            return NodeFolderRootIds.FunctionalityRootId
        case NodeType.Layout.description:
            return NodeFolderRootIds.LayoutRootId
        case NodeType.MaturityModelAnalysis.description:
            return NodeFolderRootIds.MaturityModelAnalysisRootId
        case NodeType.Middleware.description:
            return NodeFolderRootIds.MiddlewareRootId
        case NodeType.Principle.description:
            return NodeFolderRootIds.PrincipleRootId
        case NodeType.ReferenceArchitecture.description:
            return NodeFolderRootIds.ReferenceArchitectureRootId
        case NodeType.Scenario.description:
            return NodeFolderRootIds.ScenarioRootId
        case NodeType.Snapshot.description:
            return NodeFolderRootIds.SnapshotRootId
        case NodeType.UserMap.description:
            return NodeFolderRootIds.UserMapRootId
        case NodeType.Tal.description:
            return NodeFolderRootIds.TalRootId
        case NodeType.UserProfile.description:
            return NodeFolderRootIds.UserProfileRootId
        case NodeType.Workspace.description:
            return NodeFolderRootIds.WorkspaceRootId
        default:
            return NodeFolderRootIds.UnknownRootId
    }
}


