import Logger from "./Logger";

const LOGGER = new Logger("ModelUtils")

export function addActivity(businessProcess, activity) {
    LOGGER.trace("addActivity: ", activity)
    if (!businessProcess.activities) {
        businessProcess.activities = []
    }
    businessProcess.activities.push(activity)
    return businessProcess
}
export function updateActivity(businessProcess, activity) {
    LOGGER.trace("updateActivity: ", activity)
    if (!businessProcess.activities) {
        LOGGER.warn("no activities found, adding it.")
        businessProcess.activities = [activity]
    } else {
        const index = businessProcess.activities.findIndex(a => a.id === activity.id)
        if (index >= 0) {
            LOGGER.debug("activity found, updating it. for id: ", activity.id)
            businessProcess.activities[index] = activity
        } else {
            LOGGER.warn(`activity not found for id ${activity.id}, adding it.`)
            businessProcess.activities.push(activity)
        }
    }
    return businessProcess
}
export function getActivityById(businessProcess, activityId) {
    LOGGER.trace("getActivityById: ", activityId)
    if (!businessProcess.activities) {
        LOGGER.debug("businessProcess.activities undefined.")
        return null
    }
    const activity = businessProcess.activities.find(activity => activity.id === activityId)
    if (!activity) {
        LOGGER.debug("activity not found in businessProcess.activities: ", businessProcess.activities)
    }
    return activity
}
export function removeActivityById(businessProcess, activityId) {
    LOGGER.trace("removeActivityById: ", activityId)
    if (!businessProcess.activities) {
        return
    }
    businessProcess.activities = businessProcess.activities.filter(a => a.id !== activityId)
}
