import cssStyles from "./RefArchSelector.module.css";
import {Divider, InputBase, ListSubheader, MenuItem, Select} from "@mui/material";
import {styled} from "@mui/material/styles";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));

export default function RefArchSelector(props) {
    const onSelectChange = (newId) => {
        if (props?.optionSelected) {
            props.optionSelected(newId)
        }
    }
    const defaultValue=(props.defaultValue?props.defaultValue:"business-capabilities.dxp")
    return <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        defaultValue={defaultValue}
        value={props?.value}
        label={props?.label}
        onChange={(e)=>onSelectChange(e.target.value)}
        className={cssStyles.selector}
        input={<BootstrapInput/>}
    >

        <ListSubheader className={cssStyles.listSubHeader}>Business Capabilities</ListSubheader>
        <Divider />
            {/*<MenuItem value={"business-capabilities.asset-management"}>Asset Management</MenuItem>*/}
            {/*<MenuItem value={"business-capabilities.b2c-retail"}>B2C Retail</MenuItem>*/}
            <MenuItem value={"business-capabilities.dxp"}>Digital Experience Platform</MenuItem>
            {/*<MenuItem value={"business-capabilities.energy"}>Energy</MenuItem>*/}
            {/*<MenuItem value={"business-capabilities.finance"}>Finance</MenuItem>*/}
            {/*<MenuItem value={"business-capabilities.manufacturing"}>Manufacturing</MenuItem>*/}
            <MenuItem value={"business-capabilities.private-banking"}>Private Banking</MenuItem>
            <MenuItem value={"business-capabilities.aml"}>Anti-Money Laundering</MenuItem>
            <MenuItem value={"business-capabilities.fraud-management"}>Anti-Fraud Management</MenuItem>
            <MenuItem value={"business-capabilities.risk-and-compliance"}>Risk and Compliance</MenuItem>
            <MenuItem value={"business-capabilities.saas-company"}>SaaS Company</MenuItem>
        <ListSubheader className={cssStyles.listSubHeader}>Technical Capabilities</ListSubheader>
        <Divider />
        <MenuItem value={"technical-capabilities.dms"}>Document Management</MenuItem>
    </Select>
}
