import {IconButton} from "@mui/material";
import {useState} from "react";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import cssStyles from "./FullscreenButton.module.css";

export function FullscreenButton({onFullscreen, onExitFullscreen}) {

    const [isFullscreen, setIsFullscreen] = useState(false);

    function toggleFullscreen() {

        if (isFullscreen) {
            onExitFullscreen();
        } else {
            onFullscreen();
        }
        setIsFullscreen(!isFullscreen);
    }

    return (
        <div className={cssStyles.iconButtonDiv}>
            <IconButton
                color="inherit"
                onClick={toggleFullscreen}
            >
                {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
        </div>
    );


}
