import {getReportTopics} from "./ReportConstants";
import TileMenu from "../../TileMenu/TileMenu";

export default function MainReport({user}) {

    const topics = getReportTopics()

    return <TileMenu user={user} topics={topics}/>

}
