import {Tooltip} from "@mui/material";
import {default as RocketIcon} from '@mui/icons-material/Rocket';
import {default as ProLocked} from '@mui/icons-material/EnhancedEncryption';
import {useSubscriptionContext} from "../subscription/SubscriptionContext";



export function ProIconIndicator({isPro}) {

    const {subscription} = useSubscriptionContext()

    if (isPro) {
        if (subscription === "PRO") {
            return <Tooltip title="This is a PRO feature, enjoy!" placement="bottom-start">
                <RocketIcon/>
            </Tooltip>
        } else {
            return <Tooltip title="Upgrade to enjoy this PRO feature" placement="bottom-start">
                <ProLocked/>
            </Tooltip>
        }
    } else {
        return <></>
    }

}
