import cssStyles from "./CreditsTable.module.css"

export default function CreditsTable({className, credits}) {



    return <div className={(className?className + " ":"") + cssStyles.mainDiv}>
        <table>
            <thead>
            <tr>
                <th>Amount</th>
                <th>For</th>
                <th>Date</th>
            </tr>
            </thead>
            <tbody>
            {credits.map(credit => <tr key={credit.id}>
                <td>{credit.amount}</td>
                <td>{credit.reason}</td>
                <td>{credit.date}</td>
            </tr>)}
            </tbody>
        </table>
    </div>
}
