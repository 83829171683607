import React, {useEffect, useRef, useState} from "react";
import {useTreeDndContext} from "../TreeDndContext";
import cssStyles from "./DataFlowPaper.module.css"
import {useDrop} from "react-dnd";
import {ItemTypes} from "@minoru/react-dnd-treeview";
import {useModel} from "../../../../model/ModelContext";
import * as joint from "@joint/plus";
import {NodeType} from "../../../../model/Constants";
import {useSelectedNodes} from "../../../SelectedNodes/SelectedNodesProvider";
import {defineCustomShapes} from "./DataFlowShapes";
import {debounce, isEqual} from "lodash";
import {clearPaper, EMPTY_GRAPH, getCustomProp, hasType, setCustomProp} from "../utils/JointjsUtils";
import {elementTools, linkTools, connectionStrategies} from "@joint/plus";
import Logger from "../../../../utils/Logger";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import {IconButton} from "@mui/material";
import {useDataExchangeSelectorDialog} from "../../../DialogBoxes/DataExchangeSelectorDialogProvider";
import {useCreateOrEditDialog} from "../../../DialogBoxes/CreateOrEditDialogProvider";


const LOGGER = new Logger("DataFlowPaper")

const toolsScale = 1.2;

function resetToolsForCell(dia, graph, paper, cell, forceReset=false) {
    if (!graph) {
        LOGGER.debug("graph undefined")
        return
    }
    if (!paper) {
        LOGGER.debug("paper undefined")
        return
    }
    const cellView = cell.findView(paper)
    if (cellView.hasTools()) {
        if (!forceReset)  {
            //do nothing, tools already set and no forceReset
            return
        } else {
            //remove tools as forceReset is true and the cellView has tools
            cellView.removeTools()
        }
    }

    //now, set the tools depending on the cell type
    const cellType = cell.get('type')
    LOGGER.debug("resetToolsForCell.cellType: ", cellType)
    switch (cellType) {
        case 'sd.Lifeline': {
            const tools = new dia.ToolsView({
                layer: null,
                tools: [
                    new linkTools.HoverConnect({
                        scale: toolsScale
                    }),
                ]
            });
            cellView.addTools(tools)
            break
        }
        case 'sd.Message': {
            const tools = new dia.ToolsView({
                tools: [
                    new linkTools.Connect({
                        scale: toolsScale,
                        distance: -20
                    }),
                    new linkTools.Remove({
                        scale: toolsScale,
                        distance: 15
                    })
                ]
            });
            cellView.addTools(tools)
            break
        }
        case 'sd.LifeSpan': {
            const tools = new dia.ToolsView({
                tools: [
                    new linkTools.Remove({
                        scale: toolsScale,
                        distance: 15
                    })
                ]
            });
            cellView.addTools(tools)
            break
        }
        case 'sd.Role': {
            const tools = new dia.ToolsView({
                tools: [
                    new elementTools.Remove({
                        scale: toolsScale,
                        distance: '50%'
                    })
                ]
            });
            cellView.addTools(tools)
            break
        }
        default: {
            //no tools to set for this cell type
            break
        }
    }
}

function isTypeAcceptedForDrop(typeName) {
    switch (typeName) {
        case NodeType.Actor.description:
        case NodeType.Application.description:
        case NodeType.DataExchange.description:
            return true
        default:
            return false
    }
}

function saveFunctionToDebounce(onSaveGraphJSON, graphJSON) {
    LOGGER.debug("saveFunctionToDebounce called")
    if (onSaveGraphJSON) {
        LOGGER.debug("onSaveGraphJSON defined, graphJSON: ", graphJSON)
        let graphJsonString = JSON.stringify(graphJSON)
        LOGGER.debug("onSave handler defined, string: ", graphJsonString)
        if (typeof onSaveGraphJSON === 'function') {
            onSaveGraphJSON(graphJSON)
        } else {
            LOGGER.debug(`onSaveGraphJSON is not a function, got: ${typeof onSaveGraphJSON}, the object is: `, onSaveGraphJSON)
        }
    } else {
        LOGGER.debug("no onSaveGraphJSON defined")
    }
}

const debouncedSaveFunction = debounce(saveFunctionToDebounce, 100)

const onSaveHandler = function (onSaveGraphJSON, graphJSON) {
    if (typeof onSaveGraphJSON !== 'function') {
        LOGGER.debug("onSaveHandler is not a function, got: ", typeof onSaveGraphJSON)
        return
    }
    debouncedSaveFunction(onSaveGraphJSON, graphJSON)
}

export function makeSureTheRolesDontOverlap(graph, leftX=40, topY=40) {
    const roles = graph.getElements().filter(e => e.attributes.type === 'sd.Role')
    roles.sort((r1, r2)=>{
        return (r1.position().x < r2.position().x ? -1 : 1)
    }).forEach((role, index) => {
        const originalX = role.position().x
        const originalY = role.position().y
        //animateTranslation(role, leftX + index * 200 - originalX, topY - originalY, 500)
        role.translate(leftX + index * 300 - originalX, topY - originalY)
    })
}

function updateMessages(graph, getNodeById) {
    if (!graph) {
        LOGGER.debug("updateMessages - graph undefined")
        return
    }
    const messages = graph.getCells().filter(function(element) {
        return element.get('type') === "sd.Message"
    });
    messages.forEach(function(message) {
        let nodeId = getCustomProp(message, 'nodeId');
        let node = getNodeById(nodeId)
        if (node) {
            if (message) {
                if (typeof message.setName === 'function') {
                    message.setName(node.name)
                } else {
                    LOGGER.debug("message.setName is not a function, got: ", message.setName)
                }
            } else {
                LOGGER.debug("message is undefined")
            }

        } else {
            message.attr('fill', 'red')
        }
    })

}

function updateMessage(graph, node) {
    if (!graph) {
        LOGGER.debug("updateMessage - graph undefined")
        return
    }
    if (!node?.id) {
        LOGGER.debug("updateMessage - node.id undefined")
        return
    }
    var messagesWithNodeId = graph.getCells().filter(function(element) {
        let nodeId = getCustomProp(element, 'nodeId');
        return element.get('type') === "sd.Message" && nodeId === node.id;
    });
    messagesWithNodeId.forEach(function(message) {
        message.setName(node.name)
    })
}

function updateLifeLines(graph) {
    if (!graph) {
        LOGGER.debug("updateLifeLines - graph undefined")
        return
    }
    const lifeLines = graph.getLinks().filter(function(element) {
        return element.get('type') === "sd.Lifeline"
    });
    /*
     * make sure the lilines are vertical and not skewed. This is important for the message links to be drawn correctly.
     */
    lifeLines.forEach(function(lifeLine) {
        const role = lifeLine.getParentCell()
        const roleCenter = role.getBBox().center()
        lifeLine.set('target', {x: roleCenter.x, y: lifeLine.get('target').y})
    })
}

function updateRolesSelection(graph, selectedNodes, softSelectedNodes) {
    if (!graph) {
        LOGGER.debug("updateRolesSelection - graph undefined")
        return
    }
    const roles = graph.getElements().filter(function(element) {
        return element.get('type') === "sd.Role"
    });
    const selectedNodeIds = selectedNodes.map(n=>n.id)
    const softSelectedNodeIds = softSelectedNodes.map(n=>n.id)
    roles.forEach(function(role) {
        let nodeId = getCustomProp(role, 'nodeId');
        let isSelected = selectedNodeIds.includes(nodeId)
        if (isSelected) {
            role.attr('body/stroke', '#FC5185')
            role.attr('body/strokeWidth', '4')
        } else {
            let isSoftSelected = softSelectedNodeIds.includes(nodeId)
            if (isSoftSelected) {
                role.attr('body/stroke', '#FFD166')
                role.attr('body/strokeWidth', '4')
            } else {
                role.attr('body/stroke', 'skyblue')
            }
        }
    })

}

function updateRoles(graph, getNodeById, selectedNodes) {
    if (!graph) {
        LOGGER.debug("updateRoles - graph undefined")
        return
    }
    const roles = graph.getElements().filter(function(element) {
        return element.get('type') === "sd.Role"
    });
    roles.forEach(function(role) {
        let nodeId = getCustomProp(role, 'nodeId');
        let node = getNodeById(nodeId)
        LOGGER.debug("Gotten node to update role's name: ", node)
        if (node) {
            role.setName(node.name)
        } else {
            role.attr('body/stroke', 'lightgrey')
            role.attr('body/fill', 'lightgrey')
        }
    })
}

function updateRole(graph, node, getNodeById) {
    if (!node?.id) {
        LOGGER.debug("updateRole - node.id undefined")
        return
    }
    const rolesWithNodeId = graph.getElements().filter(function(element) {
        let nodeId = getCustomProp(element, 'nodeId');
        return element.get('type') === "sd.Role" && nodeId === node.id;
    });
    rolesWithNodeId.forEach(function(role) {
        role.setName(node.name)
        if (!getNodeById(node.id)) {
            role.attr('body/fill', 'red')
        }
    })
}

function addRoleToGraph(topY, droppedNode, graph, defaultName="", paperHeight=1600) {

    const role = new joint.shapes.sd.Role({position: {x: 100, y: topY}});
    role.setName(droppedNode?.name || defaultName)
    role.addTo(graph);
    const lifeline = new joint.shapes.sd.Lifeline();
    lifeline.attachToRole(role, paperHeight);
    setCustomProp(role, 'nodeId', droppedNode?.id)
    setCustomProp(lifeline, 'nodeId', droppedNode?.id)
    lifeline.addTo(graph);
    role.embed(lifeline)
}

function checkCellsAndLinks(graph, paper, getNodeById) {
    if (!graph) {
        LOGGER.debug("graph undefined")
        return
    }
    if (!paper) {
        LOGGER.debug("paper undefined")
        return
    }
    const cellToRemove = []
    graph.getCells().forEach(function(cell) {
        switch (cell.get('type')) {
            case 'sd.Role': {
                const nodeId = getCustomProp(cell, 'nodeId')
                if (!getNodeById(nodeId)) {

                }
                break
            }
            case 'sd.Lifeline': {
                //const deId = getCustomProp(cell, 'dataExchangeId')
                break
            }
            case 'sd.Message': {
                resetToolsForCell(joint.dia, graph, paper,cell, true)
                break
            }
            default: {
                LOGGER.warn("unknown element type: ", cell.get('type'))
            }
        }
    });
    graph.getLinks().forEach(function(link) {
        resetToolsForCell(joint.dia, graph, paper,link, true)
    });
    graph.removeCells(cellToRemove)

}

export default  function DataFlowPaper({paperWidth, paperHeight}) {

    //All Refs
    const paperRef = useRef();
    const newMessageLinkView = useRef(null)
    const graph = useRef(null)
    const paper = useRef(null)

    //all state variables
    const [canAcceptDrop, setCanAcceptDrop] = useState(false)
    const [node, setNode] = useState(null)
    const [topY] = useState(20)

    //all hooks
    const {selectedNodes, softSelectedNodes, setSoftSelectedNodeById} = useSelectedNodes()
    const dataExchangeSelectorDialog = useDataExchangeSelectorDialog()
    const createOrEditDialog = useCreateOrEditDialog()
    const {isDraggingActive, nodeBeingDragged} = useTreeDndContext()
    const {getNodeById, saveNode, updatedNode} = useModel()

    useEffect(() => {
        if (!graph.current) {
            LOGGER.debug("graph.current undefined")
            return
        }
        if (updatedNode?.type === NodeType.Application.description) {
            LOGGER.debug("updatedNode: ", updatedNode)
            updateRole(graph.current, updatedNode, getNodeById)
        }
        if (updatedNode?.type === NodeType.DataExchange.description) {
            LOGGER.debug("updatedNode: ", updatedNode)
            updateMessage(graph.current, updatedNode)
        }
        if (node.id === updatedNode?.id) {
            //setNode(updatedNode)
        }
        updateRoles(graph.current, getNodeById)
        updateMessages(graph.current, getNodeById)
    }, [updatedNode, graph, getNodeById, node])


    const onSaveGraphJSON = (graphJSON) => {
        LOGGER.debug("onSaveGraphJSON.graphJSON:", graphJSON)
        //TODO !!! this fucks up existing views' .graph attributes when switching rapidly between view
        if (!node) {
            LOGGER.debug("node undefined")
            return
        }
        if (graphJSON && !isEqual(graphJSON, node?.graph)) {
            LOGGER.debug("copying node: ", node)
            const nodeToSave = {...node, graph:graphJSON}
            LOGGER.debug("nodeToSave: ", nodeToSave)
            saveNode(nodeToSave)
            if (nodeToSave) {
                if (nodeToSave?.graph) {
                    LOGGER.debug("loading graph from nodeToSave: ", nodeToSave)
                    //graph.current.fromJSON(nodeToSave.graph)
                }
            }
        } else {
            LOGGER.debug("graphJSON not changed")
        }
    }

    //defintions of shapes & initialization of paper and graph
    useEffect(() => {

        LOGGER.debug("definitions of shapes & initialization of paper and graph")

        if (graph.current && paper.current) {
            LOGGER.debug("graph.current and paper.current already initialized")
            return
        }

        if (!paperRef) {
            LOGGER.debug("paperRef undefined")
            return
        }

        if (!node) {
            LOGGER.debug("node undefined")
            return
        }

        if (!saveNode) {
            LOGGER.debug("saveNode undefined")
            return
        }

        if (!paperWidth) {
            LOGGER.debug("paperWidth undefined")
            return
        }

        if (!paperHeight) {
            LOGGER.debug("paperHeight undefined")
            return
        }

        /*
         * DEFINITION OF SHAPES
         */
        let dia = joint.dia
        if (!joint.shapes.sd) {
            joint.shapes.sd = defineCustomShapes()
        }

        /*
         * INITIALIZATION OF THE PAPER
         */

        const {sd} = joint.shapes;

        graph.current = new dia.Graph({}, {cellNamespace: joint.shapes});
        paper.current = new dia.Paper({
            el: paperRef.current,
            width: paperWidth,
            height: paperHeight,
            model: graph.current,
            frozen: true,
            async: true,
            cellViewNamespace: joint.shapes,
            defaultConnectionPoint: {name: 'rectangle'},
            background: {color: '#F3F7F6'},
            moveThreshold: 5,
            linkPinning: false,
            markAvailable: true,
            preventDefaultBlankAction: false,
            restrictTranslate: function (elementView) {
                const element = elementView.model;
                const padding = (element.isEmbedded()) ? 20 : 10;
                return {
                    x: padding,
                    y: element.getBBox().y,
                    width: paperWidth - 2 * padding,
                    height: 0
                };
            },
            interactive: function (cellView) {
                const cell = cellView.model;
                return (cell.isLink())
                    ? {linkMove: false, labelMove: false}
                    : true;
            },
            defaultLink: function (cellView) {
                const type = cellView.model.get('type');
                switch (type) {
                    case 'sd.Message': {
                        const lifeSpan = new sd.LifeSpan();

                        //setShowModal(true)
                        return lifeSpan
                    }
                    case 'sd.Lifeline': {
                        const newMessage = new sd.Message();


                        newMessage.on('move', function (link, newSource) {
                            onSaveHandler(onSaveGraphJSON, graph.current.toJSON())
                        })
                        /* ACHTUNG BABY !
                         * At first I captured the change:target and change:source events on the newMessage.
                         * This was problematic, because for some reason when I'd just added the newMessage to the graph
                         * and moved it, this would fire the change:target or change:source event, which would then show the modal.
                         * This was not what I wanted, so I changed it to capture the change:target event only.
                         */
                        newMessage.on('change:target', function (link, newTarget) {
                            //if (this === newTarget) return;
                            LOGGER.debug("event..newMessage[change:target]")
                            if (link.get('source').id && link.get('target').id) {
                                newMessageLinkView.current = link
                                let sourceCell = graph.current.getCell(link.get('source').id);
                                let targetCell = graph.current.getCell(link.get('target').id);
                                let sourceNodeId = getCustomProp(sourceCell, 'nodeId')
                                let targetNodeId = getCustomProp(targetCell, 'nodeId')
                                let sourceNodeName = getNodeById(sourceNodeId)?.name
                                let targetNodeName = getNodeById(targetNodeId)?.name
                                dataExchangeSelectorDialog.showModal(
                                    "Select DataExchange",
                                    null,
                                    sourceNodeId,
                                    targetNodeId,
                                    /*onNew: */() => {
                                        LOGGER.debug("onNew selected -> call the create new DataExchange dialog")
                                        createOrEditDialog.newNode({
                                                    type: NodeType.DataExchange.description,
                                                    sourceApplicationId: sourceNodeId,
                                                    targetApplicationId: targetNodeId,
                                                    name: "New DataExchange",
                                                    description: "New DataExchange: " + sourceNodeName + " -> " + targetNodeName
                                            },
                                            /*onClose*/ async (newNode) => {
                                                LOGGER.debug("onClose selected -> call the onClose handler")
                                                const newlySavedNode = await saveNode(newNode)
                                                LOGGER.debug("newNode: ", newNode)
                                                setCustomProp(newMessageLinkView.current, 'dataExchangeId', newlySavedNode.id)
                                                newMessageLinkView.current.setDescription(newlySavedNode.name)
                                                newMessageLinkView.current = null
                                                LOGGER.debug("Calling onSaveHandler - newMessage - onClose")
                                                onSaveHandler(onSaveGraphJSON, graph.current.toJSON())
                                            },
                                            () => {
                                                LOGGER.debug("onCancel selected -> call the onCancel handler")
                                                graph.current.removeCells([link])
                                            })
                                    },
                                    /*onSelect:*/ (de) => {
                                        LOGGER.debug("onSelect selected -> call the onSelect handler")
                                        if (newMessageLinkView.current) {
                                            setCustomProp(newMessageLinkView.current, 'dataExchangeId', de.id)
                                            newMessageLinkView.current.setDescription(de.name)
                                            LOGGER.debug("Calling onSaveHandler #2")
                                            onSaveHandler(onSaveGraphJSON, graph.current.toJSON())
                                            newMessageLinkView.current = null
                                        }
                                    },
                                    /*onCancel:*/ () => {
                                        LOGGER.debug("onCancel selected -> call the onCancel handler")
                                        graph.current.removeCells([link])
                                        newMessageLinkView.current = null
                                    }
                                )
                            }
                        })

                        return newMessage;
                    }
                    default: {
                        throw new Error('Unknown link type.');
                    }
                }

            },
            connectionStrategy: function (end, cellView, magnet, p, link, endType, paper) {
                const type = link.get('type');
                switch (type) {
                    case 'sd.LifeSpan': {
                        if (endType === 'source') {
                            end.anchor = {name: 'connectionRatio', args: {ratio: 1}};
                        } else {
                            end.anchor = {name: 'connectionRatio', args: {ratio: 0}};
                        }
                        return end;
                    }
                    case 'sd.Message': {
                        if (endType === 'source') {
                            return connectionStrategies.pinAbsolute.call(paper, end, cellView, magnet, p, link, endType, paper);
                        } else {
                            end.anchor = {name: 'connectionPerpendicular'};
                            return end;
                        }
                    }
                    default: {
                        LOGGER.warn("unknown element type: ", type)
                    }
                }
            },
            validateConnection: function (cellViewS, magnetS, cellViewT, magnetT, end, linkView) {
                if (cellViewS === cellViewT) return false;
                const type = linkView.model.get('type');
                const targetType = cellViewT.model.get('type');
                switch (type) {
                    case 'sd.Message': {
                        return targetType === 'sd.Lifeline';
                    }
                    case 'sd.LifeSpan': {
                        if (targetType !== 'sd.Message') return false;
                        if (cellViewT.model.source().id !== cellViewS.model.target().id) return false;
                        return true;
                    }
                    default: {
                        LOGGER.warn("unknown element type: ", type)
                    }
                }
            },
            highlighting: {
                connecting: {
                    name: 'addClass',
                    options: {
                        className: 'highlighted-connecting'
                    }
                }
            }
        });



        function add_element(cell, collection, opt) {
            LOGGER.debug(`graph.current.add event element: ${JSON.stringify(cell)} ${collection} ${opt}`)
            if (graph.current.getCell(cell.id)) {
                LOGGER.debug(`graph.add event element on GRAPH: ${JSON.stringify(cell)} ${collection} ${opt}`)
                paper.current.renderView(cell)
                if (cell.findView(paper.current)) {
                    LOGGER.debug(`graph.current.add event element on PAPER: ${JSON.stringify(cell)} ${collection} ${opt}`)
                    if (hasType(cell, NodeType.Application.description)) {
                        LOGGER.debug(`graph.current.add event element on PAPER: ${JSON.stringify(cell)} ${collection} ${opt}`)

                    }
                }
            }
        }


        paper.current.on('link:pointermove', function (linkView, _evt, _x, y) {
            LOGGER.debug("event..paper.current[link:pointermove]")
            const type = linkView.model.get('type');
            switch (type) {
                case 'sd.Message': {
                    const sView = linkView.sourceView;
                    const tView = linkView.targetView;
                    if (!sView || !tView) return;
                    const padding = 20;
                    const minY = Math.max(tView.sourcePoint.y - sView.sourcePoint.y, 0) + padding;
                    const maxY = sView.targetPoint.y - sView.sourcePoint.y - padding;
                    linkView.model.setStart(Math.min(Math.max(y - sView.sourcePoint.y, minY), maxY));
                    LOGGER.debug("link.start:pointermove: ", linkView.model.get('start'))
                    LOGGER.debug("graphJSON:", graph.current.toJSON())
                    //saveNode({...node, graph: graph.current.toJSON()})
                    //LOGGER.debug("Calling onSaveHandler #3")
                    //onSaveHandler(onSaveGraphJSON, graph.current.toJSON())
                    break;
                }
                case 'sd.LifeSpan': {
                    break;
                }
                default: {
                    LOGGER.warn("unknown element type: ", type)
                }
            }
        });

        graph.current.on('add', function (cell) {
            LOGGER.debug(`event..graph.current[add]`)
            if (cell.isLink()) {
                resetToolsForCell(joint.dia, graph.current, paper.current, cell, true)
            }

        });

        paper.current.on('cell:mouseenter', function (cellView) {
            LOGGER.debug('event..paper.current[cell:mouseenter]')
            const cell = cellView.model;
            resetToolsForCell(joint.dia, graph.current, paper.current,cell, true)
        });

        paper.current.on('cell:mouseleave', function (cellView) {
            LOGGER.debug('event..paper.current[cell:mouseleave]')
            const cell = cellView.model;
            const type = cell.get('type');
            switch (type) {
                case 'sd.Role':
                case 'sd.LifeSpan':
                case 'sd.Message': {
                    cellView.removeTools();
                    break;
                }
                default: {
                    LOGGER.warn("unknown element type: ", type)
                }
            }
        });

        paper.current.on('cell:pointerup', function (cell) {
            LOGGER.debug('event..paper.current[element:pointerup]')
            const type = cell?.model?.get('type');
            switch (type) {
                case 'sd.Role': {
                    makeSureTheRolesDontOverlap(graph.current)
                    onSaveHandler(onSaveGraphJSON, graph?.current.toJSON())
                    break;
                }
                case 'sd.LifeSpan': {
                    updateLifeLines(graph.current)
                    onSaveHandler(onSaveGraphJSON, graph?.current.toJSON())
                    break;
                }
                case 'sd.Message': {
                    updateMessages(graph.current, getNodeById)
                    onSaveHandler(onSaveGraphJSON, graph?.current.toJSON())
                    break;
                }
                default: {
                    LOGGER.warn("unknown element type: ", type)
                }
            }
        })


        // Text Editing

        paper.current.on('cell:pointerclick', function (cell, evt, x, y) {
            LOGGER.debug('event..paper.current[cell:pointerclick]')
            let nodeId = getCustomProp(cell.model, 'nodeId')
            if (nodeId) {
                LOGGER.debug("nodeId: ", nodeId)
                setSoftSelectedNodeById(nodeId)
            } else {
                //maybe there's a dataExchangeId?
                let deId = getCustomProp(cell.model, 'dataExchangeId')
                if (deId) {
                    LOGGER.debug("deId: ", deId)
                    setSoftSelectedNodeById(deId)
                } else {
                    LOGGER.debug("no nodeId or deId found")
                }
            }
        })

        paper.current.on('cell:pointerdblclick', function (cell, evt) {
            LOGGER.debug('event..paper.current[cell:pointerdblclick]')

            if (cell?.model?.isElement() && cell?.model?.get('type') === 'sd.Role') {
                let nodeId = getCustomProp(cell.model, 'nodeId')
                let zeNode = getNodeById(nodeId)
                if (zeNode) {
                    createOrEditDialog.editNode(zeNode, async (updatedNode) => {
                        LOGGER.debug("editNode.callback-onClose")
                        //in some cases the zeNode will contain more fields than are in the updatedNode, so we need to merge them
                        // as an example, the updatedNode will not contain the graph fields, but the zeNode will
                        const mergedNode = {...zeNode, ...updatedNode}
                        LOGGER.debug("Saving mergedNode: ", mergedNode)
                        const savedApplicationNode = await saveNode(mergedNode)
                        updateRole(graph.current, savedApplicationNode, getNodeById)
                        //onSaveHandler(onSaveGraphJSON, graph?.current.toJSON())
                    }, () => {
                        LOGGER.debug("editNode.callback-onCancel")
                    })
                }
            }

            if (cell?.model?.isLink() && cell?.model?.get('type') === 'sd.Message') {
                newMessageLinkView.current = cell.model

                let sourceCell = cell.sourceView.model
                let targetCell = cell.targetView.model
                let sourceNodeId = getCustomProp(sourceCell, 'nodeId')
                let targetNodeId = getCustomProp(targetCell, 'nodeId')
                let sourceNodeName = getNodeById(sourceNodeId)?.name
                let targetNodeName = getNodeById(targetNodeId)?.name
                let deId = getCustomProp(cell.model, 'dataExchangeId')
                //setShowModal(true)
                LOGGER.trace("cell:pointerdblclick[sd.Message] setting modal to true")
                dataExchangeSelectorDialog.showModal(
                    "Select DataExchange",
                    deId,
                    sourceNodeId,
                    targetNodeId,
                    /*onNew:*/ () => {
                                LOGGER.debug("onNew selected -> call the create new DataExchange dialog")
                                createOrEditDialog.newNode({
                                        type: NodeType.DataExchange.description,
                                        sourceApplicationId: sourceNodeId,
                                        targetApplicationId: targetNodeId,
                                        name: "New DataExchange",
                                        description: "New DataExchange: " + sourceNodeName + " -> " + targetNodeName
                                },
                                /*onClose*/ async (newNode) => {
                                    LOGGER.debug("onClose selected -> call the onClose handler")
                                    const newlySavedNode = await saveNode(newNode)
                                    LOGGER.debug("saved newNode: ", newNode)
                                    setCustomProp(newMessageLinkView.current, 'dataExchangeId', newlySavedNode.id)
                                    newMessageLinkView.current.setDescription(newlySavedNode.name)
                                    //LOGGER.debug("Calling onSaveHandler #4")
                                    //onSaveHandler(onSaveGraphJSON, graph.current.toJSON())
                                },
                                /*onCancel*/ () => {
                                    LOGGER.debug("onCancel selected -> call the onCancel handler")
                                    //don't delete the link, it should remain there as it was
                                    graph.current.removeCells([newMessageLinkView.current])
                                    onSaveHandler(onSaveGraphJSON, graph.current.toJSON())
                                }
                            )
                    },
                    /*onSelect:*/ (de) => {
                        LOGGER.debug("onSelect selected -> call the onSelect handler")
                        if (newMessageLinkView.current) {
                            setCustomProp(newMessageLinkView.current, 'dataExchangeId', de.id)
                            newMessageLinkView.current.setDescription(de.name)
                            newMessageLinkView.current = null
                            //LOGGER.debug("Calling onSaveHandler #5")
                            //onSaveHandler(onSaveGraphJSON, graph?.current.toJSON())
                        }
                    },
                    /*onCancel:*/ () => {
                        LOGGER.debug("onCancel selected -> call the onCancel handler")
                        newMessageLinkView.current = null
                    })
            }


        });

        paper.current.on('cell:pointerdown', function (linkView, evt) {
            LOGGER.debug('event..paper.current[cell:pointerdown]')
            if (linkView?.model?.get('type') === 'sd.Message') {
                if (linkView.sourceView && linkView.targetView) {
                    const deId = getCustomProp(linkView.model, 'dataExchangeId')
                    if (deId) {
                        let nodeToSoftSelect = getNodeById(deId);
                        if (nodeToSoftSelect) {
                            setSoftSelectedNodeById(nodeToSoftSelect)
                        }
                    }
                }
            } else if (linkView?.model?.get('type') === 'sd.Role') {
                const nodeId = getCustomProp(linkView.model, 'nodeId')
                if (nodeId) {
                    setSoftSelectedNodeById(nodeId)
                }
            }
        })

        /*
         * Events
         */



        graph.current.on('add', function(cell, collection, opt) {
            LOGGER.debug(`event..graph.current[add]`)
            if (cell?.isElement()) {
                add_element(cell, collection, opt, paper);
            }
            //LOGGER.debug("Calling onSaveHandler #6")
            //onSaveHandler(onSaveGraphJSON, graph?.current.toJSON())
        })


        graph.current.on('remove', ()=>{
            LOGGER.debug(`event..graph.current[remove]`)
            onSaveHandler(onSaveGraphJSON, graph?.current.toJSON())
        })
        graph.current.on('change', (cell, change)=>{
            LOGGER.trace(`event..graph.current[change]`)
            const changesToIgnore = [
                "attrs/body/stroke",
                "attrs/body/stroke-width",
            ]
            if (changesToIgnore.includes(change?.propertyPath)) {
                //ignore it is just to show the hover border, no need to save!
                return
            }
            LOGGER.debug("Calling onSaveHandler #8")
            onSaveHandler(onSaveGraphJSON, graph?.current.toJSON())
        })


        // eslint-disable-next-line
    }, [paperRef, node, paperWidth, paperHeight]);

    //draw elements on paper/graph
    useEffect(() => {
        if (!paper.current && !graph.current) {
            LOGGER.debug("paper or graph not ready yet!")
            return
        } else {
            LOGGER.debug("paper/graph ready!")
        }
        if (node) {
            LOGGER.debug("set node:: ", node)
        } else {
            LOGGER.debug("no node!")
            return
        }

        if (node.graph) {
            LOGGER.debug("loading graph from node: ", node)
            paper.current.freeze()
            graph.current.fromJSON(node.graph)

            checkCellsAndLinks(graph.current, paper.current, getNodeById)

            paper.current.unfreeze()

            updateRoles(graph.current, getNodeById)
            updateMessages(graph.current, getNodeById)
            updateLifeLines(graph.current, getNodeById)

        } else {
            LOGGER.debug("no graph in node, creating empty graph")
            paper.current.freeze()
            graph.current.fromJSON(EMPTY_GRAPH)
            paper.current.unfreeze()

        }


        // eslint-disable-next-line
    }, [node, paper, graph]);


    useEffect(() => {
        if (selectedNodes && selectedNodes.length > 0) {
            LOGGER.debug("selectedNodes: ", selectedNodes)
            if (selectedNodes[0]) {
                LOGGER.debug("setNode(selectedNodes[0]): ", selectedNodes[0])
                setNode(selectedNodes[0])
            }

        }
    }, [selectedNodes]);

    useEffect(() => {
        if (graph.current) {
            updateRolesSelection(graph.current, selectedNodes, softSelectedNodes)
        }
    }, [selectedNodes, softSelectedNodes]);

    useEffect(() => {
        if (isDraggingActive) {
            setCanAcceptDrop(isTypeAcceptedForDrop(nodeBeingDragged?.type))
        } else {
            setCanAcceptDrop(false)
        }
    }, [isDraggingActive, nodeBeingDragged]);

    const [{ isOver, isOverCurrent }, drop] = useDrop(
        () => ({
            accept: ItemTypes.TREE_ITEM, //['NODE', 'ApplicationComponent'],
            canDrop(_item, monitor) {
                const itemType = _item?.data?.type
                return isTypeAcceptedForDrop(itemType)
            },
            hover(_item, monitor) {
                //still needed??
            },
            drop(_item, monitor) {
                LOGGER.trace(`isOver=${isOver}, ìsOverCurrent=${isOverCurrent}`)
                LOGGER.debug("DROP! _item=", _item)
                //LOGGER.debug("DROP! monitor", monitor)
                LOGGER.debug("DROP! monitor.getClientOffset()", monitor.getClientOffset())
                LOGGER.debug("DROP! monitor.didDrop()", monitor.didDrop())

                if (!(paperRef && graph && paper)) {
                    LOGGER.warn(`paperRef (${paperRef}), graph (${graph}) or paper (${paper}) not ready yet!`)
                    return _item
                }

                const didDrop = monitor.didDrop()
                if (didDrop) {
                    return _item
                }

                if (paperRef) {
                    const itemType = monitor?.getItemType()
                    switch (itemType) {
                        case ItemTypes.TREE_ITEM:
                            const objectType = _item?.data?.type
                            const droppedNode = getNodeById(_item.id)
                            LOGGER.debug("droppedNode: ", droppedNode)
                            LOGGER.debug("objectType: ", objectType)
                            switch (objectType) {
                                case NodeType.Actor.description:
                                    addRoleToGraph(topY, droppedNode, graph.current, "Actor", paperHeight)
                                    makeSureTheRolesDontOverlap(graph.current)
                                    break
                                case NodeType.Application.description:
                                    LOGGER.debug("drop.monitor.getClientOffset(): ", monitor.getClientOffset())
                                    //add the node to the diagram...
                                    addRoleToGraph(topY, droppedNode, graph.current, "Application", paperHeight)
                                    makeSureTheRolesDontOverlap(graph.current)
                                    break
                                case NodeType.DataExchange.description:
                                    LOGGER.debug("dropping DataExchange: ", droppedNode)

                                    break
                                default:
                                    break
                            }

                            break
                        default:
                            LOGGER.debug("unsupported item type '" + itemType + "' dropped: ", _item)
                            break
                    }

                } else {
                    LOGGER.debug("paperRef undefined")
                }

                return _item
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                clientOffset: monitor.getClientOffset() ,
            }),
        }),
        [paper, graph] /* don't forget the dependencies! kudos to: https://stackoverflow.com/a/70757948*/,
    )


    return (
        <div className={cssStyles.main}>
            <div className={cssStyles.titleDiv}>
                {node?.name}
                <IconButton className={cssStyles.redIconButton} aria-label="clear" onClick={()=>{clearPaper(graph.current, node, saveNode)}}>
                    <LayersClearIcon className={cssStyles.redIcon}/>
                </IconButton>
            </div>
            {canAcceptDrop && <div ref={drop} className={cssStyles.dragArea} data-testid={"dragarea-dataflow-paper"}>dragging: {nodeBeingDragged?.name}</div>}
            <div className={cssStyles.paperWrapper}>
                <div ref={paperRef} style={{width: paperWidth, height: paperHeight}}
                     className={cssStyles.paperDiv}></div>
            </div>
        </div>
    );
}

