import {isCapability, layoutEmbeds, resizeCapability} from "./GridStackComponent4_utils";
import {getElementsUnderElement, getNewEmbeds} from "./utils/DiagramUtils";
import {highlighters} from "@joint/plus";
import Logger from "../../../utils/Logger";
import {getCustomProp} from "./utils/JointjsUtils";

const LOGGER = new Logger("GridStackComponent4_Handlers.js")


export function elementView_pointermove(paper, elementView, evt, x, y, highlighterId, highlighterOptions) {
    const graph = paper.model;
    const element = elementView.model;
    if (!isCapability(element)) {
        LOGGER.debug("not a capability")
        return;
    }

    // The elementView is a Capability.

    const elementsUnder = getElementsUnderElement(graph, element);
    let found = false;
    if (!elementsUnder.find((el) => isCapability(el))) {
        // There is no other container under the elementView
        found = getNewEmbeds(elementsUnder, element).length > 0;
    }

    elementView.el.style.opacity = 0.7;

    if (found) {
        // and position it over elements that could be
        // embedded into the elementView
        highlighters.mask.add(
            elementView,
            "body",
            highlighterId,
            highlighterOptions
        );
    } else {
        // There is no element under the elementView
        // that could be embedded to it
        highlighters.mask.remove(elementView, highlighterId);
    }
}

export function elementView_pointerup(paper, elementView, evt, x, y, highlighterId) {
    const graph = paper.model;
    const element = elementView.model;
    const elementsUnder = getElementsUnderElement(graph, element);
    const parent = elementsUnder.findLast((el) => isCapability(el));

    if (!isCapability(element)) {
        // The elementView is not a container
        if (parent) {
            // If an element is embedded into another we make sure
            // the container is large enough to contain all the embeds
            resizeCapability(graph, parent);
        }
        return;
    }

    // The elementView is a container

    element.set("z", -1);
    elementView.el.style.opacity = "";
    highlighters.mask.remove(elementView, highlighterId);

    if (parent) {
        // The elementView was embedded into another container
        if (elementsUnder.length > 1) {
            // The container has already children and some of them
            // are located under the elementView.
            // Let's make sure none of the children stays under
            // elementView
            layoutEmbeds(graph, parent);
        }
        // If an element is embedded into another we make sure
        // the container is large enough to contain all the embeds
        resizeCapability(graph, parent);
        return;
    }

    // The elementView has not been embedded
    // We check the elements under the elementView which are not
    // containers and embed them into elementView.
    const newEmbeds = getNewEmbeds(elementsUnder, element);
    if (newEmbeds.length > 0) {
        element.embed(newEmbeds);
        resizeCapability(graph, element);
    }
}

export function element_changedParent(paper, getNodeById, element, parentId, saveNode) {
    if (!parentId) {
        LOGGER.debug("no parent")

        return;
    }
    const graph = paper.model;
    const newParentCell = graph.getCell(parentId);
    const newParentType = getCustomProp(newParentCell, "type");
    if (newParentType !== "capability") {
        LOGGER.debug("parent is not a capability")
        return;
    }
    const newParentNodeId = getCustomProp(newParentCell, "capabilityId");
    const nodeId = getCustomProp(element, "capabilityId");
    const node = getNodeById(nodeId);
    node.parentId = newParentNodeId;
    saveNode(node);

}
