import {Autocomplete, TextField} from "@mui/material";
import {Controller} from "react-hook-form"

import Logger from "../../../../utils/Logger"
import {MATURITY_MODEL_LIST} from "../../../../model/Constants";

const LOGGER = new Logger("RPOSelector")

export default function MaturityModelSelector({control, label, name, error, helperText}) {

    const maturityModelOptions = MATURITY_MODEL_LIST.map(m => m.id)

    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}})=>{
                LOGGER.debug("render.value:", value)
                return <Autocomplete
                    multiple={false}
                    autoHighlight
                    autoSelect
                    id={"combo-box-maturity-model"}
                    options={maturityModelOptions}

                    renderOption={(props, option) => {
                        LOGGER.debug("renderOption.option:", option)
                        return (
                            <li {...props} key={option}>
                                {option}
                            </li>
                        );
                    }}
                    renderInput={(params) => {
                        //LOGGER.debug("renderInput.params:", params)
                        return <TextField
                            {...params}
                            key={"tf-" + params.id} {...params}
                            label={label}
                            error={error}
                            helperText={helperText}
                            onChange={onChange}
                        />
                    }}
                    //defaultValue={(multiSelect?[]:"_capabilities")}
                    sx={{width: 500, paddingTop:"15px"}}
                    onChange={(event, values, reason) => {
                        LOGGER.debug("onChange.values:", values)
                        onChange(values)
                    }}
                    value={value}
                />
            }}
        />
    );
}
