import * as React from "react";
import cssStyles from "./FolderDiagram2.module.css"
import Logger from "../../../utils/Logger"
import {
    getRootFolderLabel,
    NodeFolderRootIds,
    NodeType,
} from "../../../model/Constants";
import {useEffect, useState} from "react";
import { MaterialReactTable } from 'material-react-table';
import {addClasses} from "../../../utils/PresentationUtils";
import {useWorkspaceContext} from "../../../security/WorkspaceContext";
import {useModel} from "../../../model/ModelContext";

const LOGGER = new Logger("FolderDiagram")

function getNodeTypeFromFolderId(folderId) {
    switch (folderId) {
        case NodeFolderRootIds.ActorRootId.description:
            return NodeType.Actor.description
        case NodeFolderRootIds.ApplicationRootId.description:
            return NodeType.Application.description
        case NodeFolderRootIds.ApplicationViewRootId.description:
            return NodeType.ApplicationView.description
        case NodeFolderRootIds.ArchitectureBuildingBlockRootId.description:
            return NodeType.ArchitectureBuildingBlock.description
        case NodeFolderRootIds.BusinessProcessRootId.description:
            return NodeType.BusinessProcess.description
        case NodeFolderRootIds.CapabilityRootId.description:
            return NodeType.Capability.description
        case NodeFolderRootIds.DataExchangeRootId.description:
            return NodeType.DataExchange.description
        case NodeFolderRootIds.DataFlowRootId.description:
            return NodeType.DataFlow.description
        case NodeFolderRootIds.DataObjectRootId.description:
            return NodeType.DataObject.description
        case NodeFolderRootIds.DesignDecisionRootId.description:
            return NodeType.DesignDecision.description
        case NodeFolderRootIds.DomainRootId.description:
            return NodeType.Domain.description
        case NodeFolderRootIds.FunctionalityRootId.description:
            return NodeType.Functionality.description
        case NodeFolderRootIds.MiddlewareRootId.description:
            return NodeType.Middleware.description
        case NodeFolderRootIds.PrincipleRootId.description:
            return NodeType.Principle.description
        case NodeFolderRootIds.ReferenceArchitectureRootId.description:
            return NodeType.ReferenceArchitecture.description
        case NodeFolderRootIds.ScenarioRootId.description:
            return NodeType.Scenario.description
        case NodeFolderRootIds.SnapshotRootId.description:
            return NodeType.Snapshot.description
        case NodeFolderRootIds.TalRootId.description:
            return NodeType.Tal.description
        default:
            return "unknown"
    }
}

function getColumnsForNodeType(workspace, getNodeById, nodeType) {
    let defaultColumns = [
        {
            id: 'name',
            header: 'Name',
            accessorKey: 'name', //simple accessorKey pointing to flat data
        },
        {
            id: 'description',
            header: 'Description',
            accessorKey: 'description', //simple accessorKey pointing to flat data
        },
    ]
    let additionalColumns = []
    switch (nodeType) {
        case NodeType.Actor.description:
            break
        case NodeType.Application.description:
            additionalColumns = [
                {
                    id: 'supportedCapabilities',
                    header: 'Supported Capability',
                    accessorKey: 'supportedCapabilities'
                },
                {
                    id: 'rpoValues',
                    header: 'RPO',
                    accessorKey: 'rpo'
                },
                {
                    id: 'rtoValues',
                    header: 'RTO',
                    accessorKey: 'rto'
                }
            ]
            break
        case NodeType.ApplicationView.description:
            break
        case NodeType.ArchitectureBuildingBlock.description:
            break
        case NodeType.BusinessProcess.description:
            break
        case NodeType.Capability.description:
            additionalColumns = [
                {
                    id: 'tier',
                    header: 'Tier',
                    accessorKey: 'tier'
                },
                {
                    id: 'level',
                    header: 'Level',
                    accessorKey: 'level'
                }
            ]
            break
        case NodeType.DataExchange.description:
            additionalColumns = [
                {
                    id: 'source',
                    header: 'Source',
                    accessorKey: 'source'
                },
                {
                    id: 'target',
                    header: 'Target',
                    accessorKey: 'target'
                },
                {
                    id: 'dataObjects',
                    header: 'Data Objects',
                    accessorKey: 'dataObjects'
                },
                {
                    id: 'supportingMiddlewares',
                    header: 'Supporting Middleware',
                    accessorKey: 'supportingMiddlewares'
                },
            ]
            break
        case NodeType.DataFlow.description:
            additionalColumns = [
                {
                    id: 'dataExchanges',
                    header: 'Data Exchanges',
                    accessorKey: 'dataExchanges'
                },
            ]
            break
        case NodeType.DataObject.description:
            break
        case NodeType.DesignDecision.description:
            additionalColumns = [
                {
                    id: 'principles',
                    header: 'Principles',
                    accessorKey: 'principleIds',
                    Cell: ({cell} ) => <>{
                        cell?.getValue()?.map(id=>{
                            const node = getNodeById(id)
                            return <>
                                <a href={`/workspace/${workspace}/${node.type}/${node?.id}`}>{node?.name}</a>&nbsp;
                            </>
                        })
                    }</>
                },
            ]
            break
        case NodeType.Domain.description:
            break
        case NodeType.Functionality.description:
            break
        case NodeType.Middleware.description:
            break
        case NodeType.Principle.description:
            additionalColumns = [
                {
                    id: 'status',
                    header: 'Status',
                    accessorKey: 'status'
                },{
                    id: 'rationale',
                    header: 'Rationale',
                    accessorKey: 'rationale'
                },{
                    id: 'implications',
                    header: 'Implications',
                    accessorKey: 'implications'
                },
            ]
            break
        case NodeType.ReferenceArchitecture.description:
            break
        case NodeType.Scenario.description:
            break
        case NodeType.Snapshot.description:
            break
        case NodeType.Tal.description:
            break

        default:
            break
    }
    return defaultColumns.concat(additionalColumns)
}

async function getDataForNodeType(nodeType, getNodeById, searchNodes) {
    return await searchNodes((n) => n.type === nodeType).map((node) => {
        switch (nodeType) {
            case NodeType.Actor.description:
                return node
            case NodeType.Application.description:
                return {
                    id: node.id,
                    name: node.name,
                    description: node.description,
                    supportedCapabilities: node.supportedCapabilityIds?.map(id=>getNodeById(id)?.name).join(", "),
                    rpo: node.rpo,
                    rto: node.rto,

                }
            case NodeType.ApplicationView.description:
                return node
            case NodeType.ArchitectureBuildingBlock.description:
                return node
            case NodeType.BusinessProcess.description:
                return node
            case NodeType.Capability.description:
                return node
            case NodeType.DataExchange.description:
                return {
                    id: node.id,
                    name: node.name,
                    description: node.description,
                    source: getNodeById(node.sourceApplicationId)?.name,
                    target: getNodeById(node.targetApplicationId)?.name,
                    dataObjects: node.dataObjectIds?.map(id=>getNodeById(id)?.name).join(", "),
                    supportingMiddlewares: node.supportingMiddlewareIds?.map(id=>getNodeById(id)?.name).join(", "),
                }
            case NodeType.DataFlow.description:
                return {
                    id: node.id,
                    name: node.name,
                    description: node.description,
                    dataExchanges: node.dataExchangeIds?.map(id=>getNodeById(id)?.name).join(", "),
                }
            case NodeType.DataObject.description:
                return node
            case NodeType.DesignDecision.description:
                return node
            case NodeType.Domain.description:
                return node
            case NodeType.Functionality.description:
                return node
            case NodeType.Middleware.description:
                return node
            case NodeType.Principle.description:

                return node
            case NodeType.ReferenceArchitecture.description:
                return node
            case NodeType.Scenario.description:
                return node
            case NodeType.Snapshot.description:
                return node
            case NodeType.Tal.description:
                return node
            default:
                return node
        }
    })
}

export function FolderDiagram2({node}) {

    const {getNodeById, searchNodes} = useModel()

    LOGGER.debug("node:", node)

    const {selectedWorkspace} = useWorkspaceContext()

    const [columns, setColumns] = useState([
        {
            id: 'name',
            header: 'Name',
            accessorKey: 'name', //simple accessorKey pointing to flat data
            enableColumnActions: false,
        },
        {
            id: 'description',
            header: 'Description',
            accessorKey: 'description', //simple accessorKey pointing to flat data
            enableColumnActions: false,
        },
    ])

    const [data, setData] = useState([])
    //recommended flat structure for data, but not required (nested data is fine, but takes more setup in column definitions)
    useEffect(() => {
        async function doAsynchStuff() {
            if (node?.type === NodeType.Folder.description) {
                const nodeType = getNodeTypeFromFolderId(node.id)
                LOGGER.debug("tempColumns.nodeType:", nodeType)
                const tempColumns = getColumnsForNodeType(selectedWorkspace,getNodeById, nodeType)
                LOGGER.debug("tempColumns.tempColumns:", tempColumns)
                setColumns(tempColumns)
                const tempData = await getDataForNodeType(nodeType, getNodeById, searchNodes)
                setData(tempData)
            }
        }
        doAsynchStuff()
        // eslint-disable-next-line
    }, [node]);

     return <div className={cssStyles.mainDiv}>

         {node.type === NodeType.Folder.description && <>
             <div className={cssStyles.folderTitleDiv}>
                 <span className={cssStyles.folderNameSpan}>{getRootFolderLabel(node.id)}</span>
             </div>
             <div className={addClasses(["muiTableDiv", cssStyles.tableDiv])}>
                 <MaterialReactTable
                     columns={columns}
                     data={data}
                     enableRowSelection //enable some features
                     enableColumnOrdering={false}
                     enableGlobalFilter={false} //turn off a feature
                     enablePagination={false}
                 />
             </div>
         </>}
     </div>
}
