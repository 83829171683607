import Logger from "../../../utils/Logger";
import cssStyles from "./DataFlowDiagram.module.css"
import DataFlowPaper from "./DataFlowDiagram/DataFlowPaper";

const LOGGER = new Logger("DataFlowDiagram")

export default function DataFlowDiagram() {

    LOGGER.trace("DataFlowDiagram created")


    const paperWidth = 2800;
    const paperHeight = 1600;

    return <div className={cssStyles.main}>
        <DataFlowPaper
            paperWidth={paperWidth}
            paperHeight={paperHeight}

        >

        </DataFlowPaper>
    </div>

}
