import cssStyles from "./AccountPage.module.css"
import cssStylesWorkspaces from "./AccountWorkspacesPage.module.css";
import {Button, CircularProgress, IconButton, Tooltip} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import React, {useEffect, useState} from "react";
import Logger from "../../../utils/Logger";
import {WorkspaceRoles} from "../../../model/Constants";
import {useNavigate} from "react-router-dom";
import AccountMenuBar from "./AccountMenuBar";
import {addClasses, calculatePercentage} from "../../../utils/PresentationUtils";
import {useSubscriptionContext} from "../../../subscription/SubscriptionContext";
import {useWorkspaceContext} from "../../../security/WorkspaceContext";
import {useStatusMessage} from "../../StatusMessenger/StatusMessageProvider";
import InfoBox from "../../InfoBox/InfoBox";

const LOGGER = new Logger("AccountWorkspacesPage")

function CloseWorkspaceButton({workspace, closeWorkspace}) {

    const [openWorkspaceId, setOpenWorkspaceId] = useState("")

    function handleOnClick() {
        const workspaceId = workspace?.id
        LOGGER.debug("handleOnClick.workspaceId:", workspaceId)
        setOpenWorkspaceId(workspaceId)
    }

    return <>
        <Tooltip
            title={"Delete workspace"}
        >
            <IconButton
                onClick={(e) => handleOnClick()}
                aria-label="delete"
                size="small"
            >
                <DeleteForeverIcon
                    className={cssStyles.closeIconButton}
                    fontSize="small"
                />
            </IconButton>
        </Tooltip>
        <ConfirmDialog
            key={"ConfirmDialog-key-" + workspace.id}
            title={"Delete workspace?"}
            open={(workspace.id === openWorkspaceId)}
            setOpen={(shouldOpen)=>{
                if (shouldOpen) {
                    setOpenWorkspaceId(workspace.id)
                } else {
                    setOpenWorkspaceId("")
                }
            }}
            onConfirm={(e) => {
                LOGGER.debug("Delete workspace confirmed: ", workspace.id)
                closeWorkspace(workspace)
            }}
        >
            Are you sure you want to delete the <b>"{workspace.name}"</b> workspace?
        </ConfirmDialog>
    </>
}

function LeaveWorkspaceButton({workspace, leaveWorkspace}) {

    const [openWorkspaceId, setOpenWorkspaceId] = useState("")

    function handleOnClick() {
        const workspaceId = workspace?.id
        LOGGER.debug("handleOnClick.workspaceId:", workspaceId)
        setOpenWorkspaceId(workspaceId)
    }

    return <>
        <Tooltip title={"leave workspace"}>
            <IconButton onClick={(e) => handleOnClick()} aria-label="delete" size="small">
                <ExitToAppIcon
                    fontSize="small"
                    className={cssStyles.leaveIconButton}
                />
            </IconButton>
        </Tooltip>
        <ConfirmDialog
            key={"ConfirmDialog-key-" + workspace.id}
            title={"Leave workspace (" + workspace.id + ")?"}
            open={(workspace.id === openWorkspaceId)}
            setOpen={(shouldOpen)=>{
                if (shouldOpen) {
                    setOpenWorkspaceId(workspace.id)
                } else {
                    setOpenWorkspaceId("")
                }
            }}
            onConfirm={(e) => {
                LOGGER.debug("Leave workspace confirmed: ", workspace.id)
                leaveWorkspace(workspace)
            }}
        >
            Are you sure you want to leave the '{workspace.name}' workspace?
        </ConfirmDialog>
    </>
}


export default function AccountWorkspacesPage({user}) {
    const setNewMessage = useStatusMessage()
    const {limits, counters, subscription} = useSubscriptionContext()
    const navigate = useNavigate();

    const {workspaces, closeWorkspace, leaveWorkspace, areWorkspacesLoading, errorLoadingMessage} = useWorkspaceContext()

    const [workspaceCount, setWorkspaceCount] = useState(0)
    const [workspaceLimit, setWorkspaceLimit] = useState(0)
    const [objectLimit, setObjectLimit] = useState(0)
    const [totalCount, setTotalCount] = useState(0)

    let isWorkspaceLimitAchieved = workspaceCount >= workspaceLimit
    let isObjectLimitAchieved = counters?.objectCount >= objectLimit

    useEffect(()=>{
        setWorkspaceLimit(limits?.maxWorkspaces)
        setObjectLimit(limits?.maxObjects)
        if (counters?.objectCount?.totalCounts && Object.values(counters?.objectCount?.totalCounts).length > 0) {
            setTotalCount(Object.values(counters?.objectCount?.totalCounts).reduce((prev, curr) => {
                return prev + curr
            }))
        }
    }, [limits, counters])

    useEffect(() => {
        if (errorLoadingMessage) {
            LOGGER.error("Error loading workspaces: ", errorLoadingMessage)
            setNewMessage("Problem loading workspaces", "alert")
        }
    }, [errorLoadingMessage, setNewMessage]);

    useEffect(()=>{
        setWorkspaceCount(workspaces.length)
    }, [workspaces])

    return <div className={cssStyles.mainDiv}>
        <AccountMenuBar pane={"workspacesPane"}/>
        <InfoBox isCloseable={false}>
            <p>Here's you'll find information about your account and across all workspaces.</p>
            <p>For instance, the Actor count you'll see below is the sum of all the actor counts in all your workspaces.</p>
        </InfoBox>
        {areWorkspacesLoading && <div className={cssStyles.statusDiv}>
            <CircularProgress size={"20px"}/> <span className={cssStyles.statusMessage}>Loading...</span>
        </div> }
        <table className={cssStyles.workspaceTable}>
            <tbody>
            {user.name && <>
                    <tr>
                        <th className={cssStyles.headerCell}>Name</th>
                        <td className={addClasses([cssStyles.leftAlign, cssStyles.valueCell])}>{user.name}</td>
                    </tr>
                    <tr>
                        <td colSpan={3}><hr/></td>
                    </tr>
                </>
            }
            <tr>
                <th className={cssStyles.headerCell}>Email</th>
                <td className={addClasses([cssStyles.leftAlign, cssStyles.valueCell])}>{user.email}</td>
            </tr>
            <tr>
                <td colSpan={3}>
                    <hr/>
                </td>
            </tr>
            {areWorkspacesLoading && <tr>
                <td colSpan={2}><span>Loading workspaces...</span></td>
            </tr>}
            {!areWorkspacesLoading && <><tr>
                    <th className={cssStyles.headerCell}>
                        Workspaces<br/>
                        <span
                            className={(isWorkspaceLimitAchieved ? cssStylesWorkspaces.workspaceCountNOK : cssStylesWorkspaces.workspaceCountOK)}>
                            {workspaceCount}
                        </span>
                        /
                        <span className={cssStylesWorkspaces.workspaceLimit}>
                            {workspaceLimit}
                        </span>
                    </th>
                    <td className={addClasses([cssStyles.leftAlign, cssStyles.valueCell])}>
                        <table className={cssStyles.workspaceListTable}>
                            <tbody>

                            {
                                workspaces &&
                                workspaces.map((workspace, index) => {
                                    return (<tr className={(index % 2 === 0 ? cssStyles.evenRows : cssStyles.oddRows)}
                                                key={"div" + workspace.id}>
                                        <td title={workspace.id}>{workspace.name}</td>
                                        <td>{workspace.role}</td>
                                        <td>
                                            {(workspace.role === WorkspaceRoles.Admin) &&
                                                <CloseWorkspaceButton workspace={workspace}
                                                                      closeWorkspace={closeWorkspace}/>}
                                            {(workspace.role === WorkspaceRoles.Editor || workspace.role === WorkspaceRoles.Reviewer) &&
                                                <LeaveWorkspaceButton workspace={workspace}
                                                                      leaveWorkspace={leaveWorkspace}/>}
                                        </td>
                                    </tr>)
                                })
                            }

                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <hr/>
                    </td>
                </tr>
            </>}
            {workspaces?.length <= 0 && <tr>
                <td colSpan={2}><span>No workspaces yet.</span></td>
            </tr>}
            {counters?.objectCount?.totalCounts && Object.values(counters?.objectCount?.totalCounts) >0 && <tr>
                <td colSpan={2}><span>Loading object counts...</span></td>
            </tr>}
            {counters?.objectCount?.totalCounts && <>
                <tr>
                    <th className={cssStyles.headerCell}>Number of Objects</th>
                    <td className={addClasses([cssStyles.leftAlign, cssStyles.valueCell])}>
                        <table>
                            <tbody>
                            <tr>
                                <td colSpan={2}>
                                    <span
                                        className={(isObjectLimitAchieved ? cssStylesWorkspaces.objectLimitNOK : cssStylesWorkspaces.objectLimitOK)}>{JSON.stringify(totalCount)}</span>
                                    &nbsp;out of&nbsp;
                                    <span className={cssStylesWorkspaces.objectLimit}>{objectLimit}</span>&nbsp;
                                    <span
                                        className={cssStylesWorkspaces.objectLimit}>({calculatePercentage(totalCount, objectLimit)}%)</span>.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Actor Objects
                                </td>
                                <td>
                                        <span
                                            className={cssStylesWorkspaces.subObjectCounter}>{counters?.objectCount?.totalCounts?.actor || 0}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Application Objects
                                </td>
                                <td>
                                        <span
                                            className={cssStylesWorkspaces.subObjectCounter}>{counters?.objectCount?.totalCounts?.application || 0}</span>
                                </td>
                            </tr>
                            {subscription === "PRO" && <>
                                <tr>
                                    <td>
                                        Business Process Objects
                                    </td>
                                    <td>
                                                <span
                                                    className={cssStylesWorkspaces.subObjectCounter}>{counters?.objectCount?.totalCounts?.business_process || 0}</span>
                                    </td>
                                </tr>
                            </>
                            }
                            <tr>
                                <td>
                                    Capability Objects
                                </td>
                                <td>
                                        <span
                                            className={cssStylesWorkspaces.subObjectCounter}>{counters?.objectCount?.totalCounts?.capability || 0}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Data Exchange Objects
                                </td>
                                <td>
                                        <span
                                            className={cssStylesWorkspaces.subObjectCounter}>{counters?.objectCount?.totalCounts?.data_exchange || 0}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Data Flow Objects
                                </td>
                                <td>
                                        <span
                                            className={cssStylesWorkspaces.subObjectCounter}>{counters?.objectCount?.totalCounts?.data_flow || 0}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Data Object Objects
                                </td>
                                <td>
                                        <span
                                            className={cssStylesWorkspaces.subObjectCounter}>{counters?.objectCount?.totalCounts?.data_object || 0}</span>
                                </td>
                            </tr>
                            {subscription === "PRO" && <>
                                <tr>
                                    <td>
                                        Design Decision Objects
                                    </td>
                                    <td>
                                                <span
                                                    className={cssStylesWorkspaces.subObjectCounter}>{counters?.objectCount?.totalCounts?.design_decision || 0}</span>
                                    </td>
                                </tr>
                            </>
                            }
                            <tr>
                                <td>
                                    Maturity Model Analysis Objects
                                </td>
                                <td>
                                        <span
                                            className={cssStylesWorkspaces.subObjectCounter}>{counters?.objectCount?.totalCounts?.middleware || 0}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Middleware Objects
                                </td>
                                <td>
                                        <span
                                            className={cssStylesWorkspaces.subObjectCounter}>{counters?.objectCount?.totalCounts?.middleware || 0}</span>
                                </td>
                            </tr>

                            {subscription === "PRO" && <>
                                <tr>
                                    <td>
                                        Principle Objects
                                    </td>
                                    <td>
                                                <span
                                                    className={cssStylesWorkspaces.subObjectCounter}>{counters?.objectCount?.totalCounts?.principle || 0}</span>
                                    </td>
                                </tr>
                            </>
                            }

                            {subscription === "PRO" && <>
                                <tr>
                                    <td>
                                        Scenario Objects
                                    </td>
                                    <td>
                                                <span
                                                    className={cssStylesWorkspaces.subObjectCounter}>{counters?.objectCount?.totalCounts?.scenario || 0}</span>
                                    </td>
                                </tr>
                            </>
                            }
                            <tr>
                                <td>
                                    TAL Objects
                                </td>
                                <td>
                                        <span
                                            className={cssStylesWorkspaces.subObjectCounter}>{counters?.objectCount?.totalCounts?.tal || 0}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    View Objects
                                </td>
                                <td>
                                        <span
                                            className={cssStylesWorkspaces.subObjectCounter}>{counters?.objectCount?.totalCounts?.application_view || 0}</span>
                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </td>

                </tr>
                <tr>
                    <td colSpan={2}>
                        <hr/>
                    </td>
                </tr>
            </>}
            {isWorkspaceLimitAchieved && <tr>
                <td colSpan={2}>
                    <div className={cssStylesWorkspaces.maxedLimitMessage}>
                        You've reached the maximum number of workspaces included in your subscription.<br/>
                        <Button variant="contained" onClick={(e) => {
                            navigate("/account/subscription")
                        }}>Become a PRO subscriber</Button>
                    </div>
                </td>
            </tr>}
            {!isWorkspaceLimitAchieved && <tr>
                <td></td>
                <td className={cssStyles.rightAlign}>
                    <Button
                        disabled={isWorkspaceLimitAchieved}
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => navigate("/account/workspaces/create")}
                    >create workspace</Button>
                </td>
            </tr>}
            </tbody>
        </table>
    </div>
}
