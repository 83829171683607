import cssStyles from "./AccountPersonalPage.module.css";
import InfoBox from "../../InfoBox/InfoBox";
import AccountMenuBar from "./AccountMenuBar";
import React, {useEffect} from "react";
import {useSecurityContext} from "../../../security/SecurityContext";
import Logger from "../../../utils/Logger";
import {addClasses, displayString} from "../../../utils/PresentationUtils";
import {useSubscriptionContext} from "../../../subscription/SubscriptionContext";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {stringToHumanDateString} from "../../../utils/StringUtils";

const LOGGER = new Logger("AccountPersonalPage")

function formatSubscriptionStatus(subscriptionStatus) {
    /*
    "active",
    "canceled",
    "past_due",
    "paused",
    "trialing",
    "unknown"
     */
    switch (subscriptionStatus) {
        case "active":
            return "Active"
        case "canceled":
            return "Canceled"
        case "past_due":
            return "Past Due"
        case "trialing":
            return "Trial"
        default:
            return "No subscription"
    }
}

export default function AccountPersonalPage() {

    const navigate = useNavigate();

    const {user} = useSecurityContext()
    const {userProfile} = useSubscriptionContext()

    useEffect(() => {
        LOGGER.debug("AccountPersonalPage.useEffect().user: ", user)
    }, [user]);

    return <div className={cssStyles.mainDiv}>
        <AccountMenuBar pane={"personalPane"}/>
        <InfoBox>The personal information we keep on record.</InfoBox>
        <table className={cssStyles.accountTable}>
            <tbody>
            {user.id && <>
                <tr>
                    <th className={cssStyles.headerCell}>Unique User id</th>
                    <td data-testid={"unique-user-id"} className={addClasses([cssStyles.leftAlign, cssStyles.valueCell])}>{user.id}</td>
                </tr>
                <tr>
                    <td colSpan={3}><hr/></td>
                </tr>
            </>
            }
            {user.name && <>
                <tr>
                    <th className={cssStyles.headerCell}>Name</th>
                    <td data-testid={"user-name"} className={addClasses([cssStyles.leftAlign, cssStyles.valueCell])}>{user.name}</td>
                </tr>
                <tr>
                    <td colSpan={3}><hr/></td>
                </tr>
            </>
            }
            {user.email && <>
                <tr>
                    <th className={cssStyles.headerCell}>Email</th>
                    <td data-testid={"email"} className={addClasses([cssStyles.leftAlign, cssStyles.valueCell])}>{user.email}</td>
                </tr>
                <tr>
                    <td colSpan={3}><hr/></td>
                </tr>
            </>
            }
            {<>
                <tr>
                    <th className={cssStyles.headerCell}>Paddle Customer ID</th>
                    <td data-testid={"paddle-customer-id"} className={addClasses([cssStyles.leftAlign, cssStyles.valueCell])}>{displayString(userProfile.paddleCustomerId)}</td>
                </tr>
                <tr>
                    <td colSpan={3}><hr/></td>
                </tr>
            </>}
            {<>
                <tr>
                    <th className={cssStyles.headerCell}>Subscription Status</th>
                    <td data-testid={"subscription-status"} className={addClasses([cssStyles.leftAlign, cssStyles.valueCell])}>{formatSubscriptionStatus(userProfile?.subscriptionStatus)} active until <b data-testid={"trial-end-date"} className={cssStyles.trialEndDate}>{stringToHumanDateString(userProfile?.trial?.end)}</b></td>
                </tr>
                <tr>
                    <td colSpan={3}><hr/></td>
                </tr>
            </>
            }
            {<>
                <tr>
                    <td colSpan={2} style={{textAlign: "center"}}>
                        <Button variant="contained" onClick={(e)=>{navigate("/set-password")}}>Change your password</Button>
                    </td>
                </tr>
                <tr>
                    <td colSpan={3}><hr/></td>
                </tr>
            </>
            }
            </tbody>
        </table>
    </div>

}
