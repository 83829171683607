import cssStyles from "./Link.module.css";
import {addClasses} from "../../../utils/PresentationUtils";

export function Link({href, type, children}) {
    let classNames = [cssStyles.main]
    if (type === "error") {
        classNames.push(cssStyles.error)
    }
    return <a className={addClasses(classNames)} href={href}>{children}</a>
}
