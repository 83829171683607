import React, {createContext, useContext, useEffect, useState} from 'react';
import * as joint from "@joint/plus";
// eslint-disable-next-line
import cssStyles from "./StatusMessageProvider.module.css"; // this import is needed to make sure the css is included in the build
import Logger from "../../utils/Logger";

const LOGGER = new Logger("StatusMessageProvider")

const StatusMessageContext = createContext();

export const useStatusMessage = () => {
    return useContext(StatusMessageContext);
};

export const StatusMessageProvider = ({ children }) => {
    const [message, setMessage] = useState(null);

    const setNewMessage = (newMessage, messageType) => {
        LOGGER.debug(`setNewMessage(${newMessage}, ${messageType})`)
        setMessage((oldValue)=>({
            text: newMessage,
            type: messageType,
        }))
    };

    useEffect(() => {
        if (typeof message?.text !== "string" || message?.text?.trim().length === 0) {
            return
        }

        let openOptions = {
            type: (message.type || "info"),
        }
        if (message.type !== "alert") {
            openOptions.closeAnimation = {
                delay: 2000, // 2 seconds delay
                duration: 1000, // 1 second duration
                easing: 'swing', // swing easing. You can use other jQuery UI easings too.
                properties: {
                    opacity: 0 // Fade out
                }
            }
        } else {
            openOptions.closeAnimation = false
        }

        joint.ui.FlashMessage.open(message.text, "", openOptions)
    }, [message]);

    return (
        <StatusMessageContext.Provider value={setNewMessage}>
            {children}
        </StatusMessageContext.Provider>
    );
};
