import Logger from "../../../../utils/Logger";
import {createContext, useContext, useState} from "react";

const LOGGER = new Logger("SearchContext", 2)

const SearchContext = createContext();

export const useSearchContext = () => {
    return useContext(SearchContext);
}

export function SearchContextProvider({children}) {

    LOGGER.trace("initializing SearchContextProvider")

    const [searchText, setSearchText] = useState("");

    return (
        <SearchContext.Provider value={{
            searchText, setSearchText
        }}>
            {children}
        </SearchContext.Provider>
    );
}
