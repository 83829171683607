import * as jointjs from "@joint/plus";
import Logger from "../../../utils/Logger";
import cssStyles from "./BusinessProcessDiagram.module.css"
import BusinessProcessPaper from "./BusinessProcessDiagram/BusinessProcessPaper";
import {FormControl, IconButton} from "@mui/material";
import ShareButton from "./ShareButton/ShareButton";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {download} from "./utils/DiagramUtils";
import {showDialog} from "./utils/JointjsUtils";
import {useModel} from "../../../model/ModelContext";

const LOGGER = new Logger("BusinessProcessDiagram")

function viewShareOptions() {
    LOGGER.debug("getting viewShareOptions")
    return  [
        {
            format: "PNG",
            label: "Export as PNG image",
            grid: true,
            executeOption: async (paper)=>{
                jointjs.format.toPNG(paper, (dataURL) => {
                    download(dataURL, 'graph.png');
                }, {grid:true});
            }
        },
    ]
}



function BusinessProcessDiagram({node}, ref) {

    LOGGER.trace("BusinessProcessDiagram created")

    const {saveNode} = useModel()

    const paperWidth = 2800;
    const paperHeight = 1600;

    const businessProcessPaperRef = useRef()

    function clearPaper() {
        showDialog({
            text: "Are you sure you wish to clear all elements from the diagram?",
            buttons: [{
                id: "yes",
                text: "YES",
                handler: () => {
                    businessProcessPaperRef.current.clearPaper()
                    node.activityIds = []
                    node.view = businessProcessPaperRef.current.toJSON()
                    saveNode(node)
                },
            }, {
                id: "no",
                text: "NO",
                handler: () => {},
                sameAsClose: true,
            } ],
        });

    }

    return <div ref={ref} className={cssStyles.main}>
        <div className={cssStyles.filterPane}>
            <FormControl component="fieldset">
                <ShareButton
                    getPaper={()=> {
                        return businessProcessPaperRef.current.getPaper()
                    }}
                    shareOptions={viewShareOptions()}
                />
            </FormControl>
            <IconButton className={cssStyles.redIconButton} aria-label="clear" onClick={()=>{clearPaper()}}>
                <LayersClearIcon className={cssStyles.redIcon}/>
            </IconButton>
        </div>
        <BusinessProcessPaper
            ref={businessProcessPaperRef}
            paperWidth={paperWidth}
            paperHeight={paperHeight}
        >

        </BusinessProcessPaper>
    </div>

}
export default forwardRef(BusinessProcessDiagram)
