import cssStyles from "./Welcome.module.css";
import {useSecurityContext} from "../../../security/SecurityContext";
import {useWizard, Wizard} from "react-use-wizard";
import React from "react";
import {CreateWorkspaceForm} from "../../CreateWorkspaceForm/CreateWorkspaceForm";
import {Button} from "@mui/material";
import {Kenchiku} from "../../Kenchiku/Kenchiku";
import {useNavigate} from "react-router-dom";

function WelcomeStep1() {

    const securityContext = useSecurityContext()

    const { nextStep } = useWizard();

    return <div className={cssStyles.step1}>

        <div className={cssStyles.stepContent}>
            <h1>Welcome {securityContext?.user?.name}</h1>
            <p>This is the first time you are logging in to <Kenchiku/>.</p>
            <p>We will take you through a few steps to get you started.</p>
        </div>
        <div className={cssStyles.stepActions}>
            <Button variant="contained" onClick={() => nextStep()}>let's go</Button>
        </div>
    </div>
}
function WelcomeStep2() {
    const { nextStep } = useWizard();

    return <div className={cssStyles.step1}>

        <div className={cssStyles.stepContent}>
            <h1>First up, we need to create your first workspace</h1>
            <p>
                You organize your work in workspaces. A workspace is a collection of model objects and diagrams.
            </p>
            <p>
                You typically create a workspace for each enterprise or customer you are working on.
            </p>
            <CreateWorkspaceForm
                onCreated={(newWorkspace) => {
                    console.debug("newWorkspace:", newWorkspace)
                    nextStep()
                }}
                onError={(errors, e)=>{
                    console.error("onError.errors:", errors)
                    console.error("onError.e:", e)
                }}
            />
        </div>

    </div>
}

function WelcomeStepFinal() {

    const navigate = useNavigate();

    return <div className={cssStyles.step1}>

        <div className={cssStyles.stepContent}>
            <h1>All set!</h1>
            <p>That's all there is to start using <Kenchiku/>.</p>
        </div>
        <div className={cssStyles.stepActions}>
            <Button variant="contained" onClick={() => {
                navigate("/quick-tutorial")
            }}>Quick tutorial</Button>
            <Button variant="contained" onClick={() => {
                navigate("/workspace")
            }}>Go play!</Button>
        </div>
    </div>
}

export function Welcome() {



    return <div className={cssStyles.main}>
        <Wizard>
            <WelcomeStep1/>
            <WelcomeStep2/>
            <WelcomeStepFinal/>
        </Wizard>

    </div>
}
