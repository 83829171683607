import ReactQuill from "react-quill";
import {useEffect, useRef, useState} from "react";
import 'react-quill/dist/quill.snow.css'; // import Quill styles
import Logger from "../../../../utils/Logger";
import {Controller} from "react-hook-form";
import {NODE_FILL_COLORS, NODE_TEXT_COLORS} from "../../../../utils/NodeTypeColors";
import cssStyles from "./RichTextLabeler.module.css";

const LOGGER = new Logger("RichTextLabeler", 1);

function getWordRegExp(word) {
    return new RegExp(" {" + word.replaceAll("(", "\\(").replaceAll(")", "\\)") + "} ", 'gi');
    //return new RegExp("^{" + word.replaceAll("(", "\\(").replaceAll(")", "\\)").replaceAll(".", "\\.") + "}$", 'gi');
}

function highlightWords(editor, wordsToHighlight) {

    if (!editor) {
        LOGGER.trace("Editor not ready yet")
        return;
    }

    const unformattedText = editor.getText() || "";

    const regexp = /\{([^}]*)\}/g;
    let match;
    while ((match = regexp.exec(unformattedText)) !== null) {

        let matchedTokenWithBrackets = match[0];
        let matchedToken = match[1];
        //console.log(matchedToken);  // Output the words inside the curly brackets
        const wordToHighlight = wordsToHighlight.find(word => word.token?.toLowerCase() === matchedToken?.toLowerCase())
        if (wordToHighlight) {
            LOGGER.trace("wordToHighlight:", wordToHighlight)
            editor.formatText(match.index + 1, matchedTokenWithBrackets.length-2, {
                background: `${wordToHighlight.backgroundColor}`,
                color: `${wordToHighlight.color}`
            });
        }

    }

}

export function RichTextLabeler({control, id="description",
                                    name="description",
                                    label="Description",
                                    minRows=3,
                                    nodesToRecognize,
                                    className="rich-text-labeler",
                                    errors
}) {

    const suggestions = useRef([]);

    const isDropdownVisible = useRef(false);

    const [wordsToHighlight, setWordsToHighlight] = useState([])

    const quillRef = useRef(null); // Create a ref to attach to ReactQuill
    const isFormatting = useRef(false); // Ref to track if the change is formatting
    const [selectedIndex, setSelectedIndex] = useState(0); // Ref to track the selected index

    const zeSelectedIndex = useRef(0);


    useEffect(() => {
        zeSelectedIndex.current = selectedIndex;
    }, [selectedIndex]);

    let editorRef = useRef(null);

    const editorModules = {
        toolbar: false,
    };

    const arrowDownCallback = (range, context) => {
        LOGGER.debug('Arrow Down key pressed');
        if (!isDropdownVisible.current) {
            LOGGER.debug("showDropdown is false")
            return;
        }
        LOGGER.debug(`current, length: ${zeSelectedIndex.current}, ${suggestions.current.length}`)
        if (zeSelectedIndex.current < suggestions.current.length - 1) {
            LOGGER.debug("zeSelectedIndex < suggestions.length - 1")
            setSelectedIndex(zeSelectedIndex.current + 1);
        } else {
            setSelectedIndex(0);
        }
    }


    const arrowUpCallback = (range, context) => {
        LOGGER.debug('Arrow Down key pressed');
        if (!isDropdownVisible.current) {
            LOGGER.debug("showDropdown is false")
            return;
        }
        LOGGER.debug(`current, length: ${zeSelectedIndex.current}, ${suggestions.current.length}`)
        if (zeSelectedIndex.current > 0) {
            LOGGER.debug("zeSelectedIndex > 0")
            setSelectedIndex(zeSelectedIndex.current - 1);
        } else {
            setSelectedIndex(suggestions.current.length - 1);
        }
    }

    const newlineCallback = (range, context) => {
        if (!isDropdownVisible.current) {
            LOGGER.debug("dropdown not shown")
            return true // Allow default behavior
        }
        LOGGER.debug("dropdown is shown")
        isDropdownVisible.current = false;
        selectSuggestion(wordsToHighlight, suggestions.current[zeSelectedIndex.current].label)
        return false // Prevent default behavior
    }

    useEffect(() => {
        if (!quillRef.current) {
            LOGGER.trace("Quill not ready yet")
            return;
        }

        editorRef.current = quillRef.current.getEditor();

        if (editorRef.current) {
            editorRef.current.keyboard.addBinding({ key: 40 }, arrowDownCallback)
            editorRef.current.keyboard.addBinding({ key: 38 }, arrowUpCallback)

            editorRef.current.keyboard.bindings[13] = [];
            editorRef.current.keyboard.addBinding({ key: 13 }, newlineCallback)
        }

    }, [quillRef]);




    useEffect(() => {

        if (!editorRef?.current) {
            LOGGER.trace("Editor not ready yet")
            return;
        }

        const editor = editorRef.current;

        setWordsToHighlight(nodesToRecognize?.map(node => {
            return {
                token: node.name,
                color: NODE_TEXT_COLORS[node.type],
                backgroundColor: NODE_FILL_COLORS[node.type],
                regexp: getWordRegExp(node.name)
            }
        }))

        const completionWords = nodesToRecognize?.map(node => {return {
            token: "{" + node.name + "}",
            label: node.name
        }})

        const handleTextChange = (delta, oldDelta, source) => {
            LOGGER.debug(`handleTextChange.source: "${source}"`)
            LOGGER.debug(`handleTextChange.delta: "${JSON.stringify(delta)}"`)

            if (source === 'api') {

                LOGGER.debug("source === 'api'")
                if (isFormatting.current) {
                    isFormatting.current = false; // Reset formatting flag
                }
                // Highlight specific words
                if (wordsToHighlight) {
                    const unformattedTextBeforeHighlight = editor.getText();
                    LOGGER.debug(`handleTextChange.unformattedTextBeforeHighlight: "${JSON.stringify(unformattedTextBeforeHighlight)}"`)
                    highlightWords(editor, wordsToHighlight)
                }
                return; // Ignore non-user changes
            }

            const unformattedText = editor.getText();

            LOGGER.debug(`handleTextChange.unformattedText: "${JSON.stringify(unformattedText)}"`)
            LOGGER.debug(`handleTextChange.delta: "${JSON.stringify(delta)}"`)
            LOGGER.debug(`handleTextChange.oldDelta: "${JSON.stringify(oldDelta)}"`)

            // Prevent handling text change during formatting
            isFormatting.current = true;

            // Reset previously applied formatting
            editor.formatText(0, unformattedText.length, 'background', false);
            editor.formatText(0, unformattedText.length, 'color', 'black');

            const unformattedTextAfterFormat = editor.getText();
            LOGGER.debug(`handleTextChange.unformattedTextAfterFormat: "${JSON.stringify(unformattedTextAfterFormat)}"`)

            // Highlight specific words
            highlightWords(editor, wordsToHighlight)

            const range = editor.getSelection();
            if (range && range.index > 0) {
                const textUpToCursor = editor.getText(0, range.index);
                const openBracketIndex = textUpToCursor.lastIndexOf("{")
                if (openBracketIndex >= 0) {
                    const lastWord = textUpToCursor.substring(openBracketIndex, range.index)
                    LOGGER.debug(`handleTextChange.lastWord:"${lastWord}"`)

                    if (lastWord.length > 0) {
                        const matchedWords = completionWords.filter(word =>
                            word.token.toLowerCase().startsWith(lastWord.toLowerCase())
                        );
                        suggestions.current = (matchedWords);
                        if (matchedWords.length > 0) {
                            isDropdownVisible.current = true;
                        } else {
                            isDropdownVisible.current = false
                        }
                    } else {
                        isDropdownVisible.current = false
                    }
                }
            }

            isFormatting.current = false; // Reset formatting flag
            //highlightWords(editor, wordsToHighlight)
        };

        const currentEditor = quillRef.current;
        if (currentEditor) {
            currentEditor.getEditor().on('text-change', handleTextChange);
        }

        return () => {
            if (currentEditor) {
                currentEditor.getEditor().off('text-change', handleTextChange);
            }
        };
        // eslint-disable-next-line
    }, [nodesToRecognize, editorRef]);

    const selectSuggestion = (wordsToHighlight, word) => {
        LOGGER.debug("selectSuggestion.word:", word)
        const editor = quillRef.current.getEditor();
        editor.focus()
        const range = editor.getSelection();
        if (range && range.index > 0) {
            const textUpToCursor = editor.getText(0, range?.index);
            const openBracketIndex = textUpToCursor.lastIndexOf("{")
            const lastWord = textUpToCursor.substring(openBracketIndex, range.index)
            LOGGER.debug("selectSuggestion.textUpToCursor:", textUpToCursor)
            LOGGER.debug("selectSuggestion.lastWord:", lastWord)
            const startDeleteIndex = range.index - range.length - (lastWord ? lastWord.length : 0);
            const endDeleteLength = range.length + (lastWord ? lastWord.length : 0);
            LOGGER.debug(`selectSuggestion.deleting from ${startDeleteIndex} for length ${endDeleteLength}`)
            editor.deleteText(startDeleteIndex, endDeleteLength);
            editor.insertText(startDeleteIndex,  "{" + word + '} ');
            isDropdownVisible.current = false;

            const unformattedText = editor.getText();
            editor.formatText(0, unformattedText.length, 'background', false);

            highlightWords(editor, wordsToHighlight)

        }
    };




    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}})=> {
                return <div
                    className={className}
                    > {/* You can set the min-height directly here */}
                    {label && <span className={cssStyles.labelSpan}><label htmlFor={id} className={cssStyles.label}>{label}</label></span>}
                    {errors?.length > 0 && <div className={cssStyles.errors}>{errors[0].message}</div>}
                    <ReactQuill
                        ref={quillRef}
                        value={value}
                        preserveWhitespace={true}
                        onChange={(content, delta, source) => {
                            if (!isFormatting.current) {
                                onChange(content)
                            }
                            if (wordsToHighlight) {
                                highlightWords(editorRef?.current, wordsToHighlight)
                            }
                        }}
                        theme="snow"
                        modules={editorModules}
                        style={{height: '200px', display: "block"}}  // Apply min-height directly to ReactQuill
                    />
                    {isDropdownVisible.current && (
                        <div className={cssStyles.dropDownDiv}>
                            {suggestions.current.map((word, index) => (
                                <div
                                    className={cssStyles.dropDownSuggestion + (index === (selectedIndex)?" " + cssStyles.isSelected:"")}
                                    key={index}
                                    onClick={() => selectSuggestion(wordsToHighlight, word.label)}
                                    style={{ padding: '10px', cursor: 'pointer' }}
                                >
                                    {word.label}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            }}
        />
    );
}
