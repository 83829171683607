export const ARROW_TYPES = {
    "smooth": {
        router: {
            name: 'manhattan',
            args: {
                step: 20,  // the size of the step/grid to which the link path will snap
                excludeEnds: ['source', 'target']  // ensures the link avoids its source and target elements
            }
        },
        connector: {
            name: 'smooth',    // or 'normal', 'smooth'
            args: { /* connector-specific arguments if needed */ }
        }
    },
    "grid": {
        router: {
            name: 'manhattan',
            args: {
                step: 20,  // the size of the step/grid to which the link path will snap
                excludeEnds: ['source', 'target']  // ensures the link avoids its source and target elements
            }
        },
        connector: {
            name: 'rounded',    // or 'normal', 'smooth'
            args: { /* connector-specific arguments if needed */ }
        }
    },
    "metro": {
        router: {
            name: 'metro',
            args: {
                step: 20,  // the size of the step/grid to which the link path will snap
                excludeEnds: ['source', 'target']  // ensures the link avoids its source and target elements
            }
        },
        connector: {
            name: 'rounded',    // or 'normal', 'smooth'
            args: { /* connector-specific arguments if needed */ }
        }
    },
    "straight": {
    }
}

export const AC_RECT_DEFAULTSIZE = {
    width: 90,
    height: 50,
}

export const AC_RECT_LABEL = {
    height: 40,
    defaultFontSize: 12,
}

export const DO_RECT_LABEL = {
    height: 25,
    paddingHeight: 5,
    paddingSides: 5,
    defaultFontSize: 12,
}
