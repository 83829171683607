import cardStyles from "./Card.module.css";
import {NodeType} from "../../../model/Constants";
import * as React from "react";
import Logger from "../../../utils/Logger";
import cssStyles from "./DataObjectDiagram.module.css";

const LOGGER = new Logger("Card")


export function Card({node, handleNodeClick, level, selectedNodes, additionalClassname=""}) {
    LOGGER.trace("Creating Card, node type: ", node?.type)
    let typeClassname = ""
    let onClickHandler = handleNodeClick
    switch (node?.type) {
        case "_label":
            typeClassname = cardStyles.labelCard
            onClickHandler = ()=>{LOGGER.debug("no onclick handling needed for labels!")}
            break
        case NodeType.Actor.description:
            typeClassname = cssStyles.actorCard
            break
        case NodeType.Application.description:
            typeClassname = cardStyles.applicationCard
            break
        case NodeType.Capability.description:
            typeClassname = cardStyles.capabilityCard
            break
        case NodeType.DesignDecision.description:
            typeClassname = cssStyles.designDecisionCard
            break
        case NodeType.DataExchange.description:
            typeClassname = cardStyles.dataExchangeCard
            break
        case NodeType.DataFlow.description:
            typeClassname = cardStyles.dataFlowCard
            break
        case NodeType.DataObject.description:
            typeClassname = cardStyles.dataObjectCard
            break
        case NodeType.Middleware.description:
            typeClassname = cardStyles.middlewareCard
            break
        case NodeType.Principle.description:
            typeClassname = cssStyles.principleCard
            break
        default:
            break
    }
    const nodeId = node?.id
    const nodeName = node?.name
    return (
        <div id={"card-"+nodeId} key={"card-key-"+nodeId} className={[additionalClassname, cardStyles.card, typeClassname, ((selectedNodes?.includes(nodeId))?cardStyles.isSelected:"")].join(" ")} data-level={(level%2===0?"even":"odd")}>
            <div className={cardStyles.nodeName} onClick={onClickHandler}>{nodeName}</div>
        </div>
    )
}
