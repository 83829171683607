import {createContext, useContext, useState} from "react";
import Logger from "../../../utils/Logger";

const LOGGER = new Logger("TreeDndContext", 2)

const TreeDndContext = createContext();

export const useTreeDndContext = () => {
    return useContext(TreeDndContext);
}

export function TreeDndContextProvider({children}) {

    LOGGER.trace("initializing TreeDndContextProvider")

    const [isDraggingActive, setIsDraggingActive] = useState("");
    const [nodeBeingDragged, setNodeBeingDragged] = useState({});
    const [droppedNode, setDroppedNode] = useState({});
    const [dragPosition, setDragPosition] = useState({});

    const dropNode = (node, monitor) => {
        setDroppedNode(node);
        //setDragPosition(position);
    }

    return (
        <TreeDndContext.Provider value={{
            isDraggingActive, setIsDraggingActive,
            nodeBeingDragged, setNodeBeingDragged,
            droppedNode, setDroppedNode,
            dragPosition, setDragPosition,
            dropNode
        }}>
            {children}
        </TreeDndContext.Provider>
    );
}
