import styles from "./CustomNode.module.css";
import {getNodeTypeIcon} from "../../../model/Constants";
import {useSelectedNodes} from "../../SelectedNodes/SelectedNodesProvider";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useDragOver} from "@minoru/react-dnd-treeview";
import {useEffect, useRef, useState} from "react";
import Logger from "../../../utils/Logger";
import TreeContextMenu from "../TreeComponent2/TreeContextMenu";
import {useModel} from "../../../model/ModelContext";
import {useLoadingOverlay} from "../../LoadingOverlay/LoadingOverlay";
import useOnEscape from "./hooks/useOnEscape";
import useOnClickOutside from "./hooks/useOnClickOutside";
import {useCreateOrEditDialog} from "../../DialogBoxes/CreateOrEditDialogProvider";

const LOGGER = new Logger("CustomNode")


export const CustomNode = ({ testIdPrefix = "", node, depth, isOpen, onToggle, path, hasChild}) => {

    const initialContextMenu = {
        showing: false,
        x: 0,
        y: 0,
        node: {},
        path: null
    }

    const renamingInputRef = useRef()

    const createOrEditDialog = useCreateOrEditDialog()
    const {removeNodeById, removeNodesByIds, saveNode, reload, getNodeById, getSiblingNodes} = useModel()
    const loadingOverlay = useLoadingOverlay()
    const {
        setSelectedNodeById,
        setSelectedNodesById,
        setSoftSelectedNodes,
        selectedNodes,
        softSelectedNodes,
        removeSelectedNodeById,
        addSelectedNodeById
    } = useSelectedNodes()


    const [newName, setNewName] = useState(node.text)
    const [isSelected, setIsSelected] = useState(false);
    const [isSoftSelected, setIsSoftSelected] = useState(false);
    const [contextMenu, setContextMenu] = useState(initialContextMenu)

    const { id, data } = node;

    const handleToggle = (e) => {
        e.stopPropagation();
        onToggle(node.id);
    };
    const handleClick = (e) => {
        e.preventDefault()
        e.stopPropagation();

        setSoftSelectedNodes([])
        LOGGER.debug("left click")
        if (e.ctrlKey || e.metaKey) {
            LOGGER.debug("ctrl key is down")
            if (isSelected) {
                removeSelectedNodeById(id)
            } else {
                addSelectedNodeById(id)
            }
        } else if (e.shiftKey) {
            LOGGER.debug("shift key is down")
            if (selectedNodes?.length === 0) {
                setSelectedNodeById(id)
            } else {
                const nodeClicked = getNodeById(id)
                const firstSelectedNode = selectedNodes[0]
                if (nodeClicked?.type && firstSelectedNode?.type === nodeClicked?.type) {
                    const siblings = getSiblingNodes(nodeClicked)
                    const nodesToSelect = []
                    if (siblings?.length > 0) {
                        const sortedSibling = siblings?.sort((a,b)=> {
                            return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                        })
                        const sortedSiblingIds = sortedSibling.map(s => s.id)
                        if (sortedSiblingIds.includes(firstSelectedNode.id)) {
                            let hasRangeStarted = false
                            siblings.forEach(sibling => {
                                if (sibling.id === firstSelectedNode.id || sibling.id === nodeClicked.id) {
                                    hasRangeStarted = !hasRangeStarted
                                    nodesToSelect.push(sibling.id)
                                } else {
                                    if (hasRangeStarted) {
                                        nodesToSelect.push(sibling.id)
                                    }
                                }

                            })
                        }
                    }
                    if (nodesToSelect?.length > 0) {
                        setSelectedNodesById(nodesToSelect)
                    }
                }
            }
        } else {
            LOGGER.debug("ctrl key is NOT down -> doing simple selection")
            setSelectedNodeById(id)
        }


    }

    const handleContextClick = (e) => {
        e.preventDefault()
        e.stopPropagation();
        setContextMenu({
            showing: true,
            x: e.clientX,
            y: e.clientY,
            node: node,
            path: path
        })
    }

    const handleDoubleClick = (e) => {
        e.preventDefault()
        e.stopPropagation();
        LOGGER.debug("double click")
        let canBeRenamed = node?.data?.type !== "folder" && !node?.id?.startsWith("_")
        if (canBeRenamed) {
            setNewName(node.text)
            setIsRenaming(true)
            renamingInputRef?.current?.focus()
        } else {
            LOGGER.debug("cannot be renamed")
        }

    }

    const handleKeyDown = (e) => {
        if (isRenaming && e.key === "Enter") {
            e.preventDefault()
            setIsRenaming(false)
            if (newName !== node.text) {
                const oldNode = getNodeById(id)
                const updatedNode = {...oldNode, name: newName}
                saveNode(updatedNode)
                //the following line is to make the change appear immediately in the tree
                node.text = newName
            }
        }
    }

    useEffect(()=>{
        if (selectedNodes && selectedNodes.length > 0) {
            setIsSelected(selectedNodes?.map(n => n?.id).includes(id))
        } else {
            setIsSelected(false)
        }
    }, [selectedNodes, id])

    useEffect(()=>{
        if (softSelectedNodes && softSelectedNodes.length > 0) {
            setIsSoftSelected(softSelectedNodes?.map(n => n?.id).includes(id))
        } else {
            setIsSoftSelected(false)
        }
    }, [softSelectedNodes, id])

    let canBeDragged = node?.data?.type !== "folder" && !node?.id?.startsWith("_")


    const dragOverProps = useDragOver(id, isOpen, onToggle)

    const [isRenaming, setIsRenaming] = useState(false)

    function stopRenaming() {
        setIsRenaming(false)
    }

    useOnClickOutside(renamingInputRef, stopRenaming)
    useOnEscape(stopRenaming)


    return (
        <div
            className={styles.root + " " + (isSelected?styles.selected:(isSoftSelected?styles.softSelected:""))}
            //style={{ paddingInlineStart: indent }}
            data-testid={`${testIdPrefix}custom-node-${id}`}
            {...dragOverProps}
            onContextMenu={handleContextClick}
        >
            <div
                data-testid={`${node.id}-toggle`}
                className={(hasChild? styles.toggle: styles.noToggle)}
                onClick={(e)=>handleToggle(e)}
            >
                {hasChild && (isOpen ? <KeyboardArrowDownIcon/>: <KeyboardArrowRightIcon/>)}
            </div>
            <div
                className={styles.itemWrapper}
                onClick={handleClick}
            >
                {canBeDragged && <div className={styles.draggable}>
                    {getNodeTypeIcon(data?.type)}
                </div>}
                {!canBeDragged && <div className={styles.undraggable}>
                    {getNodeTypeIcon(data?.type)}
                </div>}
                {!isRenaming && <div className={styles.label}>
                    <div
                        className={styles.labelText + " " + (canBeDragged?styles.draggable:"")}
                        onDoubleClick={handleDoubleClick}
                    >{node.text}</div>
                </div>}
                {isRenaming && <div className={styles.rename}>
                    <input
                        ref={renamingInputRef}
                        autoFocus={true}
                        type="text"
                        value={newName}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => {
                            setNewName(e.target.value)
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                            e.target.focus()
                        }}
                    />
                </div>}
            </div>
            {contextMenu.showing && <TreeContextMenu
                x={contextMenu.x}
                y={contextMenu.y}
                node={contextMenu.node}
                closeContextMenu={()=>setContextMenu(initialContextMenu)}
                onActionSelected={async (action) => {
                    console.log("onActionSelected: ", action)
                    switch (action.name) {
                        case "EDIT":
                            if (!node) {
                                LOGGER.error("no node provided")
                                return
                            }
                            //should be impossible, but still check:
                            if (node?.parentId?.startsWith("_")) {
                                LOGGER.error("cannot edit a node that is a root node")
                                return
                            }
                            createOrEditDialog.editNode(getNodeById(node?.id),
                                /*onClose*/ async (updatedNode) => {
                                    LOGGER.debug("onClose selected -> call the onClose handler")
                                    const newlySavedNode = await saveNode(updatedNode)
                                    LOGGER.debug("newNode: ", updatedNode)
                                    LOGGER.debug("newlySavedNode: ", newlySavedNode)
                                },
                                () => {
                                    LOGGER.debug("onCancel selected -> call the onCancel handler")
                                })
                            break
                        case "NEW":
                            if (!node) {
                                LOGGER.error("no node provided")
                                return
                            }
                            createOrEditDialog.newNode({
                                    name: "New " + node?.type,
                                    description: "[description]",
                                    type: action.type,
                                    parentId: node.id
                                },
                                /*onClose*/ async (newNode) => {
                                    LOGGER.debug("onClose selected -> call the onClose handler")
                                    const newlySavedNode = await saveNode(newNode)
                                    LOGGER.debug("newNode: ", newNode)
                                    LOGGER.debug("newlySavedNode: ", newlySavedNode)
                                },
                                () => {
                                    LOGGER.debug("onCancel selected -> call the onCancel handler")
                                })
                            break
                        case "DISPLAY":
                            setSelectedNodeById(action.node.id)
                            break
                        case "DELETE":
                            //delete node
                            loadingOverlay.show("Deleting node...")

                            const nodeIdsToDelete = [action.node.id]


                            if (selectedNodes?.length > 0) {
                                nodeIdsToDelete.push(...selectedNodes.map(n=>n.id))
                                await removeNodesByIds(nodeIdsToDelete)
                            } else {
                                await removeNodeById(action.node.id)
                            }

                            await reload()
                            break
                        default:
                            LOGGER.warn("unknown ACTION: ", action.name)
                    }
                }}
            />}
        </div>
    );
};
