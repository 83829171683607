import cssStyles from './MaturityModelDiagram.module.css'
import {useEffect, useState} from "react";
import napierMaturityModel from "./maturitymodeltemplates/NapierAML"
import Logger from "../../../../utils/Logger"
import {Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {addClasses} from "../../../../utils/PresentationUtils";
import * as React from "react";
import {createMmaPptx} from "../../../../utils/PptxUtils";
import {buildAsisToBeId, getLevelBackgroundColor, getLevelFontColor} from "../../../../utils/MaturityModelUtils";
import {download} from "../utils/DiagramUtils";
import ShareButton from "../ShareButton/ShareButton";
import * as htmlToImage from 'html-to-image';
import {useModel} from "../../../../model/ModelContext";
import {useSecurityContext} from "../../../../security/SecurityContext";
import {useWorkspaceContext} from "../../../../security/WorkspaceContext";

const LOGGER = new Logger("MaturityModelDiagram")

function getLevelClassName(selected) {
    switch (selected.toLowerCase()) {
        case "initial":
            return cssStyles.level_initial;
        case "improving":
            return cssStyles.level_improving;
        case "managed":
            return cssStyles.level_managed;
        case "mature":
            return cssStyles.level_mature;
        case "leading":
            return cssStyles.level_leading;
        default:
            return ""
    }
}



function MaturityLevelSelect({dimensionName, maturityLevels, showAsIs, asisValue, showToBe, tobeValue, onChange, backgroundColor}) {

    const [selectedAsIsMaturity, setSelectedAsIsMaturity] = useState(asisValue || "");
    const [selectedToBeMaturity, setSelectedToBeMaturity] = useState(tobeValue || "");

    const handleAsIsChange = (event) => {
        setSelectedAsIsMaturity(event.target.value);
        onChange("ASIS", event.target.value)
    };
    const handleToBeChange = (event) => {
        setSelectedToBeMaturity(event.target.value);
        onChange("TOBE", event.target.value)
    };

    const asisLevelBackgroundColor = getLevelFontColor(asisValue)
    const tobeLevelBackgroundColor = getLevelBackgroundColor(tobeValue)

    let levelBackgroundColor = "none"

    if (showAsIs && showToBe) {
        levelBackgroundColor = backgroundColor
    } else if (showAsIs && !showToBe) {
        levelBackgroundColor = asisLevelBackgroundColor
    } else if (!showAsIs && showToBe) {
        levelBackgroundColor = tobeLevelBackgroundColor
    } else {
        levelBackgroundColor = "white"
    }

    return (
        <div className={addClasses(
            cssStyles.selectDiv,
            showAsIs && cssStyles.selectDivAsIs,
            showToBe && cssStyles.selectDivToBe,
        )} style={{backgroundColor: backgroundColor}}>
            <span className={cssStyles.dimensionName}  style={{backgroundColor: levelBackgroundColor}}>{dimensionName}</span>
            {showAsIs && <div className={cssStyles.selectDivAsIs}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel className={cssStyles.inputLabel}>As Is</InputLabel>
                    <Select
                        sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                        className={cssStyles.select}
                        value={selectedAsIsMaturity}
                        onChange={handleAsIsChange}
                        label={dimensionName}
                        renderLabel={(label) => (
                            <div className={cssStyles.selectLabel}>
                                <Typography className={cssStyles.selectLevel} variant="subtitle1">{label.charAt(0).toUpperCase() + label.slice(1)}</Typography>
                            </div>
                        )}
                        renderValue={(selected) => (
                            <div className={cssStyles.selectValue}>
                                <Typography className={addClasses([cssStyles.selectLevel, getLevelClassName(selected)])} variant="subtitle1">{selected.charAt(0).toUpperCase() + selected.slice(1)}</Typography>
                                <div className={cssStyles.selectMaturityValue} variant="body2">{maturityLevels[selected]}</div>
                            </div>
                        )}
                    >
                        {Object.keys(maturityLevels).map(level => (
                            <MenuItem className={cssStyles.menuItem} key={level} value={level}>
                                <Typography className={addClasses([cssStyles.select_Level, getLevelClassName(level)])} variant="subtitle1">{level.charAt(0).toUpperCase() + level.slice(1)}</Typography>
                                <Typography className={cssStyles.select_item} variant="body2">{maturityLevels[level]}</Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>}
            {showAsIs && showToBe && <div className={cssStyles.selectDivSeparator}>
                <div className={cssStyles.selectDivSeparatorLineLeft}></div>
                <div className={cssStyles.selectDivSeparatorLineArrow}></div>
                <div className={cssStyles.selectDivSeparatorLineRight}></div>
            </div>}
            {showToBe && <>
                <div className={cssStyles.selectDivToBe}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel className={cssStyles.inputLabel}>To Be</InputLabel>
                        <Select
                            sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                            className={cssStyles.select}
                            value={selectedToBeMaturity}
                            onChange={handleToBeChange}
                            label={dimensionName}
                            renderLabel={(label) => (
                                <div className={cssStyles.selectLabel}>
                                    <Typography className={cssStyles.selectLevel} variant="subtitle1">{label.charAt(0).toUpperCase() + label.slice(1)}</Typography>
                                </div>
                            )}
                            renderValue={(selected) => (
                                <div className={cssStyles.selectValue}>
                                    <Typography className={addClasses([cssStyles.selectLevel, getLevelClassName(selected)])} variant="subtitle1">{selected.charAt(0).toUpperCase() + selected.slice(1)}</Typography>
                                    <div className={cssStyles.selectMaturityValue} variant="body2">{maturityLevels[selected]}</div>
                                </div>
                            )}
                        >
                            {Object.keys(maturityLevels).map(level => (
                                <MenuItem className={cssStyles.menuItem} key={level} value={level}>
                                    <Typography className={addClasses([cssStyles.select_Level, getLevelClassName(level)])} variant="subtitle1">{level.charAt(0).toUpperCase() + level.slice(1)}</Typography>
                                    <Typography className={cssStyles.select_item} variant="body2">{maturityLevels[level]}</Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </>}
        </div>
    );
}

function MaturityCell({cellInfo, showAsIs, asisValue, showToBe, tobeValue, onChange}) {
    return <div className={cssStyles.cellInfo}>
        <MaturityLevelSelect
            dimensionName={cellInfo.dimensionName}
            maturityLevels={cellInfo["maturity-levels"]}
            showAsIs={showAsIs}
            asisValue={asisValue}
            showToBe={showToBe}
            tobeValue={tobeValue}
            onChange={(asisOrTobe, newValue) => {
                onChange(cellInfo, asisOrTobe, newValue)
            }}
        />
    </div>
}

export function MaturityTableBody({clazzName, node, maturityTableModel, onChange, showAsIs, showToBe}) {

    let tableRows = [];

    for (let row = 0; row < maturityTableModel.maxRowCount; row++) {
        let cells = [];

        for (let column = 0; column < maturityTableModel.maxColumnCount; column++) {
            const cellInfo = maturityTableModel.cells[column][row]
            const asisTobeId = buildAsisToBeId(cellInfo.pillarId, cellInfo.dimensionId)
            let asisValue = null
            let tobeValue
            if (!node.maturityModelAnalysis) {
                node.maturityModelAnalysis = {asisTobe: {}}
            }
            if (node?.maturityModelAnalysis?.asisTobe && node.maturityModelAnalysis.asisTobe[asisTobeId]) {
                asisValue = node.maturityModelAnalysis.asisTobe[asisTobeId].asis
                tobeValue = node.maturityModelAnalysis.asisTobe[asisTobeId].tobe
            }
            cells.push(<td className={cssStyles.maturityTD} key={column} style={{backgroundColor: maturityTableModel.cells[column][row].color}}>
                        <MaturityCell
                            cellInfo={maturityTableModel.cells[column][row]}
                            showAsIs={showAsIs}
                            asisValue={asisValue}
                            showToBe={showToBe}
                            tobeValue={tobeValue}
                            onChange={(cellInfo, asisOrTobe, newValue)=>{
                                onChange(cellInfo, asisOrTobe, newValue)
                            }}
                        />
                        </td>
            );
        }

        tableRows.push(<tr key={row}>{cells}</tr>);
    }

    return (
        <table className={clazzName} style={{borderCollapse: "0px"}}>
            <thead>
                <tr>
                    {maturityTableModel.headers && maturityTableModel.headers.map((header) => {
                        return <th key={`pillar-${header.id}`} className={cssStyles.header} style={{backgroundColor: header.color}}>{header.name}</th>
                    })}
                </tr>
            </thead>
            <tbody>
            {tableRows}
            </tbody>
        </table>
    );

}

function exportPPTX(user, workspaceId, mmaNode) {
    LOGGER.debug("exportPPTX called")

    const pptx = createMmaPptx(mmaNode)

    pptx.writeFile()
}

function maturityShareOptions(user, workspaceId, node) {

    return  [
        {
            format: "PNG",
            label: "Export as PNG image",
            executeOption: async (paper, tableElement)=>{
                htmlToImage.toPng(tableElement)
                    .then(function (dataUrl) {
                        download(dataUrl, 'maturity-analysis.png');
                    });
            }
        },
        {
            format: "PPT",
            label: "Export as PowerPoint",
            executeOption: async (paper, tableElement)=>{
                exportPPTX(user, workspaceId, node)
            }
        },
    ]
}

export default function MaturityModelDiagram({node}) {

    const [showASIS, setShowASIS] = useState(true)
    const [showTOBE, setShowTOBE] = useState(true)

    const [maturityModel, setMaturityModel] = useState(napierMaturityModel)
    const [maturityTableModel, setMaturityTableModel] = useState({})

    const {saveNode} = useModel()
    const {user} = useSecurityContext()
    const {selectedWorkspace} = useWorkspaceContext()

    useEffect(() => {
        if (node && node.maturityModel) {
            setMaturityModel(node.maturityModel)
        }
    }, [node])

    useEffect(() => {
        if (maturityModel) {
            LOGGER.debug("maturityModel:", maturityModel)
            const maxColumnCount = maturityModel.pillars.length
            let maxRowCount = 0
            maturityModel.pillars.forEach((pillar) => {
                maxRowCount = Math.max(maxRowCount, pillar.dimensions.length)
            })
            LOGGER.debug(`maxColumnCount/maxRowCount: ${maxColumnCount}/${maxRowCount}`)
            const tempTableModel = {
                id: maturityModel.maturityModelId,
                name: maturityModel.name,
                maxRowCount: maxRowCount,
                maxColumnCount: maxColumnCount,
                headers: new Array(maxColumnCount).fill(0),
                cells: new Array(maxColumnCount).fill(0).map(() => new Array(maxRowCount).fill(0))
            }
            //LOGGER.debug("tempTableModel.cells:", tempTableModel.cells)
            maturityModel.pillars.forEach((pillar, pillarIndex) => {
                tempTableModel.headers[(pillar.order || pillarIndex)-1] = {
                    id: pillar.id,
                    name: pillar.name,
                    color: pillar.pillarColor
                }
                pillar.dimensions.forEach((dimension, dimensionIndex) => {
                    const cell = {
                        id: `${pillar.id}-${dimension.id}`,
                        pillarId: pillar.id,
                        dimensionId: dimension.id,
                        pillarName: pillar.name,
                        dimensionName: dimension.name,
                        value: dimension.value,
                        "maturity-levels": dimension["maturity-levels"],
                        color: pillar.dimensionColor
                    }

                    let col = (pillar.order || pillarIndex)-1
                    let row = (dimension.order||dimensionIndex)-1
                    LOGGER.debug(`col/row: ${col}/${row}, cell:`, cell)
                    tempTableModel.cells[col][row] = cell
                })
            })
            setMaturityTableModel(tempTableModel)
            LOGGER.debug("tempTableModel:", tempTableModel)
        }
    }, [maturityModel])

    return <div className={cssStyles.mainDiv}>
        <div className={cssStyles.headerDiv}>
            <div className={cssStyles.headerDivTitle}>Maturity Model: {maturityModel?.name}</div>
        </div>
        <div className={cssStyles.actionDiv}>
            <FormControlLabel
                label={"Show AS IS"}
                control={<Checkbox defaultChecked />}
                value={showASIS}
                onChange={(event)=>{setShowASIS(oldValue=>!oldValue)}}
            />
            <FormControlLabel
                label={"Show TO BE"}
                control={<Checkbox defaultChecked />}
                value={showTOBE}
                onChange={(event)=>{setShowTOBE(oldValue=>!oldValue)}}
            />
            <FormControl component="fieldset">
                <ShareButton
                    getElement={()=> {
                        return document.getElementsByClassName(cssStyles.table)[0]
                    }}
                    shareOptions={maturityShareOptions(user, selectedWorkspace, node)}
                />
            </FormControl>
        </div>

        <div className={cssStyles.contentDiv}>
            <MaturityTableBody
                node={node}
                clazzName={cssStyles.table}
                maturityTableModel={maturityTableModel}
                showAsIs={showASIS}
                showToBe={showTOBE}
                onChange={(cellInfo, asisOrTobe, newValue)=>{
                    LOGGER.debug("cellInfo: ", cellInfo)
                    LOGGER.debug(`asisOrTobe: ${asisOrTobe}, newValue: ${newValue}`)
                    let mma = node.maturityModelAnalysis
                    if (!mma || typeof mma !== "object" || Array.isArray(mma)) {
                        mma = {asisTobe:{}}
                    }
                    LOGGER.debug("Old node: ", node)
                    const asisTobeId = buildAsisToBeId(cellInfo.pillarId, cellInfo.dimensionId)
                    LOGGER.debug("asisTobeId:", asisTobeId)
                    let asisToBe = mma.asisTobe
                    LOGGER.debug("asisToBe 1:", asisToBe)
                    if (!asisToBe || typeof asisToBe !== "object" || Array.isArray(asisToBe)) {
                        asisToBe = {}
                    }
                    if (!asisToBe[asisTobeId]) {
                        asisToBe[asisTobeId] = {asis:"",tobe:""}
                        LOGGER.debug("asisToBe 2:", asisToBe)
                    }
                    if (asisOrTobe === "ASIS") {
                        LOGGER.trace("ASIS")
                        asisToBe[asisTobeId].asis = newValue
                        LOGGER.debug("asisToBe 3:", asisToBe)
                    }
                    if (asisOrTobe === "TOBE") {
                        LOGGER.trace("TOBE")
                        asisToBe[asisTobeId].tobe = newValue
                    }
                    LOGGER.debug("asisToBe 4: ", asisToBe)
                    mma.asisTobe = asisToBe
                    LOGGER.debug("mma:", mma)
                    const tempNode = {...node, maturityModelAnalysis: mma }
                    saveNode(tempNode)
                    LOGGER.debug("New node:", tempNode)
                }}
            />
        </div>
    </div>
}
