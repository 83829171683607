import cssStyles from "./ToolsPage.module.css"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Logger from "../../../utils/Logger";
import {DecisionTreeTool} from "./DecisionTreeTool/DecisionTreeTool";
import {PlateauBuilderTool} from "./PlateauBuilderTool/PlateauBuilderTool";

const LOGGER = new Logger("ToolsPage")

export default function ToolsPage({path}) {

    LOGGER.debug("Enter - ToolsPage")

    return (<div className={cssStyles.main}>
        <Tabs>
            <TabList>
                <Tab>Decision Tree</Tab>
                <Tab>Plateau Builder</Tab>
                <Tab>Bèta</Tab>
            </TabList>

            <TabPanel>
                <h2>Decision Tree</h2>
                <DecisionTreeTool/>
            </TabPanel>
            <TabPanel>
                <h2>Plateau Builder</h2>
                <PlateauBuilderTool/>
            </TabPanel>
            <TabPanel>
                <h2>Bèta</h2>
                <p>Nothing yet</p>
            </TabPanel>
        </Tabs>
    </div>)

}
