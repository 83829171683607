import {useLocation, useNavigate} from "react-router-dom";
import Logger from "../../utils/Logger";
import {useSecurityContext} from "../../security/SecurityContext";
import {useEffect} from "react";

const LOGGER = new Logger("RootRoute");

export default function RootRoute() {
    let location = useLocation();

    const navigate = useNavigate();

    const {requestPasswordRecovery} = useSecurityContext();

    useEffect(() => {
        if (!location) {
            LOGGER.debug("location is null")
            return;
        }
        LOGGER.debug("location: ", location)
        if (!location.hash) {
            LOGGER.debug("location.hash is null")
            navigate("/home")
            return;
        }
        // Remove the '#' at the beginning and split by '='
        const hashValue = location.hash.substring(1).split('=')[1];

        // Use the hashValue as needed
        console.log('Recovery token:', hashValue);
        // Here you could dispatch an action with the token, or set it in your state, etc.

        if (!hashValue) {
            LOGGER.debug("hashValue is null")
            navigate("/home")
            return;
        }

        requestPasswordRecovery(hashValue)
        navigate("/set-password")

    }, [location, navigate, requestPasswordRecovery]);

    return <div>Recovery Page</div>;
}
