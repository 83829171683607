import cssStyles from './SearchResultList.module.css';
import {useSearchContext} from "./hooks/SearchContext";
import {useModel} from "../../../model/ModelContext";
import {useEffect, useState} from "react";
import {useSelectedNodes} from "../../SelectedNodes/SelectedNodesProvider";
import {Button} from "@mui/material";
import {getNodeTypeIcon} from "../../../model/Constants";

export function SearchResultList() {

    const {setSelectedNode} = useSelectedNodes()
    const {nodes, getNodeById} = useModel()
    const {searchText, setSearchText} = useSearchContext()

    const [searchResults, setSearchResults] = useState([])

    const handleNodeClick = (nodeId) => {
        const node = getNodeById(nodeId)
        setSelectedNode(node)
    }
    useEffect(() => {
        if (searchText) {
            const results = nodes.filter((node) => {
                return node?.name?.toLowerCase().includes(searchText.toLowerCase())
            }).map((node) => {
                return <div
                    key={node.id}
                    className={cssStyles.searchResult}
                    onClick={()=>handleNodeClick(node.id)}
                >
                    <span className={cssStyles.resultIcon}>{getNodeTypeIcon(node?.type)}</span>
                    <span className={cssStyles.resultText}>{node.name}</span>
                </div>
            })
            setSearchResults(results)
        }
        // eslint-disable-next-line
    }, [searchText, nodes]);

    return <div className={cssStyles.main}>
        {searchResults?.length === 0 && <div className={cssStyles.noResultsMessage}>No Search Results for <span
            className={cssStyles.searchText}>{searchText}</span></div>}
        <div className={cssStyles.searchResults}>
            {searchResults}
        </div>
        <div className={cssStyles.clearSearchDiv}>
            <Button className={cssStyles.clearSearchButton} onClick={()=>{setSearchText("")}} variant="contained">Clear Search</Button>
        </div>
    </div>
}
