import './App.css'
import './joint-plus.css'

import {useEffect, useState} from "react"
import { styled } from '@mui/material/styles'
import {
  Box,
  Button, Container,
  Divider,
  Drawer,
  IconButton, List,
  Toolbar,
  Typography
} from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu'
import MuiAppBar from '@mui/material/AppBar'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Home from "./components/Pages/Home/Home"
import Intro from "./components/Pages/Intro/Intro"
import ReferenceCapabilityMap from "./components/ReferenceCapabilityMap/ReferenceCapabilityMap"

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate
} from 'react-router-dom';
import TreeNavigator2 from "./components/TreeNavigator2/TreeNavigator2";
import Logger from "./utils/Logger"
import MainReport from "./components/Pages/Reports/MainReport";

import HomeIcon from '@mui/icons-material/Home';
import {default as IntroIcon} from '@mui/icons-material/Handshake';
import {default as ImpactIcon} from '@mui/icons-material/Bolt';
import {default as MainIcon} from '@mui/icons-material/HomeRepairService';
import {default as ReportIcon} from '@mui/icons-material/Assessment';
import {default as CapaMapIcon} from '@mui/icons-material/Map';
import {default as AccountIcon} from '@mui/icons-material/ManageAccounts';
import {default as ImportIcon} from '@mui/icons-material/Publish';
import {default as ExportIcon} from '@mui/icons-material/FileDownload';
import RateReviewIcon from '@mui/icons-material/RateReview';
import {default as GearIcon} from '@mui/icons-material/Settings';
import CountReport from "./components/Pages/Reports/CountReport";
import ApplicationCostReport from "./components/Pages/Reports/ApplicationCostReport";
import DataLoader from "./DataLoader";
import ComplexityReport from "./components/Pages/Reports/ComplexityReport";
import DependencyReport from "./components/Pages/Reports/DependencyReport";
import QualityReport from "././components/Pages/Reports/QualityReport"
import Export from "./components/Pages/Export/Export";
import Import from "./components/Pages/Import/Import";
import QuickInsightsReport from "./components/Pages/Reports/QuickInsightsReport";
//import SnapshotImpacter from "./components/Pages/Impacter/SnapshotImpacter";
import WorkspaceSelector from "./components/WorkspaceSelector/WorkspaceSelector";
import AccountPage from "./components/Pages/Account/AccountPage";
import CreateWorkspacePage from "./components/Pages/Account/CreateWorkspacePage";
import HelpArrow from "./utils/HelpArrow";
import {SnapshotResetter} from "./components/Pages/Snapshots/SnapshotResetter";
import AccountWorkspacesPage from "./components/Pages/Account/AccountWorkspacesPage";
import AccountSubscriptionsPage from "./components/Pages/Account/AccountSubscriptionPage";
import PricingTable from "./components/Pages/Pricing/PricingTable";
import AccountPersonalPage from "./components/Pages/Account/AccountPersonalPage";
import InfoBox from "./components/InfoBox/InfoBox";
import {StatusMessageProvider} from "./components/StatusMessenger/StatusMessageProvider";
import {EnvironmentInfoProvider, useEnvironmentInfo} from "./components/EnvironmentProvider/EnvironmentInfoProvider";
import {SelectedNodesProvider} from "./components/SelectedNodes/SelectedNodesProvider";
import {Provider} from "react-redux";
import store from "./state/store";
import {LoadingOverlay} from "./components/LoadingOverlay/LoadingOverlay";
import {SecurityContextProvider, useSecurityContext} from "./security/SecurityContext";
import {SubscriptionContextProvider, useSubscriptionContext} from "./subscription/SubscriptionContext";
import SetPassword from "./components/Pages/SetPassword/SetPassword";
import {Welcome} from "./components/Pages/FirstTimeLogon/Welcome";
import {QuickTutorial} from "./components/Pages/QuickTutorial/QuickTutorial";
import {ProIconIndicator} from "./components/ProIconIndicator";
import {ACCOUNT_WORKSPACE_CREATE} from "./components/Pages/NavigationConstants";
import {useWorkspaceContext, WorkspaceContextProvider} from "./security/WorkspaceContext";
import {ModelContextProvider} from "./model/ModelContext";
import ErrorPage from "./components/Pages/Account/ErrorPage";
import SigninPage from "./components/Pages/signin/SigninPage";
import RecoverPasswordPage from "./components/Pages/signin/RecoverPasswordPage";
import RecoveryEmailSentPage from "./components/Pages/signin/RecoveryEmailSentPage";
import RootRoute from "./components/RootRoute/RootRoute";
import SharePage from "./components/Pages/Share/SharePage";
import {SearchContextProvider} from "./components/TreeNavigator2/TreeComponent2/hooks/SearchContext";
import {TreeDndContextProvider} from "./components/TreeNavigator2/diagrams/TreeDndContext";
import {DataExchangeSelectorDialogProvider} from "./components/DialogBoxes/DataExchangeSelectorDialogProvider";
import {CreateOrEditDialogProvider} from "./components/DialogBoxes/CreateOrEditDialogProvider";
import {ConfirmAccountPage} from "./components/Pages/signin/ConfirmAccountPage";
import RecoverAccountPage from "./components/Pages/signin/RecoverAccountPage";
import {LinkBar} from "./components/LinkBar/LinkBar";
import EmailPrefiller from "./components/EmailPrefiller/EmailPrefiller";
import CustomerFeedbackPage from "./components/Pages/CustomerFeedback/CustomerFeedbackPage";
import ToolsPage from "./components/Pages/Tools/ToolsPage";

const LOGGER = new Logger("App.js", 2)

const drawerWidth = 240

const resizeObserver = new ResizeObserver(entries => {
  entries.forEach(entry => {
    console.log('Resized element:', entry.target);
  });
});


window.addEventListener('error', function(event) {
  if (event.message.includes('ResizeObserver loop')) {
    console.log('ResizeObserver loop error:', event);
  }
});




const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const dataLoader = new DataLoader()

function PageContent(props) {
  const { children, value, name, requires_logon, logon,  ...other } = props;

  return (
      (requires_logon && !logon?
          <div
              key={`simple-pagecontent-${name}`}
              className={"pagecontent"}
              //hidden={value !== name}
              id={`simple-pagecontent-${name}`}
              aria-labelledby={`simple-page-${name}`}
              {...other}>
            <InfoBox id={"requiresLogonDivId"}>Please sign in...</InfoBox>
            <HelpArrow
                zIndex={1200}
                startId={"requiresLogonDivId"} //can be react ref
                endId={"signinButtonId"} //or an id
            />
          </div> :
          <div
              key={`simple-pagecontent-${name}`}
              className={"pagecontent"}
              //hidden={value !== name}
              id={`simple-pagecontent-${name}`}
              aria-labelledby={`simple-page-${name}`}
              {...other}
          >
            {/*value === name &&*/ (
                <Container maxWidth={false}>
                  <Box>
                    {children}
                  </Box>
                </Container>
            )}
          </div> )
  );
}

function GetPageTemplate() {

  const {subscription, subscriptionError} = useSubscriptionContext()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [value, setValue] = useState("home")

  const {environmentInfo} = useEnvironmentInfo()

  const navigate = useNavigate();

  const {user, logout, startInitialization} = useSecurityContext()
  const {selectedWorkspace} = useWorkspaceContext()

  useEffect(() => {
    LOGGER.debug("calling startInitialization()")
    startInitialization()
  }, [startInitialization]);

  const handleChange = (event, newValue, link) => {
    LOGGER.debug("handling change.")
    LOGGER.trace("new value: ", newValue)
    LOGGER.trace("link: ", link)
    setDrawerOpen(false)
    setValue(newValue)
    navigate(link);
  };

  useEffect(() => {
    if (subscriptionError) {
      navigate("/error/" + encodeURIComponent(subscriptionError))
    }
  }, [subscriptionError, navigate]);

  const handleDrawerOpen = (event) => {
    if (event) {
      LOGGER.debug("stopping propagation")
      event.stopPropagation();
      if (event.nativeEvent) {
        event.nativeEvent.stopImmediatePropagation();
      }
    }
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleSubscribeNow = (e) => {
    if (environmentInfo.inProduction) {
      window.location.href = "https://kenchiku.io/#pricing"
    } else {
      window.location.href = "https://staging.kenchiku.io/#pricing"
    }

    return null;
  }

  const handleSignout = (e) => {
    logout()
  }

  let upgradeDci = {name:"upgrade-to-pro", menu: false, label: "Upgrade to PRO", link:"/upgrade/pro", requiresWorkspace:false, requiresLogon:true, component:<AccountSubscriptionsPage/>}

  let drawerItemsConfig = [
    {name:"root", menu: false, label: "Root", link:"/", requiresWorkspace:false, requiresLogon:false, component:<RootRoute/>},
    {name:"confirm-account", menu: false, path:"/confirm-account/:token", label: "Confirm Account", icon:<AccountIcon/>, link:"/confirm-account", requiresWorkspace:false, requiresLogon:false, component:<ConfirmAccountPage/>},
    {name:"home", menu: true, label: "Home", icon:<HomeIcon/>, link:"/home", requiresWorkspace:true, requiresLogon:true, component:<Home/>},
    {name:"intro-wildcard", menu: false, label: "Intro", icon:<IntroIcon/>, link:"/intro/*", component:<Intro/>},
    //{name:"impacter-snapshot", menu: false, label: "Snapshot Impacter", icon:<ImpactIcon/>, link:"/impacter/snapshots", requiresWorkspace:true, requiresLogon:true, component:<SnapshotImpacter user={user} dataLoader={dataLoader}/>},
    {name:"main", menu: true, label: "Workspace", icon:<MainIcon/>, link:"/workspace", path:"/workspace/:workspaceId?/:type?/:objectId?", requiresWorkspace:false, requiresLogon:true, component:<TreeNavigator2/>},
    //kudo to https://www.educative.io/answers/what-are-the-exact-path-and-path-props-in-react-router for the next defintion!
    {name:"reports", menu: true, label: "Reports", icon:<ReportIcon/>, link:"/reports/main", requiresWorkspace:true, requiresLogon:true, component:<MainReport user={user}/>},
    {name:"reports-quick-insights", menu: false, label: "Quick Insights Report", icon:<ReportIcon/>, link:"/reports/quick-insights-report", requiresWorkspace:true, requiresLogon:true, component:<QuickInsightsReport workspaceId={selectedWorkspace} user={user}/>},
    {name:"reports-quality", menu: false, label: "Quality Report", icon:<ReportIcon/>, link:"/reports/quality-report", requiresWorkspace:true, requiresLogon:true, component:<QualityReport workspaceId={selectedWorkspace} user={user}/>},
    {name:"reports-count", menu: false, label: "Count Report", icon:<ReportIcon/>, link:"/reports/count-report", requiresWorkspace:true, requiresLogon:true, component:<CountReport workspaceId={selectedWorkspace} user={user}/>},
    {name:"reports-cost", menu: false, label: "Cost Report", icon:<ReportIcon/>, link:"/reports/application-cost-report", requiresWorkspace:true, requiresLogon:true, component:<ApplicationCostReport workspaceId={selectedWorkspace} user={user} dataLoader={dataLoader}/>},
    {name:"reports-complexity", menu: false, label: "Complexity Report", icon:<ReportIcon/>, link:"/reports/complexity-report", requiresWorkspace:true, requiresLogon:true, component:<ComplexityReport user={user}/>},
    {name:"reports-dependency", menu: false, label: "Dependency Report", icon:<ReportIcon/>, link:"/reports/dependency-report", requiresWorkspace:true, requiresLogon:true, component:<DependencyReport workspaceId={selectedWorkspace} user={user} dataLoader={dataLoader}/>},
    {name:"imports", menu: true, label: "Imports", icon:<ImportIcon/>, link:"/imports", requiresWorkspace:true, requiresLogon:true, component:<Import workspaceId={selectedWorkspace} user={user} dataLoader={dataLoader}/>},
    {name:"exports", menu: true, label: "Exports", icon:<ExportIcon/>, link:"/exports", requiresWorkspace:true, requiresLogon:true, component:<Export/>},
    {name:"referenceCapaMaps", menu: true, label: "Reference Capability Maps", isPro: true, icon:<CapaMapIcon/>, link:"/reference-capability-maps", requiresWorkspace:true, requiresLogon:true, component:<ReferenceCapabilityMap/>},
    {name:"snapshots", menu: true, label: "Snapshots", isPro: true, icon:<ImpactIcon/>, link:"/snapshots/main", requiresWorkspace:true, requiresLogon:true, component:<SnapshotResetter/>},
    {name:"account", menu: true, label: "Account", icon:<AccountIcon/>, link:"/account/main", requiresWorkspace:false, requiresLogon:true, component:<AccountPage user={user}/>},
    {name:"account-personal", menu: false, label: "Account Personal", icon:<AccountIcon/>, link:"/account/personal", requiresWorkspace:false, requiresLogon:true, component:<AccountPersonalPage/>},
    {name:"account-workspaces", menu: false, label: "Account Workspaces", icon:<AccountIcon/>, link:"/account/workspaces", requiresWorkspace:false, requiresLogon:true, component:<AccountWorkspacesPage user={user}/>},
    {name:"account-subscriptions", menu: false, label: "Account Subscriptions", icon:<AccountIcon/>, link:"/account/subscription", requiresWorkspace:false, requiresLogon:true, component:<AccountSubscriptionsPage/>},
    {name:"create-workspace", menu: false, label: "Create Workspace", link:ACCOUNT_WORKSPACE_CREATE, requiresWorkspace:false, requiresLogon:true, component:<CreateWorkspacePage/>},
    {name:"pricing-table", menu: false, label: "Pricing Table", link:"/pricing", requiresWorkspace:false, requiresLogon:false, component:<PricingTable user={user}/>},
    {name:"password-set", menu: false, label: "Setting password", link:"/set-password", requiresWorkspace:false, requiresLogon:true, component:<SetPassword/>},
    {name:"welcome", menu: false, label: "Welcome", link:"/welcome", requiresWorkspace:true, requiresLogon:true, component:<Welcome/>},
    {name:"quick-tutorial", menu: false, label: "Quick Tutorial", link:"/quick-tutorial", requiresWorkspace:true, requiresLogon:true, component:<QuickTutorial/>},
    {name:"error", menu: false, path: "error/:message", label: "error", link:"/error", requiresWorkspace:false, requiresLogon:false, component:<ErrorPage/>},
    {name:"signin", menu: false, label: "sign in", link:"/signin", requiresWorkspace:false, requiresLogon:false, component:<SigninPage/>},
    {name:"recover-account", menu: false, path:"/recover-account/:token", label: "Recover Account", icon:<AccountIcon/>, link:"/recover-account", requiresWorkspace:false, requiresLogon:false, component:<RecoverAccountPage/>},
    {name:"recover-password", menu: false, label: "recover-password", link:"/recover-password", requiresWorkspace:false, requiresLogon:false, component:<RecoverPasswordPage/>},
    {name:"recovery-email-sent", menu: false, label: "recovery-email-sent", link:"/recovery-email-sent", requiresWorkspace:false, requiresLogon:false, component:<RecoveryEmailSentPage/>},
    {name:"share-link", menu: false, label: "share-link", link:"/share/:workspaceId/:objectId", requiresWorkspace:false, requiresLogon:true, component:<SharePage/>},
    {name:"customer-feedback", menu: true, label: "Feedback", icon:<RateReviewIcon/>, link:"/feedback", requiresWorkspace:false, requiresLogon:true, component:<CustomerFeedbackPage/>},
    {name:"tools", menu: false, label: "Tools", icon:<GearIcon/>, link:"/tools", requiresWorkspace:true, requiresLogon:true, component:<ToolsPage/>},
    upgradeDci,
  ]
  function buildDciElement(dci, selectedAccount, user) {
    if (dci.requiresWorkspace && !user) {
      return <div className={"logon-infobox"}>
              <InfoBox id={"requiresLogonDivId"}>Please sign in first...</InfoBox>
              <HelpArrow
                  zIndex={1200}
                  startId={"requiresLogonDivId"} //can be react ref
                  endId={"signinButtonId"} //or an id
              />
      </div>
    } else if (dci.requiresWorkspace && !selectedAccount) {
      return <>
        <div id={"pleaseSelectAccountFirstDiv"} className={"midWindowMessage"}>&nbsp;&nbsp;Please select an workspace to work on first
        </div>
        <HelpArrow
            zIndex={1200}
            startId={"pleaseSelectAccountFirstDiv"} //can be react ref
            endId={"workspaceSelectorDivId"} //or an id
        />
      </>
    } else if (dci.isPro && subscription !== "PRO") {
      return <div className={"logon-infobox"}>
        <InfoBox id={"requiresProDivId"}>
          <p>The <b>{dci.label}</b> feature is only available to PRO subscribers.</p>
          <p><Button variant="contained" onClick={(e)=>{navigate("/account/subscription")}}>Become a PRO subscriber</Button></p>
        </InfoBox>
      </div>
    } else {
      return <PageContent
          key={"page-content-" + dci.name}
          name={dci.name}
          value={value}
          requires_logon={dci.requiresLogon}
          logon={user}
          onClick={handleDrawerClose}
      >

        {dci.component}
      </PageContent>
    }
  }
  const [appBarClassname, setAppBarClassname] = useState("")

  useEffect(() => {
    LOGGER.debug("environmentName for the AppBar: ", environmentInfo?.environmentName)
    let classNames = ["AppBar"]
    switch (environmentInfo?.environmentName) {
      case "LOCAL":
        classNames.push("AppBarLocal")
        break
      case "STAGING":
        classNames.push("AppBarStaging")
        break
      case "PROD":
        classNames.push("AppBarProd")
        break
      default:
        classNames.push("AppBarUnknown")
        break
    }
    setAppBarClassname(classNames.join(" "))
  }, [environmentInfo]);

  return (
        <div className={"app-main"}>
          <AppBar
              position="fixed"
              open={drawerOpen}

          >
            <Toolbar
                onClick={handleDrawerClose}
                className={appBarClassname}
            >

              <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={event=>handleDrawerOpen(event)}
                  edge="start"
                  sx={{ mr: 2, ...(drawerOpen && { display: 'none' }) }}
                  data-testid={"open-drawer-hamburger-button"}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div"  onClick={handleDrawerClose}>
                {/* kudos to https://www.unicode.org/cgi-bin/GetUnihanData.pl?codepoint=%E5%B8%AB */}
                <span className={"logoname"}>kenchiku</span> - &#x3051;&#x3093;&#x3061;&#x304F;
              </Typography>
              {user && <WorkspaceSelector/>}
              {user && <LinkBar/>}
              <div className={"statusMessageDiv"} onClick={handleDrawerClose}>
                {
                  (
                      user && user.email
                          ?
                          <span><b data-testid={"username-label"}>{user.email}</b>&nbsp;&nbsp;&nbsp;<Button data-testid={"signout-button"} variant="contained" onClick={(e)=>{handleSignout(e)}}>Sign out</Button></span>
                          :
                          <>
                            <Button data-testid={"signinButtonId"} id={"signinButtonId"} variant="contained" onClick={(e)=>{navigate("/signin")}}>Sign in</Button>
                            <Button data-testid={"subcribeNowButtonId"} id={"subcribeNowButtonId"} style={{paddingLeft: "10px", marginLeft: "10px"}} variant="outlined" color="secondary" onClick={(e)=>{handleSubscribeNow(e)}}>Subscribe now</Button>
                          </>
                  )
                }
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
              key={"drawer"}
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: drawerWidth,
                  boxSizing: 'border-box',
                },
              }}
              variant="persistent"
              anchor="left"
              open={drawerOpen}
          >
            <DrawerHeader key={"drawer-header"}>
              <IconButton onClick={handleDrawerClose}>
                {<ChevronLeftIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider key={"divider-one"}/>
            <List key={"list-one"}>
              {drawerItemsConfig.filter(dic=>dic.menu).map((dic)=> (
                  <div
                      key={"draweritem-" + dic.name}
                      className={"drawerDiv"}
                      onClick={(e)=>{handleChange(e, dic.name, dic.link)}}
                  >
                    <ProIconIndicator isPro={dic.isPro} subscription={subscription}/>
                    <span className={"drawerLabel"} data-testid={"hamburger-menu-item-" + dic.name}>{dic.label}</span>
                    {dic.icon}
                  </div>))}
            </List>
          </Drawer>
          <header key={"header"} className="App-header">

          </header>

          <div className={"App-body"}>
            <Routes>
              {
                 drawerItemsConfig.map((dci)=>{
                  //console.log("adding route to \"" + dci.link + "\"")

                   /*
                   if a path is given, we use that, otherwise we use the link.
                   The reason is that for most pages, the link is the same as the path.
                     But for the main page, the link is "/workspace" and the path is "/workspace/:workspaceId?/:type?/:objectId?"
                    */

                  return (
                      <Route
                          key={"route-"+dci.name}
                          exact
                          path={dci.path || dci.link}
                          element={buildDciElement(dci, selectedWorkspace, user)}>
                      </Route>
                  )
                })
              }
            </Routes>
          </div>
          <EmailPrefiller/>
        </div>
  )
}

function App() {
  return <Provider store={store}>
    <Router>
      <SearchContextProvider>
        <LoadingOverlay>
          <EnvironmentInfoProvider>
            <StatusMessageProvider>
              <SecurityContextProvider>
                <WorkspaceContextProvider>
                  <SubscriptionContextProvider>
                    <ModelContextProvider>
                      <TreeDndContextProvider>
                        <SelectedNodesProvider>
                          <DataExchangeSelectorDialogProvider>
                            <CreateOrEditDialogProvider>
                              <GetPageTemplate/>
                            </CreateOrEditDialogProvider>
                          </DataExchangeSelectorDialogProvider>
                        </SelectedNodesProvider>
                      </TreeDndContextProvider>
                    </ModelContextProvider>
                  </SubscriptionContextProvider>
                </WorkspaceContextProvider>
              </SecurityContextProvider>
            </StatusMessageProvider>
          </EnvironmentInfoProvider>
        </LoadingOverlay>
      </SearchContextProvider>
    </Router>
  </Provider>
}

export default App;
