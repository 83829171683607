import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {DndProvider} from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend'
import {TouchBackend} from "react-dnd-touch-backend";

const root = ReactDOM.createRoot(document.getElementById("root"));

// https://stackoverflow.com/a/4819886/1601953
const isTouchDevice = !!("ontouchstart" in window || navigator.maxTouchPoints);

const dndBackend = (isTouchDevice ? TouchBackend : HTML5Backend)

root.render(
    <DndProvider backend={dndBackend}>
        <App/>
    </DndProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
