import pptxgen from "pptxgenjs";
import {
    buildAsisToBeId,
    getLevelBackgroundColor,
    getLevelFontColor,
    getMaturityModelTemplateById
} from "./MaturityModelUtils";
import Logger from "./Logger";

const LOGGER = new Logger("PptxUtils")




function createOverviewTable(mma, maturityModel) {

    const pillarsCount = maturityModel?.pillars?.length || 0

    const pillarWidth = 2
    const tableWidth = pillarsCount * pillarWidth

    const headers = maturityModel.pillars.map(pillar => {
        return {text: pillar.name, options: {fill: {color: pillar.pillarColor}, color: pillar.dimensionColor}}
    })

    const description = maturityModel.pillars.map(pillar => {
        return {text: pillar.description, options: {fontSize: 14, fill: {color: pillar.dimensionColor}, color: pillar.pillarColor}}
    })

    const tableRows = [
        headers,
        description
    ]

    const tableStyles = {
        x: 0.70,
        y: 2,
        w: tableWidth,
        rowH: 1,
        fill: { color: "F7F7F7" },
        color: "FFFFFF",
        fontSize: 18,
        bold: true,
        valign: "center",
        align: "center",
        border: { pt: "1", color: "FFFFFF" },
    }


    return {tableRows, tableStyles}
}

function createPillarsAndDimensionsOverviewTable(mma, maturityModel) {

    const pillarsCount = maturityModel?.pillars?.length || 0

    const pillarWidth = 2
    const tableWidth = pillarsCount * pillarWidth

    const headers = maturityModel.pillars.map(pillar => {
        return {text: pillar.name, options: {fontSize: 18, fill: {color: pillar.pillarColor}, color: pillar.dimensionColor}}
    })


    let tableRows = [
        headers
    ]

    const numColumns = maturityModel
        .pillars
        .reduce((count, row) => count + 1, 0);

    const numRows = maturityModel
        .pillars
        .map(row => row.dimensions.length)
        .reduce((max, curr) => Math.max(max, curr), 0);




    for (let r = 0; r < numRows; r++) {
        const newRow =  []
        for (let c = 0; c < numColumns; c++) {
            const p = maturityModel.pillars[c]
            const d = p.dimensions[r]
            newRow.push({
                text: d.name,
                options: {fontSize: 18, fill: {color: p.dimensionColor}, color: p.pillarColor}
            })
        }
        tableRows.push(newRow)

    }



    const tableStyles = {
        x: 0.70,
        y: 1.9,
        w: tableWidth,
        rowH: 0.8,
        fill: { color: "F7F7F7" },
        color: "FFFFFF",
        fontSize: 18,
        bold: true,
        valign: "center",
        align: "center",
        border: { pt: "1", color: "FFFFFF" },
    }

    LOGGER.trace("tableRows: ", tableRows)
    return {tableRows, tableStyles}
}

export function cleanupExplanationText(explanationTextRaw) {
    return explanationTextRaw?.replace(/[\r\n]+/g, " ").replaceAll("\\n", " ");
}

function createSituationalTable(mmaNode, maturityModel, asisOrTobe) {

    const PILLAR_TITLE_ROW_HEIGHT = 0.40
    const LEVEL_ROW_HEIGHT = 0.20
    const EXPLANATION_ROW_HEIGHT = 0.20

    const pillarsCount = maturityModel?.pillars?.length || 0

    const pillarWidth = 2
    const tableWidth = pillarsCount * pillarWidth

    const rowHeights = []

    const isAsis = (asisOrTobe?.toLowerCase() === "asis")

    const headers = maturityModel.pillars.map(pillar => {
        return {text: pillar.name, options: {fill: {color: pillar.pillarColor}, color: pillar.dimensionColor}}
    })
    rowHeights.push(PILLAR_TITLE_ROW_HEIGHT)


    let tableRows = [
        headers
    ]

    const numColumns = maturityModel
        .pillars
        .reduce((count, row) => count + 1, 0);

    const numRows = maturityModel
        .pillars
        .map(row => row.dimensions.length)
        .reduce((max, curr) => Math.max(max, curr), 0);

    for (let r = 0; r < numRows; r++) {
        const newLevelRow =  []
        const newLevelExplanationRow =  []
        for (let c = 0; c < numColumns; c++) {
            const p = maturityModel.pillars[c]
            const d = p.dimensions[r]

            const asisTobe = mmaNode?.maturityModelAnalysis?.asisTobe
            if (asisTobe) {

                let level = ""
                const ana = asisTobe[buildAsisToBeId(p.id, d.id)]
                if (ana) {
                    if (isAsis) {
                        level = ana.asis
                    } else {
                        level = ana.tobe
                    }
                }
                const levelColor = getLevelFontColor(level)
                const levelBackroundColor = getLevelBackgroundColor(level)
                newLevelRow.push({
                    text: d.name +  " [" +  level + "]",
                    options: {fontSize: 12, fill: {color: levelBackroundColor}, color: levelColor}
                })
                rowHeights.push(LEVEL_ROW_HEIGHT)
                let explanationTextRaw = d["maturity-levels"][level]
                let explanationText = cleanupExplanationText(explanationTextRaw)
                newLevelExplanationRow.push({
                    text: explanationText,
                    options: {fontSize: 10, fill: {color: p.dimensionColor}, color: p.pillarColor}
                })
                rowHeights.push(EXPLANATION_ROW_HEIGHT)
            }
        }
        tableRows.push(newLevelRow)
        tableRows.push(newLevelExplanationRow)

    }



    const tableStyles = {
        x: 0.70,
        y: 0.5,
        w: tableWidth,
        rowH: rowHeights,
        fill: { color: "F7F7F7" },
        color: "FFFFFF",
        fontSize: 16,
        valign: "center",
        align: "center",
        border: { pt: "1", color: "FFFFFF" },
        margin: 1
    }

    LOGGER.trace("situational tableRows: ", tableRows)
    return {tableRows, tableStyles}
}


export function createMmaPptx(mmaNode) {

    const mma = mmaNode?.maturityModelAnalysis
    const templateId = mmaNode?.maturityModelId
    const maturityModel = getMaturityModelTemplateById(templateId)

    // 1. Create a Presentation
    let pres = new pptxgen()
    pres.layout = "LAYOUT_WIDE"

    // 2. Add a Slide to the presentation
    let slide1 = pres.addSlide({ sectionTitle: "Overview Table" })
    const overviewTable = createOverviewTable(mma, maturityModel)
    slide1.addTable(overviewTable.tableRows, overviewTable.tableStyles);


    let slide2 = pres.addSlide({ sectionTitle: "Pillars and Dimensions Overview Table" })
    const pillarsAndDimensionsOverviewTable = createPillarsAndDimensionsOverviewTable(mma, maturityModel)
    slide2.addTable(pillarsAndDimensionsOverviewTable.tableRows, pillarsAndDimensionsOverviewTable.tableStyles);

    let slide3 = pres.addSlide({ sectionTitle: "AS IS Table" })
    const asisTable = createSituationalTable(mmaNode, maturityModel, "asis")
    slide3.addTable(asisTable.tableRows, asisTable.tableStyles);

    let slide4 = pres.addSlide({ sectionTitle: "Pillars and Dimensions Overview Table" })
    const tobeTable = createSituationalTable(mmaNode, maturityModel, "tobe")
    slide4.addTable(tobeTable.tableRows, tobeTable.tableStyles);

    // 4. return the Presentation
    return pres

}
