import cssStyles from "../Pages/Account/CreateWorkspacePage.module.css";
import {Button, TextField} from "@mui/material";
import React from "react";
import {useYupValidationResolver} from "../TreeNavigator2/editors/EditorUtils";
import {NODETYPE_SCHEMAS} from "../../model/Constants";
import {useForm} from "react-hook-form";
import {addAuthorizationHeader} from "../../utils/Api";
import Logger from "../../utils/Logger";
import {useLoadingOverlay} from "../LoadingOverlay/LoadingOverlay";
import {useSecurityContext} from "../../security/SecurityContext";

const LOGGER = new Logger("CreateWorkspacePage")

export function CreateWorkspaceForm({onCreated, onError}) {

    const loadingOverlay = useLoadingOverlay()
    const {user} = useSecurityContext()

    const defaultValues = {
        name: "My Workspace",
        description: "A workspace for my enterprise",
        invitedUsers: []
    }



    const resolver = useYupValidationResolver(NODETYPE_SCHEMAS.workspace);
    const { register, handleSubmit, formState: { isValid, errors }, /*control*/} = useForm({
        resolver,
        defaultValues
    });
    const onSubmit = (newWorkspace) => {
        LOGGER.debug("newWorkspace form data:", newWorkspace);
        loadingOverlay.show("Creating new Workspace")
        fetch("/.netlify/functions/manage-workspaces",  user && {
            // only attach headers if user is logged in, but still make the request regardless
            headers: addAuthorizationHeader({}, null, user),
            method: "POST",
            body: JSON.stringify(newWorkspace)
        })
        .then(res =>res.json())
        .then(data => {
            console.debug("remote new workspace data:", data)
            onCreated(newWorkspace)
        })
        .catch((e)=>{
            console.error("Error creating workspace: ", e)
            onError(e)
        }).finally(()=>{
            loadingOverlay.hide()
        })

    }

    return <div className={cssStyles.main}>
        <form className={cssStyles.mainForm} onSubmit={handleSubmit(onSubmit, onError)}>
            <TextField
                {...register('name')}
                autoFocus
                margin={"dense"}
                id={"name"}
                name={"name"}
                label={"Name"}
                type={"text"}
                fullWidth
                variant={"standard"}
                error={!!errors.name}
                helperText={errors.name && errors?.name?.message}
            />
            <TextField
                {...register('description')}
                margin={"dense"}
                id={"description"}
                name={"description"}
                label={"Description"}
                type={"text"}
                fullWidth
                variant={"standard"}
                error={!!errors.description}
                helperText={errors.description && errors?.description?.message}
            />
            {/*
            <InviteUserGrid
                control={control}
                invitedUsers={invitedUsers}
                addUser={addUser}
                updateEmail={updateEmail}
                updateUserRole={updateUserRole}
            />
            */}

            <Button
                disabled={!isValid}
                type="submit"
                variant="contained"
                color="primary"
            >create workspace</Button>
        </form>
    </div>
}
