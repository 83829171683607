import {useModel} from "../../model/ModelContext";
import React, {createContext, useContext, useRef, useState} from "react";
import {NodeType} from "../../model/Constants";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import cssStyles from "../TreeNavigator2/diagrams/DataFlowDiagram/DataFlowPaper.module.css";
import {SingleNodeSelector} from "../TreeNavigator2/editors/selectors/SingleNodeSelector";
import Logger from "../../utils/Logger";


const LOGGER = new Logger("DataExchangeSelectorDialogProvider")

export const DataExchangeSelectorDialogContext = createContext();

export const useDataExchangeSelectorDialog = () => {
    return useContext(DataExchangeSelectorDialogContext);
};

export const DataExchangeSelectorDialogProvider = ({ children }) => {

    const [showModalDialog, setShowModalDialog] = useState(false)
    const [dataExchangeId, setDataExchangeId] = useState(null)

    const {getNodes, loading, getNodeById} = useModel()

    const [nodesToChooseFrom, setNodesToChooseFrom] = useState([])

    const [selectedDataExchange, setSelectedDataExchange] = useState(null)

    const [title, setTitle] = useState("")

    const [sourceName, setSourceName] = useState(null)
    const [targetName, setTargetName] = useState(null)


    const onNewHandler = useRef(null)
    const onSelectHandler = useRef(null)
    const onCancelHandler = useRef(null)


    const dataExchangeSelectorFilter = (deNode, deSourceId, deTargetId) => {
        /*
         * The node managed by this paper is a DataFlow.
         * This filter is called when the user has to select
         * a DataExchange to connect to a sd.Message arrow in the diagram.
         * So we need toget the source and target ids of the sd.Message,
         * not of the "node" variable!
         */
        if (deNode?.type === NodeType.DataExchange.description) {
            LOGGER.trace("correct type")
            if (deNode?.sourceApplicationId === deSourceId) {
                LOGGER.trace("sourceApplicationId matches")
                if (deNode?.targetApplicationId === deTargetId) {
                    LOGGER.trace("targetApplicationId matches, we have a full match!")
                    return true
                }
            }
        }
        return false
    }

    let showModal = (
        dialogTitle,
        dataExchangeId,
        sourceId,
        targetId,
        onNew,
        onSelect,
        onCancel) => {

        setTitle(dialogTitle)

        const filteredNodes = getNodes().filter(n=>n.type === NodeType.DataExchange.description && dataExchangeSelectorFilter(n, sourceId, targetId))
        setNodesToChooseFrom(filteredNodes)

        const sourceNode = getNodeById(sourceId)
        const targetNode = getNodeById(targetId)

        setSourceName(sourceNode?.name)
        setTargetName(targetNode?.name)



        onNewHandler.current = ()=>{
            if (onNew) {
                onNew()
                clearModal()
            } else {
                LOGGER.warn("No onNew handler provided")
            }
        }
        onSelectHandler.current = (selectedDataExchange)=>{
            if (selectedDataExchange) {
                onSelect(selectedDataExchange)
                clearModal()
            } else {
                LOGGER.warn("No onSelect handler provided")
            }
        }

        onCancelHandler.current = ()=>{
            if (onCancel) {
                onCancel()
                clearModal()
            } else {
                LOGGER.warn("No onCancel handler provided")
            }
        }
        if (dataExchangeId) {
            setDataExchangeId(dataExchangeId)
        }

        setShowModalDialog(true)

    }

    const clearModal = () => {
        setShowModalDialog(false)
        setTitle(null)

        setNodesToChooseFrom([])

        setSourceName(null)

        setTargetName(null)
        onNewHandler.current = null
        onSelectHandler.current = null
        onCancelHandler.current = null


        setDataExchangeId(null)
    }

    return (

        <DataExchangeSelectorDialogContext.Provider value={{showModal}}>
            {children}
            {showModalDialog && <Dialog
                open={showModalDialog}
                onClose={onCancelHandler.current}
                aria-labelledby="confirm-dialog"
                fullWidth={true}
                maxWidth={"sm"}
            >
                <DialogTitle id="confirm-dialog">{title}</DialogTitle>
                <DialogContent>
                    {loading && <p>Loading Data Exchanges...</p>}
                    {!loading && nodesToChooseFrom.length===0 && <p>No existing to choose from</p>}
                    {!loading && nodesToChooseFrom.length>0 && <div className={cssStyles.dialogInnerContent}>
                        <p><b>{sourceName}</b> --> <b>{targetName}</b></p>
                        <SingleNodeSelector
                            label={"Select an existing Data Exchange..."}
                            nodesToChooseFrom={nodesToChooseFrom}
                            filter={dataExchangeSelectorFilter}
                            onChange={(node) => {
                                setSelectedDataExchange(oldValue=>node)
                            }}
                            defaultSelectedId={dataExchangeId}
                        />
                        <p>Or create a new one by pressing the NEW button bellow</p>
                    </div>}

                </DialogContent>
                <DialogActions>
                    <Button
                        variant={"outlined"}
                        onClick={onCancelHandler.current}
                    >
                        {"Cancel"}
                    </Button>
                    <Button
                        variant={nodesToChooseFrom?.length === 0 ? "contained" : "outlined"}
                        onClick={onNewHandler.current}
                    >
                        {"New"}
                    </Button>
                    <Button
                        variant={selectedDataExchange && nodesToChooseFrom?.length>0 ? "contained" : "outlined"}
                        disabled={!selectedDataExchange}
                        onClick={() => {
                            onSelectHandler.current(selectedDataExchange)
                        }}
                    >
                        {"Select"}
                    </Button>
                </DialogActions>
            </Dialog>}
        </DataExchangeSelectorDialogContext.Provider>
    )
}
