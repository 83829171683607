import cssStyles from "./WaiterText.module.css";
import {CircularProgress} from "@mui/material";

export default function WaiterText({children}) {
    return <div className={cssStyles.main}>
        <span className={cssStyles.wrapper}>
            <CircularProgress/>
            <span className={cssStyles.waiterText}>{children}</span>
        </span>
    </div>
}
