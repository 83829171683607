import { LOAD_NODES, UPDATE_NODE } from './actions';

const initialState = {
    nodes: []
};

const nodesReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_NODES:
            return { ...state, nodes: action.payload };
        case UPDATE_NODE:
            return {
                ...state,
                nodes: state.nodes.map(node =>
                    node.id === action.payload.id ? action.payload : node
                )
            };
        default:
            return state;
    }
};

export default nodesReducer;
