
import cssStyles from './FullDependenciesGraph.module.css';

import CytoscapeComponent from "react-cytoscapejs";

import cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';
import klay from 'cytoscape-klay';

import {useEffect, useRef, useState} from "react";

import Logger from "../../../utils/Logger";
import {NodeFolderRootIds, NodeType, NodeTypeName} from "../../../model/Constants";
import * as PropTypes from "prop-types";
import {MenuItem, Select} from "@mui/material";
import WaiterText from "../../../utils/WaiterText";
import {NODE_FILL_COLORS} from "../../../utils/NodeTypeColors";
import {useModel} from "../../../model/ModelContext";

cytoscape.use(dagre);
cytoscape.use(klay);

const LOGGER = new Logger("FullDependenciesGraph")

const LAYOUT_OPTIONS = {
    "cose": {
        label: "Graph (Default)",
        layout: {
            name: 'cose',
            fit: true,
            zoom: 20,        // Zoom level
            pan: { x: 0, y: 0 },  // Pan values
        }
    },
    "dagre": {
        label: "Top Down Tree",
        layout: {
            name: 'dagre',
            fit: true,
            zoom: 20,        // Zoom level
            pan: { x: 0, y: 0 },  // Pan values
        }
    },
    "klay": {
        label: "Left-to-right Tree",
        layout: {
            name: 'klay',
            fit: true,
            zoom: 20,        // Zoom level
            pan: { x: 0, y: 0 },  // Pan values
        }
    },
}

function addRelationship(tempElements, description, nodeHash, sourceId, targetId, orphansId) {

    let zeSourceId = sourceId
    let zeTargetId = targetId

    if (!nodeHash.hasOwnProperty(sourceId)) {
        zeSourceId = orphansId
        LOGGER.debug(`${description}: Missing node sourceId:`, sourceId)
    }
    if (!nodeHash.hasOwnProperty(targetId)) {
        zeTargetId = orphansId
        LOGGER.debug(`${description}: Missing node targetId:`, targetId)
    }

    if (nodeHash.hasOwnProperty(targetId)) {
        tempElements.push({
            data: {source: zeSourceId, target: zeTargetId}
        })
    }
}

function LayoutSelector({selectedLayout, onChange}) {

    const [selectedValue, setSelectedValue] = useState("cose")

    const handleChange = (e) => {
        setSelectedValue(e.target.value)
        if (onChange) {
            const option = LAYOUT_OPTIONS[e.target.value]
            const layout = option.layout
            onChange(layout)
        }
    }

    return <Select
        onChange={(e)=>handleChange(e)}
        value={selectedValue}
        style={{ height: '20px' }}
    >
        {Object.keys(LAYOUT_OPTIONS).map((layoutKey) => {
            const option = LAYOUT_OPTIONS[layoutKey]
            return <MenuItem value={layoutKey}>{option.label}</MenuItem>
        })}
    </Select>;
}

LayoutSelector.propTypes = {onChange: PropTypes.func};
export default function FullDependenciesGraph({workspaceId, user, dataLoader}) {

    const {nodes, loading} = useModel()

    const [elements, setElements] = useState([])

    const [nodeHash, setNodeHash] = useState({})
    const [layout, setLayout] = useState(LAYOUT_OPTIONS.cose.layout)
    const cyRef = useRef(null);

    useEffect(() => {
        if (cyRef.current) {
            const currentLayout = cyRef.current.layout(layout);
            currentLayout.run();
        }
    }, [elements, layout]);

    useEffect(() => {

        if (nodeHash === undefined || Object.keys(nodeHash).length === 0) {
            return
        }

        let tempElements = []

        const orphansId = "__orphans"
        const unrealizingId = "__unrealizing"

        tempElements.push({
            data: {
                id: orphansId,
                label: "Orphans",
                type: NodeTypeName.Folder,
                level: 0
            }
        })
        tempElements.push({
            data: {
                id: unrealizingId,
                label: "Unrealizing",
                type: NodeTypeName.Folder,
                level: 0
            }
        })

        Object.values(nodeHash).filter(n=>n.type === NodeType.DataObject.description).forEach(dataObject=>{
            tempElements.push({
                data: {
                    id: NodeFolderRootIds.DataObjectRootId.description,
                    label: NodeTypeName.DataObject,
                    type: NodeTypeName.Folder,
                    level: 0
                }
            })
            tempElements.push({
                data: {
                    id: dataObject.id,
                    label: dataObject.name,
                    type: NodeTypeName.DataObject,
                    level: 1
                }
            })
            let parentId = dataObject.parentId
            let childId = dataObject.id

            addRelationship(
                tempElements,
                "parent->child",
                nodeHash, parentId, childId, orphansId);

        })

        Object.values(nodeHash).filter(n=>n.type === NodeType.Capability.description).forEach(c=>{
            tempElements.push({
                data: {
                    id: NodeFolderRootIds.CapabilityRootId.description,
                    label: NodeTypeName.Capability,
                    type: NodeTypeName.Folder,
                    level: 0
                }
            })
            tempElements.push({
                data: {
                    id: c.id,
                    label: c.name,
                    type: NodeTypeName.Capability,
                    level: 1
                }
            })
            let parentId = c.parentId
            let childId = c.id

            addRelationship(
                tempElements,
                "parent->child",
                nodeHash, parentId, childId, orphansId);

        })

        Object.values(nodeHash).filter(n=>n.type === NodeType.Application.description).forEach(a=>{

            //only add the application if it realizes a capability
            tempElements.push({
                data: {
                    id: a.id,
                    label: a.name,
                    type: NodeTypeName.Application,
                    level: 2
                }
            })

            if (a.supportedCapabilityIds?.length >0) {
                LOGGER.debug("a.supportedCapabilityIds:", a.supportedCapabilityIds)
                a.supportedCapabilityIds.forEach(cId => {
                    if (nodeHash.hasOwnProperty(cId)) {
                        LOGGER.debug("supportedCapabilityId found:", cId)
                        addRelationship(
                            tempElements,
                            "realized by",
                            nodeHash, cId, a.id, orphansId);
                    }
                })
            } else {
                LOGGER.debug("Application does not realize any capability:", a)
                addRelationship(
                    tempElements,
                    "unrealized",
                    nodeHash, unrealizingId, a.id, unrealizingId);
            }

            if (a.masterDataObjectIds?.length >0) {
                LOGGER.debug("a.masterDataObjectIds:", a.masterDataObjectIds)
                a.masterDataObjectIds.forEach(mdId => {
                    if (nodeHash.hasOwnProperty(mdId)) {
                        LOGGER.debug("masterDataObjectIds found:", mdId)
                        addRelationship(
                            tempElements,
                            "master data",
                            nodeHash, mdId, a.id, orphansId);
                    }
                })
            }

        })

        setElements(tempElements)
    }, [nodeHash])

    useEffect(() => {
        let tempNodeHash = {}
        nodes.forEach(n=>{
            tempNodeHash[n.id] = n
        })
        setNodeHash(tempNodeHash)
    },[nodes])


    let styles = [
        {
            selector: "node",
            style: {
                "label": "data(label)",
                "font-size": "8px",
                "width": "10px",
                "height": "10px",
            }
        },
        {
            selector: `node[type="${NodeType.Folder.description}"]`,
            style: {
                'background-color': NODE_FILL_COLORS[NodeType.Folder.description],
                "font-color": "white",
            }
        },
        {
            selector: `node[type="${NodeType.Capability.description}"]`,
            style: {
                'background-color': NODE_FILL_COLORS[NodeType.Capability.description]
            }
        },
        {
            selector: `node[type="${NodeType.Application.description}"]`,
            style: {
                'background-color': NODE_FILL_COLORS[NodeType.Application.description]
            }
        },
        {
            selector: `node[type="${NodeType.DataObject.description}"]`,
            style: {
                'background-color': NODE_FILL_COLORS[NodeType.DataObject.description]
            }
        },
    ];

    return <div className={cssStyles.main}>
        <div className={cssStyles.status}>
            {loading ? <WaiterText>Loading dependency graph...</WaiterText>: <div className={cssStyles.layoutSelectorDiv}><span className={cssStyles.text}>Application & Data Dependencies, applied layout:</span><LayoutSelector onChange={(layout)=> {
                setLayout(layout)
            }}/></div>}
        </div>
        <CytoscapeComponent
            cy={cy => {
                cyRef.current = cy;
            }}
            elements={elements}
            style={ {
                width: '100%',
                height: 'calc( 100% - 60px )',

            } }
            layout={layout}
            stylesheet={styles}
        />
    </div>
}
