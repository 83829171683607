import React, {useEffect, useState} from 'react';
import { TextField, Button, Grid } from '@mui/material';
import Logger from "../../../utils/Logger";
import {useSecurityContext} from "../../../security/SecurityContext";
import {useNavigate} from "react-router-dom";

const LOGGER = new Logger("RecoverPasswordComponent")

function RecoverPasswordComponent() {

    const navigate = useNavigate();

    LOGGER.debug("Enter - RecoverPasswordComponent")

    const {startInitialization, requestPasswordRecovery} = useSecurityContext();

    const [email, setEmail] = useState('');

    useEffect(() => {
        LOGGER.debug("calling netlifyIdentity.init()")
        startInitialization()
    });

    const handleRecoverPassword = async () => {
        await requestPasswordRecovery(email)
        await navigate("/recover-password")
    };

    return (
        <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
            <Grid item>
                <TextField
                    inputProps={{
                        "data-testid": "email",
                    }}
                    label="Email"
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item>
                <Button data-testid={"recoverPasswordButton"} variant="contained" color="primary" onClick={handleRecoverPassword} fullWidth>
                    Recover password
                </Button>
            </Grid>
        </Grid>
    );
};

export default RecoverPasswordComponent;
