import cssStyles from "../Intro.module.css";
import {Paper} from "@mui/material";

export default function MainIntro() {
    return <div id={"intro"} className={cssStyles.introDiv}>
        <Paper className={cssStyles.paper}>
            <h2><span className={"logoname"}>kenchiku</span> explained</h2>
            <p><span className={"logoname"}>kenchiku</span> translates to architecture in Japanese. The Japanese have a renowned expertise in distilling the essence of matters, making <span className={"logoname"}>kenchiku</span> a fitting name for a tool designed to aid architects in modeling architectures, especially for small to medium-sized enterprises (SMEs).</p>
            <p>An opinionated tool proves beneficial as it addresses the majority of use-cases (adhering to the famed 80% rule). Our goal wasn't to create a tool that ambitiously tried to tackle every problem but ended up falling short.</p>
            <p><span className={"logoname"}>kenchiku</span>  empowers architects to swiftly document the existing architecture of an SME and juxtapose it against reference architectures. </p>
            <p>It raises pertinent questions: Are there overlaps or gaps? Is there room for optimization in the architecture?</p>
        </Paper>
    </div>
}
