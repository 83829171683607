import {useSubscriptionContext} from "../../../subscription/SubscriptionContext";
import {Button, List, ListItem, ListItemIcon} from "@mui/material";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import React, {useEffect, useState} from "react";
import Logger from "../../../utils/Logger";
import cssStyles from "./SubscriptionTable.module.css"
import {addClasses} from "../../../utils/PresentationUtils";
import * as PropTypes from "prop-types";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { initializePaddle } from '@paddle/paddle-js';
import {useEnvironmentInfo} from "../../EnvironmentProvider/EnvironmentInfoProvider";
import {useSecurityContext} from "../../../security/SecurityContext";
import {stringToHumanDateString} from "../../../utils/StringUtils";

const LOGGER = new Logger("SubscriptionTable")

function subscriptionTypeToLabel(subscriptionType) {
    let subscriptionLabel = "Unknown plan"
    switch (subscriptionType) {
        case "STANDARD":
            subscriptionLabel = "Standard Plan"
            break;
        case "PRO":
            subscriptionLabel = "Pro Plan"
            break;
        default:
            subscriptionLabel = "Unknown plan"
            break;
    }
    return subscriptionLabel
}

function ActionButton({variant, newSubscriptionType, currentSubscriptionType, changeSubscriptionTo}) {

    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [title, setTitle] = useState("")
    const [message, setMessage] = useState("")
    const [okLabel, setOkLabel] = useState("")

    useEffect(() => {
        if (newSubscriptionType === "STANDARD") {
            setTitle("Downgrade subscription?")
            setMessage(`Are you sure you want to downgrade your subscription from ${subscriptionTypeToLabel(currentSubscriptionType)} to "${subscriptionTypeToLabel(newSubscriptionType)}"?`)
            setOkLabel("Downgrade")
        } else if (newSubscriptionType === "PRO") {
            setTitle("Upgrade?")
            setMessage(`Upgrade your subscription to "${subscriptionTypeToLabel(newSubscriptionType)}"?`)
            setOkLabel("Upgrade")
        } else if (newSubscriptionType === "NONE") {
            setTitle("Subscribe?")
            setMessage(`Subscribe to "${subscriptionTypeToLabel(newSubscriptionType)}"?`)
            setOkLabel("Subscribe")
        }
    }, [currentSubscriptionType, newSubscriptionType])
    let action = <><Button variant={variant || "outlined"} className={cssStyles.actionButton} onClick={
            (e) => {
                setIsDialogOpen(true)
            }
        }>
            Switch
        </Button>

        <ConfirmDialog
            title={title}
            open={isDialogOpen}
            setOpen={(shouldOpen)=>{
                setIsDialogOpen(shouldOpen)
            }}
            onConfirm={(e) => {
                changeSubscriptionTo(newSubscriptionType)
            }}
            buttons={{no:{
                    text: "Cancel",
                    focus: newSubscriptionType === "STANDARD",
                }, yes: {
                    text: okLabel,
                    focus: newSubscriptionType === "PRO",
                }}}
        >
            {message}
        </ConfirmDialog>
    </>
    LOGGER.debug(`currentSubscriptionType/subscriptionType: ${currentSubscriptionType}/${newSubscriptionType}`)
    if (currentSubscriptionType === newSubscriptionType) {
        action = <span className={cssStyles.currentPlanSpan}>Current Plan</span>
    }
    return action
}

function Feature({children}) {
    return <>
        {children}
    </>
}

function FeatureList({key, children}) {

    const features = []
    React.Children.forEach(children, child => {
        if (React.isValidElement(child)) {
            const type = child.type;
            // Checking if it's a custom React component
            if (typeof type === 'function' || typeof type === 'object') {
                console.log('Component name:', type.name || type.displayName);  // Custom component name
                features.push(child)
            }
        }
    });


    return (
        <List key={key}>
            {features.map((text, index) => (
                <ListItem key={"LI_" + index}>
                    <ListItemIcon style={{marginRight: "-20px"}}>
                        <CheckCircleOutlineIcon /> {/* This is the disc icon */}
                    </ListItemIcon>
                    <ListItem>{text}</ListItem>
                </ListItem>
            ))}
        </List>
    );
}

FeatureList.propTypes = {features: PropTypes.arrayOf(PropTypes.string)};

export function SubscriptionTable() {
    let {subscription, subscriptionStatus, changeSubscriptionTo, trial} = useSubscriptionContext()

    const [highlightedColumn, setHighlightedColumn] = useState(0)
    const [isTrial, setIsTrial] = useState(false)
    const {environmentInfo} = useEnvironmentInfo()

    useEffect(() => {
        LOGGER.debug("subscription: ", subscription)

        if (subscriptionStatus === "trialing") {
            setIsTrial(true)
            setHighlightedColumn(1)
        } else {
            setIsTrial(false)
        }

        if (subscription === "STANDARD") {
            setHighlightedColumn(0)
        } else if (subscription === "PRO") {
            setHighlightedColumn(1)
        } else if (subscription === "NONE") {
            setHighlightedColumn(1)
        }
    }, [subscription,subscriptionStatus])

    const [paddle, setPaddle] = useState();

    const {user} = useSecurityContext()

    // Download and initialize Paddle instance from CDN
    useEffect(() => {
        if (!environmentInfo) {
            LOGGER.debug("environmentInfo is empty")
            return;
        }
        if (!user?.email) {
            LOGGER.debug("user.email is empty")
            return;
        }
        //setStandardPriceId(environmentInfo.paddleStandardPrice)
        //setProPriceId(environmentInfo.paddleProPrice)
        //https://developer.paddle.com/changelog/2023/paddlejs-es-module-typescript-wrapper
        //https://developer.paddle.com/paddlejs/methods/paddle-initialize
        //https://www.npmjs.com/package/@paddle/paddle-js
        initializePaddle({
            environment: environmentInfo.paddleEnvironment,
            token: environmentInfo.paddleClientSideToken,
            pwCustomer: {
                email: user?.email,
            }
        }).then(
            (paddleInstance) => {
                if (paddleInstance) {
                    setPaddle(paddleInstance);
                }
            },
        );
    }, [environmentInfo, user?.email]);

    // eslint-disable-next-line
    const openCheckout = () => {
        paddle?.Checkout.open({
            items: [{ priceId: 'PRICE_ID_GOES_HERE' }],
        });
    };

    return (
        <div className={cssStyles.priceTableWrapperDiv}>
            <table className={(highlightedColumn===0?cssStyles.priceTableLeft:cssStyles.priceTableRight)}>
                <tbody>
                    <tr className={cssStyles.imageRow}>
                        <td className={cssStyles.imageCell}>
                            <div className={cssStyles.imageSpanWrapper}>
                            <span
                                style={{backgroundImage: 'url("/products/standard-icon-isolated.svg")'}}
                                className={addClasses([cssStyles.imageSpan, cssStyles.standardImageSpan])}>
                            </span>
                            </div>
                        </td>
                        <td className={cssStyles.imageCell}>
                            <div className={cssStyles.imageSpanWrapper}>
                            <span
                                style={{backgroundImage: 'url("/products/pro-icon-isolated.svg")'}}
                                className={addClasses([cssStyles.imageSpan, cssStyles.proImageSpan])}>
                            </span>
                            </div>
                        </td>
                    </tr>
                    <tr className={cssStyles.titleRow}>
                        <td className={cssStyles.planTitle}>Standard Plan</td>
                        <td className={cssStyles.planTitle}>Pro Plan</td>
                    </tr>
                    <tr className={cssStyles.priceRow}>
                        <td>
                            <div className={cssStyles.priceAndDurationDiv}><span
                                className={cssStyles.priceSpanWrapper}><span>€9.99</span></span><span
                                className={cssStyles.priceDuration}>per <br/>month</span>
                            </div>
                        </td>
                        <td>
                            <div className={cssStyles.priceAndDurationDiv}><span
                                className={cssStyles.priceSpanWrapper}><span>€19.99</span></span><span
                                className={cssStyles.priceDuration}>per <br/>month</span>
                            </div>
                        </td>
                    </tr>
                    <tr className={cssStyles.featuresRow}>
                        <td>
                            <FeatureList key={"STD_FEATURE_LIST"}>
                                <Feature><b>2</b>&nbsp;Workspaces</Feature>
                                <Feature><b>5000</b>&nbsp;Architecture Assets</Feature>
                                <Feature>Import / Export</Feature>
                                <Feature>Capability Mapping</Feature>
                                <Feature>Data Flow Maps</Feature>
                                <Feature>Target Application Maps</Feature>
                            </FeatureList>
                        </td>
                        <td>
                            <FeatureList key={"PRO_FEATURE_LIST"}>
                                <Feature><b>20</b>&nbsp;Workspaces</Feature>
                                <Feature><b>100000</b>&nbsp;Architecture Assets</Feature>
                                <Feature>Import / Export</Feature>
                                <Feature>Capability Mapping</Feature>
                                <Feature>Data Flow Maps</Feature>
                                <Feature>Target Application Maps</Feature>
                                <Feature>Reference Capability Maps</Feature>
                            </FeatureList>
                        </td>
                    </tr>
                    <tr className={cssStyles.buttonRow}>
                        {
                            isTrial && <>
                                <td className={cssStyles.buttonCell} colSpan={2}>
                                    Your trial is active until <b className={cssStyles.trialEndDate}>{stringToHumanDateString(trial?.end)}</b>
                                </td>
                            </>
                        }
                        {
                            !isTrial && <>
                                <td className={cssStyles.buttonCell}>
                                    <ActionButton
                                        testId={"STANDARD_BUTTON"}
                                        newSubscriptionType={"STANDARD"}
                                        currentSubscriptionType={subscription}
                                        changeSubscriptionTo={() => {
                                            changeSubscriptionTo("STANDARD")
                                        }}
                                    />
                                </td>
                                <td className={cssStyles.buttonCell}>
                                    <ActionButton
                                        testId={"PRO_BUTTON"}
                                        variant={"contained"}
                                        newSubscriptionType={"PRO"}
                                        currentSubscriptionType={subscription}
                                        changeSubscriptionTo={() => {
                                            changeSubscriptionTo("PRO")
                                        }}
                                    />
                                </td>
                            </>
                        }
            </tr>
        </tbody>
</table>
</div>
    )
}
