import * as jointjs from "@joint/plus";
import {addClasses} from "../../../../utils/PresentationUtils";
import Logger from "../../../../utils/Logger";

const LOGGER = new Logger("JointjsUtils")

export const EMPTY_GRAPH = {cells: []}

export function containsPoint(rect, {x, y}) {
    if (rect?.x < x && x < rect?.x + rect?.width && rect?.y < y && y < rect?.y + rect?.height) {
        return true
    }
    return false
}

export function hasTools(cellView) {
    return !!cellView?.toolsView
}
export function hasNoTools(cellView) {
    return !hasTools(cellView)
}

export function isEmbedded(element) {
    // Implement your logic to determine if the element is a child of another element
    // For example, check if the element has a parent:
    return !!element?.isEmbedded();
}

export function getRootAncestor(cell, graph) {
    // While the cell has a parent, update the cell to be the parent.
    while (cell.get('parent')) {
        cell = graph.getCell(cell.get('parent'));
    }
    return cell;
}

export function findLinksBetweenCells(graph, cellA, cellB) {
    return graph.getConnectedLinks(cellA).filter(function(link) {
        let sourceId = link.get('source').id;
        let targetId = link.get('target').id;
        return (sourceId === cellB.id && targetId === cellA.id) || (sourceId === cellA.id && targetId === cellB.id);
    });
}

export function findLinksWithAttribute(graph, attributeName, value) {
    return graph.getLinks().filter(function(link) {
        return getCustomProp(link, attributeName) === value;
    });
}

export function findElementsWithAttribute(graph, attributeName, value) {
    return graph.getElements().filter(function(element) {
        return getCustomProp(element, attributeName) === value;
    });
}

const contextMenuTarget = {x:0, y:0}

export function displayContextMenu(event, menuOptions) {

    const contextMenu = buildContextMenu(contextMenuTarget, menuOptions)
    contextMenuTarget.x = event.clientX
    contextMenuTarget.y = event.clientY

    menuOptions.forEach(option => {
        contextMenu.on('action:' + option.id, option.handler);
    })

    contextMenu.render()
}

export function buildContextMenu(target, menuOptions) {

    const menuTools = menuOptions.map(option => {
        return {
            action: option.id,
            content: option.text,
            customObject: option.customObject,
        }
    })

    const contextMenu = new jointjs.ui.ContextToolbar({
        padding: 10,
        target: target,
        //theme: "modern",
        tools: menuTools,
        anchor: "top-left",
        autoClose: true,
        vertical: true,
    });

    //contextMenu.remove()

    menuOptions.forEach(option => {
        contextMenu.on(`action:`+option.id, ()=>{
            option.handler(option.customObject)
            contextMenu.remove()
        })
    })

    return contextMenu

}

export function showDialog(options = {}) {
    const {
        text = "Are you sure?",
        buttons=[{
            id: "close",
            text: "Close",
            handler: () => {},
        }, {
            id: "no",
            text: "NO",
            handler: () => {},
        }, {
            id: "yes",
            text: "YES",
            handler: () => {},
        }, {
            id: "revealExisting",
            text: "Reveal existing",
            handler: () => {},
        }],
        selectOptions = [],
        isMultiSelect = false,
    } = options;



    let content = text
    if (selectOptions.length > 0) {
        content = `<p>${text}</p><p><select class="my-multiple-dataflow-select" ${isMultiSelect ? "multiple" : ""}>`
        selectOptions.forEach(option => {
            content += `<option value="${option.value}">${option.text}</option>`
        })
        content += "</select></p>"
    }

    const dialog = new jointjs.ui.Dialog({
        className: addClasses(["joint-dialog", "GridStackComponent2-dialog"]),
        theme: "modern",
        title: "Confirmation",
        width: 552,
        content: content,
        draggable: true,
        buttons: buttons.map(b => ({
            action: b.id,
            content: b.text,
            next: true,
        })),
        selectOptions: selectOptions,
    });

    dialog.open();

    let eventMap = {}
    buttons.forEach(b => {
        eventMap[`action:${b.id}` + (b.sameAsClose?" action:close":"")] = function() {
            if (selectOptions?.length > 0) {
                const selectedValues = Array.from((document.querySelector('.my-multiple-dataflow-select')?.selectedOptions || [])).map(option => option.value)
                b.handler(selectedValues)
                dialog.remove()
                return
            }
            b.handler();
            dialog.remove()
        }
    })

    dialog.on(eventMap)
}

export function clearPaper(graph, graphNode, saveNode) {
    showDialog({
        text: "Are you sure you wish to clear all elements from the diagram?",
        buttons: [{
            id: "yes",
            text: "YES",
            handler: () => {
                LOGGER.debug("clearing the paper")
                graph.clear()
                const mergedNode = {...graphNode, graph: graph.toJSON()}
                LOGGER.debug("saving mergedNode: ", mergedNode)
                saveNode(mergedNode)
            },
        }, {
            id: "no",
            text: "NO",
            handler: () => {},
            sameAsClose: true,
        } ],
    });

}

export function setCustomLaneProp(lane, name, value) {
    if (!lane) {
        LOGGER.error("No lane to setCustomLaneProp on")
        return
    }
    if (!lane.data) {
        lane.data = {}
    }
    lane.data[name] = value
}

export function getCustomLaneProp(lane, name) {
    if (!lane?.data) {
        return undefined
    }
    return lane.data[name]
}

export function setCustomProp(cell, name, value) {
    cell?.prop(`data/${name}`, value)
}

export function getCustomProp(cell, name) {
    return cell?.prop(`data/${name}`)
}

export function hasType(cell, typeString) {
    if(Array.isArray(typeString)) {
        return typeString.some(t => getCustomProp(cell, "type") === t)
    }
    return getCustomProp(cell, "type") === typeString
}

export function flashBorder(rectangle, times) {
    let counter = 0;
    let thick = true;

    const intervalId = setInterval(() => {
        if (thick) {
            rectangle.attr('body/stroke-width', 5);
        } else {
            rectangle.attr('body/stroke-width', 1);
        }
        thick = !thick;

        counter++;
        if (counter >= times * 2) { // Multiply by 2 because we change the border twice per "flash"
            clearInterval(intervalId);
        }
    }, 1000);  // The interval time (in milliseconds) defines how fast it flashes
}

export function linkLength(link) {
    return Math.sqrt(Math.pow(link?.attributes?.source?.x-link?.attributes?.target?.x, 2) + Math.pow(link?.attributes?.source?.y-link?.attributes?.target?.y, 2)) //pythagoras
}
// Assuming 'graph' is your JointJS graph and 'paper' is your JointJS paper

export function fitElementsToPaper(paper, minWidth=1000, minHeight=600) {
    //kudos to ChatGPT
    if (!paper) {
        LOGGER.debug("No paper to fit to, skipping")
        return
    }
    const graph = paper.model

    if (graph?.getElements()?.length <= 0) {
        LOGGER.debug("No elements to fit to paper, skipping")
        return
    }

    let bbox = graph.getElements().reduce(function(bbox, element) {
        return bbox.union(element.getBBox());
    }, jointjs.g.rect());

    var paperArea = jointjs.g.rect(0, 0, paper.options.width, paper.options.height);

    let width = Math.max(bbox.width, minWidth)
    let height = Math.max(bbox.height, minHeight)

    // Calculate scale to fit all elements into the paper area
    var sx = paperArea.width / width;
    var sy = paperArea.height / height;
    var scale = Math.min(sx, sy);

    // Scale and center the graph
    paper.scale(scale, scale);
    paper.translate((paperArea.width - width * scale) / 2, (paperArea.height - height * scale) / 2);
}

export function findElementByPosition(graph, x, y) {
    return graph.getElements().find(element => {
        const bbox = element.getBBox()
        return bbox.containsPoint(x, y)
    })
}


