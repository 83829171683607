import cssStyles from "./ReferenceCapabilityMap.module.css"
import React, {useEffect, useRef, useState} from "react";
import {Box, Button, TextField} from "@mui/material";
import * as htmlToImage from 'html-to-image';
import RefArchSelector from "../RefArchSelector/RefArchSelector";
import {TierRenderer} from "./TierRenderer";
import {ZoneRenderer} from "./ZoneRenderer";
import {BandedRenderer} from "./BandedRenderer";
import ShareButton from "../TreeNavigator2/diagrams/ShareButton/ShareButton";
import {download} from "../TreeNavigator2/diagrams/utils/DiagramUtils";
import {addAuthorizationHeader} from "../../utils/Api";
import {useSecurityContext} from "../../security/SecurityContext";
import {ProIconIndicator} from "../ProIconIndicator";
import {useSubscriptionContext} from "../../subscription/SubscriptionContext";
import {useLoadingOverlay} from "../LoadingOverlay/LoadingOverlay";
import {useWorkspaceContext} from "../../security/WorkspaceContext";
import {useStatusMessage} from "../StatusMessenger/StatusMessageProvider";
import Logger from "../../utils/Logger";
import {useModel} from "../../model/ModelContext";

const LOGGER = new Logger("ReferenceCapabilityMap")

function refCapaMapShareOptions() {
    return  [
        {
            format: "PNG",
            label: "Export as PNG image",
            executeOption: async (paper, tableElement)=>{
                await htmlToImage.toPng(tableElement)
                    .then(function (dataUrl) {
                        download(dataUrl, 'reference-capa-maps.png');
                    });
            }
        },
    ]
}

export default function ReferenceCapabilityMap() {

    const domEl = useRef(null);

    const {reload} = useModel()

    const {selectedWorkspace} = useWorkspaceContext()
    const {user} = useSecurityContext()
    const {subscription} = useSubscriptionContext()
    const loadingOverlay = useLoadingOverlay()
    const setNewMessage = useStatusMessage()

    const [selectedMap, setSelectedMap] = useState("business-capabilities.dxp")

    const [isPro, setIsPro] = useState(false)
    const [dxpMap, setDxpMap] = useState({})

    useEffect(function() {
        setIsPro(subscription === "PRO")
    }, [subscription])

    const handleSelectedMapChange = function(newOption) {
        LOGGER.debug("newOption:", newOption)
        setSelectedMap(newOption);
    }

    const importDiagram = () => {
        LOGGER.debug("importDiagram")
        loadingOverlay.show("Importing map")
        fetch(
            ".netlify/functions/import-reference-capability-map", {
                method: "POST",
                headers: addAuthorizationHeader({}, selectedWorkspace, user),
                body: JSON.stringify({map:selectedMap})
            })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error("Error importing diagram: " + response.statusText)
                }
                return response.json()
            })
            .then(result=> {
                console.log("result: ", result)
                setNewMessage("Successfully imported reference capability", "info")
                reload()
            }).catch(e=>{
                console.error("error importing diagram: ", e)
                setNewMessage("Error importing diagram: " + e, "alert")
            }).finally(()=>{
                loadingOverlay.hide()
        })
    }

    useEffect(function() {
        loadingOverlay.show("Loading map")
        fetch(
        ".netlify/functions/load-reference-capability-map?map="+selectedMap, {
            method: "GET",
            headers: addAuthorizationHeader({}, null, user),
        })
        .then(response => response.json())
        .then(remoteMap=> {
            LOGGER.debug("remoteMap: ", remoteMap)
            let tempDxpMap = {
                //width: width,
                //height: height,
                layouts: remoteMap.layouts,
                capabilities: remoteMap.capabilities
            }
            console.log("tempDxpMap = ",tempDxpMap)
            setDxpMap(tempDxpMap)
        }).catch(e=>{
            LOGGER.error("error loading MAP: ", e)
        }).finally(()=>{
            loadingOverlay.hide()
        })

    // eslint-disable-next-line
    }, [selectedMap, user])

    const [searchTerm, setSearchTerm] = useState("")

    const CapabilitySearchBox = function() {
        return <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <TextField
                id="searchterm"
                label="Search"
                variant="outlined"
                value={searchTerm || ''}
                onChange={(e)=> {
                    console.log("st=" + e.target.value)
                    setSearchTerm(e.target.value)
                }}
                inputRef={input => input && input.focus()}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        // Prevent default enter key action
                        event.preventDefault();
                        return false
                    }
                }}
            />
        </Box>
    }
    const howToShowTiers = "banded" // "tiered" | "zoned" | "banded"
    return <div className={cssStyles.ca}>
        <div className={cssStyles.actionBar}>
            <div className={cssStyles.internalActionBar}>
                <RefArchSelector
                    optionSelected={(newOption)=>handleSelectedMapChange(newOption)}
                    label={"Reference Architecture"}
                    defaultValue={"business-capabilities.dxp"}

                />
                <CapabilitySearchBox/>
                {isPro && <div className={cssStyles.importButtonDiv}>
                    <Button variant={"contained"} type={"button"} onClick={() => {
                        importDiagram()
                    }}>Import into workspace<ProIconIndicator isPro={true}/></Button>
                </div>}
                <ShareButton
                    getElement={()=> {
                        return domEl.current
                    }}
                    shareOptions={refCapaMapShareOptions()}
                />
            </div>
        </div>
        <div id={"domEl"} ref={domEl}>
            {dxpMap &&
                (
                    howToShowTiers === "tiered" ?
                        <TierRenderer map={dxpMap} searchTerm={searchTerm}/>:
                        (howToShowTiers === "zoned"?
                            <ZoneRenderer map={dxpMap} searchTerm={searchTerm}/>:
                            <BandedRenderer map={dxpMap} searchTerm={searchTerm}/>
                        )
                )
            }
        </div>
    </div>
}
