import cssStyles from './BusinessProcessPaper.module.css'
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react"
import * as joint from "@joint/plus"
import Logger from "../../../../utils/Logger"
import {IconButton} from "@mui/material"
import LayersClearIcon from "@mui/icons-material/LayersClear"
import {useSelectedNodes} from "../../../SelectedNodes/SelectedNodesProvider"
import {
    clearPaper,
    displayContextMenu, EMPTY_GRAPH, getCustomLaneProp, getCustomProp, setCustomProp,
} from "../utils/JointjsUtils"
import {useModel} from "../../../../model/ModelContext"
import {useDrop} from "react-dnd"
import {ItemTypes} from "@minoru/react-dnd-treeview"
import {NodeFolderRootIds, NodeType} from "../../../../model/Constants"
import {useTreeDndContext} from "../TreeDndContext"
import {saveGraphJSON} from "../utils/DiagramUtils";
import {useStatusMessage} from "../../../StatusMessenger/StatusMessageProvider";
import {
    addActivityRectangle,
    addActorLane,
    getLaneAt, getLaneGroupAt, pullLaneUp, pushLaneDown, removeLane,
    renameLane, updateActivityRectangle
} from "./BusinessProcessDiagramUtils";
import {useCreateOrEditDialog} from "../../../DialogBoxes/CreateOrEditDialogProvider";
import {useLoadingOverlay} from "../../../LoadingOverlay/LoadingOverlay";
import {addActivity, getActivityById, updateActivity} from "../../../../utils/ModelUtils";
import generateUUID from "../../../../utils/UUIDUtils";

const LOGGER = new Logger("BusinessProcessPaper")

joint.setTheme('bpmn')

function buildActorActivityLabel(actorActivityName, applicationName) {
    return actorActivityName + "\n<" + applicationName + ">"
}

function cleanNode(businessProcessNode, paperRef) {
    //see if any of the activities should be removed first, then save the business process
    //get the activity nodes from the paper
    const activityRectangles = paperRef.current.model.getElements().filter(element => getCustomProp(element, 'type') === NodeType.ActorActivity.description)
    //get the activityIds from the businessProcessNode
    const activityIds = activityRectangles.map(node => getCustomProp(node, "nodeId"))

    LOGGER.debug("saveBusinessProcess.current business process: ", businessProcessNode)
    LOGGER.debug("saveBusinessProcess.activityIds: ", activityIds)
    businessProcessNode.activities = (businessProcessNode.activities ? businessProcessNode.activities.filter(activity => activityIds.includes(activity.id)):[])
    return businessProcessNode
}

function saveBusinessProcess(businessProcessNode, paper, saveNode) {

    saveNode(cleanNode(businessProcessNode, paper))
    LOGGER.debug("saveBusinessProcess.saving business process: ", businessProcessNode)

}

let zePaperRef = null

function BusinessProcessPaper({paperWidth, paperHeight}, ref) {
    const paperWrapperRef = useRef(null);
    const paperRef = useRef();
    const navigatorRef = useRef(null);

    const paper = useRef(null)
    const scroller = useRef(null);
    const graph = useRef(null);
    const stencilRef = useRef(null);

    const loadingOverlay = useLoadingOverlay()
    const {getNodeById, saveNode, reload, updatedNode} = useModel()
    const {selectedNodes, setSelectedNodeById, setSoftSelectedNode, setSoftSelectedNodeById} = useSelectedNodes()
    const {isDraggingActive, nodeBeingDragged} = useTreeDndContext()
    const createOrEditDialog = useCreateOrEditDialog()

    const setNewMessage = useStatusMessage()

    const [businessProcessNode, setBusinessProcessNode] = useState(null)
    const [canAcceptDrop, setCanAcceptDrop] = useState(false)
    const [clientPosition, setClientPosition] = useState({x: 0, y: 0})

    useImperativeHandle(ref, ()=>{
        return ({
            getPaper: ()=>paper.current,
            toJSON: ()=>graph.current.toJSON(),
            clearPaper:()=>{
                graph.current.clear()
            },
            refreshPaper:()=>{
                //todo: refresh the paper
            }
        })
    })

    useEffect(() => {
        if (graph?.current && updatedNode) {
            LOGGER.debug("updatedNode changed: ", updatedNode)
            if (updatedNode.id) {
                graph.current.getElements().forEach(element => {
                    if (getCustomProp(element, "nodeId") === updatedNode.id) {
                        if (updatedNode.type === NodeType.ActorActivity.description) {
                            const application = getNodeById(updatedNode.applicationId)
                            //element.attr('label/text', JSON.stringify(element.position()) + buildActorActivityLabel(updatedNode.name, application?.name))
                            element.attr('label/text', buildActorActivityLabel(updatedNode.name, application?.name))
                        }
                    }
                })
            }
        }
        // eslint-disable-next-line
    }, [updatedNode]);

    useEffect(() => {
        LOGGER.debug("selectedNodes changed.")
        if (selectedNodes.length === 1) {
            if (selectedNodes[0]?.type === NodeType.BusinessProcess.description) {
                LOGGER.debug("setting business process node: ", selectedNodes[0])
                setBusinessProcessNode(selectedNodes[0])
            }
        }
    }, [selectedNodes])


    useEffect(()=>{

        if (paper.current && graph.current) {
            LOGGER.debug("paperRef & graphRef ready")
            //return
        }
        if (!paperRef) {
            LOGGER.debug("paperContainerRef not ready yet.")
            return
        }
        if (!stencilRef) {
            LOGGER.debug("stencilRef not ready yet.")
            return
        }

        LOGGER.debug("all necessary refs are ready")

        /* GRAPH */
        let bpmn2 = joint.shapes.bpmn2;
        let paperContainerEl = paperWrapperRef.current

        let BPMNTypes = {
            Pool: 'bpmn2.HeaderedPool',
            Group: 'bpmn2.Group',
            Activity: 'bpmn2.Activity',
            Event: 'bpmn2.Event',
            Gateway: 'bpmn2.Gateway',
            DataObject: 'bpmn2.DataObject',
            DataStore:'bpmn2.DataStore',
            DataAssociation: 'bpmn2.DataAssociation',
            Flow: 'bpmn2.Flow',
            Conversation: 'bpmn2.Conversation',
            ConversationLink: 'bpmn2.ConversationLink',
            Annotation: 'bpmn2.Annotation',
            AnnotationLink: 'bpmn2.AnnotationLink',
            Choreography: 'bpmn2.Choreography'
        };

        graph.current = new joint.dia.Graph({ type: 'bpmn' }, { cellNamespace: joint.shapes });
        let commandManager = new joint.dia.CommandManager({ graph: graph.current });
        let keyboard = new joint.ui.Keyboard();

        let pointerDownData = {
            time: null,
            position: null
        }

        /* PAPER + SCROLLER */

        paper.current = new joint.dia.Paper({
            autoResizePaper: true,
            width: 2800,
            height: 1600,
            model: graph.current,
            gridSize: 5,
            async: true,
            sorting: joint.dia.Paper.sorting.APPROX,
            interactive: { linkMove: false },
            snapLabels: true,
            cellViewNamespace: joint.shapes,
            clickThreshold: 10,
            // Connections
            defaultLink: function() {
                return new bpmn2.Flow({
                    attrs: {
                        line: {
                            flowType: 'sequence'
                        }
                    }
                });
            },
            validateConnection: function(cellViewS, _magnetS, cellViewT, _magnetT, _end) {
                let source = cellViewS.model;
                let target = cellViewT.model;
                // don't allow loop links
                if (source === target) return false;
                // don't allow link to link connection
                if (source.isLink()) return false;
                if (target.isLink()) return false;
                // don't allow group connections
                let sourceType = source.get('type');
                let targetType = target.get('type');
                if (sourceType === BPMNTypes.Group || targetType === BPMNTypes.Group) return false;
                if (sourceType === BPMNTypes.Pool || targetType === BPMNTypes.Pool) return false;
                return true;
            },
            defaultAnchor: {
                name: 'perpendicular'
            },
            defaultConnectionPoint: {
                name: 'boundary',
                args: { stroke: true }
            },
            // Embedding
            embeddingMode: true,
            frontParentOnly: false,
            validateEmbedding: function(childView, parentView) {
                let parentType = parentView.model.get('type');
                let childType = childView.model.get('type');
                if (parentType === BPMNTypes.Pool && childType !== BPMNTypes.Pool) return true;
                if (parentType === BPMNTypes.Activity && childType === BPMNTypes.Event) return true;
                if (parentType === BPMNTypes.Group && childType !== BPMNTypes.Group && childType !== BPMNTypes.Pool) return true;
                return false;
            },
            // Highlighting
            highlighting: {
                default: {
                    name: 'mask',
                    options: {
                        attrs: {
                            'stroke': '#3498db',
                            'stroke-width': 3,
                            'stroke-linejoin': 'round'
                        }
                    }
                }
            }
        }).on({
            'blank:pointerdown': function(evt, x, y) {
                LOGGER.debug("blank:pointerdown, evt: ", evt)
                let currentTime = new Date().getTime()
                pointerDownData = {
                    time: currentTime,
                    position: {x, y}
                }
                LOGGER.debug("blank:pointerdown, business node:", businessProcessNode)
                closeTools();
                if (evt.metaKey) {
                    LOGGER.debug("blank:pointerdown, metaKey pressed")
                    selection.startSelecting(evt, x, y);
                } else {
                    evt.preventDefault()
                    evt.stopPropagation()
                    scroller.current.startPanning(evt)
                }
            },
            'blank:pointerup': function(evt, x, y) {
                let currentTime = new Date().getTime()
                if (currentTime - pointerDownData.time < 150 && Math.abs(pointerDownData.position.x - x) < 5 && Math.abs(pointerDownData.position.y - y) < 5) {
                    LOGGER.debug("blank:pointerup, CLICK evt: ", evt)
                    closeTools();
                    selection.cancelSelection()
                    setSelectedNodeById(businessProcessNode?.id)
                }
            },
            'blank:pointerclick': function(evt, x, y) {
                LOGGER.debug("blank:pointerdown, evt: ", evt)
                LOGGER.debug("blank:pointerdown, business node:", businessProcessNode)
                closeTools();
                setSelectedNodeById(businessProcessNode?.id)
            },
            'element:pointerdown': function(cellView, evt) {
                LOGGER.debug("element:pointerdown, node:", businessProcessNode)
                closeTools();
                const actorActivityNodeId = getCustomProp(cellView.model, "nodeId")
                if (actorActivityNodeId) {
                    const activity = getActivityById(businessProcessNode, actorActivityNodeId)
                    setSoftSelectedNode(activity)
                }
            },
            'cell:pointerclick': function(cellView, event, x, y) {
                LOGGER.trace("cell:pointerclick:", cellView)
                openTools(cellView, {x, y});
                let nodeId = getCustomProp(cellView.model, "nodeId")
                LOGGER.debug("nodeId: ", nodeId)
                LOGGER.debug("cellView.type = ", cellView.model.get('type'))
                if (cellView?.model.get('type') === BPMNTypes.Pool) {
                    LOGGER.debug(`element:contextmenu on HeaderedPool found.`)
                    const lane = getLaneAt(paper.current, graph.current, businessProcessNode, {x: event.clientX, y: event.clientY})
                    if (lane) {
                        LOGGER.debug(`element:contextmenu on Lane found. Lane: `, lane)
                        //override the nodeId with the lane's nodeId
                        //this is because the lane is the actual node that we want to select
                        //and not the pool in this case
                        nodeId = getCustomLaneProp(lane, "nodeId")
                    } else {
                        LOGGER.debug(`element:contextmenu on Pool found.`)
                        //do nothing, we want to select the pool
                        setSelectedNodeById(nodeId)
                        return
                    }
                }
                //regardless of the type, we want to select the node if a nodeId is found
                if (nodeId) {
                    LOGGER.debug("nodeId found: ", nodeId)
                    setSoftSelectedNodeById(nodeId)
                } else {
                    LOGGER.debug("no nodeId found")
                }
            },
            'cell:pointerup': function(cellView) {
                const cell = cellView.model;
                const type = getCustomProp(cell, "type")
                switch (type) {
                    case NodeType.ActorActivity.description:
                        LOGGER.debug("ActorActivity position changed: ", cell.position())
                        const actorActivityNodeId = getCustomProp(cell, "nodeId")
                        if (actorActivityNodeId) {
                            const actorActivityNode = getActivityById(businessProcessNode, actorActivityNodeId)
                            if (actorActivityNode) {
                                const newPosition = cell.position()
                                const clientOffset = paper.current.localToClientPoint(newPosition)
                                const lane = getLaneAt(paper.current, graph.current, businessProcessNode, clientOffset)

                                if (lane) {
                                    const actorId = getCustomLaneProp(lane, "nodeId")
                                    if (actorId !== actorActivityNode.actorId) {
                                        LOGGER.debug("actorId changed, moving activity to new lane")
                                        const updatedNode = {...actorActivityNode, actorId}
                                        const updatedBPN = updateActivity(businessProcessNode, updatedNode)
                                        const applicationName = getNodeById(updatedNode.applicationId)?.name
                                        const actorName = getNodeById(updatedNode.actorId)?.name
                                        updateActivityRectangle(graph.current, paper.current, updatedNode, applicationName, actorName)
                                        setBusinessProcessNode(updatedBPN)
                                        saveBusinessProcess(updatedBPN, paper,saveNode)
                                        setSoftSelectedNode(updatedNode)

                                    }
                                } else {
                                    const updatedNode = {...actorActivityNode, actorId: null}
                                    const updatedBPN = updateActivity(businessProcessNode, updatedNode)
                                    const applicationName = getNodeById(updatedNode.applicationId)?.name
                                    const actorName = getNodeById(updatedNode.actorId)?.name
                                    updateActivityRectangle(graph.current, paper.current, updatedNode, applicationName, actorName)
                                    setBusinessProcessNode(updatedBPN)
                                    saveBusinessProcess(updatedBPN, paper,saveNode)
                                    setSoftSelectedNode(updatedNode)
                                }
                            }
                        }
                        break
                    default:
                        LOGGER.debug(`position changed, but no further actions needed for cell type ${type} and cell: `, cell.position())
                        break
                }
            },
            'element:pointerdblclick': function(element, event) {

                if (element?.model?.get('type') === BPMNTypes.Gateway || element?.model?.get('type') === BPMNTypes.Event) {
                    //alert("hey")

                    joint.ui.TextEditor.edit(event.target, {
                        cellView: element,
                        textProperty: ['attrs', 'label', 'text'],
                        annotationsProperty: ['attrs', 'label', 'annotations'],
                        onKeydown: (evt) => {
                            if (evt.code === 'Enter') {
                                evt.stopPropagation();
                                joint.ui.TextEditor.close();
                            }
                        }
                    });

                    return
                }


                const nodeId = getCustomProp(element?.model, "nodeId")
                const nodeType = getCustomProp(element?.model, "type")
                if (nodeId) {
                    LOGGER.debug("nodeId: ", nodeId)
                    LOGGER.debug("nodeType: ", nodeType)
                    if (nodeType === NodeType.ActorActivity.description) {
                        LOGGER.debug("nodeType is ActorActivity, getting it from the businessProcessNode.")
                        const activityNode = getActivityById(businessProcessNode, nodeId)
                        LOGGER.debug("Opening editor for activityNode: ", activityNode)
                        createOrEditDialog.editNode(activityNode, async (editedNode) => {
                            LOGGER.debug("editNode.callback-onClose")
                            const updatedBPN = updateActivity(businessProcessNode, editedNode)
                            const applicationNode = getNodeById(editedNode.applicationId)
                            const actorNode = getNodeById(editedNode.actorId)
                            updateActivityRectangle(graph.current, paper.current, editedNode, applicationNode?.name, actorNode?.name)
                            LOGGER.debug("updated activity rectangle")
                            //TODO check if the user changed the actorId, then I also need to move the rectangle to another lane!
                            setBusinessProcessNode(updatedBPN)
                            saveBusinessProcess(updatedBPN, paper,saveNode)
                        })
                    }
                    const node = getNodeById(nodeId)
                    createOrEditDialog.editNode(node, async (editedNode) => {
                        setCustomProp(element?.model, "nodeId", editedNode.id)
                        setCustomProp(element?.model, "nodeType", editedNode.nodeType)
                        const applicationNode = getNodeById(editedNode?.applicationId)
                        const actorNode = getNodeById(editedNode?.actorId)
                        updateActivityRectangle(graph.current, paper.current, editedNode, applicationNode?.name, actorNode?.name)
                        const updatedBPN = updateActivity(businessProcessNode, editedNode)
                        setBusinessProcessNode(updatedBPN)
                        saveBusinessProcess(updatedBPN, paper,saveNode)
                    })
                    return
                } else {
                    LOGGER.debug("no nodeId found")

                    const lane = getLaneAt(paper.current, graph.current, businessProcessNode, {x: event.clientX, y: event.clientY})
                    if (lane) {
                        LOGGER.debug("lane found: ", lane)
                        const actorId = getCustomLaneProp(lane, "nodeId")
                        const actorNode = getNodeById(actorId)
                        createOrEditDialog.newNode({
                                name: "New Actor Activity",
                                description: "[description]",
                                type: NodeType.ActorActivity.description,
                                parentId: NodeFolderRootIds.ActorActivityRootId.description,
                                businessProcessId: businessProcessNode.id,
                                actorId: actorNode?.id,
                                applicationId: null,
                            },
                            /*onClose*/ async (newNode) => {
                                LOGGER.debug("onClose selected -> call the onClose handler")
                                const newlySavedNode = {...newNode, id:generateUUID()}
                                LOGGER.debug("newNode: ", newNode)
                                setCustomProp(element?.model, "nodeId", newlySavedNode.id)
                                setCustomProp(element?.model, "nodeType", NodeType.ActorActivity.description)
                                const updatedBPN = updateActivity(businessProcessNode, newlySavedNode)
                                setBusinessProcessNode(updatedBPN)
                                saveBusinessProcess(updatedBPN, paper,saveNode)
                                setSoftSelectedNode(updatedNode)
                            },
                            () => {
                                LOGGER.debug("onCancel selected -> call the onCancel handler")
                        })
                    }
                    return
                }
            },

            'link:mouseenter': function(linkView) {
                // Open tool only if there is none yet
                if (linkView.hasTools()) return;

                let ns = joint.linkTools;
                let toolsView = new joint.dia.ToolsView({
                    name: 'link-hover',
                    tools: [
                        new ns.Vertices({ vertexAdding: false }),
                        new ns.SourceArrowhead(),
                        new ns.TargetArrowhead()
                    ]
                });

                linkView.addTools(toolsView);
            },

            'link:mouseleave': function(linkView) {
                // Remove only the hover tool, not the pointerdown tool
                if (linkView.hasTools('link-hover')) {
                    linkView.removeTools();
                }
            },

            'link:connect': function(linkView) {
                // Change the link type based on the connected elements
                let link = linkView.model;
                let source = link.getSourceCell();
                let target = link.getTargetCell();
                if (!source || !target) return;
                let types = [source.get('type'), target.get('type')];
                let linkType = link.get('type');

                if (source.get('type')===BPMNTypes.Gateway) {

                    let currentLabels = link.labels()
                    if (currentLabels.length > 0) {
                        if (currentLabels[0]?.attrs?.label?.text?.length > 0) {
                            //eject, no need to set label
                            return
                        }
                    }


                    let lineLabel = ""
                    let data = source.attr('data');
                    if (data.type === 'exclusive') {
                        lineLabel = "Option"
                    } else if (data.type === 'parallel') {
                        lineLabel = "Path"
                    }

                    link.labels([{
                        attrs: {
                            rect: { fill: 'black' },
                            label: {
                                //'label-idx': 0,  // Add a custom attribute to identify the label index
                                text: lineLabel,
                                fill: 'red',
                                fontSize: 12,
                                pointerEvents: 'auto',
                                cursor: 'text',
                                displayEmpty: true,
                                annotations: [{ start: 1, end: 3, attrs: { fill: '#fe854f' }}],
                                refY: -10,  // Position the label 10 points above the link
                            }
                        },
                        position: 0.5
                    }])
                    return
                }

                if (types.indexOf(BPMNTypes.Annotation) > -1) {
                    if (linkType === BPMNTypes.AnnotationLink) return;
                    replaceLink(graph.current, link, BPMNTypes.AnnotationLink);
                    return;
                }
                if (types.indexOf(BPMNTypes.Conversation) > -1) {
                    if (linkType === BPMNTypes.ConversationLink) return;
                    replaceLink(graph.current, link, BPMNTypes.ConversationLink);
                    return;
                }
                if (types.indexOf(BPMNTypes.DataObject) > -1) {
                    if (linkType === BPMNTypes.DataAssociation) return;
                    replaceLink(graph.current, link, BPMNTypes.DataAssociation);
                    return;
                }
                if (types.indexOf(BPMNTypes.DataStore) > -1) {
                    if (linkType === BPMNTypes.DataAssociation) return;
                    replaceLink(graph.current, link, BPMNTypes.DataAssociation);
                    return;
                }
                if (linkType !== BPMNTypes.Flow) {
                    replaceLink(graph.current, link, BPMNTypes.Flow);
                    return;
                }
            },

            'link:pointerdblclick': function(linkView, evt) {
                // Editing a link label
                let index = Number(linkView.findAttribute('label-idx', evt.target));
                joint.ui.TextEditor.edit(evt.target, {
                    cellView: linkView,
                    textProperty: ['labels', index, 'attrs', 'label', 'text'],
                    annotationsProperty: ['labels', index, 'attrs', 'label', 'annotations'],
                    onKeydown: (evt) => {
                        if (evt.code === 'Enter') {
                            evt.stopPropagation();
                            joint.ui.TextEditor.close();
                        }
                    }
                });
            }
        });

        const activityContextMenuOptions = [
            {
                id: "rename",
                text: "Rename",
                handler: () => {
                    LOGGER.debug("rename activity")

                },
            }
        ];
        /*
        const poolContextMenuOptions = [
            {
                id: "rename",
                text: "Rename Pool",
                handler: () => {
                    LOGGER.debug("rename pool")

                },
            },{
                id: "delete",
                text: "Delete Pool",
                handler: (customObject) => {
                    LOGGER.debug("delete pool")
                    if (!customObject?.nodeId) {
                        LOGGER.debug("no nodeId")
                        return
                    }

                },
            }
        ];
         */

        const laneContextMenuOptions = [
            {
                id: "moveUp",
                text: "Move Actor Up",
                handler: (props) => {
                    LOGGER.debug("move lane up: ", props)
                    pullLaneUp(paper.current, graph.current, businessProcessNode, props.nodeId, setNewMessage)
                },
            },{
                id: "moveDown",
                text: "Move Actor Down",
                handler: (props) => {
                    LOGGER.debug("move lane down: ", props)
                    pushLaneDown(paper.current, graph.current, businessProcessNode, props.nodeId, setNewMessage)
                },
            },{
                id: "renameActor",
                text: "Rename Actor",
                handler: (customObject) => {
                    LOGGER.debug("rename actor: ", customObject)

                    if (!customObject?.nodeId) {
                        LOGGER.debug("no nodeId")
                        return
                    }
                    const laneNode = getNodeById(customObject.nodeId)

                    if (!laneNode) {
                        LOGGER.debug("no laneNode")
                        return
                    }

                    //getLaneNodeId()

                    const actorNodeId = laneNode.id
                    createOrEditDialog.editNode(laneNode, async (updatedNode) => {
                        LOGGER.debug("editNode.callback-onClose")
                        //in some cases the zeNode will contain more fields than are in the updatedNode, so we need to merge them
                        // as an example, the updatedNode will not contain the graph fields, but the zeNode will
                        const mergedNode = {...laneNode, ...updatedNode}
                        await saveBusinessProcess(mergedNode, paper,saveNode)
                        await reload()
                        //update the lane to reflect the changed name
                        const newName = updatedNode.name
                        renameLane(paper.current, graph.current, businessProcessNode, actorNodeId, newName)
                    }, ()=> {
                        LOGGER.debug("editNode.callback-onCancel")
                    })
                    return true
                },
            },{
                id: "delete",
                text: "Delete Actor from Process",
                handler: (customObject) => {
                    LOGGER.debug("delete actor from process event")
                    if (!customObject) {
                        LOGGER.debug("no customObject")
                        return
                    }
                    if (!customObject.nodeId) {
                        LOGGER.debug("no nodeId")
                        return
                    }
                    const deleteResult = removeLane(paper.current, graph.current, businessProcessNode, customObject.nodeId)
                    if (!deleteResult) {
                        LOGGER.warn("delete actor from process failed")
                        return
                    }
                    LOGGER.debug("delete actor from process successful")
                },
            }
        ];



        if (!paper.evenHandlerContextMenu) {
            LOGGER.debug("attaching context menu to paper")
            paper.current.$el.on("contextmenu", function (event) {

                const cellView = paper.current.findView(event.target)
                LOGGER.debug(`element:contextmenu event, type: `, cellView?.model.get('type'))
                if (cellView?.model.get('type') === BPMNTypes.Activity) {
                    LOGGER.debug(`element:contextmenu on Activity found.`)
                    displayContextMenu(event, activityContextMenuOptions)
                } else if (cellView?.model.get('type') === BPMNTypes.Pool) {
                    LOGGER.debug(`element:contextmenu on HeaderedPool found.`)
                    const lane = getLaneAt(paper.current, graph.current, businessProcessNode, {
                        x: event.clientX,
                        y: event.clientY
                    })
                    if (lane) {
                        LOGGER.debug(`element:contextmenu on Lane found. Lane: `, lane)
                        const laneNodeId = lane.data.nodeId
                        displayContextMenu(event, laneContextMenuOptions.map(option => ({
                            ...option,
                            customObject: {nodeId: laneNodeId}
                        })))
                    } else {
                        LOGGER.debug(`element:contextmenu on Pool found.`)
                        //displayContextMenu(event, poolContextMenuOptions)
                    }
                }
                return false


            })
            //paper.evenHandlerContextMenu = true
        }

        function onGraphChange(changeName, change, cell, graph, saveNode, businessProcessNode) {
            const changesToIgnore = [
                "attrs/body/stroke",
                "attrs/body/stroke-width",
            ]
            if (changesToIgnore.includes(change?.propertyPath)) {
                //ignore it is just to show the hover border, no need to save!
                LOGGER.debug(`ignoring change event: `, change)
            } else {

                if (changeName === "add" || changeName === "change:position") {
                    if (changeName === "add") {
                        LOGGER.debug("cell added: ", cell)
                    } else if (changeName === "change:position") {
                        LOGGER.debug("cell position changed: ", cell.position())
                    }
                    LOGGER.debug("cell position: ", cell.position())
                    const clientPosition = paper.current.localToClientPoint(cell.position())
                    const laneGroup = getLaneGroupAt(paper.current, graph.current, businessProcessNode, clientPosition)
                    if (laneGroup) {
                        LOGGER.debug(`laneGroup found at ${JSON.stringify(cell.position())}: `, laneGroup)
                        //todo implement change of laneGroup
                        //add cell to new laneGroup

                        //
                    } else {
                        LOGGER.debug("no laneGroup found at: ", cell.position())
                    }
                }

                const graphJSON = graph.current.toJSON()
                if (graphJSON) {
                    LOGGER.debug("saving graph JSON: ", graphJSON)
                    saveGraphJSON(saveNode, cleanNode(businessProcessNode, paper), graphJSON)
                } else {
                    LOGGER.warn("graph.toJSON() returned null")
                }
            }
        }

        graph.current.on("add", (cell, change) => {
            LOGGER.trace(`graph.add event.`)
            onGraphChange("add", change, cell, graph, saveNode, cleanNode(businessProcessNode, paper));

        })

        graph.current.on("change", (cell, change) => {
            LOGGER.trace(`graph.change event.`)
            onGraphChange("change", change, cell, graph, saveNode, cleanNode(businessProcessNode, paper));

        })

        graph.current.on("remove", (cell, change) => {
            LOGGER.trace(`graph.remove event.`)
            onGraphChange("remove", change, cell, graph, saveNode, cleanNode(businessProcessNode, paper));

        })

        graph.current.on("change:position", (cell, change) => {
            LOGGER.trace(`graph.change:position event.`)

            onGraphChange("change:position", change, cell, graph, saveNode, cleanNode(businessProcessNode, paper));

        })

        scroller.current = new joint.ui.PaperScroller({
            paper: paper.current,
            autoResizePaper: true,
            cursor: 'grab',
            scrollWhileDragging: true,
            inertia: true
        });


        const nav = new joint.ui.Navigator({
            paperScroller: scroller.current,
            width: 150,
            height: 100,
            padding: 10,
            zoomOptions: { max: 3, min: 0.2 },

        });
        nav.$el.appendTo(navigatorRef.current);
        nav.render();

        paper.current.on('blank:mousewheel', (evt, ox, oy, delta) => {
            evt.preventDefault();
            scroller.current.zoom(delta * 0.2, { min: 0.2, max: 3, grid: 0.2, ox, oy });
        });
        //scroller.current.centerContent()




        /* SELECTION */

        let selection = new joint.ui.Selection({
            paper: paper.current,
            graph: graph.current,
            useModelGeometry: true,
            filter: [BPMNTypes.Pool, BPMNTypes.Group] // don't allow to select pool or group shapes
        });

        /* STENCIL */

        // Define Exclusive and Parallel Gateways
        let exclusiveGateway = new joint.shapes.bpmn2.Gateway({
            size: { width: 25, height: 25 },
            attrs: {
                data: { type: 'exclusive' }, // Custom data attribute for the gateway type
                body: { fill: '#f9c74f' }, // Yellow fill for Exclusive Gateway
                label: { text: 'Condition', fill: '#000', fontSize: 9, textAnchor: 'middle' },
                icon: {
                    iconType: 'exclusive' // Custom icon type for exclusive gateway
                }
            }
        });

        let parallelGateway = new joint.shapes.bpmn2.Gateway({
            size: { width: 25, height: 25 },
            attrs: {
                data: { type: 'parallel' }, // Custom data attribute for the gateway type
                body: { fill: '#90be6d' }, // Green fill for Parallel Gateway
                label: { text: 'parallel', fill: '#000', fontSize: 9, textAnchor: 'middle' },
                icon: {
                    iconType: 'parallel' // Custom icon type for parallel gateway
                }
            }
        });

        /* STENCIL */
        const resizeStencilFactor = 0.4

        let stencil = new joint.ui.Stencil({
            paper: paper.current,
            graph: graph.current,
            width: '100%',
            height: '60px',
            layout: {
                columnWidth: 100,
                columns: 6,
                rowHeight: 100,
            },
            mouseOver: function (cellView, evt) {
                if (cellView.model.isLink()) {
                    cellView.highlight(null, { highlighter: { name: 'stroke', options: { padding: 6 } } });
                }
            },
            dragEndClone: function(cell) {

                let clone = cell.clone();
                let type = clone.get('type');

                // some types of the elements need resizing after they are dropped
                let sizeMultipliers = {
                    [BPMNTypes.Annotation]: 1/resizeStencilFactor,
                    [BPMNTypes.Choreography]: 2/resizeStencilFactor,
                    [BPMNTypes.DataObject]: 1/resizeStencilFactor,
                    [BPMNTypes.DataStore]: 1/resizeStencilFactor,
                    [BPMNTypes.Event]: 0.5/resizeStencilFactor,
                    [BPMNTypes.Group]: 2/resizeStencilFactor,
                    [BPMNTypes.Pool]: 6/resizeStencilFactor
                };
                if (type === BPMNTypes.Activity) {
                    let multiplier = 1.2/resizeStencilFactor
                    let originalSize = clone.size();
                    clone.set('size', {
                        width: originalSize.width * multiplier,
                        height: originalSize.height * multiplier
                    });
                    clone.attr({
                        label: {
                            text: '',
                        }
                    })
                } else if (type === BPMNTypes.Pool) {
                    let height = clone.size().height;
                    clone.set({
                        lanes: [{
                            label: 'Lane 1',
                            size: height / 2
                        }, {
                            label: 'Lane 2',
                            size: height / 2
                        }],
                        padding: { top: 0, left: 30, right: 0, bottom: 0 }
                    });
                } else if (type === BPMNTypes.Gateway) {
                    let multiplier = 1/resizeStencilFactor
                    let originalSize = clone.size();
                    clone.set('size', {
                        width: originalSize.width * multiplier,
                        height: originalSize.height * multiplier
                    });
                } else if (type in sizeMultipliers) {
                    let multiplier = sizeMultipliers[type]
                    let originalSize = clone.size();
                    clone.set('size', {
                        width: originalSize.width * multiplier,
                        height: originalSize.height * multiplier
                    });
                } else if (type === BPMNTypes.Event) {
                    let multiplier = 0.5/resizeStencilFactor
                    let originalSize = clone.size();
                    clone.set('size', {
                        width: originalSize.width * multiplier,
                        height: originalSize.height * multiplier
                    });
                    clone.attr({
                        label: {
                            text: 'Event',
                            'font-size': 12,  // Adjust font size as needed
                            'ref-x': 0.5,     // Center the label horizontally
                            'ref-y': 0.5,     // Center the label vertically
                            'text-anchor': 'middle',  // Align the label's anchor point
                            'y-alignment': 'middle',  // Vertical alignment
                        }
                    })
                }

                return clone;
            }
        });
        stencilRef.current.appendChild(stencil.render().el);

        stencil.on('element:drop', function(elementView, event, _x, _y) {
            LOGGER.debug("element dropped!");
            const elementType = elementView.model.get('type');




            // Check if the dropped element is a BPMN Activity
            if (elementType === BPMNTypes.Activity) {
                // Perform the action you want when an Activity is dropped
                LOGGER.debug("BPMN Activity dropped!");
                elementView.model.attr('background', {fill: '#87CEEB'}); // Set to red, change the color as needed

                const lane = getLaneAt(paper.current, graph.current, businessProcessNode, {x: event.clientX, y: event.clientY})
                let actorId = null
                if (lane) {
                    LOGGER.debug("lane found: ", lane)
                    actorId = getCustomLaneProp(lane, "nodeId")
                }
                createOrEditDialog.newNode({
                        name: "New Actor Activity",
                        description: "[description]",
                        type: NodeType.ActorActivity.description,
                        parentId: NodeFolderRootIds.ActorActivityRootId.description,
                        businessProcessId: businessProcessNode.id,
                        actorId: actorId,
                        applicationId: null,
                    },
                    /*onClose*/ async (newNode) => {



                        LOGGER.debug("onClose selected -> call the onClose handler")
                        const newlySavedNode = {...newNode, id:generateUUID()}
                        LOGGER.debug("newNode: ", newNode)
                        setCustomProp(elementView?.model, "nodeId", newlySavedNode.id)
                        setCustomProp(elementView?.model, "type", NodeType.ActorActivity.description)
                        const updatedBPN = updateActivity(businessProcessNode, newlySavedNode)
                        setBusinessProcessNode(updatedBPN)
                        saveBusinessProcess(updatedBPN, paper,saveNode)
                        setSoftSelectedNode(updatedNode)
                        const applicationNode = getNodeById(newlySavedNode?.applicationId)
                        const applicationName = applicationNode?.name
                        const actorNode = getNodeById(newlySavedNode?.actorId)
                        const actorName = actorNode?.name
                        updateActivityRectangle(graph.current, paper.current, newlySavedNode, applicationName, actorName)
                    },
                    () => {
                        LOGGER.debug("onCancel selected -> call the onCancel handler")
                        elementView.model.remove()
                    })
            }
        })


        stencil.load([{
                type: BPMNTypes.Activity,
                size: {width: 140*resizeStencilFactor, height: 80*resizeStencilFactor},
                paperSize: {width: 140, height: 100},
                attrs: {
                    label: {
                        text: 'Activity',
                        'font-size': 9,  // Adjust font size as needed
                        'ref-x': -0.5,     // Center the label horizontally
                        'ref-y': 0,     // Center the label vertically
                        'text-anchor': 'middle',  // Align the label's anchor point
                        'y-alignment': 'middle',  // Vertical alignment
                    }
                }
            },  exclusiveGateway, parallelGateway, {
                type: BPMNTypes.Event,
                size: {width: 60*resizeStencilFactor, height: 60*resizeStencilFactor},
                paperSize: {width: 60, height: 60},
                attrs: {
                    label: {
                        text: 'Event',
                        'font-size': 9,  // Adjust font size as needed
                        'ref-x': -0.5,     // Center the label horizontally
                        'ref-y': 0,     // Center the label vertically
                        'text-anchor': 'middle',  // Align the label's anchor point
                        'y-alignment': 'middle',  // Vertical alignment
                    }
                }
            }]);

        joint.layout.GridLayout.layout(stencil.getGraph(), {
            columns: 100,
            columnWidth: 'compact',
            marginX: 20*resizeStencilFactor,
            marginY: 10*resizeStencilFactor,
            columnGap: 40*resizeStencilFactor
        });

        stencil.on('element:drop', function(elementView, _evt, _x, _y) {
            // open inspector after a new element dropped from stencil
            openTools(elementView);
        });



        /* KEYBOARD */

        keyboard.on('delete backspace', function() {
            graph.current.removeCells(selection.collection.toArray());
        });


        /* TOOLTIPS */

        new joint.ui.Tooltip({
            target: '[data-tooltip]',
            content: function(el) { return el.dataset.tooltip; },
            top: '.joint-toolbar',
            padding: 10,
            direction: 'top'
        });

// Create tooltips for all the shapes in stencil.
        stencil.getGraph().getElements().forEach(function(cell) {
            new joint.ui.Tooltip({
                target: '.joint-stencil [model-id="' + cell.id + '"]',
                content: cell.get('type').split('.')[1],
                top: '.joint-stencil',
                direction: 'bottom',
                padding: 0
            });
        });

        /* ACTIONS */

        function closeTools() {
            paper.current.removeTools();
            joint.ui.Inspector.close();
            joint.ui.FreeTransform.clear(paper.current);
            joint.ui.Halo.clear(paper.current);
        }

        function openTools(cellView, coordinates) {

            closeTools();

            let cell = cellView.model;
            let type = cell.get('type');

            selection.collection.reset([]);
            // Add the cell into the selection collection silently
            // so no selection box is rendered above the cellView.
            selection.collection.add(cell, { silent: true });

            if (cell.isElement()) {
                createElementHalo(cellView);
                createElementFreeTransform(cellView);
                if (type === BPMNTypes.Pool && coordinates) {
                    createPoolTools(cellView, coordinates);
                }
            } else {
                createLinkTools(cellView);
            }
        }

        function createPoolTools(poolView, coordinates) {

            let pool = poolView.model;

            // If there is a lane under the pointer (mouse/touch),
            // add the swimlane tools and remove the FreeTransform from the cell
            let lanesIds = pool.getLanesFromPoint(coordinates);
            if (lanesIds.length === 0) return;
            let laneId = lanesIds[0];

            let boundaryTool = new joint.elementTools.SwimlaneBoundary({
                laneId: laneId,
                padding: 0,
                attributes: {
                    'fill': 'none',
                    'stroke-width': 3,
                    'stroke': '#3498db'
                }
            });
            let transformTool = new joint.elementTools.SwimlaneTransform({
                laneId: laneId,
                minSize: 60,
                padding: 0,
            });
            let elementToolsView = new joint.dia.ToolsView({
                tools: [boundaryTool, transformTool]
            });
            poolView.addTools(elementToolsView);
            joint.ui.FreeTransform.clear(paper.current);
        }

        function createLinkTools(linkView) {
            let ns = joint.linkTools;

            const nodeId = getCustomProp(linkView.model, "nodeId")

            ns.InfoButton = ns.Button.extend({
                name: 'info-button',
                options: {
                    markup: [{
                        tagName: 'circle',
                        selector: 'button',
                        attributes: {
                            'r': 7,
                            'fill': '#001DFF',
                            'cursor': 'pointer'
                        }
                    }, {
                        tagName: 'text',
                        textContent: (nodeId?'E':'+'),
                        selector: 'icon',
                        attributes: {
                            'fill': 'white',
                            'font-size': (nodeId?12:14),
                            'text-anchor': 'middle',
                            'font-weight': 'bold',
                            'pointer-events': 'none',
                            'y': '0.3em'
                        }
                    }],
                    distance: 60,
                    offset: 0,
                    action: function(evt) {
                        //todo: open the link editor
                        alert('View id: ' + this.id + '\nModel id: ' + this.model.id);
                    }
                }
            });

            var infoButton = new ns.InfoButton();


            let toolsView = new joint.dia.ToolsView({
                name: 'link-pointerdown',
                tools: [
                    new ns.Vertices(),
                    new ns.SourceAnchor(),
                    new ns.TargetAnchor(),
                    new ns.SourceArrowhead(),
                    new ns.TargetArrowhead(),
                    new ns.Segments(),
                    new ns.Boundary({ padding: 15 }),
                    new ns.Remove({ offset: -20, distance: 40 }),
                    infoButton
                ]
            });

            linkView.addTools(toolsView);
        }


        function createElementHalo(cellView) {
            let type = cellView.model.get('type');
            let halo = new joint.ui.Halo({
                cellView: cellView,
                theme: 'default',
                type: 'toolbar',
                useModelGeometry: true,
                boxContent: false //type
            });
            halo.removeHandle('clone');
            halo.removeHandle('fork');
            halo.removeHandle('unlink');
            halo.removeHandle('rotate');
            halo.removeHandle('resize');
            if (type === BPMNTypes.Pool || type === BPMNTypes.Group) {
                halo.removeHandle('link');
                halo.removeHandle('fork');
                halo.removeHandle('unlink');
            }
            halo.render();
        }

        function createElementFreeTransform(cellView) {
            let defaultMinSize = 30;
            let freeTransform = new joint.ui.FreeTransform({
                cellView: cellView,
                allowOrthogonalResize: false,
                allowRotation: false,
                minWidth: function(el) {
                    return (el.get('type') === BPMNTypes.Pool) ? el.getMinimalSize().width : defaultMinSize;
                },
                minHeight: function(el) {
                    return (el.get('type') === BPMNTypes.Pool) ? el.getMinimalSize().height : defaultMinSize;
                }
            });
            freeTransform.render();
        }

        function replaceLink(linkGraph, link, type) {
            let link2JSON = {
                type: type,
                source: link.source(),
                target: link.target(),
                vertices: link.vertices()
            };
            link.remove();
            linkGraph.addCell(link2JSON);
        }

// Import

        paperContainerEl.addEventListener('drop', (evt) => {
            evt.preventDefault();
            paperContainerEl.classList.remove('drop-zone');
            const [file] = evt.dataTransfer.files;
            const reader = new FileReader();
            reader.onload = () => {
                const xmlDoc = (new DOMParser()).parseFromString(reader.result, 'application/xml');
                const { cells, errors } = joint.format.fromBPMN(xmlDoc, {
                    bpmn2Shapes: bpmn2
                });
                if (errors.length > 0) {
                    console.error(...errors);
                    return;
                }
                graph.current.resetCells(cells);
                commandManager.reset();
            };
            reader.readAsText(file);
        });

        paperContainerEl.addEventListener('dragover', (evt) => {
            evt.preventDefault();
            paperContainerEl.classList.add('drop-zone');
        });

        paperContainerEl.addEventListener('dragleave', (evt) => {
            evt.preventDefault();
            paperContainerEl.classList.remove('drop-zone');
        });

        paperRef.current.appendChild(scroller.current.el);
        scroller.current.render().center();

        return () => {
            nav?.$el?.remove()
            //TODO check; this could be the reason why i sometimes get an error when I switch to another diagram after visiting a GridStackComponent4 diagram
            scroller?.current?.remove();
            paper?.current?.remove();
        };
        // eslint-disable-next-line
    }, [paperRef, stencilRef, businessProcessNode])

    useEffect(() => {
        async function doRender() {
            if (!businessProcessNode) {
                LOGGER.debug("business process node is empty")
                return
            } else if (!paper) {
                LOGGER.debug("paper is empty")
                return
            } else if (!graph) {
                LOGGER.debug("graph is empty")
                return
            } else {
                if (!businessProcessNode?.graph) {
                    LOGGER.debug("node.graph is empty")
                    return
                }
                LOGGER.debug("loading node.graph")
                paper?.current?.freeze()
                try {
                    await graph?.current?.fromJSON(businessProcessNode?.graph)
                } catch (e) {
                    LOGGER.error("error loading graph: ", e)
                    businessProcessNode.graph = EMPTY_GRAPH
                } finally {
                    paper?.current?.unfreeze()
                }

            }
        }
        try {
            doRender()
        } catch (e) {

        }

    }, [graph, paper, businessProcessNode])


    function isTypeAcceptedForDrop(typeName) {
        switch (typeName) {
            case NodeType.Actor.description:
            case NodeType.Application.description:
            case NodeType.DataExchange.description:
                return true
            default:
                return false
        }
    }

    useEffect(() => {
        if (isDraggingActive) {
            setCanAcceptDrop(isTypeAcceptedForDrop(nodeBeingDragged?.type))
        } else {
            setCanAcceptDrop(false)
        }
    }, [isDraggingActive, nodeBeingDragged]);

    async function onCloseHandler(updatedNode, clientPosition) {
        LOGGER.debug("onClose selected -> call the onClose handler")
        loadingOverlay.show("Creating Activity...", 10000)
        LOGGER.debug("newNode: ", updatedNode)

        const newActivity = await addActivityRectangle(
            paper.current, graph.current,
            businessProcessNode,
            updatedNode,
            clientPosition,
            getNodeById
        )
        addActivity(businessProcessNode, updatedNode)
        saveBusinessProcess(businessProcessNode, paper,saveNode)
        loadingOverlay.hide()
    }

    const [{ isOver, isOverCurrent }, drop] = useDrop(
        () => ({
            accept: ItemTypes.TREE_ITEM, //['NODE', 'ApplicationComponent'],
            canDrop(_item, monitor) {
                const itemType = _item?.data?.type
                return isTypeAcceptedForDrop(itemType)
            },
            hover(_item, monitor) {
                //still needed?? Yass!! for some reason,asking the monitor.getClientOffset() inside the close callback of the createOrEditDialog.newNode() does not work
                // I always get the penultimate position. And thus the rectangle is placed on the wrong position compared to the mouse pointer
                setClientPosition(monitor.getClientOffset())
            },
            drop(_item, monitor) {
                LOGGER.trace(`isOver=${isOver}, ìsOverCurrent=${isOverCurrent}`)
                LOGGER.debug("DROP! _item=", _item)
                //LOGGER.debug("DROP! monitor", monitor)
                LOGGER.debug("DROP! monitor.getClientOffset()", monitor.getClientOffset())
                LOGGER.debug("DROP! monitor.didDrop()", monitor.didDrop())
                LOGGER.debug("DROP! clientPosition", clientPosition)

                if (!(paper.current && graph.current)) {
                    LOGGER.warn(`paperRef (${paperRef}), graphRef (${graph}) not ready yet!`)
                    return _item
                }

                const didDrop = monitor.didDrop()
                if (didDrop) {
                    return _item
                }

                if (paper.current) {
                    LOGGER.debug("paper.current ready")
                    const itemType = monitor?.getItemType()
                    LOGGER.debug("itemType: ", itemType)
                    switch (itemType) {
                        case ItemTypes.TREE_ITEM:
                            LOGGER.debug("dropped TREE_ITEM.")
                            const objectType = _item?.data?.type
                            const droppedNode = getNodeById(_item.id)
                            LOGGER.debug("droppedNode: ", droppedNode)
                            LOGGER.debug("objectType: ", objectType)
                            switch (objectType) {
                                case NodeType.Actor.description:
                                    LOGGER.debug("Actor dropped, droppedNode: ", droppedNode)
                                    addActorLane(paper.current, graph.current, businessProcessNode, droppedNode, monitor.getClientOffset(), setNewMessage)
                                    break
                                case NodeType.Application.description:
                                    LOGGER.debug("Application dropped")

                                    const applicationNode = droppedNode

                                    const actorLane = getLaneAt(paper.current, graph.current, businessProcessNode, monitor.getClientOffset())
                                    let actor = null
                                    if (actorLane) {
                                        LOGGER.debug("actorLane found: ", actorLane)
                                        const actorId = getCustomLaneProp(actorLane, "nodeId")
                                        actor = getNodeById(actorId)
                                    }

                                    const actorActivityNode = {
                                        id: generateUUID(),
                                        name: "New Actor Activity !",
                                        description: "[description]",
                                        type: NodeType.ActorActivity.description,
                                        parentId: NodeFolderRootIds.ActorActivityRootId.description,
                                        businessProcessId: businessProcessNode.id,
                                        actorId: actor?.id,
                                        applicationId: droppedNode?.id,
                                    }

                                    const updatedBPN = addActivity(businessProcessNode, actorActivityNode)
                                    setBusinessProcessNode(updatedBPN)
                                    saveBusinessProcess(updatedBPN, paper,saveNode)

                                    createOrEditDialog.editNode(actorActivityNode,
                                        /*onClose*/ (updatedNode) => {
                                            LOGGER.debug("onClose selected -> call the onClose handler. clientPosition: ", clientPosition)

                                            onCloseHandler(updatedNode, clientPosition)
                                        },
                                        /* onCancel */ () => {
                                            LOGGER.debug("onCancel selected -> call the onCancel handler")
                                        }
                                    )

                                    break
                                case NodeType.DataExchange.description:
                                    LOGGER.debug("DataExchange dropped")
                                    break
                                default:
                                    LOGGER.debug("unsupported item type dropped.")
                                    break
                            }

                            break
                        default:
                            LOGGER.debug("unsupported item type '" + itemType + "' dropped: ", _item)
                            break
                    }

                } else {
                    LOGGER.debug("paperRef undefined")
                }

                return _item
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                clientOffset: monitor.getClientOffset() ,
            }),
        }),
        [paper, graph, businessProcessNode, clientPosition] /* don't forget the dependencies! kudos to: https://stackoverflow.com/a/70757948*/,
    )


    return (<div className={cssStyles.main}>
            <div className={cssStyles.titleDiv}>
                {businessProcessNode?.name}
                <IconButton className={cssStyles.redIconButton} aria-label="clear" onClick={() => {
                    clearPaper(graph.current, businessProcessNode, saveNode)
                }}>
                    <LayersClearIcon className={cssStyles.redIcon}/>
                </IconButton>
            </div>
            {canAcceptDrop && <div ref={drop} className={cssStyles.dragArea}  data-testid={"dragarea-businessprocess-paper"}>dragging: {nodeBeingDragged?.name}</div>}
            <div ref={stencilRef} className={cssStyles.stencilDiv}></div>
            <div ref={paperWrapperRef} className={cssStyles.paperWrapper}>
                <div ref={paperRef} className={cssStyles.paperDiv}></div>
                <div className={cssStyles.navigationWrapper}>
                    <div ref={navigatorRef} className={cssStyles.navigatorDiv}></div>
                </div>
            </div>
        </div>
    );
}
export default forwardRef(BusinessProcessPaper)
