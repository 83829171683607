import cssStyles from "./PasswordTextField.module.css"
import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {useState} from "react";

export default function PasswordTextField({label, onChange, allowShowPassword, testid}) {
	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return <FormControl variant="outlined">
		<InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
		<OutlinedInput
			inputProps={{'data-testid': testid}}
			onChange={onChange}
			className={cssStyles.textField}
			type={showPassword ? 'text' : 'password'}
			label={label}
			sx={{color: "lightgray"}}
			endAdornment={
				allowShowPassword && <InputAdornment position="end">
					<IconButton
						aria-label="toggle password visibility"
						onClick={handleClickShowPassword}
						onMouseDown={handleMouseDownPassword}
						edge="end"
						style={{color: "lightgray"}}
					>
						{showPassword ? <VisibilityOff /> : <Visibility />}
					</IconButton>
				</InputAdornment>
			}
		/>
	</FormControl>
}
