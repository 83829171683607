import cssStyles from "./StepList.module.css"

export function StepListItem({children}) {
    return <span className={cssStyles.stepListContent}>{children}</span>
}

export function StepList({children}) {
    return <div className={cssStyles.stepList}>
        {children.map((child, index)=>{
            return <div className={cssStyles.stepRow}>
                <span className={cssStyles.stepListStep}>{index+1}</span>
                {child}
            </div>
        })}
    </div>
}
