import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Logger from "./Logger";
import {useEffect, useState} from "react";

const LOGGER = new Logger("MessagePopup")

export default function MessagePopup({isOpen, title, message, buttonLabel, onClose}) {

    const [open, setOpen] = useState(false);

    useEffect(()=>{
        setOpen(isOpen)
    }, [isOpen])

    function handleClose() {
        setOpen(false)
        if (onClose)  {
            onClose()
            return
        }
        LOGGER.warn("no onclose handler")
    }

    return <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>{buttonLabel}</Button>
        </DialogActions>
    </Dialog>
}
