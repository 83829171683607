import cssStyles from './LoginComponent.module.css';
import React, {useEffect, useState} from 'react';
import { TextField, Button, Grid } from '@mui/material';
import Logger from "../../../utils/Logger";
import {useSecurityContext} from "../../../security/SecurityContext";
import {useNavigate} from "react-router-dom";

const LOGGER = new Logger("LoginComponent")

function LoginComponent() {

    const navigate = useNavigate();

    LOGGER.debug("Enter - LoginComponent")
    LOGGER.debug("process.env.IDENTITY_END_POINT: ", process.env.IDENTITY_END_POINT)

    const {
        startInitialization,
        signin
    } = useSecurityContext();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        LOGGER.debug("calling netlifyIdentity.init()")
        startInitialization()
    });


    const handleLogin = (e) => {
        e.preventDefault();
        signin(email, password)
    };

    const handleForgotPassword = () => {
        navigate("/recover-password")
    };

    return (
        <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
            <Grid className={cssStyles.gridTextFieldItem} item>
                <TextField
                    data-testid={"email"}
                    label="Email"
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid className={cssStyles.gridTextFieldItem} item>
                <TextField
                    data-testid={"password"}
                    label="Password"
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item>
                <Button variant="contained" color="primary" onClick={handleLogin} fullWidth>
                    Sign In
                </Button>
            </Grid>
            <Grid item>
                <Button data-testid={"forgot-password"} color="secondary" onClick={handleForgotPassword}>
                    Forgot Password?
                </Button>
            </Grid>
        </Grid>
    );
};

export default LoginComponent;
