import {NodeType} from "../../../model/Constants";

export class DataExchange {
    id //uuid
    name //string
    parentId //uuid
    description //string
    dataObjectIds //uuid[]
    sourceApplicationId //uuid
    targetApplicationId //uuid
    supportingMiddlewareIds //uuid[]
    type //NodeType.DataFlow.description
    constructor() {
        this.type = NodeType.DataExchange.description
    }
}
