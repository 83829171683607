import {createContext, useContext, useEffect, useState} from "react";
import Logger from "../utils/Logger";
import {useSecurityContext} from "../security/SecurityContext";
import {useLoadingOverlay} from "../components/LoadingOverlay/LoadingOverlay";
import {addAuthorizationHeader} from "../utils/Api";
import {useStatusMessage} from "../components/StatusMessenger/StatusMessageProvider";
import {SUPPORTED_SUBSCRIPTION_TYPES} from "../model/Constants";

const LOGGER = new Logger("SubscriptionContext")

const SubscriptionContext = createContext();

export const useSubscriptionContext = () => {
    return useContext(SubscriptionContext);
}

export function SubscriptionContextProvider({children}) {

    const {user} = useSecurityContext()
    const setNewMessage = useStatusMessage()

    const [subscription, setSubscription] = useState({});
    const [subscriptionStatus, setSubscriptionStatus] = useState({});
    const [trial, setTrial] = useState({});
    const [userProfile, setUserProfile] = useState({});
    const [limits, setLimits] = useState({});
    const [counters, setCounters] = useState({});
    const [supportedObjectTypes, setSupportedObjectTypes] = useState([]);
    const [subscriptionError, setSubscriptionError] = useState(false);


    useEffect(() => {
        LOGGER.debug("useEffect - ENTER - user: ", user)
        if (!user) {
            LOGGER.debug("useEffect - EXIT - user not found")
            return
        }
        //clear any errors
        //setSubscriptionError(false)

        if (subscriptionError) {
            LOGGER.debug("subscriptionError found, returning")
            return
        }

        LOGGER.debug("Getting subscription for user, calling get-subscription-features: ", user)
        fetch("/.netlify/functions/get-subscription-features", {
            headers: addAuthorizationHeader({}, null, user),
            method: "GET",
        }).then((response) => {
            if (response.status === 200) {
                LOGGER.debug("Successfully retrieved subscriptionFeatureInfo")
                return response.json()
            } else {
                LOGGER.debug("Failed to retrieve subscriptionFeatureInfo (response.ok=false)")
                LOGGER.debug("response (stringify): ", JSON.stringify(response))
                if (setNewMessage) {
                    setNewMessage("Failed to retrieve subscription features", "alert")
                    //todo: should we throw an error? Isn't this a bit harsh?
                    //throw new Error("Failed to retrieve subscription features.")
                    LOGGER.error("Failed to retrieve subscription features: ", response)
                }
            }
        }).then((subscriptionFeatureInfo) => {
            LOGGER.debug("subscriptionInfo: ", subscriptionFeatureInfo)
            setLimits(subscriptionFeatureInfo?.limits)
            setSupportedObjectTypes(subscriptionFeatureInfo?.supportedObjectTypes)
        }).catch((error) => {
            LOGGER.debug("Failed to retrieve subscriptionFeatureInfo")
            LOGGER.debug("Error: ", error)
            if (setNewMessage) {
                setNewMessage("Failed to retrieve subscription features", "alert")
                throw new Error("Failed to retrieve subscription features.")
            }
            setSubscriptionError("Failed to retrieve subscription feature")
        })
        // eslint-disable-next-line
    }, [user, setNewMessage]);

    useEffect(()=>{
        if (subscriptionError) {
            LOGGER.debug("subscriptionError found, returning")
            return
        }
        if (user) {
            LOGGER.debug("Getting user profile for user: ", user)
            fetch("/.netlify/functions/manage-user-profiles", {
                headers: addAuthorizationHeader({}, null, user),
                method: "GET",
            }).then((response) => {
                if (response.ok) {
                    LOGGER.debug("Successfully retrieved user profile")
                    return response.json()
                } else {
                    LOGGER.debug("Failed to retrieve subscription")
                    LOGGER.debug("response: ", response)
                    setSubscriptionError("Failed to retrieve user profile.", "alert")
                }
            }).then((userProfile) => {
                LOGGER.debug("userProfile: ", userProfile)
                setUserProfile(userProfile)
            }).catch((error) => {
                LOGGER.debug("Failed to retrieve user profile")
                LOGGER.debug("Error: ", error)
                setSubscriptionError("Failed to retrieve user profile")
            })

            LOGGER.debug("Getting report manager for user: ", user)
            fetch("/.netlify/functions/get-account-information", {
                headers: addAuthorizationHeader({}, null, user),
                method: "GET",
            }).then((response) => {
                if (response.status === 200) {
                    LOGGER.debug("Successfully retrieved account-information")
                    return response.json()
                } else {
                    LOGGER.debug("Failed to retrieve account-information")
                    LOGGER.debug("response: ", response)
                    throw new Error("Failed to retrieve account-information.")
                }
            }).then((accountInformation) => {
                LOGGER.debug("accountInformation: ", accountInformation)
                setCounters({objectCount: accountInformation.objectCount})
            }).catch((error) => {
                LOGGER.debug("Failed to retrieve account-information")
                LOGGER.debug("Error: ", error)
                setSubscriptionError("Failed to retrieve account-information")
            })
        }
        // eslint-disable-next-line
    }, [user])

    useEffect(()=>{
        LOGGER.debug("userProfile changed: ", JSON.stringify(userProfile))
        if (!userProfile) {
            LOGGER.debug("No userProfile found")
            return
        }
        if (userProfile.netlifyUserId && (userProfile.subscriptionStatus !== "active" && userProfile.subscriptionStatus !== "trialing")) {
            setSubscriptionError("Inactive subscription...")
            return
        }
        if (userProfile.subscriptionType) {
            setSubscriptionStatus(userProfile.subscriptionStatus)
            LOGGER.debug("updating subscription to: ", userProfile.subscriptionType)
            if (userProfile.subscriptionStatus === "active" || userProfile.subscriptionStatus === "trialing") {
                setSubscription(userProfile.subscriptionType)
                if (userProfile.subscriptionStatus === "trialing") {
                    setTrial(userProfile.trial)
                }
            } else {
                LOGGER.debug("No active subscription found, setting subscription type to NONE")
                setSubscription(SUPPORTED_SUBSCRIPTION_TYPES.NONE)
            }
        } else {
            LOGGER.debug("No subscription type found, setting subscription type to NONE")
            setSubscription("unknown")
        }
    }, [userProfile])

    const loadingOverlay = useLoadingOverlay()
    const changeSubscriptionTo = (newSubscriptionType) => {
        LOGGER.debug("Changing subscription to: ", newSubscriptionType)
        loadingOverlay.show(`Updating subscription to ${newSubscriptionType}...`)
        fetch("/.netlify/functions/manage-subscriptions", {
            headers: addAuthorizationHeader({}, null, user),
            method: "PUT",
            body: JSON.stringify({
                newSubscriptionType: newSubscriptionType,
            })
        }).then((response) => {
            if (response.ok) {
                LOGGER.debug("Successfully changed subscription to ", newSubscriptionType)
                LOGGER.debug("user reloaded")
                if (newSubscriptionType === "STANDARD") {
                    loadingOverlay.show("Successfully downgraded to Standard plan")
                    setSubscription(newSubscriptionType)
                } else if (newSubscriptionType === "PRO") {
                    loadingOverlay.show("Thank you for upgrading to the Pro plan, enjoy!")
                    setSubscription(newSubscriptionType)
                }
            } else {
                LOGGER.debug("Failed to change subscription to (response.ok=false): ", newSubscriptionType)
                LOGGER.debug("response: ", response)
                loadingOverlay.show("Failed to change subscription.", "alert")
            }
        }).catch((error) => {
            LOGGER.debug("Failed to change subscription to: ", newSubscriptionType)
            LOGGER.debug("Error: ", error)
            setSubscriptionError("Failed to change subscription")
        }).finally(()=>{
            setTimeout(()=>{
                window.location.reload()
            }, 2000)


        })
    }


    return <SubscriptionContext.Provider value={{
        subscription,
        subscriptionStatus,
        userProfile,
        counters,
        limits,
        supportedObjectTypes,
        changeSubscriptionTo,
        subscriptionError,
        trial
    }}>
        {children}
    </SubscriptionContext.Provider>
}
