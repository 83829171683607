//https://rogulski.it/blog/react-material-checkbox-form-validation/
//https://www.youtube.com/watch?v=10Z0OCPfubI

import cssStyles from "./PrincipleEditor.module.css"

import {useEffect} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";
import NodeSelector from "./selectors/NodeSelector";

import {Controller, useForm} from "react-hook-form"
import {object, string, date, mixed} from 'yup';
import {useYupValidationResolver} from "./EditorUtils"

import Logger from "../../../utils/Logger"
import {
    DATE_FORMAT_DAYJS,
    DEFAULT_PrincipleStatus,
    NodeFolderRootIds,
    NodeType
} from "../../../model/Constants";
import PrincipleStatusSelector from "./selectors/PrincipleStatusSelector";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {MultilineTextField} from "./fields/MultilineTextField";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

const LOGGER = new Logger("PrincipleEditor")

let PRINCIPLE_SCHEMA = object().shape({
    //id: string().required(),
    name: string().required(),
    parentId: string().required().default(NodeFolderRootIds.PrincipleRootId.description),
    description: string().required(),
    createdOn: date().default(() => new Date()),
    updatedOn: date().default(() => new Date()),
    rationale: string().required(),
    implications: string().required(),
    status: mixed().oneOf(["Proposed", "Accepted", "Rejected", "Deprecated"]).required(),
});

const PARENT_NODE_LABEL = "Parent Principle"
const PARENT_NODE_ROOT_ID = NodeFolderRootIds.PrincipleRootId.description
const PARENT_NODE_TYPE = NodeType.Principle.description
const PARENT_NODE_TYPE_NAME = "Principle"

async function createObject(
    id, name, description, parentId,
    rationale, implications, dateTaken, status
) {
    LOGGER.trace("createObject.id:", id)
    LOGGER.trace("createObject.name:", name)
    LOGGER.trace("createObject.description:", description)
    LOGGER.trace("createObject.parentId:", parentId)
    const validationObject = await PRINCIPLE_SCHEMA.validate({
        id,
        name,
        description,
        parentId,
        rationale,
        implications,
        dateTaken,
        status,
        type: PARENT_NODE_TYPE
    })
    LOGGER.trace("validationObject: ", validationObject)
    const node = await PRINCIPLE_SCHEMA.validate(validationObject)
    LOGGER.trace("done validating, returning node:", node)
    return node
}

export default function PrincipleEditor({
         isOpen,
         titleOfForm,
         descriptionOfForm,
         node,
         onClose,
         onCancel
}) {

    const handleClose = async (data, reason) => {
        LOGGER.debug("data:", data)
        LOGGER.debug("reason:", reason)
        if (reason && ["escapeKeyDown", "backdropClick"].includes(reason)) {
            handleCancel()
            return
        }
        let newObject = await createObject(
            data.id,
            data.name,
            data.description,
            data.parentId,
            data.rationale,
            data.implications,
            data.dateTaken,
            data.status
        )
        LOGGER.debug("newObject:", newObject)
        onClose(newObject)
    };
    const handleCancel = () => {
        onCancel(node?.id)
    };

    const defaultValues = {
        id:node?.id,
        name: "[name]",
        description: "[description]",
        parentId: node?.parentId,
        rationale: node?.rationale || "[rationale]",
        implications: node?.implications || "[implications]",
        dateTaken: node?.dateTaken || dayjs(),
        status: node?.status || DEFAULT_PrincipleStatus
    }

    const resolver = useYupValidationResolver(PRINCIPLE_SCHEMA);
    const { register, handleSubmit, formState: {errors}, setValue, control } = useForm({
        resolver,
        defaultValues
    });

    useEffect(()=>{
        if (node) {
            Object.keys(node).forEach(k => setValue(k, node[k]))
        }
    }, [node, setValue])

    useEffect(()=>{
        LOGGER.debug("errors:", errors)
    }, [errors])

    return (
        <div>
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle className={cssStyles.dialogTitle}>{titleOfForm}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {descriptionOfForm}
                    </DialogContentText>
                    <form
                        className={cssStyles.form}
                        onSubmit={handleSubmit(data => handleClose(data))}
                        noValidate
                    >
                        <input
                            type={"hidden"}
                            name={"id"}
                            value={node?.id}
                            {...register("id")}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            name={"name"}
                            label="Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            {...register("name")}
                            error={!!errors?.name}
                            helperText={errors?.name ? errors.name.message : null}
                        />
                        <NodeSelector
                            label={PARENT_NODE_LABEL}
                            nodeType={PARENT_NODE_TYPE}
                            nodeTypeName={PARENT_NODE_TYPE_NAME}
                            nodeRootFolderId={PARENT_NODE_ROOT_ID}
                            multiSelect={false}
                            name={"parentId"}
                            control={control}
                            error={!!errors?.parentId}
                            helperText={errors?.parentId ? errors.parentId.message : null}
                        />
                        <MultilineTextField
                            id={"rationale"}
                            name={"rationale"}
                            label={"Rationale"}
                            minRows={5}
                            maxRows={5} //to avoid crashes see: https://github.com/patrizz/kenchiku/issues/244
                            register={register}
                            errors={errors}
                        />
                        <MultilineTextField
                            id={"implications"}
                            name={"implications"}
                            label={"Implications"}
                            minRows={5}
                            maxRows={5} //to avoid crashes see: https://github.com/patrizz/kenchiku/issues/244
                            register={register}
                            errors={errors}
                        />
                        <PrincipleStatusSelector
                            label={"Status"}
                            name={"status"}
                            control={control}
                            error={!!errors?.status}
                            helperText={errors?.status ? errors.status.message : null}
                        />
                        <Controller
                            name="dateTaken"
                            control={control}
                            {...register("dateTaken")}
                            render={({ field }) => (
                                <div className={cssStyles.datePickerWrapper}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Date Taken"
                                            format={DATE_FORMAT_DAYJS}
                                            defaultValue={dayjs()}
                                            value={dayjs(field.value)}
                                            onChange={(newValue) => {
                                                field.onChange(newValue.format(DATE_FORMAT_DAYJS))
                                            }}
                                        />
                                    </LocalizationProvider>
                                </div>
                            )}
                        />
                        <DialogActions className={cssStyles.actionsDiv}>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={cssStyles.submit}
                            >Save</Button>
                        </DialogActions>

                    </form>
                </DialogContent>

            </Dialog>
        </div>
    );
}
