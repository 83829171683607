import {NodeType} from "../../../model/Constants";
import cssStyles from "./ApplicationDataExchangeDiagram2.module.css";
import Logger from "../../../utils/Logger";
import {useEffect, useRef} from "react";
import * as jointjs from "@joint/plus";
import {getCustomProp, setCustomProp} from "./utils/JointjsUtils";
import {useModel} from "../../../model/ModelContext";
import {useSelectedNodes} from "../../SelectedNodes/SelectedNodesProvider";
import {NODE_FILL_COLORS, NODE_TEXT_COLORS} from "../../../utils/NodeTypeColors";

const LOGGER = new Logger("ApplicationDataExchangeDiagram2")

const blockWidth = 100
const blockHeight = 100

const xPadding = 100

const incomingDependencyStartY = 0
const nodeApplicationStartY = 250
const outgoingDependencyStartY = 500

function createActorRectangle(x, y, width, height, label, isSelected=false, isSoftSelected=false, fillColor, strokeColor) {
    return new jointjs.shapes.standard.Rectangle({
        position: {x: x, y: y},
        size: {width: width, height: height},
        attrs: {
            body: {
                fill: fillColor,
                opacity: 1,
                strokeWidth: 4,
                stroke: (isSelected ? '#FC5185' : (isSoftSelected ? '#FFD166' : fillColor)),
                rx: 5,
                ry: 5,
            },
            label: {
                text: label,
                fill: strokeColor,
                fontSize: 12,
                fontWeight: 'bold',
                textWrap: {
                    width: blockWidth - 10,
                    height: blockHeight - 10,
                    ellipsis: true,
                },
                pointerEvents: 'none',
            },
        },
    })
}

function createDependencyLink(sourceRectangle, targetRectangle) {

    let linkOptions = {
        source: {
            id: sourceRectangle.id,
            anchor: {
                name: 'center'
            }
        },
        target: {
            id: targetRectangle.id,
            anchor: {
                name: 'center'
            }
        },
        //router: ARROW_TYPES["grid"]?.router,
        //connector: ARROW_TYPES["grid"]?.connector,
        line: {
            stroke: 'black',
            strokeWidth: 2,
            targetMarker: {
                type: 'path',
                d: 'M 10 -5 0 0 10 5 z'
            }
        },
        vertices: ([]),
    };

    let link = new jointjs.shapes.standard.Link(linkOptions);
    return link
}

export default function ActorDiagram({node}) {

    const {searchNodes, getNodeById} = useModel()
    const {selectedNodes, softSelectedNodes,setSoftSelectedNodeById} = useSelectedNodes()

    const namespace = jointjs.shapes

    const paperRef = useRef()

    const graph = useRef(null)
    const paper = useRef(null)
    const scroller = useRef(null)

    useEffect(()=> {

        if (!paperRef.current) {
            LOGGER.debug("no paperRef.current")
            return
        }

        LOGGER.debug("Creating new Graph instance")
        graph.current = new jointjs.dia.Graph({}, {cellNamespace: namespace})


        //avoid embedded elements from being moved separately from their parent
        //kudos to: https://stackoverflow.com/a/56113520/425677
        const interactive = function (cellView, eventString) {
            const isLocked = getCustomProp(cellView.model, "locked")
            if (isLocked) {
                return {
                    elementMove: false
                };
            }
            if (!cellView.model.isLink()) {
                return {
                    linkMove: false,
                    labelMove: true,
                    arrowheadMove: false,
                    vertexMove: false,
                    vertexAdd: false,
                    vertexRemove: false,
                    useLinkTools: false,
                }
            }
            if (cellView.model.isEmbedded()) {
                return {
                    elementMove: false,
                };
            }

            return true;
        }
        paper.current = new jointjs.dia.Paper({
            foreignObjectRendering: true,
            snapLabels: true,
            model: graph.current,
            drawGrid: true,
            cellViewNamespace: namespace,
            interactive: interactive,
            autoResizePaper: true,
            cursor: 'grab',
            scrollWhileDragging: true,
            inertia: true,
            connectionStrategy: jointjs.connectionStrategies.pinAbsolute,

        });

        paper.current.on('cell:pointerdown',
            function(cellView, evt, x, y) {
                const nodeId = getCustomProp(cellView.model, "nodeId")
                if (nodeId) {
                    setSoftSelectedNodeById(nodeId)
                }
            }
        )

        scroller.current = new jointjs.ui.PaperScroller({
            paper: paper.current,
            autoResizePaper: true,
            cursor: 'grab',

        });

        paper.current.on('blank:contextmenu', (evt, x, y) => {
            evt.preventDefault()
            evt.stopPropagation()
            scroller.current.startPanning(evt)
        })

        paperRef.current.appendChild(scroller.current.el);
        scroller.current.render().center();

        return () => {
            scroller.current.remove();
            paper.current.remove();
        };
        // eslint-disable-next-line
    }, [paperRef, namespace])

    useEffect(()=>{

        const selectedNodeIds = selectedNodes.map((n)=>n.id)
        const softSelectedNodeIds = softSelectedNodes.map((n)=>n.id)


        graph.current.clear()

        const sourceMap = new Map()
        const targetMap = new Map()

        let incomingDependencies = []
        incomingDependencies = incomingDependencies.concat(searchNodes((n)=>{
            return (n.type === NodeType.Actor.description && n.id === node?.parentId)
        }))

        incomingDependencies.forEach((ca)=>{
            if (sourceMap.get(ca.id)) {
                LOGGER.error("Duplicate parent actor id: ", ca.id)
            } else {
                sourceMap.set(ca.id, ca)
            }
        })

        let outgoingDependencies = []

        outgoingDependencies = outgoingDependencies.concat(searchNodes((n)=>{
            return (n.type === NodeType.DataObject.description && n.dataOwnerId === node?.id)
        }))

        outgoingDependencies = outgoingDependencies.concat(searchNodes((n)=>{
            return (n.type === NodeType.Actor.description && n.parentId === node?.id)
        }))

        outgoingDependencies = outgoingDependencies.concat(searchNodes((n)=>{
            return (n.type === NodeType.ActorActivity.description && n.actorId === node?.id)
        }))

        outgoingDependencies.forEach((ca)=>{
            if (targetMap.get(ca.id)) {
                LOGGER.error("Duplicate child actor id: ", ca.id)
            } else {
                targetMap.set(ca.id, ca)
            }
        })

        const numberOfSources = sourceMap.size
        const numberOfTargets = targetMap.size
        const sourceRectanglesWidth = numberOfSources * blockWidth + (numberOfSources-1) * xPadding
        const targetRectanglesWidth = numberOfTargets * blockWidth + (numberOfTargets-1) * xPadding

        const middleX = 200

        const sourceStartX = middleX - sourceRectanglesWidth/2
        const nodeApplicationStartX = middleX - blockWidth/2
        const targetStartX = middleX - targetRectanglesWidth/2

        let sourceCounter = 0
        let targetCounter = 0

        const nodeApplicationLabel = node?.name
        const nodeApplicationRectangle = createActorRectangle(
            nodeApplicationStartX,
            nodeApplicationStartY,
            blockWidth,
            blockHeight,
            nodeApplicationLabel,
            selectedNodeIds.includes(node?.id),
            softSelectedNodeIds.includes(node?.id),
            NODE_FILL_COLORS[NodeType.Actor.description],
            NODE_TEXT_COLORS[NodeType.Actor.description],
        )
        graph.current.addCell(nodeApplicationRectangle)

        sourceMap.forEach((incomingNode, incomingNodeId)=> {
            const sourceNode = incomingNode //getNodeById(incomingNodeId)
            const sourceLabel = sourceNode?.name
            const sourceRectangle = createActorRectangle(
                sourceStartX + sourceCounter * (blockWidth + xPadding),
                incomingDependencyStartY,
                blockWidth,
                blockHeight,
                sourceLabel,
                selectedNodeIds.includes(incomingNodeId),
                softSelectedNodeIds.includes(incomingNodeId),
                NODE_FILL_COLORS[incomingNode.type],
                NODE_TEXT_COLORS[incomingNode.type],
            )
            setCustomProp(sourceRectangle, "nodeId", incomingNode.id)
            graph.current.addCell(sourceRectangle)
            sourceCounter++

            const link = createDependencyLink(
                sourceRectangle,
                nodeApplicationRectangle,
                false
            )
            graph.current.addCell(link)
        })

        targetMap.forEach((outgoingNode, outgoingNodeId)=> {
            const targetDataExchanges = outgoingNode.targetDfArray
            const targetApplicationNode = getNodeById(outgoingNodeId)
            const targetApplicationLabel = targetApplicationNode?.name
            const targetApplicationRectangle = createActorRectangle(
                targetStartX + targetCounter * (blockWidth + xPadding),
                outgoingDependencyStartY,
                blockWidth,
                blockHeight,
                targetApplicationLabel,
                selectedNodeIds.includes(outgoingNodeId),
                softSelectedNodeIds.includes(outgoingNodeId),
                NODE_FILL_COLORS[outgoingNode.type],
                NODE_TEXT_COLORS[outgoingNode.type],
            )
            setCustomProp(targetApplicationRectangle, "nodeId", outgoingNode.id)
            graph.current.addCell(targetApplicationRectangle)
            targetCounter++

            const link = createDependencyLink(
                nodeApplicationRectangle,
                targetApplicationRectangle,
                targetDataExchanges,
                false
            )
            graph.current.addCell(link)
        })

        // eslint-disable-next-line
    }, [node, selectedNodes, softSelectedNodes])

    return <div ref={paperRef} className={cssStyles.main}>
    </div>
}
