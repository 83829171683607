import cssStyles from "./ApplicationCostReport.module.css"

import ReportMenuBar from "./ReportMenuBar";
import {LifeCycleStageValues, NodeType} from "../../../model/Constants";
import {useEffect, useRef, useState} from "react";
import Logger from "../../../utils/Logger";
import {REPORTS} from "./ReportConstants";

import WaiterText from "../../../utils/WaiterText";
import EmptyListText from "../../../utils/EmptyListText";
import {ResponsiveTreeMap} from "@nivo/treemap";

const LOGGER = new Logger("ApplicationCostReport")

/*
 * Kudos to https://codesandbox.io/s/treemap-react-viz-tmmh4?file=/src/App.js
 * for providing a good code example that was the basis of this component
 */

export default function ApplicationCostReport({workspaceId, user, dataLoader}) {

    const [applicationNodes, setApplicationNodes] = useState()
    const [treemapWidth, setTreemapWidth] = useState(0);
    const [treemapHeight, setTreemapHeight] = useState(0);
    const [treeMapData, setTreeMapData] = useState([])

    const wrapperRef = useRef(null);
    //alert(JSON.stringify(applicationNodes))
    useEffect(() => {
        if (wrapperRef.current) {
            const observeTarget = wrapperRef.current;

            const resizeObserver = new ResizeObserver(entries => {
                entries.forEach(entry => {
                    setTreemapWidth(entry.contentRect.width);
                    setTreemapHeight(entry.contentRect.height);
                });
            });

            resizeObserver.observe(observeTarget);

            return () => {
                // Clean up the observer on component unmount
                resizeObserver.unobserve(observeTarget);
            };
        }
    }, []);


    function includeApplication(node) {
        return (
            node.type === NodeType.Application.description
            &&
            node.yearlyCost > 0
            &&
            node.lifeCycleStage !== LifeCycleStageValues.Decommissioned
        )
    }

    useEffect(()=>{
        LOGGER.debug("loading data for workspaceId: ", workspaceId)
        dataLoader.loadData(
            workspaceId,
            user,
            function startLoading() {},
            function stopLoading(loadedData) {
                let filteredNodes = loadedData.nodes.filter((n)=>{return includeApplication(n)});
                LOGGER.debug("filteredNodes:", filteredNodes)
                setApplicationNodes(filteredNodes)
                //setLoading(false)
            }, false)
    }, [dataLoader, workspaceId, user])

    useEffect(() => {
        if (!wrapperRef?.current) return;
        setTreemapWidth(wrapperRef.current.offsetWidth);
        setTreemapHeight(wrapperRef.current.offsetHeight);
    }, [wrapperRef]);

    function getColor(sizeInK) {

        if (sizeInK <= 0) {
            return "#EEEEEE"
        } else if (sizeInK < 150) {
            return "#00FF00"
        } else if (sizeInK < 450) {
            return "#FFFF00"
        } else {
            return "#FF0000"
        }
    }

    useEffect(() => {
        if (applicationNodes?.length > 0) {
            setTreeMapData((oldValue) =>({
                name: "Application Costs",
                colorIndex: 0,
                children: applicationNodes.map((n) => {
                    let size = (n.yearlyCost ? n.yearlyCost / 1000 : 0)
                    return ({
                        name: n.name,
                        title: n.name,
                        color: getColor(size),
                        children: [{
                            name: `${n.name} - $${size}K`,
                            title: `${n.name} $${size}K`,
                            size: size,
                            color: getColor(size)
                        }]
                    })
                })
            }))
            LOGGER.debug("treeMapData:", treeMapData)
        }
    }, [treeMapData, applicationNodes])

    return (
        <div className={cssStyles.main}>
            <ReportMenuBar
                user={user}
                report={REPORTS.applicationCostReport}
            />
            {(!applicationNodes) && <WaiterText>Loading data...</WaiterText>}
            {(applicationNodes?.length === 0) && <EmptyListText>No data to display (did you specify cost information?)</EmptyListText>}

            <div className={cssStyles.treeMap}>
                <div ref={wrapperRef} className={cssStyles.wrapper}>
                    {(applicationNodes?.length > 0 && treemapWidth>0 && treemapHeight>0) &&
                        <ResponsiveTreeMap
                            colors={function (d) {
                                return getColor(d.value)
                            }}
                            data={treeMapData}
                            identity="name"
                            value="size"
                            valueFormat={v => `$${v}K`}
                            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                            labelSkipSize={12}
                            labelTextColor={{ from: "colorIndex", modifiers: [["darker", 1.2]] }}
                            parentLabelTextColor={{ from: "colorIndex", modifiers: [["darker", 2]] }}
                            //borderColor={{ from: "colorIndex", modifiers: [["darker", 0.3]] }}
                            animate={true}
                        />
                    }
                    </div>
                </div>
        </div>
    )

}
