import cssStyles from "./QuickTutorial.module.css";
import {useWizard, Wizard} from "react-use-wizard";
import React from "react";
import {Button} from "@mui/material";
import {Kenchiku} from "../../Kenchiku/Kenchiku";
import {useNavigate} from "react-router-dom";
import {ModelObject} from "./ModelObject";
import {StepList, StepListItem} from "./StepList";
import {Link} from "./Link";

function QuickTutorialStep1() {

    const navigate = useNavigate();

    const { nextStep } = useWizard();

    return <div className={cssStyles.step1}>

        <div className={cssStyles.stepContent}>
            <h1>Load your model objects</h1>
            <p>First step is to load your capabilities, applications, and data objects into <Kenchiku/>.</p>
            <p>Each object has a specific color in <Kenchiku/>:</p>
            <p>
                <div><ModelObject type={"capability"}>Capabilities</ModelObject> are <ModelObject type={"capability"}>orange</ModelObject></div>
                {/*
                <div><ModelObject type={"actor"}>Actors</ModelObject> are <ModelObject type={"actor"}>Vivid Mangenta</ModelObject></div>
                <div><ModelObject type={"businessProcess"}>Business Processes</ModelObject> are <ModelObject type={"businessProcess"}>Sunset Coral</ModelObject></div>
                <div><ModelObject type={"functionality"}>Functionalities</ModelObject> are <ModelObject type={"functionality"}>Fresh Lime</ModelObject></div>
                */}
                <div><ModelObject type={"application"}>Applications</ModelObject> are <ModelObject type={"application"}>blue</ModelObject></div>
                <div><ModelObject type={"dataObject"}>Data Objects</ModelObject> are <ModelObject type={"dataObject"}>Whispering Sky Blue</ModelObject></div>
                <div><ModelObject type={"dataExchange"}>Data Exchanges</ModelObject> are also <ModelObject type={"dataExchange"}>Whispering Sky Blue</ModelObject></div>
                <div><ModelObject type={"dataFlow"}>Data Flows</ModelObject> are also <ModelObject type={"dataFlow"}>Whispering Sky Blue</ModelObject></div>
                <div><ModelObject type={"folder"}>Folders</ModelObject> are <ModelObject type={"folder"}>black</ModelObject></div>
            </p>
        </div>
        <div className={cssStyles.stepActions}>
            <Button variant="outlined" onClick={() => navigate("/workspace")}>End tutorial</Button>
            <Button variant="contained" onClick={() => nextStep()}>Next - Populating kenchiku</Button>
        </div>
    </div>
}

function QuickTutorialPopulatingKenchikuStep() {
    const {previousStep, nextStep} = useWizard();

    const navigate = useNavigate();

    return <div className={cssStyles.step1}>

        <div className={cssStyles.stepContent}>
            <h1>Populating <Kenchiku/></h1>
            <p>
                You can manually create model objects in <Kenchiku/> or you can import them from a spreadsheet.
            </p>
            <p>
                Importing them from a spreadsheet is the fastest way to get started. But requires you to have a
                spreadsheet with the model objects you want to import in the correct format.
            </p>
            <p>
                <StepList>
                    <StepListItem>Download the template spreadsheet from the <Link href={"/templates"}>templates
                        page</Link></StepListItem>
                    <StepListItem>Once you have a spreadsheet with the model objects you want to import</StepListItem>
                    <StepListItem>open the hamburger menu,</StepListItem>
                    <StepListItem>select the imports menu item.</StepListItem>
                    <StepListItem>click the cloud button to upload your spreadsheet. Or, drag and drop it on the drop
                        zone.</StepListItem>
                    <StepListItem><Kenchiku/> will then import the model objects from the spreadsheet.</StepListItem>
                </StepList>
            </p>
        </div>
        <div className={cssStyles.stepActions}>
            <Button variant="outlined" onClick={() => navigate("/workspace")}>End tutorial</Button>
            <Button variant="outlined" onClick={() => previousStep()}>Previous</Button>
            <Button variant="contained" onClick={() => nextStep()}>Next - Manually create objects</Button>
        </div>
    </div>
}

function QuickTutorialCreatingManuallyKenchikuStep() {
    const {previousStep, nextStep} = useWizard();

    const navigate = useNavigate();

    return <div className={cssStyles.step1}>

        <div className={cssStyles.stepContent}>
            <h1>Manually feeding <Kenchiku/></h1>
            <p>
                You can also manually create model objects in <Kenchiku/> as follows:
            </p>
            <p>
                <StepList>
                    <StepListItem>Go to the main page</StepListItem>
                    <StepListItem>Right-click a <ModelObject type={"folder"}/>folder</StepListItem>
                    <StepListItem>Select new from the context menu.</StepListItem>
                    <StepListItem>Fill out the form</StepListItem>
                    <StepListItem>Press save</StepListItem>
                    <StepListItem><Kenchiku/> will then create the object in your work space.</StepListItem>
                </StepList>
            </p>
        </div>
        <div className={cssStyles.stepActions}>
            <Button variant="outlined" onClick={() => navigate("/workspace")}>End tutorial</Button>
            <Button variant="outlined" onClick={() => previousStep()}>Previous</Button>
            <Button variant="contained" onClick={() => nextStep()}>Next - Manually create objects</Button>
        </div>
    </div>
}

function QuickTutorialStepFinal() {

    const navigate = useNavigate();

    const {previousStep} = useWizard();

    return <div className={cssStyles.step1}>

        <div className={cssStyles.stepContent}>
            <h1>All set!</h1>
            <p>That's all there is to start using <Kenchiku/>.</p>
        </div>
        <div className={cssStyles.stepActions}>
            <Button variant="outlined" onClick={() => {
                previousStep()
            }}>Previous</Button>
            <Button variant="contained" onClick={() => {
                navigate("/workspace")
            }}>Go play!</Button>
        </div>
    </div>
}

export function QuickTutorial() {



    return <div className={cssStyles.main}>
        <div className={cssStyles.wizardWrapper}>
            <Wizard>
                <QuickTutorialStep1/>
                <QuickTutorialPopulatingKenchikuStep/>
                <QuickTutorialCreatingManuallyKenchikuStep/>
                <QuickTutorialStepFinal/>
            </Wizard>
        </div>
    </div>
}
