
import Logger from "../../../utils/Logger";
import TileMenu from "../../TileMenu/TileMenu";
import {getAccountPaneTopics} from "./AccountConstants";

const LOGGER = new Logger("AccountPage")

export default function AccountPage({user}) {

    LOGGER.debug("Enter - AccountPage")

    const topics = getAccountPaneTopics()

    return <TileMenu user={user} topics={topics}/>

}
