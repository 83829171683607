import * as React from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import clsx from 'clsx'
import {Box, Button, LinearProgress, Typography} from "@mui/material";

import classes from "./FileUploader.module.css"
import Logger from "../../utils/Logger";

const LOGGER = new Logger("FileUploader")

const fileUploadImage = require('./fileupload_image.png')

export default function FileUploader ({
      accept,
      imageButton = false,
      hoverLabel = 'Click or drag to upload file',
      dropLabel = 'Drop file here',
      width = '600px',
      height = '100px',
      backgroundColor = '#fff',
      image: {
          url = fileUploadImage,
          imageStyle = {
              height: 'inherit',
          },
      } = {},
      contentText="Select your file to import",
      onCancel,
      onDrop,
      uploadMessage="Uploading...",
      showCloseButton= true,
    }) {

    const onDropHandler = async (files) => {
        setIsUploading(true)
        setLabelText(hoverLabel)
        setIsDragOver(false)
        await onDrop(files)
        setIsUploading(false)
    }

    const [labelText, setLabelText] = React.useState(hoverLabel)
    const [isDragOver, setIsDragOver] = React.useState(false)
    const [isMouseOver, setIsMouseOver] = React.useState(false)
    const [isUploading, setIsUploading] = React.useState(false)

    const stopDefaults = (e) => {
        e.stopPropagation()
        e.preventDefault()
    }
    const dragEvents = {
        onMouseEnter: () => {
            setIsMouseOver(true)
        },
        onMouseLeave: () => {
            setIsMouseOver(false)
        },
        onDragEnter: (e) => {
            stopDefaults(e)
            setIsDragOver(true)
            setLabelText(dropLabel)
        },
        onDragLeave: (e) => {
            stopDefaults(e)
            setIsDragOver(false)
            setLabelText(hoverLabel)
        },
        onDragOver: stopDefaults,
        onDrop: (e) => {
            stopDefaults(e)
            onDropHandler(e.dataTransfer.files)
        },
    }

    const handleChange = (event) => {

        LOGGER.debug("handleChange.event: ", event)

        //kudos to: https://stackoverflow.com/q/27120757
        const zeFiles = event.target.files

        onDropHandler(zeFiles)

    }

    return (<>
            {isUploading && <div className={classes.linearProgressWrapper}><LinearProgress padding={5}/></div>}
            {isUploading && uploadMessage && <div className={classes.uploadMessage}>{uploadMessage}</div> }
            {(!isUploading && <>
                <span className={classes.contentText}>
                    {contentText}
                </span>
                <input
                    onChange={handleChange}
                    accept={accept}
                    className={classes.hidden}
                    id="file-upload"
                    type="file"
                />
                <label
                    htmlFor="file-upload"
                    {...dragEvents}
                    className={clsx(classes.root, isDragOver && classes.onDragOver)}
                >
                    <Box
                        width={width}
                        height={height}
                        bgcolor={backgroundColor}
                        className={classes.noMouseEvent}
                    >
                        {imageButton && (
                            <Box
                                position="absolute"
                                height={height}
                                width={width}
                            >
                                <img
                                    alt="file upload"
                                    src={url}
                                    style={imageStyle}
                                />
                            </Box>
                        )}

                        {(!imageButton || isDragOver || isMouseOver) && (
                            <>
                                <Box
                                    height={height}
                                    width={width}
                                    className={classes.iconText}
                                >
                                    <CloudUploadIcon fontSize="large"/>
                                    <Typography>{labelText}</Typography>
                                </Box>
                            </>
                        )}
                    </Box>
                </label>
                {showCloseButton && <Box width={"100%"} height={"30px"}>
                    <Button variant={"contained"} type={"button"} onClick={() => {
                        if (onCancel) {
                            onCancel()
                        }
                    }}>Close</Button>
                </Box>}
            </>)}
        </>
    )

}
