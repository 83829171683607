import cssStyles from "./ReportCounterFragment.module.css";
import {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import {getNodeTypeIcon} from "../../../../model/Constants";

export default function ReportCounterFragment({testId, label, value, nodeTypeName, isPro}) {

    const [isLoading, setIsLoading] = useState(true)

    useEffect(()=>{
        if ((value || value === 0) && ("" + value).length >0) {
            setIsLoading(false)
        } else {
            setIsLoading(true)
        }
    }, [value])

    let zeStyle = {
        backgroundColor: (isLoading?"#AAAAAA":"")
    }

    return (<div className={cssStyles.counterDiv}>
        <div
            className={cssStyles.counterValueDiv}
            style={
                zeStyle
            }
        >
            {nodeTypeName && <span className={cssStyles.iconSpan}>{getNodeTypeIcon(nodeTypeName, "large")}</span>}
            {!isLoading && <span className={cssStyles.counterValue} data-testid={testId}>{value}</span>}
            {isLoading && <span className={cssStyles.counterValue}><CircularProgress/></span>}
        </div>
        <span className={cssStyles.counterLabel}>{label}</span>
    </div>)
}
