import cssStyles from "./ErrorBox.module.css"
import HelpIcon from '@mui/icons-material/Help'
import {useState} from "react";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function ErrorBox({isCloseable, id, children}) {
    const [showing, setShowing] = useState(true || !isCloseable) //if not closeable, then we need to show it by default :)

    function closeInfoBox() {
        setShowing(false)
    }

    function openInfoBox() {
        setShowing(true)
    }

    return <div id={id} className={cssStyles.mainDiv + (showing?" " + cssStyles.open:"")}>
        {isCloseable && (showing?<HighlightOffIcon onClick={closeInfoBox}/>:<HelpIcon onClick={openInfoBox}/>)}
        {<div
            className={cssStyles.contentDiv + " " + (showing?cssStyles.contentShow:cssStyles.contentHide)}
        >{children}</div>}
    </div>
}
