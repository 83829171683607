//https://rogulski.it/blog/react-material-checkbox-form-validation/
//https://www.youtube.com/watch?v=10Z0OCPfubI

import cssStyles from "./MiddlewareEditor.module.css"

import {useEffect} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import NodeSelector from "./selectors/NodeSelector";

import {useForm} from "react-hook-form"
import {object, string, date} from 'yup';
import {useYupValidationResolver} from "./EditorUtils"
import Logger from "../../../utils/Logger"
import {NodeFolderRootIds, NodeType} from "../../../model/Constants";
import {MultilineTextField} from "./fields/MultilineTextField";

const LOGGER = new Logger("MiddlewareEditor")

const MIDDLEWARE_SCHEMA = object().shape({
    //id: string().required(),
    name: string().required(),
    description: string().required(),
    parentId: string().required().default(NodeFolderRootIds.MiddlewareRootId.description),
    createdOn: date().default(() => new Date()),
    updatedOn: date().default(() => new Date()),
});

const PARENT_NODE_LABEL = "Parent Middleware Object"
const PARENT_NODE_ROOT_ID = NodeFolderRootIds.MiddlewareRootId.description
const PARENT_NODE_TYPE = NodeType.Middleware.description
const PARENT_NODE_TYPE_NAME = "Middleware"


async function createObject(id, name, description, parentId) {
    LOGGER.trace("createObject")
    let validationObject = {
        id,
        name,
        description,
        parentId,
        type: PARENT_NODE_TYPE
    }
    LOGGER.trace("validationObject: ", validationObject)
    const node = await MIDDLEWARE_SCHEMA.validate(validationObject)
    LOGGER.trace("done validating, returning node:", node)
    return node
}

export default function MiddlewareEditor({
                              isOpen,
                              titleOfForm,
                              descriptionOfForm,
                              node,
                              onClose,
                              onCancel
                          }) {

    const handleClose = async (data, reason) => {
        LOGGER.debug("data:", data)
        LOGGER.debug("reason:", reason)
        if (reason && ["escapeKeyDown", "backdropClick"].includes(reason)) {
            handleCancel()
            return
        }
        onClose(await createObject(
            data.id,
            data.name,
            data.description,
            data.parentId
        ))
    };
    const handleCancel = () => {
        onCancel(node.id)
    };

    const defaultValues = {
        id:node?.id,
        name: "[name]",
        description: "[description]",
        parentId: node?.parentId
    }

    const resolver = useYupValidationResolver(MIDDLEWARE_SCHEMA);
    const { register, handleSubmit, formState: {errors}, setValue, control } = useForm({
        resolver,
        defaultValues
    });

    useEffect(()=>{
        if (node) {
            Object.keys(node).forEach(k => setValue(k, node[k]))
        }
    }, [node, setValue])

    useEffect(()=>{
        LOGGER.debug("errors:", errors)
    }, [errors])

    return (
        <div>
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle className={cssStyles.dialogTitle}>{titleOfForm}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {descriptionOfForm}
                    </DialogContentText>
                    <form
                        data-testid={"middlewares-editor-form"}
                        className={cssStyles.form}
                        onSubmit={handleSubmit(data => handleClose(data))}
                        noValidate
                    >
                        <input
                            type={"hidden"}
                            name={"id"}
                            value={node.id}
                            {...register("id")}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            name={"name"}
                            label="Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            {...register("name")}
                            error={!!errors?.name}
                            helperText={errors?.name ? errors.name.message : null}
                        />
                        <MultilineTextField
                            register={register}
                            errors={errors}
                        />
                        <NodeSelector
                            label={PARENT_NODE_LABEL}
                            nodeType={PARENT_NODE_TYPE}
                            nodeTypeName={PARENT_NODE_TYPE_NAME}
                            nodeRootFolderId={PARENT_NODE_ROOT_ID}
                            multiSelect={false}
                            name={"parentId"}
                            control={control}
                            error={!!errors?.parentId}
                            helperText={errors?.parentId ? errors.parentId.message : null}
                        />
                        <DialogActions className={cssStyles.actionsDiv}>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={cssStyles.submit}
                            >Save</Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}
