import cssStyles from './SigninPage.module.css';
import Logger from "../../../utils/Logger";
import LoginComponent from "./LoginComponent";


const LOGGER = new Logger("SigninPage")

export default function SigninPage() {
    LOGGER.debug("Enter - SigninPage")
    return <div className={cssStyles.mainDiv}>
        <div className={cssStyles.formDiv}>
            <h1>Sign in</h1>
            <LoginComponent/>
        </div>
    </div>
}
