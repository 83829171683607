import TileMenu from "../../TileMenu/TileMenu";
import Logger from "../../../utils/Logger";
import {addAuthorizationHeader} from "../../../utils/Api";
import {writeFileXLSX as xlsx_writeFileXLSX, utils as xlsx_utils} from "xlsx";
import {getNodeTypeFields} from "../../../model/Constants";
import {useModel} from "../../../model/ModelContext";
import {useSecurityContext} from "../../../security/SecurityContext";
import {useWorkspaceContext} from "../../../security/WorkspaceContext";
import {useSubscriptionContext} from "../../../subscription/SubscriptionContext";
import {useLoadingOverlay} from "../../LoadingOverlay/LoadingOverlay";
import {useStatusMessage} from "../../StatusMessenger/StatusMessageProvider";

const LOGGER = new Logger("Export")

const OPTION_NODETYPE_LABELS = {
    "actor": "Actors",
    "application": "Applications",
    "architecture_building_block": "Architecture Building Blocks",
    "business_process": "Business Processes",
    "capability": "Capabilities",
    "capability_analysis": "Capability Analyses",
    "data_exchange": "Data Exchanges",
    "data_flow": "Data Flows",
    "data_object": "Data Objects",
    "design_decision": "Design Decisions",
    "domains": "Domains",
    "functionality": "Functionalities",
    "maturity_model_analysis": "Maturity Model Analyses",
    "middleware": "Middlewares",
    "principle": "Principles",
    "reference_architecture": "Reference Architectures",
    "scenario": "Scenarios",
    "tal": "Tals",
    "view": "Views",
}


export default function Export() {

    const loadingOverlay = useLoadingOverlay()
    const setNewMessage = useStatusMessage()
    const {supportedObjectTypes} = useSubscriptionContext()
    const {user} = useSecurityContext()
    const {selectedWorkspace} = useWorkspaceContext()
    const {getNodesByType, loading} = useModel()


    LOGGER.debug("Rendering Export")

    function downloadFile(url, filename, filetype, dataJsonPath) {

        loadingOverlay.show("Preparing download...")

        if (dataJsonPath === "xlsx") {
            try {
                const wb = xlsx_utils.book_new()
                supportedObjectTypes.forEach((typeName) => {
                    const fields = getNodeTypeFields(typeName)
                    const sheetObjects = getNodesByType(typeName).map((n) => {
                        const filteredNode = {}
                        fields.forEach((f) => {
                            const value = n[f]
                            if (Array.isArray(value)) {
                                filteredNode[f] = value.join("; ")
                            } else {
                                filteredNode[f] = value
                            }

                        })
                        return filteredNode
                    })
                    let sheet = xlsx_utils.json_to_sheet(sheetObjects)
                    xlsx_utils.book_append_sheet(wb, sheet, typeName);
                })
                xlsx_writeFileXLSX(wb, "MODEL.xlsx");
                setNewMessage("Download complete", "info")
                return
            } catch (e) {
                console.error("Error generating xlsx: ", e)
                setNewMessage("Error generating xlsx: " + e.message, "alert")
                return
            } finally {
                loadingOverlay.hide()

            }

        }

        fetch(url, user && {
            // only attach headers if user is logged in, but still make the request regardless
            headers: addAuthorizationHeader({}, selectedWorkspace, user)
        })
        .then(response=>{
            LOGGER.debug("response:", response)
            return response.json()
        })
        .then(json => {

            let data = json[dataJsonPath]
            let blobby = new Blob([data], {
                type: filetype
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blobby);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            setNewMessage("Download complete", "info")
        })
        .catch((e)=>{
            console.error("Error fetching capability analysis: ", e)
            setNewMessage("Error fetching capability analysis: " + e.message, "alert")
        }).finally(()=>{
            loadingOverlay.hide()
        })
    }

    function handleSelectChange(e) {

        const type = e.target.options[e.target.selectedIndex].value
        if (type==="none") {
            LOGGER.debug("customer selected 'none', doing nothing")
            return
        }

        LOGGER.debug("target: ", e.target)

        const url = "/.netlify/functions/export?format=csv&type=" + type


        downloadFile(url, type + ".csv", "application/csv", "csv")
    }

    function handleXlsxClick() {
        downloadFile("/.netlify/functions/export?format=xlsx",
            "model.xlsx",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "xlsx")
    }



    const topics = [
        {title: "CSV", description: <div>
                Export <select name={"exportNames"} onChange={(e)=>handleSelectChange(e)}>
                <option value={"none"}>Select...</option>
                {supportedObjectTypes.map((k) => (<option value={k}>{OPTION_NODETYPE_LABELS[k]}</option>))}
            </select> as CSV.
            </div>, download: true, doNotGenerateLinks: true},
        {
            title: "Excel",
            filename:"model.xlsx",
            description: <>
                {loading && <span>Loading model</span>}
                {!loading && <span>Export the model in Excel format</span>}
            </>,
            link: "/.netlify/functions/export-xlsx",
            download: true,
            onClick: handleXlsxClick
        },
        /*
        {title: "Application Templates", link: "application-templates"},
        {title: "APIs", link: "apis"},
        {title: "Solutions", link: "solutions"},
        {title: "Collaborations", link: "collaborations"},
        {title: "T-Shirt Sizing", link: "t-shirt-sizing"},
         */
    ]

    return <TileMenu user={user} topics={topics}/>
}
