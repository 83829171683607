import React, {createContext, useContext, useEffect, useState} from 'react';
import Logger from "../../utils/Logger";
import {useModel} from "../../model/ModelContext";

const LOGGER = new Logger("SelectedNodesProvider", 2)

export const SelectedNodesContext = createContext();

export const useSelectedNodes = () => {
    return useContext(SelectedNodesContext);
};

export const SelectedNodesProvider = ({ children }) => {

    const [selectedNodes, setSelectedNodes] = useState([]);
    //these are nodes that are selected but don't need to be activated in the diagram
    //for example, when a node is being edited, it is selected but not activated in the diagram,
    // but it is still considered to be "selected" for the purposes of displaying the node properties
    const [softSelectedNodes, setSoftSelectedNodes] = useState([]);
    const [nodeBeingEdited, setNodeBeingEdited] = useState(null);

    const {getNodeById} = useModel()

    const setSelectedNodeById = (nodeId) => {
        LOGGER.trace("setSelectedNodeById: ", nodeId)
        if (!nodeId) {
            LOGGER.warn("setSelectedNodeById called with null nodeId.")
            setSelectedNode(null)
            return
        }
        const node = getNodeById(nodeId)
        if (node) {
            setSelectedNode(node)
        } else {
            LOGGER.warn("node not found for id: ", nodeId)
        }
    }

    function setSelectedNodesById(nodeIds) {
        LOGGER.trace("setSelectedNodesById: ", nodeIds)
        const nodes = nodeIds.map(id => getNodeById(id))
        setSelectedNodes(nodes)
    }

    const setSelectedNode = (node) => {
        LOGGER.trace("setSelectedNode.")
        if (!node) {
            setSelectedNode([])
            return
        }
        setSelectedNodes([node])
    }

    const setSoftSelectedNode = (node) => {
        LOGGER.trace("setSoftSelectedNode.")
        setSoftSelectedNodes([node])
    }

    const clearNodeBeingEdited = () => {
        LOGGER.trace("clearNodeBeingEdited.")
        setNodeBeingEdited(null)
    }

    const removeSelectedNodeById = (nodeId) => {
        LOGGER.trace("removeSelectedNodeById: ")
        setSelectedNodes(selectedNodes.filter(n => n.id !== nodeId))
    }

    const addSelectedNodeById = (nodeId) => {
        LOGGER.trace("addSelectedNodeById: ")
        if (!selectedNodes.map(n=>n.id).includes(nodeId)) {
            const node = getNodeById(nodeId)
            setSelectedNodes([...selectedNodes, node])
        } else {
            LOGGER.trace("node to select already in selectedNodes: ", nodeId)
            LOGGER.trace("Still, changing the whole array, to trigger a re-render of depending components.")
            setSelectedNodes([...selectedNodes])
        }
    }

    const setSoftSelectedNodeById = (nodeId) => {
        LOGGER.trace("setSoftSelectedNodeById: ", nodeId)
        const node = getNodeById(nodeId)
        if (node) {
            setSoftSelectedNode(node)
        } else {
            LOGGER.warn("node not found for id: ", nodeId)
        }

    }

    useEffect(() => {
        LOGGER.trace("nodes changed event detected: ", selectedNodes)
    }, [selectedNodes]);

    return (
        <SelectedNodesContext.Provider value={{
            selectedNodes,
            setSelectedNode, setSelectedNodes, setSelectedNodeById, setSelectedNodesById,
            nodeBeingEdited, setNodeBeingEdited, clearNodeBeingEdited,
            softSelectedNodes,
            setSoftSelectedNode, setSoftSelectedNodes, setSoftSelectedNodeById,
            removeSelectedNodeById, addSelectedNodeById
        }}>
            {children}
        </SelectedNodesContext.Provider>
    );
};
