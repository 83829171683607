import cssStyles from "./DecisionTreeTool.module.css"
import CodeMirror from '@uiw/react-codemirror'
import { markdown, markdownLanguage } from '@codemirror/lang-markdown'
import { languages } from '@codemirror/language-data'
import {useRef, useState} from "react"
import {Tree, TreeNode} from "react-organizational-chart"
import YAML from 'yaml'
import Logger from "../../../../utils/Logger"
import {downloadAsPngImage} from "../../../TreeNavigator2/diagrams/utils/DiagramUtils";
import {Button} from "@mui/material";

const LOGGER = new Logger("DecisionTreeTool")

// use https://codemirror.net/

const yamlCode = 'title: \'My Decision Tree\'\n' +
	'questions:\n' +
	'  - title: \'Is this a question?\'\n' +
	'  - questions:\n' +
	'      - title: \'Yes\'\n' +
	'        questions:\n' +
	'         - title: \'Left\'\n' +
	'         - title: \'Rights\'\n' +
	'      - title: \'No\'\n' +
	'        questions:\n' +
	'        - title: \'up\'\n' +
	'        - title \'down\''

function questionToTreeNode(question) {
	if (!question) {
		LOGGER.error("questionToTreeNode: question is null")
		return <></>
	}
	return <TreeNode label={<div>{question.title}</div>}>
		{questionsToTreeNodes(question.questions)}
		{answersToTreeNodes(question.answers)}
	</TreeNode>
}

function answerToTreeNode(answer) {
	if (!answer) {
		LOGGER.error("answerToTreeNode: answer is null")
		return <></>
	}
	if (answer.title) {
		return <TreeNode label={<div>{answer.title}</div>}/>
	} else {
		return <TreeNode label={<div>{answer}</div>}/>
	}
}

function answersToTreeNodes(answers) {
	if (!answers) {
		LOGGER.error("toTreeNodes: answers is null")
		return false
	}
	return answers.map((a)=> {
		return answerToTreeNode(a)
	})
}

function questionsToTreeNodes(questions) {
	if (!questions) {
		LOGGER.error("toTreeNodes: questions is null")
		return false
	}
	return [...questions.map((q)=> {
		return questionToTreeNode(q)
	})]
}

function toTree(yaml) {
	LOGGER.debug("updating decision tree to match yaml: ", yaml)
	if (typeof yaml !== "object") {
		LOGGER.error("toTree: yaml is not an object")
		return false
	}
	const treeNodes = questionsToTreeNodes(yaml.questions)
	return <Tree label={<div>{yaml.title}</div>}>
		{(treeNodes?treeNodes:[])}
	</Tree>
}

function updateDecisionTreeView(value) {
	LOGGER.debug("updateDecisionTreeView: ", value)
	try {
		const yaml = YAML.parse(value)
		return toTree(yaml)
	} catch (e) {
		LOGGER.error(e);
		return false
	}

}

export function DecisionTreeTool() {

	const decisionTreeViewRef = useRef()
	const [value, setValue] = useState("title: 'My Decision Tree'\nquestions:\n  - title: 'Is this a question?'\n    questions:\n      - title: 'Yes'\n      - title: 'No'")
	const [decisionTreeView, setDecisionTreeView] = useState(yamlCode)

	let onTextChange = (value) => {
		LOGGER.debug("onTextChange: ", value)
		const newTree = updateDecisionTreeView( value);
		if (newTree) {
			setDecisionTreeView(newTree)
		} else {
			LOGGER.error("Failed to update decision tree")
			setDecisionTreeView("can't parse yaml")
		}
	};
	return (<div className={cssStyles.main}>
	  	<h1>DecisionTreeTool</h1>
		<CodeMirror
			value={value}
			extensions={[markdown({ base: markdownLanguage, codeLanguages: languages })]}
			onChange={onTextChange}
		/>
		<Button
			className={cssStyles.downloadButton}
			onClick={(event)=>downloadAsPngImage(decisionTreeViewRef, "decisiontree.png")}
		>Download as image</Button>
		<div ref={decisionTreeViewRef} className={cssStyles.treeDiv}>{decisionTreeView}</div>
	</div>
  );
}
