import {g} from "@joint/plus";
import {getCustomProp} from "./utils/JointjsUtils";

export function isCapability(element) {
    return getCustomProp(element, "type") === "capability";
}
export function resizeCapability(graph, container, increaseOnly = true, padding = 20) {
    const embeds = container.getEmbeddedCells();
    const currentBBox = container.getBBox();
    let bbox;
    if (embeds.length === 0) {
        bbox = new g.Rect(currentBBox.x, currentBBox.y, 200, 100);
    } else {
        bbox = graph.getCellsBBox(embeds).inflate(padding);
        if (increaseOnly) {
            bbox = bbox.union(currentBBox);
        }
    }
    container.position(bbox.x, bbox.y);
    container.resize(bbox.width, bbox.height);
    const parent = container.getParentCell();
    if (parent) {
        resizeCapability(graph, parent, increaseOnly, padding);
    }
}

export function layoutEmbeds(graph, container, gap = 10) {
    let x = gap
    let y = gap + 40 /* add height of header */;
    container.getEmbeddedCells().forEach((el) => {
        el.position(x, y, { deep: true, parentRelative: true });
        y += el.size().height + gap;
    });
}
