import cssStyles from "./ImprovementsPane.module.css"

export default function ImprovementsPane({improvementsArray, showLinkToNode}) {
    return <div className={cssStyles.main}>
            {improvementsArray && improvementsArray.map((improvement)=>{
                return <div key={improvement.nodeId + "/" + improvement.message} className={cssStyles.row}>
                    {showLinkToNode && <a href={"/node?id=" + improvement.id}>{improvement.message}</a>}
                    {!showLinkToNode && improvement.message}
                </div>
            })}
        {(!improvementsArray || improvementsArray.length === 0) && <div className={cssStyles.empty}>None</div> }
    </div>
}
