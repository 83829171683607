import cssStyles from "./SnapshotResetter.module.css"
import {useEffect, useState} from "react";
import {Button, IconButton, Tooltip} from "@mui/material";
import * as React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {addAuthorizationHeader} from "../../../utils/Api";
import WaiterText from "../../../utils/WaiterText";
import EmptyListText from "../../../utils/EmptyListText";
import {useWorkspaceContext} from "../../../security/WorkspaceContext";
import {useSecurityContext} from "../../../security/SecurityContext";
import InfoBox from "../../InfoBox/InfoBox";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import {useLoadingOverlay} from "../../LoadingOverlay/LoadingOverlay";

export function SnapshotResetter() {

    const {user} = useSecurityContext()
    const {selectedWorkspace} = useWorkspaceContext()
    const loadingOverlay = useLoadingOverlay()


    const [snapshots, setSnapshots] = useState([])
    const [statusMessage, setStatusMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [snapshotToRecover, setSnapshotToRecover] = useState(null)
    const [snapshotToDelete, setSnapshotToDelete] = useState(null)

    const fetchSnapshots = () => {
        if (selectedWorkspace === undefined || user === null) {
            return
        }
        setIsLoading(true)
        fetch("/.netlify/functions/manage-snapshots",  user && {
            // only attach headers if user is logged in, but still make the request regardless
            headers: addAuthorizationHeader({}, selectedWorkspace, user),
            method: "GET"
        })
            .then(res =>res.json())
            .then(async data => {
                console.log("data:", data)
                data.sort((a, b)=>{
                    if (a.createdAt > b.createdAt) {
                        return -1;
                    }
                    if (b.createdAt > a.createdAt) {
                        return 1;
                    }
                    return 0;
                })
                setSnapshots(data)
            })
            .catch((e)=>{
                console.error("Error leaving workspace: ", e)
            }).finally(()=> {
                setIsLoading(false)
                setStatusMessage("")
        })
    }

    useEffect(()=>{
        fetchSnapshots()
        // eslint-disable-next-line
    }, [selectedWorkspace, user])

    function handleDeleteSnapshot(snapshotId) {
        fetch("/.netlify/functions/manage-snapshots",  user && {
            // only attach headers if user is logged in, but still make the request regardless
            headers: addAuthorizationHeader({}, selectedWorkspace, user),
            method: "DELETE",
            body: JSON.stringify({action:"delete", id:snapshotId})
        })
            .then(res =>res.json())
            .then(async data => {
                console.debug("data:", data)
                setStatusMessage("Snapshot deleted")
                setSnapshotToDelete(null)
                fetchSnapshots()
            })
            .catch((e)=>{
                console.error("Error leaving workspace: ", e)
            }).finally(()=>setStatusMessage(""))
    }
    function handleRecoverySnapshot(snapshotId) {
        loadingOverlay.show("Recovering snapshot...")
        fetch("/.netlify/functions/manage-snapshots",  user && {
            // only attach headers if user is logged in, but still make the request regardless
            headers: addAuthorizationHeader({}, selectedWorkspace, user),
            method: "PUT",
            body: JSON.stringify({action:"recover", id:snapshotId})
        })
            .then(res =>res.json())
            .then(async data => {
                console.debug("data:", data)
                setStatusMessage("Snapshot recovered")
                setSnapshotToRecover(null)
                fetchSnapshots()
            })
            .catch((e)=>{
                console.error("Error leaving workspace: ", e)
            }).finally(()=> {
            setStatusMessage("")
            loadingOverlay.hide()
        })
    }

    return <div className={cssStyles.mainDiv}>
        <div className={cssStyles.infoBoxWrapper}>
            <InfoBox>
                <p>Be careful with the <b>recover</b> feature, it <b>will reset the workspace</b> to the state it was when the snapshot was taken.</p>
                <p>Also, <b>deleting</b> a snapshot is permanent and <b>can't be undone</b></p>
            </InfoBox>
        </div>
        {isLoading && <div className={cssStyles.statusDiv}>
            <WaiterText>Loading snapshots...</WaiterText>
        </div> }
        {statusMessage && statusMessage && <div className={cssStyles.statusDiv}>
            <WaiterText>{statusMessage}</WaiterText>
        </div> }
        <div className={cssStyles.tableDiv}>
            <ConfirmDialog
                title={"Confirm recovery"}
                open={snapshotToRecover !== null}
                setOpen={(shouldOpen)=>{
                    if (!shouldOpen) {
                        setSnapshotToRecover(null)
                    }
                }}
                onConfirm={(e) => {
                    handleRecoverySnapshot(snapshotToRecover)
                }}
                buttons={{no:{
                        text: "Cancel",
                        focus: false,
                    }, yes: {
                        text: "Recover",
                        focus: true,
                    }}}
            >
                Recover the snapshot?
            </ConfirmDialog>
            <ConfirmDialog
                title={"Confirm delete"}
                open={snapshotToDelete !== null}
                setOpen={(shouldOpen)=>{
                    if (!shouldOpen) {
                        setSnapshotToDelete(null)
                    }
                }}
                onConfirm={(e) => {
                    handleDeleteSnapshot(snapshotToDelete)
                }}
                buttons={{no:{
                        text: "Cancel",
                        focus: false,
                    }, yes: {
                        text: "Delete",
                        focus: true,
                    }}}
            >
                Delete the snapshot?
            </ConfirmDialog>
            <table className={cssStyles.tableDiv} data-testid={"snapshot-table"}>
                <tbody>
                    {!snapshots?.length && !isLoading && <tr><td><EmptyListText>No snapshots (yet)</EmptyListText></td></tr>}
                    {snapshots?.length > 0 && snapshots?.map((snapshot)=>{
                        return <tr key={`tr-${snapshot.id}`}>
                            <td>{snapshot.name} (#={snapshot?.content?.length})</td>
                            <td>{snapshot.createdAt.substring(0, 10)}</td>
                            <td>
                                <Tooltip
                                    title={"Delete snapshot"}
                                >
                                    <IconButton
                                        onClick={(e) => {
                                            setSnapshotToDelete(snapshot.id)
                                        }}
                                        aria-label="delete"
                                        size="small"
                                    >
                                        <DeleteForeverIcon
                                            className={cssStyles.closeIconButton}
                                            fontSize="small"
                                        />
                                    </IconButton>
                                </Tooltip>
                            </td>
                            <td>
                                <Button variant={"contained"} type={"button"} onClick={() => {
                                    setSnapshotToRecover(snapshot.id)
                                }}>Recover</Button>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    </div>
}


