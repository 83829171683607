import cssStyles from "./WorkspaceSelector.module.css"
import {FormControl, MenuItem, Select} from "@mui/material";
import Logger from "../../utils/Logger";
import {useMatch, useNavigate} from "react-router-dom";
import {ACCOUNT_WORKSPACE_CREATE} from "../Pages/NavigationConstants";
import {useWorkspaceContext} from "../../security/WorkspaceContext";
import {useEffect} from "react";
import Cookies from "js-cookie";

const LOGGER = new Logger("WorkspaceSelector", 2)

const COOKIE_NAME = 'workspace-selector-value'

export default function WorkspaceSelector() {

    const {workspaces, selectWorkspace, selectedWorkspace,
        areWorkspacesLoading, errorLoadingMessage} = useWorkspaceContext()

    let fullMatch = useMatch("/workspace/:workspaceId/:objectType/:objectId");
    let workspaceMatch = useMatch("/workspace/:workspaceId");

    useEffect(() => {
        LOGGER.debug("useEffect.workspaces:", workspaces)
        if (!workspaces || workspaces.length === 0) {
            LOGGER.debug("No workspaces")
            return
        }

        if (workspaces?.length === 1) {
            selectWorkspace(workspaces[0].id)
            return
        }

        if (!fullMatch) {
            if (workspaceMatch && workspaceMatch?.params?.workspaceId && workspaceMatch?.params?.workspaceId !== "null" && workspaceMatch?.params?.workspaceId !== "undefined") {
                const workspaceId = workspaceMatch.params.workspaceId
                if (workspaces?.find(workspace=>workspace.id===workspaceId)) {
                    selectWorkspace(workspaceId)
                } else {
                    if (workspaces?.length > 0) {
                        const workspaceId = workspaces[0].id
                        selectWorkspace(workspaceId)
                    }
                }
                return
            }


            return
        }
        LOGGER.debug("match:", fullMatch)
        let params = fullMatch?.params
        let paramWorkspaceId = params.workspaceId
        let paramObjectId = params.objectId
        LOGGER.debug(`params:`, params)
        LOGGER.debug(`workspaces:`, workspaces)
        let paramWorkspace = workspaces?.find(workspace=>workspace.id===paramWorkspaceId)
        LOGGER.debug(`paramWorkspace:`, paramWorkspace)
        if (paramWorkspace) {
            LOGGER.debug(`paramWorkspaceId ${paramWorkspaceId} part of workspaces:`, workspaces)
            selectWorkspace(paramWorkspaceId)
            if (paramObjectId) {
                LOGGER.debug(`paramObjectId ${paramObjectId} part of paramWorkspace:`, paramWorkspace)
                //xxx
            }
            //onWorkspaceSelected(paramWorkspaceId)
        } else {
            LOGGER.debug(`paramWorkspaceId "${paramWorkspaceId}" NOT part of workspaces`)
        }
        // eslint-disable-next-line
    }, [fullMatch, workspaces, workspaceMatch]);

    useEffect(() => {
        if (selectedWorkspace) {
            LOGGER.debug("selectedWorkspace ", selectedWorkspace)
            if (workspaces?.find(workspace=>workspace.id===selectedWorkspace)) {
                LOGGER.debug("setting cookie to ", selectedWorkspace)
                Cookies.set(COOKIE_NAME, selectedWorkspace)
            }
        }
    }, [selectedWorkspace, workspaces])

    useEffect(()=>{
        if (!fullMatch && !workspaceMatch) {
            LOGGER.debug("No match")
            let cookieValue = Cookies.get(COOKIE_NAME)
            LOGGER.debug("cookieValue:", cookieValue)
            if (cookieValue !== undefined && cookieValue !== null && cookieValue !== "null" && cookieValue !== "undefined") {
                if (workspaces?.find(workspace=>workspace.id===cookieValue)) {
                    LOGGER.debug("selecting workspace from cookie:", cookieValue)
                    selectWorkspace(cookieValue)
                    return
                }
            }
            LOGGER.debug("cookieValue not found in workspaces, selecting first workspace in list")
            if (workspaces?.length > 0) {
                selectWorkspace(workspaces[0].id)
            }
        }
    }, [selectWorkspace, fullMatch, workspaceMatch, workspaces])


    const selectSX = {
        backgroundColor: "white",
        borderColor: "#999999",
        height:"45px"
    };

    const handleChange = (e) => {
        LOGGER.debug("handleChange.e:", e)
        if (e.target.value) {
            if (e.target.value === "CREATE_NEW") {
                LOGGER.debug("handleChange.e.target.value CREATE_NEW workspace")
                navigate(ACCOUNT_WORKSPACE_CREATE)
                return
            }
            LOGGER.debug("handleChange.e.target.value selecting workspace:", e.target.value)
            selectWorkspace(e.target.value)
        }
    }

    const navigate = useNavigate()

    useEffect(()=>{
        LOGGER.debug("selectedWorkspace changed:", selectedWorkspace)
        if ((fullMatch?.params?.workspaceId || workspaceMatch?.params?.workspaceId) && workspaces?.find(workspace=>workspace.id===selectedWorkspace)) {
            navigate("/workspace/" + selectedWorkspace)
        }
        // eslint-disable-next-line
    }, [selectedWorkspace])

    function conditionallyShowCreateOption() {
        //todo: also don't show this if the user reached the max number of workspaces
        if (true) {
            return <MenuItem
                key={"menuItem-CREATE_NEW"}
                value={"CREATE_NEW"}
            >
                <i>Create&nbsp;<b>new workspace</b></i>
            </MenuItem>
        }
    }

    return <div id={"workspaceSelectorDivId"} className={cssStyles.mainDiv}>
        {errorLoadingMessage && <span className={cssStyles.errorLoadingSpan}>{errorLoadingMessage}</span>}
        {!errorLoadingMessage && areWorkspacesLoading && <span className={cssStyles.loadingSpan}>Loading workspaces...</span>}
        {!areWorkspacesLoading && workspaces && workspaces.length===0 && <span data-testid={"workspaceselector-create-workspace-span"} className={cssStyles.createSpan} onClick={()=>{navigate(ACCOUNT_WORKSPACE_CREATE)}}>Create Workspace...</span>}
        {!areWorkspacesLoading && selectedWorkspace && workspaces && workspaces.length>0 && <FormControl>
                <Select
                    labelId={"workspace-selector-label-id"}
                    id={"workspace-selector"}
                    data-testid={"workspace-selector"}
                    onChange={(e) => handleChange(e)}
                    value={selectedWorkspace}
                    sx={selectSX}
                    autoWidth={true}
                    displayEmpty={true}
                >
                    { conditionallyShowCreateOption()}
                    {workspaces?.map((workspace) => {
                        return <MenuItem
                            key={"menuItem-" + workspace.id}
                            value={workspace.id}
                            selected={workspace.id === selectedWorkspace}
                        ><b>{workspace.name}</b>&nbsp; {"[" + workspace.role + "]"}</MenuItem>
                    })}
                </Select>
            </FormControl>
        }
    </div>
}
