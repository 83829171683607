import cssStyles from "./CreateWorkspacePage.module.css"
import Logger from "../../../utils/Logger";
import React from "react";
import InfoBox from "../../InfoBox/InfoBox";
import {useNavigate} from "react-router-dom";
import {CreateWorkspaceForm} from "../../CreateWorkspaceForm/CreateWorkspaceForm";
import {Kenchiku} from "../../Kenchiku/Kenchiku";

const LOGGER = new Logger("CreateWorkspacePage")
//kudos: https://stackoverflow.com/questions/59197551/yup-deep-validation-in-array-of-objects
/*
for editing: const ACOUNT_SCHEMA = object().shape({
    id: string().required().default(""),
    name: string().required(),
    description: string().required(),
    parentId: string().required().default(NodeFolderRootIds.WorkspaceRootId.description),
    users: array().required().of(object().shape({
        user_id: string().required(),
        role: string().required(),
    })),
    invitedUsers: array().required().of(object().shape({
        user_id: string().when('email', {
            is: (email) => !email || email.length === 0,
            then: string()
                .required('Either email or user_id must be provided'),
        }),
        email: string().when('user_id', {
            is: (user_id) => !user_id || user_id.length === 0,
            then: string()
                .required('Either email or user_id must be provided'),
        }),
        role: string().required(),
    })),
});
 */

export default function CreateWorkspacePage() {

    LOGGER.debug("CreateWorkspacePage called")

    const navigate = useNavigate();

    const onCreated = (newWorkspace) => {
        //maybe update the workspace context?
        navigate("/account/workspaces")
        navigate(0)   //forces a refresh
    }

    const onError = (errors, e) => {
        console.error("Error creating workspace: ", e)
        alert("An error occured while creating the workspace:", e?.getMessage())
    }

    return <div className={cssStyles.mainDiv}>
        <InfoBox>
            <p>You create a <b>workspace</b> to organize your architecture work. You can also give access to other users to specific workspaces so that you can collaborate or they can review the architecture for you.</p>
            <p>You must have at least one workspace.</p>
            <p>When you subscribed, <Kenchiku/> will have created a default workspace for you.</p>
        </InfoBox>
        <CreateWorkspaceForm
            onError={onError}
            onCreated={onCreated}
        />
    </div>
}
