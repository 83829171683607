import cssStyles from './TableField.module.css';
import {createRow, MaterialReactTable} from "material-react-table";
import {useEffect, useMemo, useRef} from "react";
import Logger from "../../../../utils/Logger";
import {Controller} from "react-hook-form";
import {Box, IconButton, Tooltip} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {showDialog} from "../../diagrams/utils/JointjsUtils";
import generateUUID from "../../../../utils/UUIDUtils";

const LOGGER = new Logger("TableField")

export default function TableField({
   id, label, mainNode, name, columns,
   control, setValue, error, helperText,
   enableEditing
}) {

    const tableData = useRef([])
    const tableColumns = useMemo(() => {
        if (!columns) {
            LOGGER.debug("columns is null")
            return []
        }
        const tColumns = columns.map(column => {
            let mrtColumn = {
                accessorKey: column.propertyName,
                header: column.columnName,
                grow: true,
                size: 20,
                Cell: ({ cell }) => (
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                        {cell.getValue()}
                    </div>
                ),
            }
            if (column.idColumn) {
                mrtColumn.enableHiding = true
                mrtColumn.show = false
            }
            if (column.multiline) {
                mrtColumn.muiEditTextFieldProps = {
                    multiline: true,
                    minRows: 3,
                    helperText: "Shift & Enter for new line. '+' for pro, '-' for con.",
                }
            }
            if (column.placeholder) {
                mrtColumn.muiEditTextFieldProps.placeholder = column.placeholder
            }
            return mrtColumn
        })
        return tColumns
    }, [columns])

    useEffect((factory, deps) => {
        if (!mainNode) {
            LOGGER.debug("mainNode is null")
            return
        }
        if (!mainNode[name]) {
            LOGGER.debug("mainNode[name] is null")
            return
        }
    }, [mainNode, name, tableColumns]);

    const handleCreateRow = ({table, row, values}) => {
        LOGGER.debug("handleCreateRow: ", {row, values})

        tableData.current.push({id:generateUUID(), ...values});
        LOGGER.debug("tableData: ", tableData)
        table.setCreatingRow(null);
        LOGGER.debug("exited creating mode")
        setValue(name, tableData)
    };

    const handleSaveRow = ({table, row, values}) => {
        LOGGER.debug("handleSaveRow: ", {row, values})
        const newTableData = tableData.current.map((oldRow)=>{
            LOGGER.debug(`oldRow.id = ${oldRow.id}, row.original.id = ${row.original.id}`)
            if (oldRow.id === row.original.id) {
                LOGGER.debug("oldRow.id === row.original.id")
                const newRow = {...oldRow, ...values}
                LOGGER.debug("newRow: ", newRow)
                return newRow
            }
            return oldRow
        })
        LOGGER.debug("newTableData: ", newTableData)
        tableData.current = newTableData
        LOGGER.debug("tableData: ", tableData)
        table.setEditingRow(null);
        LOGGER.debug("exited editing mode, field ", name)
        setValue(name, newTableData)
    };

    function confirmDelete(confirmText, row) {
        LOGGER.debug("confirmDelete: ", row)
        showDialog({
            text: confirmText,
            buttons: [{
                id: "yes",
                text: "Remove",
                handler: () => {
                    const newTableData = tableData.current.filter((oldRow)=>{
                        return oldRow.id && oldRow.id !== row?.original?.id
                    })
                    LOGGER.debug("newTableData: ", newTableData)
                    tableData.current = newTableData
                    setValue(name, newTableData)
                }
            }, {
                id: "no",
                text: "Cancel",
                handler: () => {},
                sameAsClose: true,
            }],
        });
    }

    return <Controller
        name={name}
        control={control}
        render={({field: {onChange, value}})=> {
            LOGGER.debug("render.value:", value)
            tableData.current = (value || [])
            return <div className={cssStyles.tableField}>
                <MaterialReactTable
                    data={tableData.current}
                    columns={tableColumns}
                    className={cssStyles.table}
                    columnVisibility={{id: false, name: true, description: true}}
                    createDisplayMode={'row'}
                    editDisplayMode="row"
                    enableColumnActions={true}
                    enableEditing={enableEditing}
                    enableHiding={true}
                    initialState={{columnVisibility: {id: false}}}
                    onCreatingRowSave={({table, row, values}) =>{
                        handleCreateRow({table, row, values})
                        onChange(tableData.current)
                    }}
                    onEditingRowSave={({table, row, values}) =>{
                        handleSaveRow({table, row, values})
                        onChange(tableData.current)
                    }}
                    onRowClick={(row) => {
                        LOGGER.debug("row clicked: ", row)
                    }}
                    renderRowActions= {({ row, table }) => (
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <Tooltip title="Edit">
                                <IconButton onClick={() => table.setEditingRow(row)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton color="error" onClick={() => confirmDelete(
                                    "Are you sure you want to delete this row?",
                                    row
                                )}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                    renderTopToolbarCustomActions={({table}) => {
                        return <div>
                            <span className={cssStyles.label}>{label}</span>
                            <IconButton onClick={(event) => {
                                event.preventDefault()
                                LOGGER.debug("custom action clicked")
                                table.setCreatingRow(
                                    createRow(table, {
                                        id: generateUUID(),
                                        name: "new name",
                                        description: "new description"
                                    }),
                                );
                            }}>
                                <AddIcon />
                            </IconButton>
                        </div>
                    }}
                />
            </div>
        }}
    />
}
