import {Typography} from "@mui/material";

import cssStyles from "./LinkBar.module.css";
import {waitForCondition} from "../../utils/PresentationUtils";
import Logger from "../../utils/Logger";

const LOGGER = new Logger("LinkBar")

export function LinkBar(props) {

    const onSupportClick = async (event) => {
        event.preventDefault();
        document.querySelector('[data-test="popup-button"]').click();

        //we need to use waitForCondition, because the form is part of a SaaS solution that sometimes takes while to appear...
        const waitResult = await waitForCondition(() => {
            return document.querySelector('[data-test="feedback-form"]')
        }, 100, 15)

        if (waitResult) {
            waitResult.classList.add(cssStyles.flashingDiv);
        } else {
            LOGGER.debug("timeout waiting for form to appear, not letting it flash...")
        }


        setTimeout(() => {
            document.querySelector('[data-test="feedback-form"]').classList.remove(cssStyles.flashingDiv);
        }, 3000)
    }

    return <>
        <Typography variant="h6" noWrap component="div">
            {/* kudos to https://www.unicode.org/cgi-bin/GetUnihanData.pl?codepoint=%E5%B8%AB */}
            <span>
                <a
                    className={"external_link"}
                    href={"https://blog.kenchiku.ninja"}
                    rel="noreferrer"
                    target={"_blank"}
                >blog</a>
            </span>
        </Typography>
        <Typography variant="h6" noWrap component="div">
            {/* kudos to https://www.unicode.org/cgi-bin/GetUnihanData.pl?codepoint=%E5%B8%AB */}
            <span>|<a className={"external_link"} href={"https://docs.kenchiku.ninja/b446b1a892364fe9b0978adab0399a4f?v=be87c79713c943a2bbffae0ab5afc613"} rel="noreferrer"
                      target={"_blank"}>docs</a></span>
        </Typography>
        <Typography variant="h6" noWrap component="div">
            {/* kudos to https://www.unicode.org/cgi-bin/GetUnihanData.pl?codepoint=%E5%B8%AB */}
            <span>|<span className={"external_link"} onClick={onSupportClick}>support</span></span>
        </Typography>
    </>
}
