import {Autocomplete, TextField} from "@mui/material";
import cssStyles from "./SingleNodeSelector.module.css"

export function SingleNodeSelector({nodesToChooseFrom, onChange, defaultSelectedId}) {



    return <div className={cssStyles.main}>
        {<Autocomplete
            options={nodesToChooseFrom}
            getOptionLabel={(option) => option.name}
            style={{ width: 400 }}
            renderInput={(params) => <TextField {...params} label={"Select..."} variant="standard" />}
            onChange={(event, newValue) => {
                onChange(newValue)
            }}
            defaultValue={nodesToChooseFrom.find(node => node.id === defaultSelectedId)}
            variant={"outlined"}
        />}
    </div>

}
