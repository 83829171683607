import Logger from "./Logger";

const LOGGER = new Logger("Labels")

export function buildDataObjectsLabel(getNodeById, dataObjectIds) {
    let dataObjectsLabel = "---"
    if (dataObjectIds && Array.isArray(dataObjectIds)) {
        dataObjectsLabel = "[" + getNodeById(dataObjectIds)?.map((n)=>n.name)?.join("&") + "]"
    }
    return dataObjectsLabel
}

export function buildFullLabel(getNodeById, nodeId, depth=0) {
    if (depth>15) {
        LOGGER.warn("reached the max depth in Labels.buildFullLabel. Giving up... In context of nodeId: ", nodeId)
        return
    }
    if (!nodeId) {
        return ""
    }
    let node = getNodeById(nodeId)
    if (!node) {
        return nodeId
    }
    if (!nodeId.indexOf('_') === 0) {
        let parentLabel = buildFullLabel(getNodeById, node?.parentId, depth + 1)
        if (parentLabel && parentLabel.trim().length > 0) {
            return parentLabel + " > " + node.name
        }
    }
    return node.name
}
