
export const getAccountPaneNames = function() {
    return Object.keys(ACCOUNT_PANES)
}
export const getAccountPaneTopics = function() {
    return Object.values(ACCOUNT_PANES)
}

export const ACCOUNT_PANES = {
    personalPane: {
        name:"personalPane",
        title: "Personal",
        description: "Your personal details we keep on record.",
        link: "/account/personal"
    },
    workspacesPane: {
        name:"workspacesPane",
        title: "Workspaces",
        description: "All the workspaces you can work on.",
        link: "/account/workspaces"
    },
    subscriptionPane: {
        name:"subscriptionPane",
        title: "Subscription",
        description: "Your current subscription, its status, billing, invoices, upgrades.",
        link: "/account/subscription"
    },

}
