import * as React from "react";
import Logger from "../../../utils/Logger";
import cssStyles from "./CapabilityDiagram2.module.css"
import {useState} from "react";
import {Tree, TreeNode} from "react-organizational-chart";
import {Checkbox, FormControlLabel} from "@mui/material";
import {NodeType} from "../../../model/Constants";
import {useModel} from "../../../model/ModelContext";
import {useSelectedNodes} from "../../SelectedNodes/SelectedNodesProvider";
import {Card} from "./Card";
import {NodeLabel} from "./NodeLabel";

const LOGGER = new Logger("CapabilityDiagram")

function buildTree(searchNodes, node, level, showApplications) {
    if (level > 20) {
        LOGGER.error("buildTree: too many levels!")
        return null
    }
    const capaChildren = searchNodes((n)=>n.parentId===node.id)
        .map(c=>buildTree(searchNodes, c, level+1, showApplications)).filter(n=>n!==null)
    let appChildren =  []
    if (showApplications) {
        appChildren = searchNodes((n)=>n.type===NodeType.Application.description && n.supportedCapabilityIds?.includes(node.id))
            .map(c=>buildTree(searchNodes, c, level+1, showApplications)).filter(n=>n!==null)
    }
    return {
        id: node.id,
        name: <NodeLabel node={node}/>,
        type: node.type,
        children: [...capaChildren, ...appChildren],
        level: level,
    }
}

function buildTreeNode(node, setSelectedNodeById, selectedNodes) {
    return <TreeNode label={<Card selectedNodes={selectedNodes} node={node} level={node.level} handleNodeClick={()=>setSelectedNodeById(node.id)}/>} >
        {node.children.map((c)=>buildTreeNode(c, setSelectedNodeById, selectedNodes))}
    </TreeNode>
}

export default function CapabilityDiagram2({node}) {

    const {selectedNodes, setSelectedNodeById} = useSelectedNodes()

    const {searchNodes} = useModel()

    const [showApplications, setShowApplications] = useState(true)

    const tree = buildTree(searchNodes, node, 0, showApplications)


    const labelAppObjects = { label:"Show Applications" };

    return (
        <div key={'cd-'+node.id} className={[cssStyles.main, ].join(" ")}>
            {node?.name}
            <hr/>
            <div className={cssStyles.filterPane}>
                <FormControlLabel
                    {...labelAppObjects}
                    control={<Checkbox defaultChecked />}
                    value={showApplications}
                    onChange={(event)=>{setShowApplications(oldValue=>!oldValue)}}
                />
            </div>
            <Tree label={<div>Capability</div>}>
                {buildTreeNode(tree, setSelectedNodeById, selectedNodes)}
            </Tree>
        </div>);
}
