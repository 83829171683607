import styles from "./TreeComponent2.module.css";
import {TextField} from "@mui/material";
import React from "react";
import {useSearchContext} from "./hooks/SearchContext";

export function SearchField() {

    const {searchText, setSearchText} = useSearchContext()

    const handleFilterChange = (searchText) => {
        console.log("searching for ", searchText)
        setSearchText(searchText || "")
    }
    return <div className={styles.searchFieldWrapper}>
        <TextField
            id="outlined-basic"
            name={"searchText"}
            label="Search"
            variant="standard"
            value={searchText}
            className={styles.searchField}
            onChange={(e) => handleFilterChange(e.target.value)}
        />
    </div>
}
