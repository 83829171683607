import cssStyles from './RecoverPasswordPage.module.css';
import Logger from "../../../utils/Logger";

const LOGGER = new Logger("RecoveryEmailSentPage")

export default function RecoveryEmailSentPage() {
    LOGGER.debug("Enter - RecoveryEmailSentPage")
    return <div className={cssStyles.mainDiv}>
        <div className={cssStyles.formDiv}>
            <h1>Recovery email sent</h1>
        </div>
    </div>
}
