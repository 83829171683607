import cssStyles from "./ModelStatusBox.module.css";
import {useModel} from "../../model/ModelContext";
import {LinearProgress} from "@mui/material";



export function ModelStatusBox() {
    const {loading, removing, updating} = useModel()
    return (
        <div className={cssStyles.main}>
            {(loading) && <div className={cssStyles.loading}><LinearProgress
                sx={{
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#00D1FF',
                    },
                }}
                /></div>
            }
            {(removing) && <div className={cssStyles.removing}><LinearProgress
                sx={{
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#FF0000',
                    },
                }}
            /></div>}
            {updating && <div className={cssStyles.updating}><LinearProgress
                sx={{
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#282c34',
                    },
                }}
            /></div>}
        </div>
    )
}
