import cssStyles from './SharePage.module.css';
import {LinearProgress} from "@mui/material";
import {useParams} from "react-router";
import {useEffect} from "react";
import {useWorkspaceContext} from "../../../security/WorkspaceContext";
import {useSelectedNodes} from "../../SelectedNodes/SelectedNodesProvider";
import {useNavigate} from "react-router-dom";
import Logger from "../../../utils/Logger";

const LOGGER = new Logger("SharePage")

export default function SharePage() {

    const navigate = useNavigate()
    const {workspaceId, objectId} = useParams()
    const {selectWorkspace, selectedWorkspace} = useWorkspaceContext();
    const {setSelectedNode, selectedNodes} = useSelectedNodes()

    useEffect(() => {
        if (workspaceId && objectId) {
            selectWorkspace(workspaceId)

        }
    }, [workspaceId, objectId, selectWorkspace]);

    useEffect(() => {
        if (selectedWorkspace && objectId) {
            LOGGER.debug(`selectedWorkspace: ${selectedWorkspace} & objectId: ${objectId}`)
            setSelectedNode(objectId)

        }
    }, [selectedWorkspace, objectId, setSelectedNode])

    useEffect(() => {
        if (selectedNodes?.length > 0) {
            LOGGER.debug("selectedNodes: ", selectedNodes)
            navigate("/workspace")
        }
    }, [selectedNodes, navigate]);

    return (
        <div className={cssStyles.main}>
            <h1>Share Page</h1>
            <p>Redirecting...</p>
            <LinearProgress/>
        </div>
    );
}
