import cssStyles from "./Import.module.css"
import Logger from "../../../utils/Logger";
import React, {useState} from "react";
import {uploadFile} from "../../TreeNavigator2/UploadUtils";
import FileUploader from "../../FileUpload/FileUploader";
import {useModel} from "../../../model/ModelContext";
import {useStatusMessage} from "../../StatusMessenger/StatusMessageProvider";

const LOGGER = new Logger("Export")

export default function Import({workspaceId, user, dataLoader}) {

    const {reload} = useModel()

    const [loaderMessage, setLoaderMessage] = useState("")
    const [uploadMessage, setUploadMessage] = useState("")

    const [showUserMessage, setShowUserMessage] = useState("")
    const [showUploadForCategory, setShowUploadForCategory] = useState("")

    const setNewMessage = useStatusMessage()

    return (<div className={cssStyles.mainDiv}>
                <div className={cssStyles.uploadContainerDiv}>
                    <span className={cssStyles.userMessage}>{showUserMessage}</span>
                    <span className={cssStyles.statusMessage}>{loaderMessage}</span>
                    <FileUploader
                        contentText={"Drop your Excel (xlsx) file in the rectangle below..."}
                        showCloseButton={false}
                        onDrop={async (files) => {
                            LOGGER.debug("onDrop.files:", files)
                            setUploadMessage("")
                            if (files && files[0]) {
                                // at least one file has been dropped so do something
                                LOGGER.debug("onDrop.files[0]:", files[0])
                                setLoaderMessage("Uploading file...")
                                try {
                                    await uploadFile(
                                        workspaceId,
                                        user,
                                        "xlsx",
                                        showUploadForCategory,
                                        files[0],
                                        (result) => {
                                            setNewMessage("Done uploading")
                                            setShowUploadForCategory("")
                                            setShowUserMessage("Successfully uploaded " + Object.values(result.counts).reduce((a, b) => a + b, 0) + " row(s)")
                                            reload()
                                        }, (error)=>{
                                            setShowUploadForCategory("")
                                            setShowUserMessage("")
                                            setNewMessage("ERROR: " + error, "alert")
                                        }, (message)=> {
                                            //setUploadMessage(message)
                                            //setStatusMessage(oldMessage=><>{oldMessage}<p>{message}</p></>)
                                            setShowUserMessage(message)
                                        }
                                    )
                                } catch (e) {
                                    setShowUploadForCategory("")
                                    setLoaderMessage("")
                                    setShowUserMessage("Error happened during file upload: " + JSON.stringify(e))
                                    LOGGER.error("onDrop.readFileAsTextString failed: ", e)
                                } finally {
                                    setLoaderMessage("")
                                }
                            }
                        }}
                        onCancel={()=>{setShowUploadForCategory("")}}
                        handleError={(msg, e) => {
                            LOGGER.error(msg, e)
                        }}
                        uploadMessage={uploadMessage}

                    />
                </div>
            </div>)

}
