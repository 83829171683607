import {Autocomplete, TextField} from "@mui/material";
import {Controller} from "react-hook-form"

import Logger from "../../../../utils/Logger"
import {
    StrategicClassificationValues, DEFAULT_StrategicClassificationValues
} from "../../../../model/Constants";

const LOGGER = new Logger("StrategicClassificationSelector")

export default function StrategicClassificationSelector({control, label, name, error, helperText}) {

    const strategicClassificationOptions = StrategicClassificationValues.map(v=>v.name)

    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}})=>{
                LOGGER.debug("render.value:", value)
                return <Autocomplete
                    multiple={false}
                    autoHighlight
                    autoSelect
                    id={"combo-box-lcso"}
                    options={strategicClassificationOptions}

                    renderOption={(props, option) => {
                        //LOGGER.debug("renderOption.option:", option)
                        return (
                            <li {...props} key={option}>
                                {option}
                            </li>
                        );
                    }}
                    renderInput={(params) => {
                        //LOGGER.debug("renderInput.params:", params)
                        return <TextField
                            {...params}
                            key={"tf-" + params.id} {...params}
                            label={label}
                            error={error}
                            helperText={helperText}
                            onChange={onChange}
                        />
                    }}
                    //defaultValue={(multiSelect?[]:"_capabilities")}
                    sx={{width: 500, paddingTop:"15px"}}
                    onChange={(event, values, reason) => {
                        LOGGER.debug("onChange.values:", values)
                        onChange(values)
                    }}
                    value={value || DEFAULT_StrategicClassificationValues}
                />
            }}
        />
    );
}
