import cssStyles from "./ReportPercentFragment.module.css";
import {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import {getNodeTypeIcon} from "../../../../model/Constants";

function getPercentageRangeColor(unsupportedPercentage) {
    const supportedPercentage = 1 - unsupportedPercentage
    if (supportedPercentage>0.99) {
        return "#0F0"
    }
    if (supportedPercentage>0.90) {
        return "#1bda1b"
    }
    if (supportedPercentage>0.75) {
        return "#8cc54f"
    }
    if (supportedPercentage>0.50) {
        return "#ffd500"
    }
    if (supportedPercentage>0.35) {
        return "#ffae00"
    }
    return "#F00"
}

export default function ReportPercentFragment({testId, label, total, count, percentage, nodeTypeName}) {

    const [isLoading, setIsLoading] = useState(true)

    useEffect(()=>{
        if ( (percentage || percentage === 0) && ("" + percentage).length >0) {
            setIsLoading(false)
        } else {
            setIsLoading(true)
        }
    }, [percentage])

    const zeStyle = {
        backgroundColor: (isLoading?"#AAAAAA":getPercentageRangeColor(percentage))
    }

    return (<div className={cssStyles.counterDiv}>

        <div
            className={cssStyles.counterValueDivWrapper}
            style={
                zeStyle
            }
        >
            {nodeTypeName && <span className={cssStyles.iconSpan}>{getNodeTypeIcon(nodeTypeName, "large")}</span>}
            <div
                className={cssStyles.counterValueDiv}
                style={
                    zeStyle
                }
            >
                {!isLoading && <span className={cssStyles.percentageValue} data-testid={testId}>{Math.round(percentage * 1000) / 10}%</span>}
                {!isLoading && <span className={cssStyles.absoluteValue} data-testid={testId}>{count} out of {total}</span>}
                {isLoading && <span className={cssStyles.counterValue}><CircularProgress/></span>}
            </div>
        </div>
        <span className={cssStyles.counterLabel}>{label}</span>
    </div>)
}
