import React from "react";

import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

export default function ConfirmDialog({title, children, open, setOpen, onConfirm, buttons}) {



    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button
                    variant={buttons?.no?.focus ? "contained" : "outlined"}
                    onClick={() => setOpen(false)}
                >
                    {buttons?.no?.text || "No"}
                </Button>
                <Button
                    variant={buttons?.yes?.focus ? "contained" : "outlined"}
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                >
                    {buttons?.yes?.text || "Yes"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

