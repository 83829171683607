
import Logger from "../../../utils/Logger";
import cssStyles from "./CustomerFeedbackPage.module.css";
import {useSecurityContext} from "../../../security/SecurityContext";

const LOGGER = new Logger("CustomerFeedbackPage")

export default function CustomerFeedbackPage() {

    LOGGER.debug("Enter - CustomerFeedbackPage")

    const {user} = useSecurityContext()
    const feedbackHref = `https://docs.google.com/forms/d/e/1FAIpQLSc9ICTtLVkIxZPEAkwlLIKjiw05UVfhB5WmlhOGZC5WKXTwrg/viewform?usp=pp_url&entry.879531967=${user.email}`
    setTimeout(()=>{
        window.location.href = feedbackHref
    }, 3000)

    return <div className={cssStyles.main}>
        <h1>Customer Feedback</h1>
        <p>Do you have any feedback for us? We would love to hear from you.</p>
        <p>Redirecting you to our feedback Google Form...</p>
        <p>If you are not redirected in a few seconds, please click <a href={feedbackHref}>here</a>.</p>
    </div>

}
