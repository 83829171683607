import cssStyles from "./ComplexityReport.module.css"

import ReportMenuBar from "./ReportMenuBar";
import Logger from "../../../utils/Logger";
import {REPORTS} from "./ReportConstants";

const LOGGER = new Logger("ComplexityReport")

export default function ComplexityReport({user}) {

    LOGGER.debug("entering ComplexityReport")

    return (
        <div className={cssStyles.main}>
            <ReportMenuBar
                user={user}
                report={REPORTS.complexityReport}
            />
            <p>Work in progress :)</p>
        </div>
    )

}
