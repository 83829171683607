import cssStyles from "./SetPassword.module.css"
import {Button, InputAdornment} from "@mui/material";
import React, {useEffect} from "react";
import InfoBox from "../../InfoBox/InfoBox";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PasswordTextField from "./PasswordTextField";
import {useSecurityContext} from "../../../security/SecurityContext";
import {useStatusMessage} from "../../StatusMessenger/StatusMessageProvider";
import {Kenchiku} from "../../Kenchiku/Kenchiku";

function hasValidFormat(password) {
    return (password && password.length >= 12 &&
        password.match(/[a-z]/) &&
        password.match(/[A-Z]/) &&
        password.match(/[0-9]/) &&
        password.match(/[^a-zA-Z\d]/))
}

export default function SetPassword() {

    const {setNewPassword} = useSecurityContext()

    const setNewMessage = useStatusMessage()

    const [password, setPassword] = React.useState("")
    const [passwordConfirm, setPasswordConfirm] = React.useState("")
    const [passwordValid, setPasswordValid] = React.useState(false)
    const [passwordConfirmValid, setPasswordConfirmValid] = React.useState(false)

    useEffect(() => {
        setPasswordValid(hasValidFormat(password))
        setPasswordConfirmValid(hasValidFormat(passwordConfirm) && password === passwordConfirm)
    }, [password, passwordConfirm])

    const handleSetNewPassword = async () => {
        if (password !== passwordConfirm) {
            setNewMessage("passwords don't match!", "alert")
            return
        }
        setNewPassword(password, passwordConfirm)
        setNewMessage("password set!", "info")
    }

    function passwordChangeHandler(event) {
        setPassword(event.target.value)
    }

    function passwordConfirmChangeHandler(event) {
        setPasswordConfirm(event.target.value)
    }

    return <div className={cssStyles.main}>
        <h1 data-testid={"page-title"}>Set Password</h1>
        <InfoBox>
            <p data-testid={"infobox-1"}>Set a password for your user-account. </p>
            <p data-testid={"infobox-2"}>This will be used to login to the <Kenchiku/> website. </p>
            Making sure:
            <div className={cssStyles.listWrapper}>
                <ul className={cssStyles.list}>
                    <li>It's <b>at least <span style={{color: "green"}}>12 characters long</span></b></li>
                    <li>has <b>at least one <span style={{color: "green"}}>number</span></b></li>
                    <li>has <b>at least one <span style={{color: "green"}}>uppercase letter</span></b></li>
                    <li>has <b>at least one <span style={{color: "green"}}>lowercase letter</span></b></li>
                    <li>has <b>at least one <span style={{color: "green"}}>special character</span></b></li>
                    <li>not to reuse the same password as you use for other websites</li>
                </ul>
            </div>
        </InfoBox>
        <p>
            <form className={cssStyles.form}>
                <div className={cssStyles.fieldDiv}>
                    <PasswordTextField
                        testid={"password-field-1"}
                        className={cssStyles.textField}
                        label={"password"}
                        value={password}
                        onChange={passwordChangeHandler}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><VisibilityIcon/></InputAdornment>,
                        }}
                        allowShowPassword={true}
                    />
                    {passwordValid && <CheckCircleIcon className={cssStyles.validIcon} />}
                    {!passwordValid && <UnpublishedIcon className={cssStyles.invalidIcon} />}
                </div>
                <div className={cssStyles.fieldDiv}>
                    <PasswordTextField
                        testid={"password-field-2"}
                        className={cssStyles.textField}
                        label={"password confirmation"}
                        value={passwordConfirm}
                        onChange={passwordConfirmChangeHandler}
                        allowShowPassword={false}
                    />
                    {passwordConfirmValid && <CheckCircleIcon className={cssStyles.validIcon} />}
                    {!passwordConfirmValid && <UnpublishedIcon className={cssStyles.invalidIcon} />}
                </div>
                <div className={cssStyles.buttonDiv}>
                    <Button data-testid={"set-password-button"} className={cssStyles.button} disabled={!(passwordValid && passwordConfirmValid)} onClick={handleSetNewPassword} variant="contained">Set Password</Button>
                </div>
            </form>
        </p>
    </div>
}
