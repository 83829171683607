import * as jointjs from "@joint/plus";
import {download} from "../utils/DiagramUtils";
import Logger from "../../../../utils/Logger";

const LOGGER = new Logger('talShareOptions.js');

export function talShareOptions(environmentInfo) {
    LOGGER.debug("talShareOptions: ", environmentInfo)
    return  [
        {
            format: "PNG",
            label: "Export as PNG image",
            executeOption: async (paper)=>{
                jointjs.format.toPNG(paper, (dataURL) => {
                    download(dataURL, 'graph.png');
                }, {grid:true});
            }
        }
    ]
}
