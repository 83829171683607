import React, {createContext, useContext, useEffect, useState} from 'react';
import Logger from "../../utils/Logger";

const LOGGER = new Logger("EnvironmentInfoProvider")

const EnvironmentInfoContext = createContext();

export const useEnvironmentInfo = () => {
    return useContext(EnvironmentInfoContext);
};

export const EnvironmentInfoProvider = ({ children }) => {
    LOGGER.debug("EnvironmentInfoProvider() - ENTER")

    const [environmentInfo, setEnvironmentInfo] = useState(null);
    const [inProduction, setInProduction] = useState(false);
    const [inStaging, setInStaging] = useState(false);
    const [inLocal, setInLocal] = useState(false);

    useEffect(()=>{
        LOGGER.debug("useEffect() - ENTER")
        fetch("/.netlify/functions/get-environment")
            .then(res=> {
                LOGGER.debug("res:", res)
                return res.json()
            })
            .then(jsonData=>{
                LOGGER.debug("get-environment.json: ", jsonData)
                setEnvironmentInfo(jsonData || {})
                setInProduction(jsonData?.IS_LOCAL || jsonData?.IS_DEV)
                setInStaging(jsonData?.IS_DEV)
                setInLocal(jsonData?.IS_LOCAL)
            })
            .catch(error=>LOGGER.error("Error fetching environment name.", error))
    }, [])

    return (
        <EnvironmentInfoContext.Provider value={{environmentInfo, inProduction, inStaging, inLocal}}>
            {children}
        </EnvironmentInfoContext.Provider>
    );
};
