import {createContext, useContext, useEffect, useState} from "react";
import Logger from "../utils/Logger";
import DataLoader from "../DataLoader";
import {addAuthorizationHeader} from "../utils/Api";
import {useSecurityContext} from "./SecurityContext";
import {useLoadingOverlay} from "../components/LoadingOverlay/LoadingOverlay";

const LOGGER = new Logger("WorkspaceContext", 2)

const WorkspaceContext = createContext();

export const useWorkspaceContext = () => {
    return useContext(WorkspaceContext);
}




export function WorkspaceContextProvider({children}) {

    const {show, hide} = useLoadingOverlay()
    const {user} = useSecurityContext()

    const [workspaces, setWorkspaces] = useState([]); // [{id: "123", name: "workspace1"}, {id: "456", name: "workspace2"}
    const [areWorkspacesLoading, setAreWorkspacesLoading] = useState(false);
    const [selectedWorkspace, setSelectedWorkspace] = useState(null);
    const [errorLoadingMessage, setErrorLoadingMessage] = useState(null);

    useEffect(() => {
        LOGGER.trace("initializing workspace context")
    });

    useEffect(() => {

        if (!user) {
            LOGGER.debug("no user")
        } else {
            LOGGER.debug("Got user, loading workspaces...", user)
            const fetchData = async function () {
                setAreWorkspacesLoading(true)
                const dataLoader = new DataLoader()
                const workspaceNodes = await dataLoader.fetchWorkspaces(user, (workspaces)=>{
                    LOGGER.debug("workspaces loaded: ", workspaces)
                    setWorkspaces(workspaces)
                    setAreWorkspacesLoading(false)
                }, (error)=>{
                    const zeErrorLoadingMessage = <><span>Error Loading Workspaces </span><span className={"retryLink"} onClick={window.location.reload(true)}>retry</span></>
                    setErrorLoadingMessage(zeErrorLoadingMessage)
                }).finally(()=> {
                    setAreWorkspacesLoading(false)
                })
                LOGGER.debug("loaded workspaceNodes.")
                LOGGER.trace("workspaceNodes: ", workspaceNodes)
                //otherwise it crashes, and we don't want that...
                setWorkspaces((Array.isArray(workspaceNodes)?workspaceNodes:[]))
            }
            fetchData()
        }
        //i know:  missing dependency: 'navigate'. Either include it or remove the dependency array  react-hooks/exhaustive-deps
        //but if i add it, it triggers a reload of the workspace on every navigate :s
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    function createWorkspace() {}

    function closeWorkspace(workspace) {
        LOGGER.debug("Closing workspace: ", workspace)
        show("closing the '" + workspace.name + "' workspace")

        fetch("/.netlify/functions/manage-workspaces",  user && {
            // only attach headers if user is logged in, but still make the request regardless
            headers: addAuthorizationHeader({}, null, user),
            method: "PUT",
            body: JSON.stringify({action:"close", id:workspace?.id})
        })
            .then(res =>res.json())
            .then(async data => {
                console.debug("data:", data)
                //everything went wel, remove the workspace from the list
                setWorkspaces(oldList=>oldList.filter(acc=>acc.id !== workspace.id))
            })
            .catch((e)=>{
                console.error("Error cloasing workspace: ", e)
                alert("Error closing workspace:", e?.getMessage())
            }).finally(()=>hide())
    }
    function leaveWorkspace(workspace) {
        LOGGER.debug("Leaving workspace: ", workspace)
        show("leaving the '" + workspace.name + "' workspace")

        fetch("/.netlify/functions/manage-workspaces",  user && {
            // only attach headers if user is logged in, but still make the request regardless
            headers: addAuthorizationHeader({}, null, user),
            method: "PUT",
            body: JSON.stringify({action:"leave", id:workspace?.id})
        })
            .then(res =>res.json())
            .then(async data => {
                console.debug("data:", data)
            })
            .catch((e)=>{
                console.error("Error leaving workspace: ", e)
            }).finally(()=>hide())
    }

    function selectWorkspace(workspace) {
        LOGGER.debug("selecting workspace: ", workspace)

        if (!workspace || workspace.trim() === "") {
            LOGGER.error("no workspace provided!")
            return
        }

        if (selectedWorkspace && !workspaces?.find(workspace=>workspace.id===selectedWorkspace)) {
            LOGGER.error("workspace not found in workspaces list!")
            if (workspaces.length > 0) {
                LOGGER.error("selecting first workspace in list")
                setSelectedWorkspace(workspaces[0].id)
            }
            return
        }
        setSelectedWorkspace(workspace)
    }

    return <WorkspaceContext.Provider value={{
        workspaces, selectedWorkspace, selectWorkspace,
        createWorkspace, closeWorkspace, leaveWorkspace,
        areWorkspacesLoading, errorLoadingMessage
    }}>
        {children}
    </WorkspaceContext.Provider>
}
