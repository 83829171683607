import cssStyles from "./ClickableInlineObjectList.module.css"
import {useModel} from "../../model/ModelContext";
import {useSelectedNodes} from "../SelectedNodes/SelectedNodesProvider";
import {NODE_FILL_COLORS} from "../../utils/NodeTypeColors";
import Logger from "../../utils/Logger";

const LOGGER = new Logger("ClickableInlineObjectList")

export function ClickableInlineObjectList({listName="unknown", idList}) {
    const {getNodeById} = useModel()
    const {setSoftSelectedNodeById} = useSelectedNodes()

    if (!idList) {
        LOGGER.warn("idList is null for listName: ", listName)
        return <></>
    }

    let objectList = idList?.map(id => getNodeById(id))

    if (!objectList) {
        LOGGER.warn("objectList is null for listName: ", listName)
        return <></>
    }
    const onClick = (objectId) => {
        LOGGER.debug("Clicked on objectId: ", objectId)
        setSoftSelectedNodeById(objectId)
    }
    return (
        objectList.map((object, index) => {
            if (!object) {
                LOGGER.warn("object passed is null for listName: ", listName)
                return <></>
            }

            const color = NODE_FILL_COLORS[object.type]
            return (
                <span key={listName + "-" + index} className={cssStyles.objectListName} style={{color:color}} onClick={() => onClick(object.id)}>{object.name}</span>
            )
        })
    )
}
