import cssStyles from './RecoverAccountPage.module.css';
import Logger from "../../../utils/Logger";

import {useParams} from "react-router";
import {useEffect, useState} from "react";
import {useEnvironmentInfo} from "../../EnvironmentProvider/EnvironmentInfoProvider";
import {useSecurityContext} from "../../../security/SecurityContext";
import {useStatusMessage} from "../../StatusMessenger/StatusMessageProvider";
import {useNavigate} from "react-router-dom";
import {CircularProgress} from "@mui/material";


const LOGGER = new Logger("RecoverAccountPage")

export default function RecoverAccountPage() {
    LOGGER.debug("Enter - RecoverAccountPage")

    const { token } = useParams()

    const [confirming, setConfirming] = useState(true)
    const [error, setError] = useState(false)
    const [confirmed, setConfirmed] = useState(false)

    const {environmentInfo} = useEnvironmentInfo()
    const {signinWithUser} = useSecurityContext()
    const setNewMessage = useStatusMessage()

    const navigate = useNavigate();

    try {

        useEffect(() => {

            if (confirmed) {
                LOGGER.debug("Account already recovered")
                return
            }

            if (error) {
                LOGGER.debug("Error in account recovery")
                return
            }

            if (!environmentInfo) {
                LOGGER.debug("environmentInfo is not available yet")
                return
            }
            LOGGER.debug("environmentInfo set, recovering account")
            fetch(
                `/.netlify/functions/recover-account`,
                {
                    method: 'POST',
                    body: JSON.stringify({token: token}),
                    headers: {'Content-Type': 'application/json'}
                }
            ).then(response => {
                LOGGER.debug("response:", response)
                if (response.status === 200 || response.status === 204) {
                    LOGGER.debug("response status OK")
                    return response.json()
                } else {
                    LOGGER.debug("response status KO")
                    throw new Error(`Error: ${response.status}`)
                }
            }).then(userResponse => {
                LOGGER.debug("response user:", userResponse)
                const user = userResponse?.user
                LOGGER.debug("user:", user)
                if (user) {
                    LOGGER.debug("Got a user object, signing in")
                    signinWithUser(user)
                    setConfirming(false)
                    setConfirmed(true)
                    setError(false)
                    setNewMessage("Account recovered. You are being signed in.", "info")
                    navigate("/set-password")
                } else {
                    LOGGER.debug("No user in the response")
                    setConfirming(false)
                    setConfirmed(false)
                    setError(true)
                    setNewMessage("Account recovery failed. Please try again.", "alert")
                }
            }).catch(error => {
                LOGGER.error("Error while recovering account", error)
                setConfirming(false)
                setConfirmed(false)
                setError(true)
                setNewMessage("Account receovery failed. Please try again.", "alert")
            })
            // eslint-disable-next-line
        }, [environmentInfo]);
    } catch (e) {
        LOGGER.error("Error in RecoverAccountPage", e)
    }

    return <div className={cssStyles.mainDiv}>
        <div className={cssStyles.formDiv}>
            {confirming ?
                <>
                    <h1>Recovering account...</h1>
                    <CircularProgress/>
                </>
                :
                (error?
                    <>
                        <h1>Account recovery failed</h1>
                        <p>Please try again.</p>
                    </> : <>
                        <h1>Account recovery succeeded</h1>
                        <p>Your account has been recovered. Please set a new password.</p>
                    </>
                )
            }
        </div>
    </div>
}
