import * as React from "react";
import Logger from "../../../utils/Logger";
import cssStyles from "./MiddlewareDiagram.module.css"
import {useState} from "react";
import {Tree, TreeNode} from "react-organizational-chart";
import {Checkbox, FormControlLabel} from "@mui/material";
import {useModel} from "../../../model/ModelContext";
import {useSelectedNodes} from "../../SelectedNodes/SelectedNodesProvider";
import {Card} from "./Card";
import {NodeLabel} from "./NodeLabel";

const LOGGER = new Logger("MiddlewareDiagram")

function buildTree(searchNodes, node, level) {
    if (level > 20) {
        LOGGER.error("buildTree: too many levels!")
        return null
    }
    let mwChildren =  []
    return {
        id: node.id,
        name: <NodeLabel node={node}/>,
        type: node.type,
        children: [...mwChildren],
        level: level,
    }
}

function buildTreeNode(node, setSelectedNodeById, selectedNodes) {
    return <TreeNode label={<Card selectedNodes={selectedNodes} node={node} level={node.level} handleNodeClick={()=>setSelectedNodeById(node.id)}/>} >
        {node.children.map((c)=>buildTreeNode(c, setSelectedNodeById, selectedNodes))}
    </TreeNode>
}

export default function MiddlewareDiagram({node}) {

    const {selectedNodes, setSelectedNodeById} = useSelectedNodes()

    const {searchNodes} = useModel()

    const tree = buildTree(searchNodes, node, 0, true)

    return (
        <div key={'cd-'+node.id} className={[cssStyles.main, ].join(" ")}>
            {node?.name}
            <hr/>
            <Tree label={<div>Middleware</div>}>
                {buildTreeNode(tree, setSelectedNodeById, selectedNodes)}
            </Tree>
        </div>);
}
