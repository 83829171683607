import Logger from "./Logger"
import {WORKSPACE_AUTHENTICATION_HEADER} from "../model/Constants";

const LOGGER = new Logger("Api")

export function addAuthorizationHeader(headers, workspaceId, user) {
    LOGGER.debug("addAuthorizationHeader.workspaceId: ", workspaceId)
    LOGGER.trace("addAuthorizationHeader.user: ", user)
    const newHeaders =  {
        ...headers, //take over the existing headers
    }
    if (user) {
        if (!user.token) {
            LOGGER.error("user.token is null for user: ", JSON.stringify(user))
            return false
        }
        newHeaders['Authorization'] = "Bearer " + user.token.access_token //add the authorization header
    }
    if (workspaceId) {
        newHeaders[WORKSPACE_AUTHENTICATION_HEADER] = workspaceId
    }
    LOGGER.trace("user: ", user)
    LOGGER.trace("headers: ", headers)
    LOGGER.trace("newHeaders: ", newHeaders)
    return newHeaders
}
