import cssStyles from "./IdNameDescriptionObjectList.module.css"
import {Tooltip} from "@mui/material";

function TruncateWithLink({text, length = 50, moreText = "...", ommission="...", tooltipText, onClick}) {

    const zeTooltipText = tooltipText || text

    if (text?.length > length) {
        return (<Tooltip title={zeTooltipText}>
            {text.substring(0, length)}{ommission}
        </Tooltip>)
    }
    return <Tooltip title={zeTooltipText}>
        {text}
    </Tooltip>;
}

export function IdNameDescriptionObjectList({objectList, tooltipGenerator}) {

    let zeTooltipGenerator = tooltipGenerator

    if (!zeTooltipGenerator) {
        zeTooltipGenerator = (object) => {
            return object.description
        }
    }

    return (
        <ul className={cssStyles.objectList}>
            {objectList.map((object, index) => {

                return (
                    <li key={index} className={cssStyles.objectListItem}>
                        <div className={cssStyles.objectListNameDiv}>{object.name}: </div>
                        {object.description && <div className={cssStyles.objectListDescriptionDiv}>
                            <TruncateWithLink text={object.description} moreText={"[hover for more]"} tooltipText={zeTooltipGenerator(object)}/>
                        </div>}
                    </li>
                )
            })}
        </ul>
    )
}
