
export const getReportNames = function() {
    return Object.keys(REPORTS)
}
export const getReportTopics = function() {
    return Object.values(REPORTS)
}

export const REPORTS = {
    quickInsightsReport: {
        name:"quickInsightsReport",
        title: "Quick Insights Report",
        description: "Reports on some quick insights like the overall complexity or cost of the architecture.",
        link: "/reports/quick-insights-report"
    },
    qualityReport: {
        name:"qualityReport",
        title: "Quality Report",
        description: "Reports on how complete and well the different objects have been documented.",
        link: "/reports/quality-report"
    },
    countReport: {
        name:"countReport",
        title: "Count Report",
        description: "Reports on how many objects have been created",
        link: "/reports/count-report"
    },
    applicationCostReport: {
        name:"applicationCostReport",
        title: "Application Cost Report",
        description: "Shows the cost of applications in a tree map format",
        link: "/reports/application-cost-report"
    },
    /* TODO
    complexityReport: {
        name:"complexityReport",
        title: "Complexity Report",
        description: "Shows the complexity of applications & business processes",
        link: "/reports/complexity-report"
    },

     */
    dependencyReport: {
        name:"dependencyReport",
        title: "Dependency Report",
        description: "Shows the dependencies between all the objects and allows you to navigate them",
        link: "/reports/dependency-report"
    },

}
