import * as React from "react";
import Logger from "../../../utils/Logger";
import cssStyles from "./PrincipleDiagram.module.css"
import {Tree, TreeNode} from "react-organizational-chart";
import {NodeType} from "../../../model/Constants";
import {useModel} from "../../../model/ModelContext";
import {useSelectedNodes} from "../../SelectedNodes/SelectedNodesProvider";
import {Card} from "./Card";
import {NodeLabel} from "./NodeLabel";

const LOGGER = new Logger("PrincipleDiagram")

function buildTree(searchNodes, node, level) {

    if (level > 20) {
        LOGGER.error("buildTree: too many levels!")
        return null
    }
    const principleChildren = searchNodes((n)=>n.parentId===node.id)
        .map(c=>buildTree(searchNodes, c, level+1)).filter(n=>n!==null)
    let designDecisionChildren =  []
    designDecisionChildren = searchNodes((n)=>n.type===NodeType.DesignDecision.description && n.principleIds?.includes(node.id))
        .map(c=>buildTree(searchNodes, c, level+1)).filter(n=>n!==null)
    return {
        id: node.id,
        name: <NodeLabel node={node}/>,
        type: node.type,
        children: [...principleChildren, ...designDecisionChildren],
        level: level,
    }
}

function buildTreeNode(node, setSelectedNode, selectedNodes) {
    return <TreeNode label={<Card selectedNodes={selectedNodes} node={node} level={node.level} handleNodeClick={()=>setSelectedNode(node)}/>} >
        {node.children.map((c)=>buildTreeNode(c, setSelectedNode, selectedNodes))}
    </TreeNode>
}

export default function PrincipleDiagram({node}) {

    const {searchNodes} = useModel()
    const {selectedNodes, setSelectedNode} = useSelectedNodes()

    const tree = buildTree(searchNodes, node, 0)

    return (
        <div key={'pd-'+node.id} className={[cssStyles.main, ].join(" ")}>
            {node?.name}
            <hr/>
            <div className={cssStyles.filterPane}>
            </div>
            <Tree label={<div>Principle</div>}>
                {selectedNodes && buildTreeNode(tree, setSelectedNode, selectedNodes)}
            </Tree>
        </div>);
}
