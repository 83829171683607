import Xarrow from "react-xarrows";

export default function HelpArrow({startId, endId, startRef, endRef}) {
    let start = (startId?startId:startRef)
    let end = (endId?endId:endRef)
    return <Xarrow
        key={"help-arrow-" + (start) + "-" + (end)}
        zIndex={5000}
        start={start} //can be html ID or React REF
        end={end} //can be html ID or React REF
        startAnchor={"auto"}
        endAnchor={"auto"}
        lineColor={"lightgray"}
        headColor={"lightgray"}
    />
}

/*
<Xarrow
                            key={'xarrow-' + view.id + "-" + connector.id}
                            start={Constants.ID_PREFIX_APP + view.id + "-" + connector.sourceApplicationComponentId}
                            end={Constants.ID_PREFIX_APP + view.id + "-" + connector.targetApplicationComponentId}
                            startAnchor={"auto"}
                            endAnchor={"auto"}
                            labels={{middle: <ArrowLabel key={'label-' + view.id + "-" + connector.id} connector={connector} onNodeSelected={(nodeId)=>onSelectNodeCallback(nodeId)}/>}}
                            arrowBodyProps={{
                                onClick: () => onSelectNodeCallback(connector.id)
                            }}
                            lineColor={"lightgray"}
                            headColor={"lightgray"}
                        />
 */
