import cssStyles from "./BandedRenderer.module.css";
import {HtmlTooltip} from "../Tooltips/Tooltips";

function Capability({capability, index, depth, searchTerm}) {

    let bandStyles = [
        cssStyles.band01,
        cssStyles.band02,
        cssStyles.band03,
        cssStyles.band04,
        cssStyles.band05,
        cssStyles.band06,
        cssStyles.band07,
        cssStyles.band08,
        cssStyles.band09,
        cssStyles.band10,
    ]

    const classNames = [(depth%2===0?cssStyles.horizontallyPositionedBand:cssStyles.verticallyPositionedBand)]
    if (depth === 0) {
        classNames.push(bandStyles[index % bandStyles.length])
    } else {
        classNames.push(cssStyles.subBand)
    }
    if (searchTerm?.length > 0 && capability.name.toLowerCase().includes(searchTerm?.toLowerCase())) {
        classNames.push(cssStyles.highlighted)
    } else {
        classNames.push(cssStyles.nonHighlighted)
    }

    let title = <b>{capability?.name}</b>
    if (capability?.description) {
        title = <span><b>{capability?.name}</b> - {capability?.description}</span>
    }

    return <div key={"div-" + capability.id}
                className={classNames.join(" ")}>
        <HtmlTooltip title={title}>
            <span className={cssStyles.bandTitle}>{capability?.name?.replaceAll("/", " / ")}</span>
        </HtmlTooltip>
        {capability?.subCapabilities?.map((sc, scIndex) => {

            return <Capability
                capability={sc}
                index={scIndex}
                depth={depth + 1}
                searchTerm={searchTerm}
            />
        })}
    </div>
}

export function BandedRenderer({searchTerm, map}) {

    if (!map) {
        return <p>loading...</p>
    }



    return (
        <div className={cssStyles.map}>
            <div colSpan={map.width} className={cssStyles.mapRow}>
                {map.capabilities?.map((capability, index) => {
                    return <Capability
                        capability={capability}
                        index={index}
                        depth={0}
                        searchTerm={searchTerm}
                    />
                })}
                </div>
        </div>
    )
}
