import {MATURITY_MODEL_LIST} from "../model/Constants";

import NapierMaturityModel from "./maturitymodeltemplates/NapierAML";

export function buildAsisToBeId(pillarId, dimensionId) {
    return [pillarId, dimensionId].join("+")
}

export function getPillarAndDimensionId(asIsToBeId) {
    let split = asIsToBeId.splice("+")
    if (split?.length !== 2) {
        return null
    }
    return {
        pillarId: split[0],
        dimensionId: split[1]
    }
}

export function getLevelFontColor(level) {
    switch (level?.toLowerCase()) {
        case "initial":
            return "#FFFFFF";
        case "improving":
            return "#FFFFFF";
        case "managed":
            return "#000000";
        case "mature":
            return "#000000";
        case "leading":
            return "#FFFFFF";
        default:
            return "#000000";

    }
}
export function getLevelBackgroundColor(level) {

    switch (level?.toLowerCase()) {
        case "initial":
            return "#FB0008";
        case "improving":
            return "#B20004";
        case "managed":
            return "#FDA4A8";
        case "mature":
            return "#DCEDD0";
        case "leading":
            return "#447129";
        default:
            return "#DDDDDD";

    }
}

export function getMaturityModelTemplateById(maturityModelId) {
    switch (maturityModelId) {
        case "Napier":
            return NapierMaturityModel
        default:
            return null
    }
}
export function getMaturityModelTemplateIds() {
    return MATURITY_MODEL_LIST.map(m => m.id)
}

export function checkIsMaturityModelTemplateId(id) {
    return getMaturityModelTemplateIds().includes(id)
}
