import * as React from "react";
import Logger from "../../../utils/Logger";
import cssStyles from "./DesignDecisionDiagram.module.css"
import {Tree, TreeNode} from "react-organizational-chart";
import {NodeType} from "../../../model/Constants";
import {useModel} from "../../../model/ModelContext";
import {useSelectedNodes} from "../../SelectedNodes/SelectedNodesProvider";
import {Card} from "./Card";
import {NodeLabel} from "./NodeLabel";

const LOGGER = new Logger("DesignDecisionDiagram")

function buildTree(searchNodes, node, level) {
    if (level > 20) {
        LOGGER.error("buildTree: too many levels!")
        return null
    }
    const designDecisionChildren = searchNodes((n)=>n.parentId===node.id)
        .map(c=>buildTree(searchNodes, c, level+1)).filter(n=>n!==null)
    let principleChildren = searchNodes((n)=>n.type===NodeType.Principle.description && node.principleIds?.includes(n.id))
        .map(c=>buildTree(searchNodes, c, level+1)).filter(n=>n!==null)
    return {
        id: node.id,
        name: <NodeLabel node={node}/>,
        type: node.type,
        children: [...designDecisionChildren, ...principleChildren],
        level: level,
    }
}

function buildTreeNode(node, handleNodeClick, selectedNodes) {
    return <TreeNode label={<Card selectedNodes={selectedNodes} node={node} level={node.level} handleNodeClick={()=>handleNodeClick(node.id)}/>} >
        {node.children.map((c)=>buildTreeNode(c, handleNodeClick, selectedNodes))}
    </TreeNode>
}

export default function DesignDecisionDiagram({node}) {

    const {searchNodes} = useModel()
    const {setSelectedNode, selectedNodes} = useSelectedNodes()

    const tree = buildTree(searchNodes, node, 0)

    function handleNodeClick(nodeId) {
        LOGGER.debug("handleNodeClick:", nodeId)
        setSelectedNode(nodeId)
    }

    return (
        <div key={'cd-'+node.id} className={[cssStyles.main, ].join(" ")}>
            {node?.name}
            <hr/>
            <div className={cssStyles.filterPane}>
            </div>
            <Tree label={<div>Design Decision</div>}>
                {buildTreeNode(tree, handleNodeClick, selectedNodes)}
            </Tree>
        </div>);
}
