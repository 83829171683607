import Papa from "papaparse"
import {addAuthorizationHeader} from "../../utils/Api"
import Logger from "../../utils/Logger"
//import {read as xslx_read, utils as xslx_utils} from "xlsx"
import {NodeFolderRootIds, NodeTypeName} from "../../model/Constants";
import * as XLSX from "xlsx";

const LOGGER = new Logger("UploadUtils")

const ROOT_OBJECT_TYPES = {
    "_actors":"actor",
    "_applications":"application",
    "_architecture_building_blocks":"architecture_building_block",
    "_business_processes":"business_process",
    "_capabilities":"capability",
    "_capability_analysis":"capability_analysis",
    "_domains":"domain",
    "_data_flows":"data_flow",
    "_data_objects":"data_object",
    "_functionalities":"functionality"
}

export function getObjectType(rootFolderId) {
    LOGGER.debug("rootFolderId:", rootFolderId)
    LOGGER.debug("ROOT_OBJECT_TYPES[rootFolderId]:", ROOT_OBJECT_TYPES[rootFolderId])
    return ROOT_OBJECT_TYPES[rootFolderId]
}

export function buildImportEndpoint(objectTypeOrId) {
    switch (objectTypeOrId) {
        case NodeTypeName.Actor:
        case NodeFolderRootIds.ActorRootId.description:
            return '.netlify/functions/import-actors-objects'
        case NodeTypeName.Application:
        case NodeFolderRootIds.ApplicationRootId.description:
            return '.netlify/functions/import-applications-objects'
        case NodeTypeName.ArchitectureBuildingBlock:
        case NodeFolderRootIds.ArchitectureBuildingBlockRootId.description:
            return '.netlify/functions/import-architecture-building-blocks-objects'
        case NodeTypeName.BusinessProcess:
        case NodeFolderRootIds.BusinessProcessRootId.description:
            return '.netlify/functions/import-business-processes-objects'
        case NodeTypeName.Capability:
        case NodeFolderRootIds.CapabilityRootId.description:
            return '.netlify/functions/import-capabilities-objects'
        case NodeTypeName.DataExchange:
        case NodeFolderRootIds.DataExchangeRootId.description:
            return '.netlify/functions/import-data-exchanges-objects'
        case NodeTypeName.DataFlow:
        case NodeFolderRootIds.DataFlowRootId.description:
            return '.netlify/functions/import-data-flows-objects'
        case NodeTypeName.DataObject:
        case NodeFolderRootIds.DataObjectRootId.description:
            return '.netlify/functions/import-data-objects-objects'
        case NodeTypeName.Domain:
        case NodeFolderRootIds.DomainRootId.description:
            return '.netlify/functions/import-domains-objects'
        case NodeTypeName.Functionality:
        case NodeFolderRootIds.FunctionalityRootId.description:
            return '.netlify/functions/import-functionalities-objects'
        case NodeTypeName.ReferenceArchitecture:
        case NodeFolderRootIds.ReferenceArchitectureRootId.description:
            return '.netlify/functions/import-reference-architectures-objects'
        case NodeTypeName.Scenario:
        case NodeFolderRootIds.ScenarioRootId.description:
            return '.netlify/functions/import-scenarios-objects'
        default:
            LOGGER.debug("no url for type: ", objectTypeOrId)
            return null

    }

}

export async function uploadRows(workspaceId, user, objectType, fieldsObject, rows) {
    let filteredRows = rows.filter(r => r.data[0] && r.data[1]).map(row => row.data);
    if (filteredRows.length !== 0) {
        filteredRows.unshift(fieldsObject)
        LOGGER.debug("uploading rows: ", filteredRows)

        const csvData = Papa.unparse(filteredRows, {delimiter: ";"})
        LOGGER.debug("csvData:", csvData)
        const importUrl = "/" + buildImportEndpoint(objectType)
        LOGGER.debug("importUrl: ", importUrl)
        await fetch(importUrl, {
            headers: addAuthorizationHeader({
                "Content-Type": "application/json"
            }, workspaceId, user),
            method: 'POST',
            body: csvData
        }).catch((e) => {
            LOGGER.error("Error uploading...")
        })
        return (filteredRows.length - 1 /*don't count the header that was added!*/)
    } else {
        LOGGER.debug("no rows to upload")
        return 0
    }

}
/*
async function processSheet(workspaceId, user, workbook, sheetName, onProgress) {

    return new Promise(async (resolve, reject) => {
        let rows = []
        let fieldsObject = null
        let totalCount = 0
        const csvParsingConfig = {
            delimiter: ";",
            newLine: "\r\n",
            quoteChar: "\"",
            header: false,
            worker: true,
            step: function (row) {
                LOGGER.trace("Row:", row.data);
                if (fieldsObject === null) {
                    //first row is the header
                    fieldsObject = row.data
                    LOGGER.debug("fieldsObject: ", fieldsObject)
                    return
                }

                rows.push(row)
            },
            complete: async function () {
                LOGGER.debug("rows in complete function: ", rows);
                const rowCount = (rows ? rows.length : 0)

                while (rows.length > 0) {
                    const subsetRows = rows.splice(0, 10)

                    await uploadRows(workspaceId, user, sheetName?.toLowerCase(), fieldsObject, subsetRows)

                    totalCount += subsetRows.length

                    if (onProgress) {
                        onProgress('"' + sheetName + "' sheet -> processed " + totalCount + " rows out of " + rowCount)
                    }
                }

                LOGGER.debug("Processed " + totalCount + " rows...");
                onProgress("Processed " + totalCount + " rows...")
                resolve(totalCount)
            },
            error: reject
        }
        onProgress("Processing sheet: " + sheetName)
        const sheet = workbook.Sheets[sheetName]
        const csvRows = XLSX.utils.sheet_to_csv(sheet, {RS: "\n", FS: ";", forceQuotes: true})

        await Papa.parse(csvRows, csvParsingConfig)
        LOGGER.debug("csvRows (promise) [" + sheetName + "]: ", csvRows)
        return csvRows
    })

}
*/
export async function uploadFile(workspaceId, user, fileType, objectTypeName, file, onReady, onError, onProgress) {

    LOGGER.debug("uploadFile called")
    LOGGER.debug("workspaceId: ", workspaceId)
    LOGGER.debug("user: ", user)
    LOGGER.debug("fileType: ", fileType)
    LOGGER.debug("objectTypeName: ", objectTypeName)
    LOGGER.debug("file: ", file)


    if (fileType === "xlsx") {

        /* file is a File */
        const data = await file.arrayBuffer();
        /* data is an ArrayBuffer */
        const workbook = XLSX.read(data);
        LOGGER.debug("workbook read: ", workbook)
        try {

            let jsonWorkbook = {sheets:{}}
            workbook?.SheetNames?.forEach((sheetName) => {
                LOGGER.debug("sheetName: ", sheetName)
                const theSheet = workbook.Sheets[sheetName]
                LOGGER.debug("theSheet: ", theSheet)
                if (theSheet) {
                    const jsonData = XLSX.utils.sheet_to_json(theSheet);
                    // Convert attribute names to lowercase
                    LOGGER.debug("jsonData: ", jsonData)
                    jsonWorkbook.sheets[sheetName] = jsonData
                } else {
                    LOGGER.debug("no sheet found for sheetName: ", sheetName)
                }
            })
            LOGGER.debug("jsonWorkbook: ", jsonWorkbook)
            //now fetch POST this to /.netlify/functions/import-model-objects
            fetch('/.netlify/functions/import-model-objects', {
                headers: addAuthorizationHeader({
                    "Content-Type": "application/json"
                }, workspaceId, user),
                method: 'POST',
                body: JSON.stringify(jsonWorkbook)
            }).then((res) => res.json())
            .then((json) => {
                LOGGER.debug("response: ", json)
                if (json.counts) {
                    onReady(json)
                } else {
                    if (json.message) {
                        onError(json.message)
                    } else {
                        onError("Error uploading...")
                    }
                }
            }).catch((e) => {
                LOGGER.error("Error uploading: ", e)
                onError("Error uploading...")
            })

        } catch (e) {
            console.error("Error parsing sheets: ", e)
        }

    }

    if (fileType === "csv") {
        let rows = []
        let fieldsObject = null

        const csvParsingConfig = {
            delimiter: ";",
            newLine: "\n",
            quoteChar: "'",
            header: false,
            worker: true,
            step: function (row) {
                //LOGGER.trace("Row:", row.data);
                if (fieldsObject === null) {
                    //first row is the header
                    fieldsObject = row.data
                    LOGGER.debug("fieldsObject: ", fieldsObject)
                    return
                }
                rows.push(row)
            },
            complete: async function () {
                LOGGER.debug("rows: ", rows);
                const rowCount = (rows ? rows.length : 0)
                let totalCount = 0
                while (rows.length > 0) {
                    const subsetRows = rows.splice(0, 10)
                    let count = await uploadRows(workspaceId, user, getObjectType(objectTypeName), fieldsObject, subsetRows)
                    totalCount += count
                    if (onProgress) {
                        onProgress("processed " + totalCount + " rows out of " + rowCount)
                    }
                }
                LOGGER.debug("Processed " + totalCount + " rows...");
                onReady(totalCount)
            }
        }
        Papa.parse(file, csvParsingConfig);
    }

}
