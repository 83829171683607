import cssStyles from "../Intro.module.css";
import {Paper} from "@mui/material";
import SupportDocumentationLink from "../../../../utils/SupportDocumentationLink";

export default function ModelIntro() {
    return <div id={"intro"} className={cssStyles.introDiv}>
        <Paper className={cssStyles.paper}>
            <h2>The <span className={"logoname"}>kenchiku</span> Model</h2>
            <p>The pre-defined model allows us to create standard reports and do quality checks immediately after you've provided the data—no need to configure the tool first.</p>
            <div className={cssStyles.paragraph}>
                We have support for the following objects:
                <ul>
                    <li><b>Application</b> - any software that can run on its own</li>
                    <li><b>Capabilities</b> - something a business must be capable of doing to exist or survive</li>
                    <li><b>Data Exchanges</b> - an object representing data that is exchanged between two applications</li>
                    <li><b>Data Flows</b> - an object representing a flow of data between multiple applications</li>
                    <li><b>Data Object</b> - an object representing data that a business process or Application can access</li>
                    <li><b>TALs</b> - Target Application Landscapes to show which application implements which capability</li>
                    <li><b>Views</b> - To display applications and their dataflows</li>
                </ul>
                For more details on the subject, please refer to the <SupportDocumentationLink documentId={"The-Model-847a3f2e11d34570a50f7018aec9104a"}>The Model's support page</SupportDocumentationLink>.
            </div>
        </Paper>
    </div>
}
