import cssStyles from "./SplitterButtons.module.css";
import {IconButton} from "@mui/material";
import ThreePanesIcon from "./icons/SVG/Panes_1_2_3.svg";
import LeftPaneIcon from "./icons/SVG/Panes_1_2.svg";
import RightPaneIcon from "./icons/SVG/Panes_2_3.svg";
import CenterPaneIcon from "./icons/SVG/Pane_2.svg";
import FullscreenIcon from "./icons/SVG/FullScreen.svg";
import Logger from "../../utils/Logger";

const LOGGER = new Logger("SplitterButtons", 2)

export function SplitterButtons({className, selectedButton, buttonClicked}) {
    LOGGER.trace("SplitterButton - ENTER/EXIT")
    return <div className={[className, cssStyles.splitterButtonsDiv].join(" ")}>
        <IconButton
            className={(selectedButton===1?cssStyles.selectedIconButton:cssStyles.iconButton)}
            aria-label="delete"
            size={"small"}
            onClick={()=> {
                buttonClicked(1)
            }}
        >
            <img
                className={(selectedButton===1?cssStyles.selectedButton:"")}
                src={ThreePanesIcon} width={"30"} height={"20"} alt="Three Panes Icon"
            />
        </IconButton>
        <IconButton
            className={(selectedButton===2?cssStyles.selectedIconButton:cssStyles.iconButton)}
            aria-label="delete"
            size={"small"}
            onClick={()=>buttonClicked(2)}
        >
            <img
                className={(selectedButton===2?cssStyles.selectedButton:"")}
                src={LeftPaneIcon} width={"30"} height={"20"} alt="Three Panes Icon"
            />
        </IconButton>
        <IconButton
            className={(selectedButton===3?cssStyles.selectedIconButton:cssStyles.iconButton)}
            aria-label="delete"
            size={"small"}
            onClick={()=>buttonClicked(3)}
        >
            <img
                className={(selectedButton===3?cssStyles.selectedButton:"")}
                src={RightPaneIcon} width={"30"} height={"20"} alt="Three Panes Icon"
            />
        </IconButton>
        <IconButton
            className={(selectedButton===4?cssStyles.selectedIconButton:cssStyles.iconButton)}
            aria-label="delete"
            size={"small"}
            onClick={()=>buttonClicked(4)}
        >
            <img
                className={(selectedButton===4?cssStyles.selectedButton:"")}
                src={CenterPaneIcon} width={"30"} height={"20"} alt="Three Panes Icon"
            />
        </IconButton>
        <IconButton
            className={(selectedButton===5?cssStyles.selectedIconButton:cssStyles.iconButton)}
            aria-label="delete"
            size={"small"}
            onClick={()=>buttonClicked(5)}
        >
            <img
                className={(selectedButton===4?cssStyles.selectedButton:"")}
                src={FullscreenIcon} width={"30"} height={"20"} alt="Three Panes Icon"
            />
        </IconButton>
    </div>
}
