import cssStyles from "./Intro.module.css"
import {useLocation, useNavigate} from "react-router-dom";
import MainIntro from "./content/MainIntro";
import ModelIntro from "./content/ModelIntro";
import Logger from "../../../utils/Logger";
import {Button} from "@mui/material";

function capitalize(aString) {
    return aString.charAt(0).toUpperCase() + aString.slice(1);
}

const LOGGER = new Logger("Intro")

function HomeButton(props) {
    const navigate = useNavigate();
    return <Button variant="contained" onClick={(e)=>{navigate("/")}}>Home</Button>
}

export default function Intro({contentId}) {

    let zeContentId = contentId
    const location = useLocation()
    if (!zeContentId) {
        zeContentId = capitalize(location.pathname.substring("/intro/".length)) + "Intro"
        LOGGER.debug("pathname=", location.pathname)
    }
    LOGGER.debug("zeContentId=", zeContentId)



    switch (zeContentId) {
        case "MainIntro":
            return <div className={cssStyles.mainDiv}>
                <HomeButton/>
                <MainIntro/>
            </div>
        case "ModelIntro":
            return <div className={cssStyles.mainDiv}>
                <HomeButton/>
                <ModelIntro/>
            </div>
        default:
            LOGGER.warn("Unexistant content requested: ", zeContentId)
          return <div className={cssStyles.mainDiv}>
              <HomeButton/>
              <MainIntro/>
          </div>
    }
}
