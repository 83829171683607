import cssStyles from "./BulletedTextField.module.css";
import { Button, LinearProgress, FormHelperText, InputLabel } from "@mui/material";
import { useRef, useState } from "react";
import Logger from "../../../../utils/Logger";
import {Controller} from "react-hook-form";

const LOGGER = new Logger("BulletedTextField");

export function BulletedTextField({
                                      register,
                                      control,
                                      errors,
                                      id = "description",
                                      bullet = "+",
                                      name = "description",
                                      label = "Description",
                                      minRows = 5,
                                      maxRows = 5,
                                      generator = false
                                  }) {
    const [isGenerating, setIsGenerating] = useState(false);
    const textfieldInputRef = useRef(null);
    const caretPosition = useRef({line: 1, column: 1});

    const handleGenerate = async () => {
        if (generator) {
            LOGGER.debug("generator is enabled");
            setIsGenerating(true);
            setTimeout(() => {
                generator().then(response => {
                    setIsGenerating(false)
                });
            }, 200)
        } else {
            LOGGER.debug("generator is not enabled");
        }
    };

    const getCursorPosition = (event) => {
        const text = event?.target?.value;
        const cursorIndex = event?.target?.selectionStart;

        if (!text || cursorIndex === undefined) {
            LOGGER.debug("No text or cursor index");
            return false;
        }

        // Split the text by lines
        const lines = text.split('\n');
        let cumulativeLength = 0;
        let currentLine = 0;
        let column = 0;

        // Determine the line and column based on the cumulative length
        for (let i = 0; i < lines.length; i++) {
            const lineLength = lines[i].length + 1; // +1 for the newline character
            if (cursorIndex < cumulativeLength + lineLength) {
                currentLine = i + 1; // Line number (1-based)
                column = cursorIndex - cumulativeLength + 1; // Column number (1-based)
                break;
            }
            cumulativeLength += lineLength;
        }

        return ({line: currentLine, column, lines});
    };

    const moveToPosition = (row, column) => {
        if (!textfieldInputRef?.current?.value) {
            LOGGER.debug("No text field value available (anymore?)");
            return;
        }
        const text = textfieldInputRef.current.value;
        const lines = text.split('\n');

        LOGGER.debug("moveToPosition.lines: ", lines);
        LOGGER.debug("row: ", row);
        LOGGER.debug("column: ", column);

        let position = 0;
        for (let i = 0; i < row - 1; i++) {
            position += lines[i].length + 1; // Add 1 for the newline character
        }
        position += column - 1; // Subtract 1 to convert from 1-based to 0-based index

        // Set the caret position
        textfieldInputRef.current.setSelectionRange(position, position);
        //textfieldInputRef.current.setSelectionRange(5, 5);
        textfieldInputRef.current.focus(); // Focus the text field to show the caret
    };

    const handleKeyDown = (event, onChange) => {
        LOGGER.debug("event?.key: ", event?.key);

        if (event?.key === 'Backspace') {
            LOGGER.debug("Backspace key pressed");
            const cp = getCursorPosition(event);
            LOGGER.debug("Cursor position: ", cp);
            if (cp.column <= 3) {
                LOGGER.debug("Cursor position <= 3, joining line with the previous line");

                if (cp.line > 1) {
                    event.preventDefault()
                    const bulletlessLines = cp.lines.map(item => {
                        if (item.length >= bullet.length && item.substring(0, bullet.length) === bullet) {
                            return item.substring(bullet.length).trimStart()
                        }
                        return item.trimStart()
                    })
                    let newLines = []
                    for (let i = 0; i < bulletlessLines.length; i++) {
                        if (i === cp.line - 2) {
                            newLines.push(bulletlessLines[i] + bulletlessLines[i + 1]);
                            i++;
                        } else {
                            newLines.push(bulletlessLines[i]);
                        }
                    }

                    textfieldInputRef.current.value = newLines.join('\n');
                    caretPosition.current = {line: cp.line - 1, column: bulletlessLines[cp.line - 2].length + 3};
                    LOGGER.debug("newLines: ", newLines);
                    onChange(newLines);
                    return false // Prevent default behavior

                } else {
                    event.preventDefault()
                }

                return;
            }
        } else if (event.key === 'Enter') {
            LOGGER.debug("Enter key pressed");
            const cp = getCursorPosition(event);
            LOGGER.debug("Cursor position: ", cp);
            if (cp.column >= 3) {
                event.preventDefault()
                LOGGER.debug("Cursor position >= 3, adding a new line");

                LOGGER.debug("cp.lines: ", cp.lines);
                const bulletlessLines = cp.lines.map(item => {
                    if (item.length >= bullet.length && item.substring(0, bullet.length) === bullet) {
                        return item.substring(bullet.length).trimStart()
                    }
                    return item.trimStart()
                })

                const oldLine = bulletlessLines[cp.line - 1].substring(0, cp.column - 1 - (bullet + " ").length);
                LOGGER.debug("oldLine: ", oldLine);
                const newLine = bulletlessLines[cp.line - 1].substring(cp.column - 1 - (bullet + " ").length);
                LOGGER.debug("newLine: ", newLine);


                const newLines = [...bulletlessLines.slice(0, cp.line - 1), oldLine, newLine, ...bulletlessLines.slice(cp.line)];
                LOGGER.debug("newLines: ", newLines);
                //textfieldInputRef.current.value = newLines.join('\n');
                onChange(newLines);
                LOGGER.debug("moving caret to line: ", cp.line + 1);
                LOGGER.debug("moving caret to column: ", 1);
                caretPosition.current = {line: cp.line + 1, column: 3};
                return false; // Prevent default behavior
            }
        } else {
            caretPosition.current = false
        }
    }

    const handleChange = (event, onChange) => {

        LOGGER.debug("handleChange called");

        const value = event.target.value;
        const lines = value?.split('\n').map(item => {
            LOGGER.debug("item: ", item);
            if (item.length >= bullet.length && item.substring(0, bullet.length) !== bullet) {
                return item.trimStart()
            }
            return item.trimStart().substring((bullet + " ").length).trimStart()
        }); // Split the string into an array on new lines

        LOGGER.debug("handleChange.lines: ", lines);

        onChange(lines);

    };




    const textAreaFunction = (onChange, value) => {
        const textArea = <textarea
            ref={textfieldInputRef}
            id={id}
            name={name}
            className={cssStyles.bulletedTextField}
            style={{"--bullet-char": `${bullet}`}} // Pass the bullet character to CSS
            rows={minRows}
            onChange={(event) => handleChange(event, onChange)}
            onKeyDown={(event) => handleKeyDown(event, onChange)}
            value={value?.map(item => `${bullet} ${item}`).join('\n') || ""}
        />
        //this is a dirty trick to move the caret to the correct position after the text is updated
        //we have to wait for the text to be updated before moving the caret
        setTimeout(() => {
            LOGGER.debug("setTimeout.moving caret to: ", caretPosition.current);
            if (caretPosition.current) {
                moveToPosition(caretPosition.current.line, caretPosition.current.column);
            }
        }, 10)
        return textArea;
    }

    return <Controller
        name={name}
        control={control}
        render={({field: {onChange, value}}) => {
            LOGGER.debug("render.value for field '" + name + "':", value)
            //setTextArray(value);
            return (
                <>
                    <InputLabel shrink htmlFor={id}>
                        {label}
                    </InputLabel>
                    {textAreaFunction(onChange, value)}
                    {errors?.[id] && <FormHelperText>{errors?.[id].message}</FormHelperText>}
                    {isGenerating && <span className={cssStyles.progressSpan}><LinearProgress/></span>}
                    {generator && !isGenerating && (
                        <span className={cssStyles.buttonSpan}>
                            <Button
                                className={cssStyles.button}
                                disabled={isGenerating}
                                onClick={handleGenerate}
                            >
                                {isGenerating ? "Generating..." : "Generate"}
                            </Button>
                        </span>
                    )}
                </>)
        }}
    />
}
