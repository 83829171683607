import * as React from "react";
import Logger from "../../../utils/Logger";
import cssStyles from "./ScenarioDiagram.module.css"
import {useEffect, useState} from "react";
import {Tree, TreeNode} from "react-organizational-chart";
import {Checkbox, FormControlLabel} from "@mui/material";
import {NodeType} from "../../../model/Constants";
import {useSelectedNodes} from "../../SelectedNodes/SelectedNodesProvider";
import {useModel} from "../../../model/ModelContext";
import {Card} from "./Card";
import {NodeLabel} from "./NodeLabel";

const LOGGER = new Logger("ScenarioDiagram")

function buildTree(searchNodes, node, level, showApplications, additionalClassname) {
    LOGGER.trace("buildtree")
    if (!node) {
        LOGGER.trace("buildtree - node is null")
        return null
    }

    if (level > 20) {
        LOGGER.error("buildTree: too many levels!")
        return null
    }

    let newApplicationChildren = []
    let updatedApplicationChildren = []
    let deletedApplicationChildren = []
    if (node?.type === NodeType.Scenario.description) {
        LOGGER.trace("type is Scenario")
        const newApplicationsArray = searchNodes((n) => n.type === NodeType.Application.description &&  node?.newApplicationIds?.includes(n.id))
            .map(c => buildTree(searchNodes, c, level + 1, showApplications, cssStyles.newApplication))
        if (newApplicationsArray.length >= 1) {
            LOGGER.trace("creating newApplicationsArray")
            newApplicationChildren = [{
                id: "label=" + node.id,
                name: <span className={cssStyles.labelNewApplication}>New</span>,
                type: "_label",
                children: newApplicationsArray
            }]
        }
        const updatedApplicationsArray = searchNodes((n) => n.type === NodeType.Application.description && node?.updatedApplicationIds?.includes(n.id))
            .map(c => buildTree(searchNodes, c, level + 1, showApplications, cssStyles.updatedApplication))
        if (updatedApplicationsArray.length >= 1) {
            updatedApplicationChildren = [{
                id: "label=" + node.id,
                name: <span className={cssStyles.labelUpdatedApplication}>Updated</span>,
                type: "_label",
                children: updatedApplicationsArray
            }]
        }
        const deletedApplicationsArray = searchNodes((n) => n.type === NodeType.Application.description && node?.deletedApplicationIds?.includes(n.id))
            .map(c => buildTree(searchNodes, c, level + 1, showApplications, cssStyles.deletedApplication))
        if (deletedApplicationsArray.length >= 1) {
            deletedApplicationChildren = [{
                id: "label=" + node.id,
                name: <span className={cssStyles.labelDeletedApplication}>Deleted</span>,
                type: "_label",
                children: deletedApplicationsArray
            }]
        }

    }

    return {
        id: node.id,
        name: <NodeLabel node={node}/>,
        type: node.type,
        children: [...newApplicationChildren, ...updatedApplicationChildren, ...deletedApplicationChildren],
        level: level,
        additionalClassname: additionalClassname
    }
}

function buildTreeNode(node, handleNodeClick, selectedNodes) {
    if (!node) {
        return <></>
    }
    return <TreeNode
                label={<Card
                            additionalClassname={node.additionalClassname}
                            selectedNodes={selectedNodes}
                            node={node}
                            level={node?.level}
                            handleNodeClick={()=>handleNodeClick(node)}
                />}
    >
        {node?.children.map((c)=>buildTreeNode(c, handleNodeClick, selectedNodes))}
    </TreeNode>
}

export default function ScenarioDiagram() {

    const [node, setNode] = useState(null)
    const [tree, setTree] = useState(null)


    const {searchNodes, getNodeById, updatedNode} = useModel()
    const {nodeBeingEdited, selectedNodes, setSoftSelectedNodeById} = useSelectedNodes()

    useEffect(() => {
        setTree(buildTree(searchNodes, node, 0, true))
    }, [node, searchNodes]);

    //this useEffect is needed to initially seed the node
    // the following is only triggered when the selected node has changed (that's not on initial redner!)
    // eslint-disable-next-line
    useEffect(() => {
        if (selectedNodes?.length > 0) {
            setNode(selectedNodes[0])
        }
    });

    useEffect(() => {
        if (selectedNodes?.length > 0) {
            setNode(selectedNodes[0])
        }
    }, [selectedNodes]);

    useEffect(() => {
        if (nodeBeingEdited) {
            const nodeToEdit = getNodeById(nodeBeingEdited)
            setNode(nodeToEdit)
        }
        // eslint-disable-next-line
    }, [nodeBeingEdited]);

    useEffect(() => {
        if (updatedNode) {
            /*
             * The node being displayed has been updated (potentially) by another component, let's update it here
             */
            LOGGER.debug("updatedNode: ", updatedNode)
            setNode(updatedNode)
        }
    }, [updatedNode]);

    function handleNodeClick(node) {
        LOGGER.debug("handleNodeClick:", node)
        setSoftSelectedNodeById(node.id)
    }

    const nodeId = node?.id
    const nodeName = node?.name
    return (
        <div key={'cd-'+nodeId} className={[cssStyles.main, ].join(" ")}>
            {nodeName}
            <hr/>
            <Tree label={<div>Scenario</div>}>
                {buildTreeNode(tree, handleNodeClick, selectedNodes)}
            </Tree>
        </div>);
}
