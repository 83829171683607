import {
    containsPoint,
    findElementsWithAttribute, getCustomLaneProp,
    setCustomLaneProp,
    setCustomProp
} from "../utils/JointjsUtils";
import Logger from "../../../../utils/Logger";
import * as joint from "@joint/plus"
import {NodeType} from "../../../../model/Constants";
import {truncateString} from "../../../../utils/StringUtils";

const LANE_HEIGHT = 300
const LANE_WIDTH = 500

const LOGGER = new Logger("BusinessProcessDiagramUtils")

export function getLaneNodeId(lane) {
    LOGGER.debug("lane: ", lane)
    const theData = lane?.get('data')
    LOGGER.debug("theData: ", theData)
    return theData?.nodeId
}

/*
function getLaneIdForNodeId(headeredPool, nodeId) {
    let lanes = headeredPool.prop('lanes') || []
    let theOne = lanes.map((lane, index)=>{
        return {
            ...lane,
            id: "lane_" + index
        }
    }).find(lane => {
        const laneNodeId = getCustomLaneProp(lane, "nodeId")
        return laneNodeId === nodeId && laneNodeId !== undefined
    })
    if (theOne) {
        return theOne.id
    }
    return false
}
 */

function getLaneIndexForNodeId(headeredPool, nodeId) {
    let lanes = headeredPool.prop('lanes') || []
    let theOne = lanes.map((lane, index)=>{
        return {
            ...lane,
            index
        }
    }).find(lane => {
        const laneNodeId = getCustomLaneProp(lane, "nodeId")
        return laneNodeId === nodeId && laneNodeId !== undefined
    })
    if (theOne) {
        return theOne.index
    }
    return false
}

export function renameLane(paper, graph, businessProcessNode, actorNodeId, newName) {
    const headeredPool = getHeaderedPool(paper, graph, businessProcessNode)

        //headeredPool.attr(`lanes/${actorNodeId}/label`, newName)

        paper.freeze()

        const laneIndex = getLaneIndexForNodeId(headeredPool, actorNodeId)
        LOGGER.debug("laneIndex: ", laneIndex)
        if (laneIndex !== false) {
            const lanes = headeredPool.get('lanes')
            const newLanes = lanes.map((lane, index) => {
                const newLane = {...lane}
                if (laneIndex === index) {
                    //this updates the name in the model
                    newLane.label = newName
                }
                return newLane
            })
            headeredPool.set('lanes', newLanes)
        }

        paper.updateViews()
        paper.unfreeze()


}

export function removeLane(paper, graph, businessProcessNode, actorNodeId) {
    LOGGER.debug("entered removeLane with id ", actorNodeId)
    const headeredPool = getHeaderedPool(paper, graph, businessProcessNode)

    paper.freeze()

    const laneIndex = getLaneIndexForNodeId(headeredPool, actorNodeId)
    LOGGER.debug("laneIndex: ", laneIndex)
    if (laneIndex !== false) {
        const lanes = headeredPool.get('lanes')
        const newLanes = lanes.filter((lane, index) => {
            return laneIndex !== index
        })
        headeredPool.set('lanes', newLanes)
    } else {
        return false
    }

    paper.updateViews()
    paper.unfreeze()

    return true
}

export function getLaneGroupAt(paper, graph, businessProcessNode, clientPosition) {
    const existingHeaderedPools = findElementsWithAttribute(graph, "nodeId", businessProcessNode?.id)

    if (existingHeaderedPools.length === 0) {
        return false
    }

    const existingHeaderedPool = existingHeaderedPools[0]

    var laneGroups = existingHeaderedPool?.attributes?.markup?.filter(function(markup) {
        if (Array.isArray(markup?.groupSelector)) {
            return markup?.groupSelector.includes('laneGroups')
        }
        return false
    });

    for (let i = 0; i < laneGroups.length; i++) {
        const laneGroup = laneGroups[i];
        const laneGroupId = laneGroup.attributes.laneGroupId
        const laneIndex = laneGroupId.substring("lanes_".length)
        const svgElement = paper.el.querySelector(`[joint-selector=${laneGroup.selector}]`)
        const bbox = svgElement.getBoundingClientRect()
        LOGGER.debug("clientPosition in getLaneGroupAt: ", clientPosition)
        if (containsPoint(bbox, {x:clientPosition.x, y:clientPosition.y})) {
            return {index: laneIndex, group:laneGroup}
        }
    }
    return false
}

export function getLaneAt(paper, graph, businessProcessNode, clientOffset) {

    const existingHeaderedPools = findElementsWithAttribute(graph, "nodeId", businessProcessNode?.id)

    if (existingHeaderedPools.length === 0) {
        return false
    }

    const existingHeaderedPool = existingHeaderedPools[0]

    const result = getLaneGroupAt(paper, graph, businessProcessNode, clientOffset)
    if (!result) {
        return false
    }

    const {index, group} = result

    const lane = existingHeaderedPool?.get("lanes")[index]
    const svgElement = paper.el.querySelector(`[joint-selector=${group.selector}]`)
    const bbox = svgElement.getBoundingClientRect()
    if (containsPoint(bbox, {x:clientOffset.x, y:clientOffset.y})) {
        var lanes = existingHeaderedPool?.get("lanes")
        let laneObject = lanes.find(aLane => {
            const laneNodeId = getCustomLaneProp(lane, "nodeId")
            const aLaneNodeId = getCustomLaneProp(aLane, "nodeId")
            return aLaneNodeId && aLaneNodeId === laneNodeId
        })
        LOGGER.debug("laneObject: ", laneObject)
        return laneObject;
    }


    return false
}

function moveLane(paper, graph, businessProcessNode, laneId, setNewMessage, direction) {

    LOGGER.debug("moveLane with id ", laneId)
    if (!laneId) {
        LOGGER.error("laneId is missing.")
        return
    }

    if (direction !== "down" && direction !== "up") {
        LOGGER.error("Invalid direction: ", direction)
        setNewMessage("Invalid direction.", "alert")
        return
    }

    const headeredPool = getHeaderedPool(paper, graph, businessProcessNode)
    if (!headeredPool) {
        LOGGER.debug("headeredPool not found.")
        return
    }
    const lanes = headeredPool.get('lanes')

    const index = lanes.findIndex(lane => {
        const laneNodeId = getCustomLaneProp(lane, "nodeId")
        return laneNodeId === laneId
    });

    LOGGER.debug("lane index: ", index)

    // If the object is found and it's not the first element
    if (direction === "up" && index > 0) {
        LOGGER.debug("Lane is not at the top. Swapping with the lane above.")
        // Swap the object with the one to its left
        const leftLane = lanes[index - 1]
        const rightLane = lanes[index]
        const newLanes = lanes.map((lane, i) => {
            if (i === index - 1) {
                return rightLane
            } else if (i === index) {
                return leftLane
            }
            return lane
        })
        LOGGER.debug("newLanes: ", newLanes)
        headeredPool.set('lanes', newLanes)
    } else if (direction === "down" && 0 <= index && index < lanes.length - 1) {
        LOGGER.debug("Lane is not at the bottom. Swapping with the lane beneath.")
        // Swap the object with the one to its left
        const leftLane = lanes[index]
        const rightLane = lanes[index  + 1]
        const newLanes = lanes.map((lane, i) => {
            if (i === index) {
                return rightLane
            } else if (i === index + 1) {
                return leftLane
            }
            return lane
        })
        LOGGER.debug("newLanes: ", newLanes)
        headeredPool.set('lanes', newLanes)
    } else {
        if (direction === "up") {
            LOGGER.debug("Lane is already at the top.")
            setNewMessage("Lane is already at the top.", "info")
        } else {
            LOGGER.debug("Lane is already at the bottom.")
            setNewMessage("Lane is already at the bottom.", "info")
        }
    }
}

export function pullLaneUp(paper, graph, businessProcessNode, laneId, setNewMessage) {
    moveLane(paper, graph, businessProcessNode, laneId, setNewMessage, "up")
}

export function pushLaneDown(paper, graph, businessProcessNode, laneId, setNewMessage) {
    moveLane(paper, graph, businessProcessNode, laneId, setNewMessage, "down")
}

export function getHeaderedPool(paper, graph, businessProcessNode) {
    const existingHeaderedPools = findElementsWithAttribute(graph, "nodeId", businessProcessNode?.id)
    if (existingHeaderedPools.length > 0) {
        LOGGER.debug("existingHeaderedPool found: ", existingHeaderedPools[0])
        return existingHeaderedPools[0]
    }
    return false
}

export function getOrCreateHeaderedPool(paper, graph, businessProcessNode, clientOffset, setNewMessage) {
    LOGGER.debug("getHeaderedPool with name ", businessProcessNode?.name)

    const existingHeaderedPool = getHeaderedPool(paper, graph, businessProcessNode)
    if (existingHeaderedPool) {
        LOGGER.debug("existingHeaderedPool found: ", existingHeaderedPool)
        setNewMessage("Business Process already exists.")
        return existingHeaderedPool
    }

    let paperOffset = paper.clientToLocalPoint(clientOffset.x, clientOffset.y);
    let bpmn2 = joint.shapes.bpmn2;
    let headeredPool = new bpmn2.HeaderedPool({
        position: { x: paperOffset.x, y: paperOffset.y },
        size: { width: LANE_WIDTH, height: LANE_HEIGHT },
        attrs: {
            'header': {
                fill: '#ea796a',
                stroke: 'none'
            },
            'headerLabel': {
                text: businessProcessNode?.name,
                fill: '#ffffff',
                fontSize: 14,
                fontWeight: 'bold'
            },
            'body': {
                fill: '#ffffff',
                stroke: '#87CEEB',
                strokeWidth: 2
            },
            laneHeaders: {
                fill: '#a41cda'
            },
            'laneLabels': {
                fontSize: 13,
                stroke: "#FFFFFF"
            },
            'milestoneLabels': {
                fontSize: 13,
                fill: '#333333'
            }
        },
        lanes: [],
    });
    setCustomProp(headeredPool, "nodeId", businessProcessNode?.id)
    graph.addCell(headeredPool);
    return headeredPool
}

export function addActorLane(paper, graph, businessProcessNode, actorNode, clientOffset,   setNewMessage) {
    LOGGER.debug("addActorLane with name ", actorNode?.name)
    LOGGER.debug("addActorLane with id ", actorNode?.id)

    const headeredPool = getOrCreateHeaderedPool(paper, graph, businessProcessNode, clientOffset, setNewMessage)
    const lanes = headeredPool.get('lanes')
    const newLane = {
        label: actorNode?.name,
    }
    setCustomLaneProp(newLane, "nodeId", actorNode?.id)


    if (lanes?.length > 0) {
        if (!lanes.find(lane => {

            const laneNodeId = getCustomLaneProp(lane, "nodeId")
            const newLaneNodeId = getCustomLaneProp(newLane, "nodeId")

            return laneNodeId === newLaneNodeId && laneNodeId !== undefined
        })) {

            const laneGroupId = `lanes_${lanes.length}`
            const path = headeredPool.getLanePath(laneGroupId) + '/label';
            headeredPool.prop(path, 'new label', { rewrite: true });

            const size = headeredPool.size()
            headeredPool.resize(size.width, size.height + LANE_HEIGHT)
            headeredPool.set('lanes', [...lanes, newLane])

        } else {
            setNewMessage("A Lane for the actor already exists.", "alert")
        }
    } else {
        headeredPool.set('lanes', [newLane])

    }
}

function buildActivityRectangleLabel(activityName, applicationName, actorName) {
    return truncateString(activityName || "-", 25) + "\n"
        + truncateString(applicationName || "-", 25) + "\n"
        + truncateString(actorName || "-", 25) + ""
}

export function getActivityIconType(applicationName, actorName) {
    if (actorName) {
        if (applicationName) {
            return 'user'
        } else {
            return 'manual'
        }
    } else {
        if (applicationName) {
            return 'service'
        }
    }
    return 'none'
}

export function updateActivityRectangle(graph, paper, activity, applicationName, actorName) {
    findElementsWithAttribute(graph, "nodeId", activity?.id).forEach(cell => {
        cell.attr('label/text', buildActivityRectangleLabel(activity?.name, applicationName, actorName))
        cell.attr('icon/iconType', getActivityIconType(applicationName, actorName))
    })
}

export function addActivityRectangle(
    paper, graph,
    businessProcessNode,
    actorActivityNode,
    clientOffset,
    getNodeById
) {
    let applicationNode = getNodeById(actorActivityNode?.applicationId)
    let actorNode = getNodeById(actorActivityNode?.actorId)

    const applicationName = applicationNode?.name
    const actorName = actorNode?.name

    LOGGER.debug("addActivityRectangle with name ", applicationName)
    if (!actorActivityNode?.id) {
        LOGGER.error("actorActivityNode.id is missing")
        return
    }
    const lane = getLaneAt(paper, graph, businessProcessNode, clientOffset)
    if (lane) {
        LOGGER.debug("lane found: ", lane)
    }
    LOGGER.debug("clientOffset: ", clientOffset)
    const paperOffset = paper.clientToLocalPoint(clientOffset);
    LOGGER.debug("paperOffset: ", paperOffset)
    let bpmn2 = joint.shapes.bpmn2;

    let activity = new bpmn2.Activity({
        position: { x: paperOffset.x, y: paperOffset.y},
        size: { width: 168, height: 96 },
        attrs: {
            'background': { fill: '#87CEEB' },
            'icon': { iconType: getActivityIconType(applicationName, actorName) },
            'label': {
                text:buildActivityRectangleLabel(actorActivityNode?.name, applicationName, actorName),
                'font-size': 10,
                'font-weight': 'bold',
                'fill': '#000000',
                textWrap: {
                    width: -10, // Ensures text fits within the element padding
                    height: "80%",
                    //maxLines: 4, // Wrap text after this many lines
                    ellipsis: true, // Shorten the text if it exceeds the height
                },
            },
            'markers': {
                iconTypes: ['none'],
                iconsFlow: 'column',
                iconColor: '#ffffff'
            }
        }
    });
    setCustomProp(activity, "nodeId", actorActivityNode?.id)
    setCustomProp(activity, "type", NodeType.ActorActivity.description)
    setCustomProp(activity, "object", actorActivityNode)
    graph.addCell(activity);

    return activity
}
