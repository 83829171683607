import {createElement, useEffect} from "react"
import cssStyles from "./PricingTable.module.css"
import Logger from "../../../utils/Logger";

const LOGGER = new Logger("PricingTable")

const PRICING_TABLE_ID = "prctbl_1Njo6wJV0Qu70vkuKR579xv0"
const PUBLISHABLE_KEY = "pk_test_51NjkQDJV0Qu70vku0zMsuvYiPfqrMrtDVFuFLr2BnGMdwZxSkGyDKNsBTm8mati34rMOHzQWGTyYDQDzaI71O1zs00TvLynX9E"

export default function PricingTable({user}) {
    LOGGER.debug("PRICING_TABLE_ID:", PRICING_TABLE_ID)
    LOGGER.debug("PUBLISHABLE_KEY:", PUBLISHABLE_KEY)

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    let props = {
        "class": "stripe-pricing-table",
        "pricing-table-id": PRICING_TABLE_ID,
        "publishable-key": PUBLISHABLE_KEY,
    }
    if (user?.id) {
        props["client-reference-id"] = user?.id
    }
    LOGGER.debug("initializing stripe-pricing-table with props:", props)
    return <div className={cssStyles.main}>
        {createElement(
            "stripe-pricing-table",
            props
        )}
    </div> ;
}
