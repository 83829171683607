import cssStyles from "./TileMenu.module.css"
import {Grid, Paper} from "@mui/material";
import {Link} from "react-router-dom";
import {addAuthorizationHeader} from "../../utils/Api";
import Logger from "../../utils/Logger";
import {ProIconIndicator} from "../ProIconIndicator";

const LOGGER = new Logger("TileMenu")

function DownloadLink({title, filename, description, url, user, onClick}) {

    const onClickHandler = function (e) {
        e.preventDefault()
        if (onClick) {
            LOGGER.debug("an onClick was given")
            onClick(e)
            return
        }
        fetch(url, user && {
            // only attach headers if user is logged in, but still make the request regardless
            headers: addAuthorizationHeader({}, user)
        })
            .then(response=>{
                LOGGER.debug("response:", response)
                return response.blob()
            })
            .then(blobby => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blobby);
                link.download = filename;
                document.body.appendChild(link);
                link.click();
            })
            .catch((e)=>{
                console.error("Error fetching export file: ", e)
            })
    }
    return <>
        <a className={cssStyles.link} target="_self" href={url} onClick={(e)=>onClickHandler(e)}>{title}</a>
        <a className={cssStyles.linkDescription} target="_self" href={url} onClick={(e)=>onClickHandler(e)}>{description}</a>
    </>
}

function buildLinks(user, topic) {
    if (topic.download) {
        if (topic.doNotGenerateLinks) {
            return <>
                <div className={cssStyles.proIndicator}><ProIconIndicator isPro={topic.isPro}/></div>
                <div className={cssStyles.link}>{topic.title}</div>
                <div className={cssStyles.linkDescription}>{topic.description}</div>
            </>
        }
        return <DownloadLink filename={topic.filename} title={topic.title} description={topic.description} user={user} url={topic.link} onClick={topic.onClick}/>
    } else {
        return <>
            <div className={cssStyles.proIndicator}><ProIconIndicator isPro={topic.isPro}/></div>
            <Link className={cssStyles.link} to={topic.link}>{topic.title}</Link>
            <Link className={cssStyles.linkDescription} to={topic.link}>{topic.description}</Link>
        </>
    }
}

export default function TileMenu({user, topics}) {

    const spacing = 2

    return (<div className={cssStyles.main}>
        <Grid sx={{flexGrow: 1}} container spacing={2}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={spacing}>
                    {topics.map((topic) => (
                        <Grid key={topic.title} item>
                            <Paper
                                className={cssStyles.paper}
                                sx={{
                                    height: 140,
                                    width: 300,
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                }}
                            >
                                {buildLinks(user, topic)}
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    </div>)
}
