import cssStyles from './RecoverPasswordPage.module.css';
import Logger from "../../../utils/Logger";
import RecoverPasswordComponent from "./RecoverPasswordComponent";


const LOGGER = new Logger("RecoverPasswordPage")

export default function RecoverPasswordPage() {
    LOGGER.debug("Enter - RecoverPasswordPage")
    return <div className={cssStyles.mainDiv}>
        <div className={cssStyles.formDiv}>
            <h1>Recover password</h1>
            <RecoverPasswordComponent/>
        </div>
    </div>
}
