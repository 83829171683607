import cssStyles from "./QualityReport.module.css"
import {useEffect, useState} from "react";
import {addAuthorizationHeader} from "../../../utils/Api";
import ReportQualityFragment from "./ReportFragments/ReportQualityFragment";
import ReportMenuBar from "./ReportMenuBar";
import {REPORTS} from "./ReportConstants";

import {NodeType} from "../../../model/Constants";
import ErrorBox from "../../ErrorBox/ErrorBox";
import {Link} from "../QuickTutorial/Link";
import {useSubscriptionContext} from "../../../subscription/SubscriptionContext";

export default function QualityReport({workspaceId, user}) {

    const {subscription} = useSubscriptionContext()

    //const [actorQuality, setActorQuality] = useState("")
    const [applicationQuality, setApplicationQuality] = useState("")
    //const [architectureBuildingBlockQuality, setArchitectureBuildingBlockQuality] = useState("")
    //const [businessProcessQuality, setBusinessProcessQuality] = useState("")
    const [capabilityQuality, setCapabilityQuality] = useState("")
    const [dataExchangeQuality, setDataExchangeQuality] = useState("")
    const [dataFlowQuality, setDataFlowQuality] = useState("")
    const [dataObjectQuality, setDataObjectQuality] = useState("")
    const [designDecisionQuality, setDesignDecisionQuality] = useState("")
    //const [domainQuality, setDomainQuality] = useState("")
    //const [functionalityQuality, setFunctionalityQuality] = useState("")
    const [middlewareQuality, setMiddlewareQuality] = useState("")
    const [principleQuality, setPrincipleQuality] = useState("")
    //const [scenarioQuality, setScenarioQuality] = useState("")
    const [viewQuality, setViewQuality] = useState("")
    const [talQuality, setTalQuality] = useState("")
    const [errorLoading, setErrorLoading] = useState(false)



    useEffect(()=>{
        fetch("/.netlify/functions/report-quality", user && {
            // only attach headers if user is logged in, but still make the request regardless
            headers: addAuthorizationHeader({}, workspaceId, user)
        })
            .then(res =>{
                if (res.status === 200) {
                    return res.json()
                } else {
                    throw new Error("Error fetching report count data")
                }
            })
            .then(data=>{
                console.debug("report quality data:", data)

                let pro = (subscription === "PRO")

                //setActorQuality(data.actorQuality.score)
                setApplicationQuality(data.applicationQuality.score)
                //setArchitectureBuildingBlockQuality(data.architectureBuildingBlockQuality.score)
                //setBusinessProcessQuality(data.businessProcessQuality.score)
                setCapabilityQuality(data.capabilityQuality.score)
                setDataExchangeQuality(data.dataExchangeQuality.score)
                setDataFlowQuality(data.dataFlowQuality.score)
                setDataObjectQuality(data.dataObjectQuality.score)
                if (pro) {
                    setDesignDecisionQuality(data.designDecisionQuality.score)
                }
                //setDomainQuality(data.domainQuality.score)
                //setFunctionalityQuality(data.functionalityQuality.score)
                setMiddlewareQuality(data.middlewareQuality.score)
                if (pro) {
                    setPrincipleQuality(data.principleQuality.score)
                }
                //setScenarioQuality(data.scenarioQuality.score)
                setViewQuality(data.viewQuality.score)
                setTalQuality(data?.talQuality?.score)
            })
            .catch((e)=>{
                console.error("Error fetching capability analysis: ", e)
                setErrorLoading(true)
            })
    }, [workspaceId, user, subscription])

    return <div className={cssStyles.main}>
        <ReportMenuBar
            user={user}
            report={REPORTS.qualityReport}
        />
        {
            errorLoading && <ErrorBox isCloseable={false} id={"qualityreport-errorbox"}>
                Error loading report data. If the error persists, please <Link href={"https://docs.kenchiku.io/support/tickets/new"}>contact support</Link> .
            </ErrorBox>
        }
        <div className={cssStyles.report}>
            {
                !errorLoading && <>

                    <ReportQualityFragment
                        testId={"applications-quality"}
                        label={"applications' quality"}
                        percentage={applicationQuality}
                        nodeTypeName={NodeType.Application.description}
                    />

                    <ReportQualityFragment
                        testId={"capabilities-quality"}
                        label={"capabilities' quality"}
                        percentage={capabilityQuality}
                        nodeTypeName={NodeType.Capability.description}
                    />

                    <ReportQualityFragment
                        testId={"data-exchanges-quality"}
                        label={"data exchanges' quality"}
                        percentage={dataExchangeQuality}
                        nodeTypeName={NodeType.DataExchange.description}
                    />

                    <ReportQualityFragment
                        testId={"data-flows-quality"}
                        label={"data flows' quality"}
                        percentage={dataFlowQuality}
                        nodeTypeName={NodeType.DataFlow.description}
                    />

                    <ReportQualityFragment
                        testId={"data-objects-quality"}
                        label={"data objects' quality"}
                        percentage={dataObjectQuality}
                        nodeTypeName={NodeType.DataObject.description}
                    />

                    { subscription === "PRO" && <ReportQualityFragment
                        testId={"design-decision-quality"}
                        label={"design decision' quality"}
                        percentage={designDecisionQuality}
                        nodeTypeName={NodeType.DesignDecision.description}
                    /> }

                    <ReportQualityFragment
                        testId={"middlewares-quality"}
                        label={"middlewares' quality"}
                        percentage={middlewareQuality}
                        nodeTypeName={NodeType.Middleware.description}
                    />

                    { subscription === "PRO" && <ReportQualityFragment
                        testId={"principles-quality"}
                        label={"principles' quality"}
                        percentage={principleQuality}
                        nodeTypeName={NodeType.Principle.description}
                    /> }

                    <ReportQualityFragment
                        testId={"views-quality"}
                        label={"views' quality"}
                        percentage={viewQuality}
                        nodeTypeName={NodeType.ApplicationView.description}
                    />

                    <ReportQualityFragment
                        testId={"tals-quality"}
                        label={"tals' quality"}
                        percentage={talQuality}
                        nodeTypeName={NodeType.Tal.description}
                    />
                </>
            }
        </div>
    </div>
}
