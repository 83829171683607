export const LOAD_NODES = 'LOAD_NODES';
export const UPDATE_NODE = 'UPDATE_NODE';

export const loadNodes = nodes => ({
    type: LOAD_NODES,
    payload: nodes
});

export const updateNode = node => ({
    type: UPDATE_NODE,
    payload: node
});
