import cssStyles from "./LoadingOverlay.module.css"
import {LinearProgress} from "@mui/material";
import {addClasses} from "../../utils/PresentationUtils";
import {createContext, useContext, useState} from "react";
import Logger from "../../utils/Logger";

const LOGGER = new Logger("LoadingOverlay")

const LoadingOverlayContext = createContext();


export const useLoadingOverlay = () => {
    return useContext(LoadingOverlayContext);
}

export function LoadingOverlay({children}) {

    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [loadingCount, setLoadingCount] = useState(0);
    const [autoHideTimer, setAutoHideTimer] = useState(500);

    const show = (message, callerDefinedAutoHideTimer=500) => {
        LOGGER.debug("showing loading overlay with message: ", message)
        setMessage(message)
        setLoadingCount(loadingCount+1)
        if (message !== "") {
            setIsLoading(true)
        }
        if (callerDefinedAutoHideTimer>0) {
            setAutoHideTimer(callerDefinedAutoHideTimer)
        }
    }

    const hide = () => {
        LOGGER.debug("hiding loading overlay with message: ", message)
        setLoadingCount(Math.max(0, loadingCount-1))
        if (loadingCount<=0) {
            setIsLoading(false)
        }
        setTimeout(()=>{
            setIsLoading(false)
            //setMessage("")
        }, autoHideTimer)
    }

    return <LoadingOverlayContext.Provider value={{show, hide}}>
        <div className={addClasses([cssStyles.main, (isLoading?cssStyles.isLoading:"")])} style={{display: isLoading?"block":"none"}}>
            <div className={cssStyles.innerDiv}>
                <div className={cssStyles.loadingSpinner}>
                    <LinearProgress />
                </div>
                <div className={cssStyles.loadingMessage}>{message}</div>
            </div>
        </div>
        {children}
    </LoadingOverlayContext.Provider>
}
