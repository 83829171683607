import cssStyles from "./DependencyReport.module.css"
import Logger from "../../../utils/Logger";
import {REPORTS} from "./ReportConstants";
import ReportMenuBar from "./ReportMenuBar";
import FullDependenciesGraph from "./FullDependenciesGraph";

const LOGGER = new Logger("DependencyReport")

export default function DependencyReport({workspaceId, user, dataLoader}) {

    LOGGER.debug("entering DependencyReport")

    return (
        <div className={cssStyles.main}>
            <ReportMenuBar
                user={user}
                report={REPORTS.dependencyReport}
            />
            <div className={cssStyles.selectorBar}></div>
            <FullDependenciesGraph workspaceId={workspaceId} user={user} dataLoader={dataLoader}/>
        </div>
    )

}
