// Hook
import {useEffect} from "react";

export default function useOnEscape(handler) {
    useEffect(
        () => {
            const listener = (event) => {
                // if escape typed, call handler
                if(event.key === "Escape") {
                    handler(event);
                }
            };
            document.addEventListener("keydown", listener);
            return () => {
                document.removeEventListener("keydown", listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [handler]
    );
}
