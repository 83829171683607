import {Autocomplete, TextField} from "@mui/material";
import {Controller} from "react-hook-form"

import Logger from "../../../../utils/Logger"
import {buildFullLabel} from "../../../../utils/Labels";
import {useModel} from "../../../../model/ModelContext";

const LOGGER = new Logger("NodeSelector")



export default function NodeSelector({
                 label,
                 nodeType,
                 nodeTypeName,
                 nodeRootFolderId,
                 name,
                 multiSelect,
                 control,
                 error,
                 helperText,
                 defaultValue
    }) {

    const {getNodeById, getNodesByType} = useModel()

    /*
      TODO: display the tree structure in the drop down. Now we don't show any hierarchy. github issue #229
     */

    let options = {}
    let optionIds = getNodesByType(nodeType).sort((n, m)=>{
        return n.name<m.name?-1:1
    }).map((n)=>{
        options[n.id] = n.name
        return n.id

    })

    optionIds.push(nodeRootFolderId)
    options[nodeRootFolderId] = nodeTypeName

    //LOGGER.trace("multiSelect:", multiSelect)
    //LOGGER.trace("capabilityIds:", capabilityIds)
    //LOGGER.trace("capabilities:", capabilities)

    //LOGGER.debug("defaultValue: ", defaultValue)
    const defValue = defaultValue || (multiSelect?[]:nodeRootFolderId)
    //LOGGER.debug("defValue: ", defValue)

    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}})=>{
                LOGGER.debug("render.value for field '" + name + "':", value)
                let theValue = value
                if (!value?.length) {
                    if (multiSelect) {
                        theValue = []
                    } else {
                        theValue = ""
                    }
                }
                return <Autocomplete
                    multiple={multiSelect}
                    autoHighlight
                    autoSelect
                    id={"combo-box-" + nodeType}
                    options={optionIds}
                    getOptionLabel={(option)=>buildFullLabel(getNodeById, option)}
                    renderOption={(props, option) => {
                        //LOGGER.debug("renderOption.option:", option)
                        return (
                            <li {...props} key={option}>
                                {buildFullLabel(getNodeById, option)}
                            </li>
                        );
                    }}
                    renderInput={(params) => {
                        //LOGGER.debug("renderInput.params:", params)
                        return <TextField
                            {...params}
                            key={"tf-" + params.id} {...params}
                            label={label}
                            error={error}
                            helperText={helperText}
                            //don't do this it fucks up the multi select... onChange={onChange}
                        />
                    }}
                    defaultValue={defValue}
                    sx={{width: 500, paddingTop:"15px"}}
                    onChange={(event, values, reason) => {
                        LOGGER.debug("onChange.values:", values)
                        onChange(values)
                    }}
                    value={theValue}
                />
            }}
        />
    );
}
