import TileMenu from "../../TileMenu/TileMenu";
import {useSecurityContext} from "../../../security/SecurityContext";

export default function Home() {

    const {user} = useSecurityContext()

    //console.log("Rendering HOME")

    const topics = [
        {title: "Introduction", description: "Get started with the meta model, importing data, available reports.", link: "/intro"},
        {title: "The Model", description: "Learn all about the objects, their properties and their relationships. How are applications linked to business processes and actors to name a thing.", link: "/intro/model"},
        {title: "Reference Capability Maps", description: "Get to know our reference capability maps and how they can accelerate your daily work as an enterprise architect.", link: "/reference-capability-maps", isPro:true},
        //{title: "Reference Architectures", description: "Get to know our reference architectures and how they can accelerate your daily work as an enterprise architect.", link: "/reference-architectures", isPro:true},
        {title: "Reports", description: "Check out the reports that help you understand the complexity of your architecture.", link: "/reports/main"},
        /*
        {title: "Application Templates", link: "application-templates"},
        {title: "APIs", link: "apis"},
        {title: "Solutions", link: "solutions"},
        {title: "Collaborations", link: "collaborations"},
        {title: "T-Shirt Sizing", link: "t-shirt-sizing"},
         */
    ]

    return <TileMenu user={user} topics={topics}/>
}
