import React, {useEffect, useRef, useState} from "react";
import {
    Tree,
    MultiBackend,
    getBackendOptions,
    DndProvider
} from "@minoru/react-dnd-treeview";
import { CustomNode } from "./CustomNode";
import { CustomDragPreview } from "./CustomDragPreview";
import { Placeholder } from "./Placeholder";
import styles from "./TreeComponent2.module.css";
import {useModel} from "../../../model/ModelContext";
import {getNodeTypeRootFolderId, NodeFolderRootIds} from "../../../model/Constants";
import {useSubscriptionContext} from "../../../subscription/SubscriptionContext";
import {SearchField} from "./SearchField";
import {useSearchContext} from "./hooks/SearchContext";
import {SearchResultList} from "./SearchResultList";
import {useSelectedNodes} from "../../SelectedNodes/SelectedNodesProvider";
import Logger from "../../../utils/Logger";
import {useTreeDndContext} from "../diagrams/TreeDndContext";

const LOGGER = new Logger("TreeComponent2")

function nodesToTreeData(nodes, subscription) {
    if (!nodes) {
        LOGGER.debug("nodesToTreeData: nodes is null or undefined.")
        return []
    }
    let tempTreeData = []

    const nodeIdsToIndexMap = {}
    nodes.forEach((node, index) => {
        nodeIdsToIndexMap[node.id] = index
    })

    const supportedRootNodeIds = [
        NodeFolderRootIds.ActorRootId.description,
        //NodeFolderRootIds.ActorActivityRootId.description,
        NodeFolderRootIds.ApplicationRootId.description,

        NodeFolderRootIds.CapabilityRootId.description,

        NodeFolderRootIds.DataExchangeRootId.description,
        NodeFolderRootIds.DataFlowRootId.description,
        NodeFolderRootIds.DataObjectRootId.description,

        NodeFolderRootIds.MiddlewareRootId.description,
        NodeFolderRootIds.MaturityModelAnalysisRootId.description,

        NodeFolderRootIds.ScenarioRootId.description,

        NodeFolderRootIds.TalRootId.description,
        NodeFolderRootIds.ApplicationViewRootId.description,

    ]
    if (subscription === "PRO") {
        supportedRootNodeIds.push(NodeFolderRootIds.BusinessProcessRootId.description,)
        //supportedRootNodeIds.push(NodeFolderRootIds.ActorActivityRootId.description)
        supportedRootNodeIds.push(NodeFolderRootIds.DesignDecisionRootId.description)
        supportedRootNodeIds.push(NodeFolderRootIds.PrincipleRootId.description)
    }

    const filterNode = (node) => {
        if (!node || !node.id || !node.name || !node.type) {
            return false
        }
        if (node.id.startsWith("_")) {
            return supportedRootNodeIds.includes(node.id)
        }
        return true
    }


    tempTreeData = tempTreeData
        .concat(nodes
            .filter(filterNode).map((node, index) => {

                return {
                    "droppable": true,
                    "id": node.id,
                    "text": node.name,
                    "parent": node.parentId || ((node.id?.startsWith("_")) ? "_zeRoot" : (node.type === "folder" ? "_zeRoot" : getNodeTypeRootFolderId(node.type).description)),
                    "data": {
                        "type": node.type
                    }
                }
            })
        )

    /*
     * Sort the data in such a way that the parent node is always before the child node in the Array.
     * This is to ensure that the parent node is always present before the child node in the tree.
     * Otherwise, react-dnd-treeview will not create the link between the child and its parent.
     * And the child will not be displayed under the parent.
     */
    return tempTreeData.sort((a, b) => {

        if (a.parentId === b.id) {
            return 1
        }

        return a.text.toLowerCase().localeCompare(b.text.toLowerCase())
    })
}

function TreeComponent2() {
    LOGGER.trace("TreeComponent2.render")

    const {selectedNodes, softSelectedNodes} = useSelectedNodes()

    const {nodes, getNodeById, saveNode, updatedNode} =  useModel()

    const [treeData, setTreeData] = useState([]);

    const {subscription} = useSubscriptionContext()

    const [initialOpen, setInitialOpen] = useState([])

    const {setIsDraggingActive, setNodeBeingDragged, dropNode} = useTreeDndContext()

    const openCloseMap = useRef({})

    /*
     * Workaround alert:
     * Strangly enough when I go from "/workspace" to another page and then back to "/workspace"
     * the treeData is empty, but the nodes are not. Also, the first time I go to "/workspace" the treeData is not empty.
     * So, I have to check if the treeData is empty and the nodes are not, then I have to create the treeData from the nodes.
     */
    // eslint-disable-next-line
    useEffect(() => {
        if (treeData?.length === 0 && nodes?.length > 0) {
            LOGGER.debug("treeData is empty, but nodes are not. Creating treeData from nodes.")
            let tempTreeData = nodesToTreeData(nodes, subscription);
            LOGGER.debug("treeData created from nodes. Initializing treeData with tempTreeData.")
            setTreeData(tempTreeData)
        }
    })

    useEffect(() => {
        LOGGER.trace("treeData has changed: ", treeData)
    }, [treeData])

    useEffect(() => {
        LOGGER.trace("Nodes or subscription has changed. Going to create treeData from nodes.")
        let tempTreeData = nodesToTreeData(nodes, subscription);
        LOGGER.trace("treeData created from nodes. Initializing treeData with tempTreeData.")
        setTreeData(tempTreeData)
        LOGGER.trace("treeData has been initialized with tempTreeData: ", tempTreeData)
    }, [nodes, subscription, updatedNode]);

    const handleDrop = (newTree, dragInfo) => {
        setTreeData(newTree)
        const {dragSourceId, dropTargetId} = dragInfo;
        const sourceNode = getNodeById(dragSourceId)
        if (sourceNode.parentId === dropTargetId) {
            //nothing to change
        } else {
            //get the node to make sure the dropTargetId is correct
            const targetNode = getNodeById(dropTargetId)
            if (targetNode) {
                //update the parentId of the sourceNode
                sourceNode.parentId = dropTargetId
                saveNode(sourceNode)
            }
        }


    };

/*
    const handleDragEnd = (node, monitor) => {
        let itemType = monitor?.getItemType();
        LOGGER.debug("itemType:", itemType)
    }
 */

    const {searchText} = useSearchContext()


    useEffect(() => {
        let tempInitialOpen = []
        Object.keys(openCloseMap).forEach((nodeId) => {
            if (openCloseMap[nodeId] === true) {
                tempInitialOpen.push(nodeId)
                const node = getNodeById(nodeId)
                if (node) {
                    let ancestorId = node.parentId
                    while (ancestorId) {
                        const ancestor = getNodeById(ancestorId)
                        if (ancestor) {
                            tempInitialOpen.push(ancestorId)
                            ancestorId = ancestor?.parentId
                        } else {
                            ancestorId = null
                        }
                    }
                }
            }
        })

        selectedNodes.concat(softSelectedNodes).forEach((node) => {
            if (node) {
                tempInitialOpen.push(node.id)
                let ancestorId = node.parentId
                while (ancestorId) {
                    const ancestor = getNodeById(ancestorId)
                    if (ancestor) {
                        tempInitialOpen.push(ancestorId)
                        ancestorId = ancestor?.parentId
                    } else {
                        ancestorId = null
                    }
                }
            }
        })
        LOGGER.debug("initialOpen: ", initialOpen)
        if (tempInitialOpen?.length > 0) {
            setInitialOpen(tempInitialOpen)
        }
        //not including initialOpen in the dependencies array, because that would cause an infinite loop.
        // eslint-disable-next-line
    }, [softSelectedNodes, selectedNodes]);

    return (
            <div className={styles.main}>
                <SearchField/>
                {searchText.length > 0 && <SearchResultList/>}
                {searchText.length === 0 && <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                    <div className={styles.scrollWrapper}>
                        <div className={styles.app}>
                            {<Tree
                                initialOpen={initialOpen}
                                tree={treeData}
                                rootId={"_zeRoot"}
                                sort={false}
                                insertDroppableFirst={false}
                                dropTargetOffset={10}
                                canDrop={(tree, {dragSource, dropTargetId}) => {
                                    if (dragSource?.parent === dropTargetId) {
                                        return true;
                                    }
                                }}
                                render={(node, {depth, isOpen, onToggle, path, hasChild}) => {
                                    openCloseMap[node.id] = isOpen
                                    return <CustomNode
                                        node={node}
                                        depth={depth}
                                        isOpen={isOpen}
                                        onToggle={onToggle}
                                        path={path}
                                        hasChild={hasChild}
                                    />
                                }}
                                dragPreviewRender={(monitorProps) => (
                                    <CustomDragPreview monitorProps={monitorProps}/>
                                )}
                                placeholderRender={(node, {depth}) => (
                                    <Placeholder node={node} depth={depth}/>
                                )}
                                onDrop={handleDrop}
                                canDrag={(node) => {
                                    return (node?.data?.type !== "folder" && !node?.id?.startsWith("_"));
                                }}
                                classes={{
                                    root: styles.treeRoot,
                                    draggingSource: styles.draggingSource,
                                    dropTarget: styles.dropTarget,
                                    placeholder: styles.placeholderContainer
                                }}
                                onDragStart={(node) => {

                                    setNodeBeingDragged(getNodeById(node?.id))
                                    setIsDraggingActive(true)
                                }}
                                onDragEnd={(node, monitor) => {

                                    let itemType = monitor?.getItemType();
                                    LOGGER.debug("itemType:", itemType)
                                    setIsDraggingActive(false)

                                    dropNode(node, monitor)

                                }}

                            />}
                        </div>
                    </div>
                </DndProvider>}
            </div>
    );
}

export default TreeComponent2;
