import {createContext, useContext, useEffect, useState} from "react";
import Logger from "../utils/Logger";
import {useStatusMessage} from "../components/StatusMessenger/StatusMessageProvider";
import {useNavigate} from "react-router-dom";
import GoTrue from 'gotrue-js';
import {useEnvironmentInfo} from "../components/EnvironmentProvider/EnvironmentInfoProvider";
import {addAuthorizationHeader} from "../utils/Api";


const LOGGER = new Logger("SecurityContext")

const SecurityContext = createContext();

export const useSecurityContext = () => {

    return useContext(SecurityContext);
}
export function SecurityContextProvider({children}) {
    LOGGER.debug("SecurityContextProvider() - ENTER")
    const {environmentInfo} = useEnvironmentInfo()
    LOGGER.debug("before defining goTrue")
    const [goTrue, setGoTrue] = useState(null)
    LOGGER.debug("before useEffect")
    useEffect(() => {
        LOGGER.debug("useEffect() - ENTER")
        if (!environmentInfo) {
            LOGGER.debug("environmentInfo is not available yet")
            return
        } else {
            LOGGER.debug("environmentInfo set")
        }
        const newGoTrue = new GoTrue({
            APIUrl: environmentInfo.identityEndPoint,
            audience: '',
            setCookie: true,
        })
        LOGGER.debug("setting GoTrue")
        setGoTrue(newGoTrue)
        LOGGER.debug("goTrue set")
    }, [environmentInfo]);

    const setNewMessage = useStatusMessage()
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [initializing, setInitializing] = useState(false);

    const startInitialization = () => {
        LOGGER.debug("startInitialization() - ENTER")
        if (!goTrue) {
            LOGGER.debug("startInitialization(): goTrue is not available yet")
            setInitializing(false)
            return
        }
        if (user) {
            LOGGER.debug("startInitialization(): user already set")
            setInitializing(false)
            return
        }
        setInitializing(true)
        LOGGER.debug("startInitialization(): getting currentUser")
        const currentUser = goTrue.currentUser()
        LOGGER.debug("startInitialization(): currentUser: ", currentUser)
        if (currentUser) {
            LOGGER.debug("startInitialization(): setting currentUser")
            setUser(currentUser)
        } else {
            LOGGER.debug("startInitialization(): currentUser not set")
        }
        setInitializing(false)
        LOGGER.debug("startInitialization(): done")
    }

    const initiateLogon = () => {
        LOGGER.debug("initiateLogon() - ENTER")
        try {
            navigate("/signin")
        } catch (e) {
            LOGGER.debug("Error navigating to /signin: ", e)
            setNewMessage("Error navigating to /signin, if the problem persists, please contact support.", "alert")
        }
    }

    const signin = async (email, password) => {
        LOGGER.debug("signin() - ENTER")
        try {
            const user = await goTrue.login(email, password, true)
            LOGGER.debug("login response: ", user)
            if (user) {
                LOGGER.debug("login successful, user: ", user)
                setUser(user)
                navigate("/")
            } else {
                LOGGER.debug("login failed")
                setNewMessage("Login failed, please check your credentials and try again.", "alert")
            }
        } catch (errorResponse) {
            LOGGER.error("error logging on: ", errorResponse)
            const errorDescription = errorResponse?.json?.error_description || "unknown error"
            setNewMessage(`Error logging on '${errorDescription}' <br> If the problem persists, please contact <a href="https://docs.kenchiku.io/support/tickets/new">support</a>.`, "alert")
        }
    }

    const signinWithUser = async (user) => {
        LOGGER.debug("signinWithUser() - ENTER")
        if (user) {
            LOGGER.debug("setting user: ", user)
            if (!user.token) {
                LOGGER.debug("user.token not set")
                setNewMessage("Error logging on, if the problem persists, please contact support.", "alert")
                return false
            }
            if (!user.token.access_token) {
                LOGGER.debug("user.token.access_token not set")
                setNewMessage("Error logging on, if the problem persists, please contact support.", "alert")
                return false
            }
            LOGGER.debug("updating user")
            setUser(user)
            localStorage.setItem('gotrue.user', JSON.stringify(user))
            LOGGER.debug("user updated")
        } else {
            LOGGER.debug("user not set")
        }
    }

    const logout = () => {
        LOGGER.debug("logout() - ENTER")
        try {
            goTrue.currentUser().logout()
            setUser(null)
            navigate("/")
            navigate(0)
        } catch (e) {
            LOGGER.error("error logging out: ", e)
            setNewMessage("Error logging out, if the problem persists, please contact support.", "error")
        }
    }

    const requestPasswordRecovery = async (email) => {
        LOGGER.debug("requestPasswordRecovery() - ENTER")
        try {
            let waitCounter = 0
            while (!goTrue) {
                LOGGER.debug("waiting for goTrue to be available")
                await new Promise(resolve => setTimeout(resolve, 1000))
                waitCounter++
                if (waitCounter > 10) {
                    LOGGER.error("goTrue not available after 10 seconds")
                    setNewMessage("Error requesting new password, if the problem persists, please contact support.", "alert")
                    return
                }
            }
            await goTrue.requestPasswordRecovery(email)
            setNewMessage(`A password reset link has been sent to ${email}.`, "info")
            LOGGER.debug("password reset link sent")
        } catch (errorResponse) {
            LOGGER.error("error requesting new password: ", errorResponse)
            const errorDescription = errorResponse?.message || "unknown error"
            setNewMessage(`Error requesting new password '${errorDescription}' <br> If the problem persists, please contact <a href="https://docs.kenchiku.io/support/tickets/new">support</a>.`, "alert")
        }
    }

    const checkRecoveryToken = async (token) => {
        LOGGER.debug("checkRecoveryToken() - ENTER")
        try {
            await goTrue.recover(token)
            navigate("/set-password")
        } catch (errorResponse) {
            LOGGER.error("error checking recovery token: ", errorResponse)
            const errorDescription = errorResponse?.json?.error_description || "unknown error"
            setNewMessage(`Error checking recovery token '${errorDescription}' <br> If the problem persists, please contact <a href="https://docs.kenchiku.io/support/tickets/new">support</a>.`, "alert")
        }
    }

    const setNewPassword = async (password, confirmPassword) => {
        LOGGER.debug("setNewPassword() - ENTER")
        try {
            if (password !== confirmPassword) {
                setNewMessage("passwords don't match!", "alert")
                LOGGER.debug("passwords don't match")
                return
            }
            LOGGER.debug("setting new password")
            LOGGER.debug("for user: ", user)
            fetch("/.netlify/functions/update-password", {
                headers: addAuthorizationHeader({
                    'Content-Type': 'application/json',
                }, null, user),
                method: "PUT",
                body: JSON.stringify({newPassword: password}),
            })
            .then(response => {
                if (response.ok) {
                    if (response.status === 200) {
                        LOGGER.debug("new password set")
                        setNewMessage("New password set, you are being signed in.", "info")
                        navigate("/")
                    } else {
                        setNewMessage("Error setting new password", "alert")
                    }
                } else {
                    LOGGER.error("error setting new password: ", response)
                    setNewMessage(`Error setting new password<br> If the problem persists, please contact <a href="https://docs.kenchiku.io/support/tickets/new">support</a>.`, "alert")
                }
            })
            LOGGER.debug("updating current user's password: ", password)
            const updateResult = await goTrue.currentUser().update({
                password: password,
            });
            LOGGER.debug("updated current user, updateResult:", JSON.stringify(updateResult))
            if (!updateResult) {
                LOGGER.error("error setting new password: ", JSON.stringify(updateResult))
                setNewMessage(`Error setting new password<br> If the problem persists, please contact <a href="https://docs.kenchiku.io/support/tickets/new">support</a>.`, "alert")
                return
            } else {
                LOGGER.debug("new password set")
                setNewMessage("New password set, you are being signed in.", "info")
            }
            LOGGER.debug("new password set")
        } catch (errorResponse) {
            LOGGER.error("error setting new password: ", errorResponse)
            const errorDescription = errorResponse?.json?.error_description || "unknown error"
            setNewMessage(`Error setting new password '${errorDescription}' <br> If the problem persists, please contact <a href="https://docs.kenchiku.io/support/tickets/new">support</a>.`, "alert")
        }
    }

    LOGGER.debug("returning SecurityContext.Provider")
    return <SecurityContext.Provider value={{
        user,
        startInitialization, initializing,
        initiateLogon, signin, signinWithUser, logout,
        requestPasswordRecovery, checkRecoveryToken, setNewPassword
    }}>
        {children}
    </SecurityContext.Provider>
}
