import {useEffect, useState} from "react";
import {NodeType} from "../../../../model/Constants";
import cssStyles from "./DataFlowGrid.module.css";
import {addClasses} from "../../../../utils/PresentationUtils";
import {MaterialReactTable} from "material-react-table";
import * as React from "react";
import Logger from "../../../../utils/Logger";
import {useModel} from "../../../../model/ModelContext";

const LOGGER = new Logger("DataFlowGrid")

export default function ApplicationGrid({applicationNodeIds}) {

    LOGGER.debug("ApplicationGrid created")

    const {searchNodes} = useModel()

    const [columns] = useState([
        {
            id: 'id',
            header: 'ID',
            accessorKey: 'id', //simple accessorKey pointing to flat data
            enableColumnActions: false,

        },
        {
            id: 'name',
            header: 'Name',
            accessorKey: 'name', //simple accessorKey pointing to flat data
            enableColumnActions: false,
        },
        {
            id: 'from',
            header: 'from',
            accessorKey: 'from', //simple accessorKey pointing to flat data
            enableColumnActions: false,
        },
        {
            id: 'to',
            header: 'to',
            accessorKey: 'to', //simple accessorKey pointing to flat data
            enableColumnActions: false,
        },
        {
            id: 'data_objects',
            header: 'data objects',
            accessorKey: 'data_objects', //simple accessorKey pointing to flat data
            enableColumnActions: false,
        },
        {
            id: 'description',
            header: 'Description',
            accessorKey: 'description', //simple accessorKey pointing to flat data
            enableColumnActions: false,
        },
    ])

    const [data, setData] = useState([])
    //recommended flat structure for data, but not required (nested data is fine, but takes more setup in column definitions)
    useEffect(() => {
        async function doAsynchStuff() {
            const tempData = searchNodes((n)=> {
                return n.type === NodeType.Application.description && (applicationNodeIds?.includes(n.id))
            }).map(app=>{
                return {
                    id: app.id,
                    name: app.name,
                    description: app.description,
                }
            })
            setData(tempData)
        }
        doAsynchStuff()
    }, [searchNodes, applicationNodeIds]);

    return <div className={cssStyles.mainDiv}>
        <div className={addClasses(["muiTableDiv", cssStyles.tableDiv])}>
            <MaterialReactTable
                columns={columns}
                data={data}
                enableHiding={true}
                enableRowSelection={false} //enable some features
                enableColumnOrdering={false}
                enableGlobalFilter={false} //turn off a feature
                enablePagination={false}
                enableDensityToggle={true}
                initialState={{ density: 'compact', columnVisibility: { id: false } }}
                getRowId={(originalRow) => originalRow.id}
            />
        </div>
    </div>
}
