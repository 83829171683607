import React, {useEffect} from 'react';
import Logger from "../../utils/Logger";
import {useSubscriptionContext} from "../../subscription/SubscriptionContext";

const LOGGER = new Logger("EmailPrefiller")

const EmailPrefiller = () => {

    const {userProfile} = useSubscriptionContext()

    useEffect(() => {


        // Function to handle the click event
        const handleButtonClick = () => {
            setTimeout(() => {
                console.log('Button clicked! userProfile: ', userProfile);
                const form = document.querySelector('form[data-test="feedback-form"]');
                let emailInput
                if (form) {
                    console.log('Form found:', form);
                    emailInput = form.querySelector('input');
                    if (emailInput) {
                        console.log('First input found:', emailInput);
                        // Perform any additional actions with the first input here
                    } else {
                        console.log('No input found in the form');
                    }
                } else {
                    console.log('Form not found');
                }
                const userEmail = userProfile?.email;
                if (userEmail) {
                    LOGGER.debug('User email available: ', userEmail);
                    if (emailInput) {
                        LOGGER.debug('Setting email input value:', userEmail);
                        emailInput.value = userEmail;
                        //TODO do we really want this? -> emailInput.disabled = true;
                    } else {
                        LOGGER.warn('No email input found');
                    }
                }
            }, 500)
        };

        // Select the button using the data-test attribute
        const button = document.querySelector('[data-test="popup-button"]');

        // Check if the button exists before adding the event listener
        if (button) {
            button.addEventListener('click', handleButtonClick);
        }

        // Clean up the event listener on component unmount
        return () => {
            if (button) {
                button.removeEventListener('click', handleButtonClick);
            }
        };
    }, [userProfile])


    return (
        <div>
        </div>
    );
};

export default EmailPrefiller;
