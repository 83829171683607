import cssStyles from "./PlateauBuilderTool.module.css";
import Logger from "../../../../utils/Logger";
import {useEffect, useState} from "react";
import Xarrow from "react-xarrows";
import {addClasses} from "../../../../utils/PresentationUtils";
import {Checkbox} from "@mui/material";

const LOGGER = new Logger("PlateauBuilderTool", 1)

const SOLUTIONS= [
    ["Integrations", "Partner API eco-system", ["CIAM", "CRM"]],
    ["CIAM & Sign", "Signing / Authentication (CIAM)", ["CIAM"]],
    ["Digitalization", "Advancing mobile channel capabilities (\"close the gap\")", ["Cross-channel services alignment", "Digibank services refactor"]],
    ["Digitalization", "Track & trace", []],
    ["Digitalization", "Self Service customer onboarding", ["New CRM", "Create single customer service"]],
    ["", "ATM Replacement", []],
    ["CRM", "New CRM", ["Customer database rationalization"]],
    ["Digitalization", "Targeted omni channel with specific flows (e.g sign)", ["Cross-channel services alignment", "Digibank services refactor"]],
    ["", "Service Now Optimization", []],
    ["", "Cash Strategy", []],
    ["Agent Front End", "New daily agent front end", ["Baseline CBS to latest version"]],
    ["CRM", "CRM Predictive Module", ["CRM"]],
    ["Customer referential", "Customer Management / Referential", ["Customer database rationalization", "CRM", "Create single customer service"]],
    ["Digitalization", "Cross-channel services alignment", []],
    ["Customer referential", "Customer database rationalization", ["Create single customer service"]],

    ["", "KYC", []],
    ["", "Ligis_collections overlap", []],
    ["", "FSO phase out", ["ServiceNow Optimization"]],
    ["", "Baseline CBS to latest version", ["Customer database rationalization" , "CRM", "CBS integration re-engineering"]],
    ["RPA", "RPA for daily banking", []],
    ["", "Swift outsourcing", ["Optimize payment flow"]],
    ["", "Optimize payment flow", []],
    ["Digitalization", "Rationalize loan origination: consumer", ["Unified scoring & pricing for loans", "Standardize Loan Servicing", "Digibank services refactor"]],
    ["Digitalization", "Rationalize loan origination: professional", ["Unified scoring & pricing for loans", "Standardize Loan Servicing", "Digibank services refactor"]],
    ["", "Unified scoring & pricing for loans", []],
    ["", "CADIS replacement", ["Rationalize loan origination", "Standardize Loan Servicing", "Replace all app in powerbuilder"]],
    ["", "Standardize Loan Servicing", []],
    ["Digitalization", "Self Service loan", ["Standardize Loan Servicing", "Replace all app in powerbuilder", "Digibank services refactor"]],
    ["RPA", "RPA automation for loans", []],
    ["", "End 2 end risk monitoring", []],

    ["", "Crelan Assets Management", ["End 2 end risk monitoring"]],
    ["", "Replace all app in powerbuilder", []],
    ["", "KBC Securities outsource", ["Optimize CBS-CWM integrations"]],
    ["Digitalization", "Expose digital capabilities in channels", ["Optimize CBS-CWM integrations"]],
    ["", "Optimize CBS-CWM integrations", []],
    ["New accounting Architecture", "New accounting system (saas)", ["New architecture for accounting (incl data)", "Setup of Oracle EBS"]],
    ["New accounting Architecture", "New architecture for accounting (incl data)", ["Setup of Oracle EBS"]],
    ["", "New reconciliation (regulatory)", ["Setup of Oracle EBS"]],
    ["New accounting Architecture", "Setup of Oracle EBS", []],
    ["", "P2p to saas", []],
    ["", "Remove CADIS for risk calculation", ["Rationalize loan origination", "Standardize Loan Servicing"]],
    ["", "Liquidity & Risk mgt: simplification (QRM & FIS)", []],
    ["", "Decommission DBRate/ESB", []],
    ["", "Automation case handling", []],
    ["", "Fully automated dataflows", []],

    ["", "Improve doc handling", []],
    ["", "Improve Risk scoring KYC", []],
    ["", "Migration netreveal Saas (inclu. Ucomply)", []],
    ["", "IRA migration", []],
    ["", "remove all adhoc reporting to powerBI", []],
    ["", "Data platform in Azure", []],
    ["", "Create a generative AI framework", []],
    ["", "One enterprise data vault model", ["Data platform in Azure"]],
    ["", "Power BI migration", ["Data platform in Azure"]],
    ["", "Collibra (data mgt)", ["Data platform in Azure"]],

    ["RPA", "RPA framework improvement", []],
    ["Document management", "Document management migration to new DMS", ["Digitalization of physical credit dossier"]],
    ["Document management", "Decommissioning of legacy document mgt (incl. Documentum)", ["Document management migration to new DMS", "Digitalization of physical credit dossier"]],
    ["Document management", "Correct metadata document", ["Document management migration to new DMS", "Digitalization of physical credit dossier"]],
    ["", "Digitalization of physical credit dossier", []],
    ["Document management", "Documentum workflow phaseout", []],
    ["Document management", "Recomatics/easy flow rewrite", []],
    ["", "New testing framework", []],
    ["integration", "Digibank services refactor", ["Domain refactor", "CQRS"]],
    ["", "Create single customer service", ["Domain refactor"]],
    ["", "CBS integration re-engineering", ["Domain refactor"]],
    ["integration", "Domain refactor", []],
    ["integration", "CQRS", []],
    ["", "Batch rework", ["CQRS", "Redhat integration suite"]],
    ["integration", "Redhat integration suite", []],

    //["Customer referential", "Customer database rationalization", []],
    //["Digitalization", "Digibank services refactor", []],
    ["Data ¨Platform", "Data plarform in Azure", []],
    //["Customer referential", "Create single customer service", []],
    ["Security", "CIAM", []],
    ["CRM", "CRM", []],
    ["Invest", "Rationalize loan origination", []],
    //["CBS modularity", "CBS integration re-engineering", []],
    //["", "Standardize loans servicing", []],
    //["", "Unified scoring & pricing for loans", []],
    //["Integration", "Domain refactor", []],
    //["Integration", "CQRS", []],
    ["", "ServiceNow Optimization", []],
    //["", "Replace all app in powerbuilder", []],
    //["", "Optimize CBS-CWM integrations", []],
    //["New archi for accounting", "New architecture for accounting (incl data)", []],
    //["New archi for accounting", "Setup of Oracle EBS", []],
    //["", "Digitalization of physical credit dossier", []],

]

function createPlateaus(solutions, plateauIndex=0) {

    solutions.forEach((s) => {
        if (!s?.internalDependencies) {
            s.internalDependencies = s.dependencies
        }
        return s

    })

    let leafSolutions = solutions.filter((s) => s.internalDependencies.length === 0)
    if (leafSolutions.length === 0) {
        LOGGER.error("No leaf solutions found")
        return []
    }
    let firstPlateau = {
        title: "Plateau #" + (plateauIndex+1),
        solutions: leafSolutions
    }
    let leafSolutionsIds = leafSolutions.map((s) => s.id)
    let branchSolutions = solutions.filter((s) => s.internalDependencies.length > 0).map((s) => {
        return {
            logicalGroup: s.logicalGroup,
            id: s.id,
            dependencies: s.dependencies,
            internalDependencies: s.internalDependencies.filter((d) => {
                return !leafSolutionsIds.includes(d)
            })
        }
    })
    let nextPlateaus = createPlateaus(branchSolutions, plateauIndex+1)
    return [firstPlateau, ...nextPlateaus]
}

export function PlateauBuilderTool() {
    LOGGER.debug("entering PlateauBuilderTool")

    const [message, setMessage] = useState("")

    const [arrows, setArrows] = useState(<></>)

    const [showDeps, setShowDeps] = useState(true)

    const plateaus = createPlateaus(SOLUTIONS.map((s) => {
        return {
            logicalGroup: s[0],
            id: s[1],
            dependencies: s[2],
        }
    }))

    const showDependencyLinks = (solution) => {
        let solutionId=solution.id
        let dependencies=solution.dependencies
        let dependantIds = SOLUTIONS.filter((s) => s[2].includes(solutionId)).map((s) => s[1])
        let tempArrows1 = []
        let tempArrows2 = []
        if (dependencies) {
            tempArrows1 = dependencies?.map((d) => {
                LOGGER.debug(`dependency: ${solutionId} -> ${d}`)
                return <Xarrow key={"dependency/"-solutionId+"/"+d} start={solutionId} end={d}/>
            })
        }
        if (dependantIds) {
            tempArrows2 = dependantIds?.map((d) => {
                LOGGER.debug(`dependant: ${d} -> ${solutionId}`)
                return <Xarrow key={"dependant/" + d+"/"+solutionId} start={d} end={solutionId}/>
            })
        }
        setArrows([...tempArrows1, ...tempArrows2])
    }

    const noDependencies = (solution) => {
        return solution.dependencies.length === 0
    }

    const noDependants = (solution) => {
        return SOLUTIONS.filter((s) => s[2].includes(solution.id)).length === 0
    }


    useEffect(() => {
        const duplicateSolutionIds = SOLUTIONS.map((s) => s[1]).filter((value, index, self) => self.indexOf(value) !== index)
        if (duplicateSolutionIds.length > 0) {
            LOGGER.error("Duplicate solution ids found: ", duplicateSolutionIds)
            setMessage("Duplicate solution ids found: " + duplicateSolutionIds.join(", "))
        }
        const unknownDependencies = SOLUTIONS.map((s) => s[2]).flat().filter((value) => !SOLUTIONS.map((s) => s[1]).includes(value))
        if (unknownDependencies.length > 0) {
            LOGGER.error("Unknown dependencies found: ", unknownDependencies)
            setMessage("Unknown dependencies found: " + unknownDependencies.join(", "))
        }
    }, [SOLUTIONS]);

    return <div className={cssStyles.main}>
        <h2>PlateauBuilderTool</h2>
        <div className={cssStyles.message}>{message}</div>
        <div>
            <div>
                <Checkbox
                    key={"checkbox-showDeps"}
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={showDeps}
                    onChange={(e)=>setShowDeps(!showDeps)}
                />
                Show dependencies & dependants?
            </div>
            <div className={cssStyles.plateaus}>
            {plateaus.map((p, i) => {
                return <div key={i} className={cssStyles.plateau}>
                    <h3>{p.title}</h3>
                    <div className={cssStyles.solutions}>
                        {p.solutions.map((s, j) => {
                            LOGGER.trace("Styling solution: ", s)
                            let theStyle = cssStyles.solution
                            if (noDependencies(s)) {
                                LOGGER.trace("1. noDependencies")
                                if (noDependants(s)) {
                                    LOGGER.trace("2. noDependants")
                                    theStyle = addClasses([theStyle, cssStyles.noDeps])
                                } else {
                                    LOGGER.trace("2. hasDependants")
                                    theStyle = addClasses([theStyle, cssStyles.noDependencies])
                                }
                            } else {
                                LOGGER.trace("1. hasDependencies")
                                if (noDependants(s)) {
                                    LOGGER.trace("2. noDependants")
                                    theStyle = addClasses([theStyle, cssStyles.noDependants])
                                } else {
                                    LOGGER.trace("2. hasDependants")
                                    theStyle = addClasses([theStyle, cssStyles.hasDeps])
                                }
                            }

                            return <div key={j}
                                        className={theStyle}>
                                <h4
                                    id={s.id}
                                    data-solution-id={s.id}
                                    className={cssStyles.solutionName}
                                    data-dependencies={s?.dependencies?.join(";")}
                                    onClick={(e) => {
                                        showDependencyLinks(s)
                                    }}
                                > {s.id}</h4>
                                <p>{s.description}</p>
                                {showDeps && <ul>
                                    {s.dependencies.map((a, k) => {
                                        return <li key={k} data-solution-id={a}>{a}</li>
                                    })}
                                </ul>}
                            </div>
                        })}
                    </div>
                </div>
            })}
            </div>
        </div>
        <>{arrows}</>
    </div>
}
