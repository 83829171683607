export function addClasses(classes) {
    if (Array.isArray(classes)) {
        return classes.join(" ")
    } else {
        return classes
    }
}

export function formatDate(date) {
    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    let year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

export function calculatePercentage(numberString, totalString){
    const zeNumber = (typeof numberString === "string"?parseInt(numberString):numberString);
    const zeTotal = (typeof totalString === "string"?parseInt(totalString):totalString);
    let percentage="";
    if(isNaN(zeNumber) || isNaN(zeTotal)){
        percentage=0;
    }else{
        percentage = ((zeNumber/zeTotal) * 100).toFixed(2);
    }

    return percentage
}

export function displayString(str, defaultString="-") {
    return (str?str:defaultString)
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export async function waitForCondition(checkCondition, interval, maxRetries) {
    for (let i = 0; i < maxRetries; i++) {
        const checkResult = checkCondition();
        if (checkResult) {
            console.log('Condition met');
            return checkResult;  // Exit early if the condition is met
        }

        console.log(`Condition not met, retrying (${i + 1}/${maxRetries})...`);
        await sleep(interval);  // Wait for the specified interval before checking again
    }

    console.log('Condition was not met after max retries');
    return false;  // Return false if the condition wasn't met after maxRetries attempts
}
