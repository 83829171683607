import React, { useMemo } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {Card, CardHeader} from "@mui/material";
import cssStyles from "./ReferenceCapabilityMap.module.css";

const ReactGridLayout = WidthProvider(RGL)

export const Container = (props) => {

    const elements = props.elements
    const searchTerm = props.searchTerm
    const layoutOverride = props.layout

    const gridLayout = useMemo(() => {
        let grid = []
        let layout = []

        if (elements.length) {
            for (const child of elements) {
                //console.log("searchTerm:" + searchTerm + ", " + child.text)
                let highlightCapaL1 = ""
                if (searchTerm && searchTerm?.trim().length>0 && child.text?.toLowerCase().indexOf(searchTerm?.toLowerCase())>=0) {
                    highlightCapaL1 = cssStyles.highlightCapaL1
                }
                let tierCss = (child.style?child.style:cssStyles.nontiered)
                //console.log("tierCss:", tierCss)
                grid = [
                    ...grid,
                    <Card
                        key={child.id}
                        className={[child.draggable?"":"no-drag", highlightCapaL1, tierCss].join(" ")}
                    >
                        <CardHeader className={cssStyles.cardHeader} subheader={<span className={cssStyles.cardHeader}>{child.text}</span>} />
                        <Container
                            elements={child.children}
                            searchTerm={searchTerm}
                            layout={child?.layout}
                        />
                    </Card>
                ];
                if (!layoutOverride || !Array.isArray(layoutOverride)) {
                    layout = [...layout, {...child.position, i: child.id}]
                }
            }
            if (layoutOverride) {
                if (Array.isArray(layoutOverride)) {
                    layout = layoutOverride
                } else {
                    console.log("non-array layout: ", layoutOverride)
                }
            }
        }
        return {
            grid,
            layout
        };
    }, [elements, layoutOverride, searchTerm]);

    const { layout, grid } = gridLayout;

    return (
        <ReactGridLayout
            layout={layout}
            cols={12}
            draggableCancel=".no-drag"
            rowHeight={48}
            //isDroppable={isDroppable}
            measureBeforeMount={true}
            droppingItem={{ i: "b3", w: 12, h: 1 }}
            autoSize={true}
            preventCollision={true}
            compactType={"vertical"}
            onLayoutChange={(layout)=>{
                console.log("layout:", layout)
            }}
        >
            {grid}
        </ReactGridLayout>
    );
};
