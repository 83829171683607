import cssStyles from "./AccountMenuBar.module.css";
import {Link} from "react-router-dom";
import {ACCOUNT_PANES, getAccountPaneNames} from "./AccountConstants";


export default function AccountMenuBar({user, pane}) {

    return (<>
        <div className={cssStyles.main}>

            {getAccountPaneNames().map((name) => {
                let topic = ACCOUNT_PANES[name]
                let isSelected = (pane === name)
                return <div
                    key={"linkButton-" + topic.title}
                    className={(isSelected?cssStyles.selectedLinkButton:cssStyles.linkButton)}><Link
                    to={topic.link}>{topic.title}</Link></div>
            })}

        </div>
    </>)

}
