import cssStyles from "./ReportMenuBar.module.css";
import {getReportNames, REPORTS} from "./ReportConstants";
import {Link} from "react-router-dom";


export default function ReportMenuBar({user, report}) {

    return (<>
        <div className={cssStyles.main}>

            {getReportNames().map((name) => {
                let topic = REPORTS[name]
                let isSelected = (report?.name === name)
                return <div
                    key={"linkButton-" + topic.title}
                    className={(isSelected?cssStyles.selectedLinkButton:cssStyles.linkButton)}><Link
                    to={topic.link}>{topic.title}</Link></div>
            })}

        </div>
    </>)

}
