
export function stringToHumanDateString(stringYyyyMmDd) {
    if (!stringYyyyMmDd) {
        return ""
    }
    // Parse the date string to create a Date object
    const dateObject = new Date(stringYyyyMmDd)
    // Define options for formatting the date
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    // Format the date to the desired format
    const formattedDate = dateObject.toLocaleDateString('en-US', options);
    // Return the formatted date string
    return formattedDate; // Output: "May 14, 2024"
}

export function truncateString(str, maxLength) {
    if (str.length > maxLength) {
        return str.slice(0, maxLength - 3) + '...'; // Subtract 3 to account for the ellipsis
    }
    return str;
}
