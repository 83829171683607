import cssStyles from "./ErrorPage.module.css"
import Logger from "../../../utils/Logger";
import InfoBox from "../../InfoBox/InfoBox";
import {useParams} from "react-router";

const LOGGER = new Logger("ErrorPage")

export default function ErrorPage() {

    LOGGER.debug("Enter - ErrorPage")

    const {message}= useParams()


    return <div className={cssStyles.main}>
        <InfoBox isCloseable={false}>
            <div className={cssStyles.content}>
                <div className={cssStyles.message}>
                    {decodeURIComponent(message)}<br/>
                    If the problem persists, please contact <a href={"https://docs.kenchiku.io/support/tickets/new"}>support</a>.
                </div>
            </div>
        </InfoBox>
    </div>
}
