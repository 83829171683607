import cssStyles from "./ReportQualityFragment.module.css";
import {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import {getNodeTypeIcon} from "../../../../model/Constants";

function getQualityRangeColor(qualityPercentage) {
    if (qualityPercentage<0) {
        return "#AAAAAA"
    }
    if (qualityPercentage>0.99) {
        return "#0F0"
    }
    if (qualityPercentage>0.90) {
        return "#1bda1b"
    }
    if (qualityPercentage>0.75) {
        return "#8cc54f"
    }
    if (qualityPercentage>0.50) {
        return "#ffd500"
    }
    if (qualityPercentage>0.35) {
        return "#ffae00"
    }
    return "#F00"
}

export default function ReportQualityFragment({testId, label, percentage, nodeTypeName}) {

    const [isLoading, setIsLoading] = useState(true)

    useEffect(()=>{
        if ( (percentage || percentage === 0) && ("" + percentage).length >0) {
            setIsLoading(false)
        } else {
            setIsLoading(true)
        }
    }, [percentage])


    let zeStyle = {
        backgroundColor: (isLoading?"#AAAAAA":getQualityRangeColor(percentage))
    }

    return (<div className={cssStyles.counterDiv}>
        <div
            className={cssStyles.counterValueDiv}
            style={
                zeStyle
            }
        >
            {nodeTypeName && <span className={cssStyles.iconSpan}>{getNodeTypeIcon(nodeTypeName, "large")}</span>}
            {!isLoading && <span className={cssStyles.counterValue} data-testid={testId}>{Math.round(percentage * 1000) / 10}%</span>}
            {isLoading && <span className={[cssStyles.counterValue, cssStyles.loading].join(" ")}><CircularProgress/></span>}
        </div>
        <span className={cssStyles.counterLabel}>{label}</span>
    </div>)
}
