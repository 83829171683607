//https://rogulski.it/blog/react-material-checkbox-form-validation/
//https://www.youtube.com/watch?v=10Z0OCPfubI

import cssStyles from "./DesignDecisionEditor.module.css"

import {useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";
import NodeSelector from "./selectors/NodeSelector";

import {Controller, useForm} from "react-hook-form"
import {object, string, date, mixed, array} from 'yup';
import {useYupValidationResolver} from "./EditorUtils"

import Logger from "../../../utils/Logger"
import {DATE_FORMAT_DAYJS, NodeFolderRootIds, NodeType} from "../../../model/Constants";
import DesignDecisionStatusSelector from "./selectors/DesignDecisionStatusSelector";
import dayjs from "dayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {MultilineTextField} from "./fields/MultilineTextField";
import TableField from "./fields/TableField";
import {FullscreenButton} from "./buttons/FullscreenButton";

const LOGGER = new Logger("DesignDecisionEditor")

const ID_NAME_DESCRIPTION_SCHEMA = array()
    .of(
        object().shape({
            id: string().required(),
            name: string().required(),
            description: string(),
        })
    )

let DESIGN_DECISION_SCHEMA = object().shape({
    //id: string().required(),
    name: string().required(),
    parentId: string().required().default(NodeFolderRootIds.DesignDecisionRootId.description),
    description: string().required(),
    status: mixed().oneOf(["Proposed", "Accepted", "Rejected", "Deprecated"]).required(),
    decision: string(),
    alternatives: ID_NAME_DESCRIPTION_SCHEMA,
    rationale: string(),
    implications: ID_NAME_DESCRIPTION_SCHEMA,
    tradeOffs: ID_NAME_DESCRIPTION_SCHEMA,
    risksAndMitigations: ID_NAME_DESCRIPTION_SCHEMA,
    assumptions: ID_NAME_DESCRIPTION_SCHEMA,
    personTakingDecision: string(),
    dateTaken: string().default(() => dayjs().format(DATE_FORMAT_DAYJS)) ,
    references: ID_NAME_DESCRIPTION_SCHEMA,
    principleIds: array().of(string()).default([]),
    createdOn: date().default(() => new Date()),
    updatedOn: date().default(() => new Date()),
});

const PARENT_NODE_LABEL = "Parent Design Decision"
const PARENT_NODE_ROOT_ID = NodeFolderRootIds.DesignDecisionRootId.description
const PARENT_NODE_TYPE = NodeType.DesignDecision.description
const PARENT_NODE_TYPE_NAME = "DesignDecision"

async function createObject(
    id,
    name,
    parentId,
    description,
    status,
    decision,
    alternatives,
    rationale,
    implications,
    tradeOffs,
    risksAndMitigations,
    assumptions,
    personTakingDecision,
    dateTaken,
    references,
    principleIds
) {
    LOGGER.trace("createObject.id:", id)
    LOGGER.trace("createObject.name:", name)
    LOGGER.trace("createObject.description:", description)
    LOGGER.trace("createObject.parentId:", parentId)
    const validationObject = await DESIGN_DECISION_SCHEMA.validate({
        id,
        name,
        parentId,
        description,
        status,
        decision,
        alternatives,
        rationale,
        implications,
        tradeOffs,
        risksAndMitigations,
        assumptions,
        personTakingDecision,
        dateTaken,
        references,
        principleIds,
        type: PARENT_NODE_TYPE
    })
    LOGGER.trace("validationObject: ", validationObject)
    const node = await DESIGN_DECISION_SCHEMA.validate(validationObject)
    LOGGER.trace("done validating, returning node:", node)
    return node
}

export default function DesignDecisionEditor({
         isOpen,
         titleOfForm,
         descriptionOfForm,
         node,
         onClose,
         onCancel
}) {

    const [showFullscreen, setShowFullscreen] = useState(false)

    const handleClose = async (data, reason) => {
        LOGGER.debug("data on close:", data)
        LOGGER.debug("reason:", reason)
        if (reason && ["escapeKeyDown", "backdropClick"].includes(reason)) {
            handleCancel()
            return
        }
        let newObject = await createObject(
            data.id,
            data.name,
            data.parentId,
            data.description,
            data.status,
            data.decision,
            data.alternatives,
            data.rationale,
            data.implications,
            data.tradeOffs,
            data.risksAndMitigations,
            data.assumptions,
            data.personTakingDecision,
            data.dateTaken,
            data.references,
            data.principleIds
        )
        LOGGER.debug("newObject:", newObject)
        onClose(newObject)
    };
    const handleCancel = () => {
        onCancel(node?.id)
    };

    const defaultValues = {
        id:node?.id,
        name: "[name]",
        parentId: node?.parentId || NodeFolderRootIds.DesignDecisionRootId.description,
        description: "[description]",
        status: node?.status || "Proposed",
        decision: node?.decision || "[decision]",
        alternatives: node?.alternatives || [],
        rationale: node?.rationale || "[rationale]",
        implications: node?.implications || [],
        tradeOffs: node?.tradeOffs || [],
        risksAndMitigations: node?.risksAndMitigations || [],
        assumptions: node?.assumptions || [],
        personTakingDecision: node?.personTakingDecision || "[Name(s) of decision taker(s)]",
        dateTaken: node?.dateTaken || dayjs().format(DATE_FORMAT_DAYJS),
        references: node?.references || [],
        principleIds: node?.principleIds || []
    }

    const resolver = useYupValidationResolver(DESIGN_DECISION_SCHEMA)
    const { register, handleSubmit, formState: {errors}, setValue, control } = useForm({
        resolver,
        defaultValues
    });

    useEffect(()=>{
        if (node) {
            Object.keys(node).forEach(k => setValue(k, node[k]))
        }
    }, [node, setValue])

    useEffect(()=>{
        LOGGER.debug("errors:", errors)
    }, [errors])

    return (
        <div>
            <Dialog open={isOpen} onClose={handleClose} fullScreen={showFullscreen}>
                <DialogTitle className={cssStyles.dialogTitle}>
                    <span>{titleOfForm}</span>
                    <div className={cssStyles.fullscreenButton}>
                        <FullscreenButton
                            onFullscreen={()=>setShowFullscreen(true)}
                            onExitFullscreen={()=>setShowFullscreen(false)}
                        />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {descriptionOfForm}
                    </DialogContentText>
                    <form
                        data-testid={"design-decision-editor-form"}
                        className={cssStyles.form}
                        onSubmit={handleSubmit(data => handleClose(data))}
                        noValidate
                    >
                        <input
                            type={"hidden"}
                            name={"id"}
                            value={node?.id}
                            {...register("id")}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            name={"name"}
                            label="Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            {...register("name")}
                            error={!!errors?.name}
                            helperText={errors?.name ? errors.name.message : null}
                        />

                        <MultilineTextField
                            id={"description"}
                            name={"description"}
                            label={"Description"}
                            minRows={5}
                            maxRows={5} //to avoid crashes see: https://github.com/patrizz/kenchiku/issues/244
                            register={register}
                            errors={errors}
                        />

                        <MultilineTextField
                            id={"decision"}
                            name={"decision"}
                            label={"Decision"}
                            minRows={5}
                            maxRows={5} //to avoid crashes see: https://github.com/patrizz/kenchiku/issues/244
                            register={register}
                            errors={errors}
                        />

                        <MultilineTextField
                            id={"rationale"}
                            name={"rationale"}
                            label={"Rationale"}
                            minRows={5}
                            maxRows={5} //to avoid crashes see: https://github.com/patrizz/kenchiku/issues/244
                            register={register}
                            errors={errors}
                        />

                        <NodeSelector
                            label={PARENT_NODE_LABEL}
                            nodeType={PARENT_NODE_TYPE}
                            nodeTypeName={PARENT_NODE_TYPE_NAME}
                            nodeRootFolderId={PARENT_NODE_ROOT_ID}
                            multiSelect={false}
                            name={"parentId"}
                            control={control}
                            error={!!errors?.parentId}
                            helperText={errors?.parentId ? errors.parentId.message : null}
                        />
                        <DesignDecisionStatusSelector
                            label={"Status"}
                            name={"status"}
                            control={control}
                            error={!!errors?.status}
                            helperText={errors?.status ? errors.status.message : null}
                        />
                        <TableField
                            id="alternatives"
                            label="Alternatives"
                            mainNode={node}
                            name={"alternatives"}
                            columns={[
                                {columnName: "ID", propertyName: "id", idColumn: true},
                                {columnName: "Name", propertyName: "name"},
                                {columnName: "Description", propertyName: "description", multiline: true},
                                {columnName: "Contributing Factors", propertyName: "contributingFactors", multiline: true, placeholder: "+ pro 1\n+ pro 2\n- con 1"}
                            ]}
                            enableEditing={true}
                            control={control}
                            setValue={setValue}
                            error={!!errors?.alternatives}
                            helperText={errors?.alternatives ? errors.alternatives.message : null}
                        />
                        <TableField
                            id="implications"
                            label="Implications"
                            mainNode={node}
                            name={"implications"}
                            columns={[
                                {columnName: "ID", propertyName: "id", idColumn: true},
                                {columnName: "Name", propertyName: "name"},
                                {columnName: "Description", propertyName: "description"}
                            ]}
                            enableEditing={true}
                            control={control}
                            setValue={setValue}
                            error={!!errors?.implications}
                            helperText={errors?.implications ? errors.implications.message : null}
                        />
                        <TableField
                            id="tradeOffs"
                            label="Trade-offs"
                            mainNode={node}
                            name={"tradeOffs"}
                            columns={[
                                {columnName: "ID", propertyName: "id", idColumn: true},
                                {columnName: "Name", propertyName: "name"},
                                {columnName: "Description", propertyName: "description"}
                            ]}
                            enableEditing={true}
                            control={control}
                            setValue={setValue}
                            error={!!errors?.tradeOffs}
                            helperText={errors?.tradeOffs ? errors.tradeOffs.message : null}
                        />
                        <TableField
                            id="risksAndMitigations"
                            label="Risks And Mitigations"
                            mainNode={node}
                            name={"risksAndMitigations"}
                            columns={[
                                {columnName: "ID", propertyName: "id", idColumn: true},
                                {columnName: "Name", propertyName: "name"},
                                {columnName: "Description", propertyName: "description"}
                            ]}
                            enableEditing={true}
                            control={control}
                            setValue={setValue}
                            error={!!errors?.risksAndMitigations}
                            helperText={errors?.risksAndMitigations ? errors.risksAndMitigations.message : null}
                        />
                        <TableField
                            id="assumptions"
                            label="Assumptions"
                            mainNode={node}
                            name={"assumptions"}
                            columns={[
                                {columnName: "ID", propertyName: "id", idColumn: true},
                                {columnName: "Name", propertyName: "name"},
                                {columnName: "Description", propertyName: "description"}
                            ]}
                            enableEditing={true}
                            control={control}
                            setValue={setValue}
                            error={!!errors?.assumptions}
                            helperText={errors?.assumptions ? errors.assumptions.message : null}
                        />
                        <TextField
                            margin="dense"
                            id="personTakingDecision"
                            name={"personTakingDecision"}
                            label="Person Taking Decision"
                            type="text"
                            fullWidth
                            variant="standard"
                            {...register("personTakingDecision")}
                            error={!!errors?.personTakingDecision}
                            helperText={errors?.personTakingDecision ? errors.personTakingDecision.message : null}
                        />
                        <Controller
                            name="dateTaken"
                            control={control}
                            {...register("dateTaken")}
                            render={({ field }) => (
                                <div className={cssStyles.datePickerWrapper}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Date Taken"
                                            format={DATE_FORMAT_DAYJS}
                                            defaultValue={dayjs()}
                                            value={dayjs(field.value)}
                                            onChange={(newValue) => {
                                                field.onChange(newValue.format(DATE_FORMAT_DAYJS))
                                            }}
                                        />
                                    </LocalizationProvider>
                                </div>
                            )}
                        />
                        <TableField
                            id="references"
                            label="References"
                            mainNode={node}
                            name={"references"}
                            columns={[
                                {columnName: "ID", propertyName: "id", idColumn: true},
                                {columnName: "Name", propertyName: "name"},
                                {columnName: "Description", propertyName: "description"}
                            ]}
                            enableEditing={true}
                            control={control}
                            setValue={setValue}
                            error={!!errors?.references}
                            helperText={errors?.references ? errors.references.message : null}
                        />
                        <NodeSelector
                            label={"Supporting Principles"}
                            nodeType={NodeType.Principle.description}
                            nodeTypeName={"Principle"}
                            nodeRootFolderId={"_principles"}
                            multiSelect={true}
                            name={"principleIds"}
                            control={control}
                            error={!!errors?.principleIds}
                            helperText={errors?.principleIds ? errors.principleIds.message : null}
                        />
                        <NodeSelector
                            label={"Impacted Applications"}
                            nodeType={NodeType.Application.description}
                            nodeTypeName={"Application"}
                            nodeRootFolderId={"_applications"}
                            multiSelect={true}
                            name={"impactedApplicationIds"}
                            control={control}
                            error={!!errors?.impactedApplicationIds}
                            helperText={errors?.impactedApplicationIds ? errors.impactedApplicationIds.message : null}
                        />
                        <NodeSelector
                            label={"Impacted Data Exchanges"}
                            nodeType={NodeType.DataExchange.description}
                            nodeTypeName={"Data Exchange"}
                            nodeRootFolderId={"_data_exchanges"}
                            multiSelect={true}
                            name={"impactedDataExchangeIds"}
                            control={control}
                            error={!!errors?.impactedDataExchangeIds}
                            helperText={errors?.impactedDataExchangeIds ? errors.impactedDataExchangeIds.message : null}
                        />
                        <DialogActions className={cssStyles.actionsDiv}>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={cssStyles.submit}
                            >Save</Button>
                        </DialogActions>

                    </form>
                </DialogContent>

            </Dialog>
        </div>
    );
}
